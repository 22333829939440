<script setup lang="ts">
const props = defineProps({
	class: { type: String, required: true },
});
</script>

<template>
	<div class="px-[34px] rounded-b-[60px]" :class="props.class">
		<slot />
	</div>
</template>

<style scoped></style>
