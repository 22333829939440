export const setSessionVariable = (key: string, value: string): void => {
	try {
		sessionStorage.setItem(key, value);
	} catch (e) {
		console.log('Unable to setItem');
		console.error(e);
	}
};

export const getSessionVariable = (key: string): string | null | undefined => {
	try {
		return sessionStorage.getItem(key);
	} catch (e) {
		console.log('Unable to getItem');
		console.error(e);
	}
};

export const removeSessionVariable = (key: string): void => {
	try {
		sessionStorage.removeItem(key);
	} catch (e) {
		console.log('Unable to removeItem');
		console.error(e);
	}
};

const useSessionVariable = () => {
	return {
		getSessionVariable,
		removeSessionVariable,
		setSessionVariable,
	};
};

export default useSessionVariable;
