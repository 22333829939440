import { modalController } from '@ionic/vue';
import type { DownloadModalProps } from '@/shared/interfaces/download';

import DownloadModal from '@/shared/components/DownloadModal.vue';

const presentDownloadAvailableModal = async (item: DownloadModalProps, didDismissCallback?: () => any) => {
	const modal = await modalController.create({
		component: DownloadModal,
		componentProps: {
			...item,
		},
	});

	modal.onDidDismiss().then(didDismissCallback);
	await modal.present();
};

const useWallet = () => {
	return {
		presentDownloadAvailableModal,
	};
};

export default useWallet;
