import { computed, ref, watch } from 'vue';
import router from '@/router';
import { useQuery } from '@tanstack/vue-query';

import { anykrowdApi } from '@/api/anykrowdApi';
import { Friend, FriendsResponse } from '@/shared/interfaces/friends';

// Get friends api call
const _getFriends = async (params: any = {}): Promise<FriendsResponse> => {
	const { data } = await anykrowdApi.get<FriendsResponse>('/friends/list', true, { params });
	return data;
};

// Get friends
const getFriends = (params: any) => {
	// Init
	const friends = ref<Friend[]>()!;

	const { data: friendsData, isFetching } = useQuery({
		queryKey: ['friends'],
		queryFn: () => _getFriends(params),
		retry: 3,
	});

	// Watch friendsData
	watch(
		friendsData,
		() => {
			if (friendsData.value) {
				friends.value = [...friendsData.value.friends];
			}
		},
		{ immediate: true },
	);

	// Return
	return {
		friends,
		isFetchingFriends: computed(() => isFetching.value),
	};
};

// useFriendsSelect
const useFriendsSelect = (type: string, id: string, additionalInfo: any) => {
	// Handle person selected
	const onPersonSelected = async (friend: Friend) => {
		const friendData = {
			friendFullName: friend.full_name,
			friendEmail: friend.email,
			friendId: friend.id,
		};

		if (type && id) {
			switch (type) {
				case 'ticket': {
					await router.push({
						path: `/events/${additionalInfo.eventId}/tickets/${id}/details`,
						query: {
							...friendData,
							ticketId: id,
						},
					});
					break;
				}
				case 'ticket-creation': {
					await router.push({
						path: `/events/${additionalInfo.eventId}/tickets/addmembers`,
						query: {
							...friendData,
							cartItemIndex: id,
						},
					});
				}
			}
		}
	};

	return {
		getFriends,
		onPersonSelected,
	};
};

export default useFriendsSelect;
