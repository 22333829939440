<script setup lang="ts">
import router from '@/router';
import { IonButton, IonContent, IonIcon } from '@ionic/vue';
import TopBar from '@/shared/components/TopBar.vue';
import TicketDetailItem from '@/modules/tickets/components/TicketDetailItem.vue';
import { chevronBackOutline, downloadOutline, pencil, search, shareSocialOutline } from 'ionicons/icons';

// Go back
const goBack = () => {
	router.back();
};
</script>

<template>
	<ion-content>
		<div class="flex flex-col h-full justify-between">
			<div>
				<TopBar class="px-[34px]">
					<template #left>
						<div class="flex items-center justify-start cursor-pointer" @click="goBack">
							<ion-icon :icon="chevronBackOutline" class="text-white"></ion-icon>
						</div>
					</template>

					<template #center>
						<div class="flex grow items-center justify-center w-full">
							<div class="mr-2">
								<span class="font-sourcesanspro-semibold text-l2">Ticket Details</span>
							</div>
							<div>
								<span class="font-sourcesanspro-regular text-base font-color-text-50">(7/24)</span>
							</div>
						</div>
					</template>

					<template #right>
						<div class="flex grow items-center justify-end cursor-pointer">
							<ion-icon :icon="search" class="mr-2"></ion-icon>
							<ion-icon :icon="pencil"></ion-icon>
						</div>
					</template>
				</TopBar>
			</div>

			<div class="flex pb-4 w-[100%] items-start overflow-scroll">
				<TicketDetailItem class="ml-[34px] mr-4" />
				<TicketDetailItem class="mr-4" />
			</div>

			<div class="flex w-full px-[34px] align-center mb-2">
				<ion-button class="w-full mb-4" expand="full" size="large" shape="round">
					<div class="flex items-center justify-center">
						<ion-icon :icon="downloadOutline" class="text-dark-blue mr-2"></ion-icon>
						<span class="text-m2">Download</span>
					</div>
				</ion-button>
				<ion-button class="w-full" expand="full" size="large" shape="round" fill="outline">
					<div class="flex items-center justify-center text-white">
						<ion-icon :icon="shareSocialOutline" class="mr-2"></ion-icon>
						<span class="text-m2">Share</span>
					</div>
				</ion-button>
			</div>
		</div>
	</ion-content>
</template>

<style lang="scss" scoped></style>
