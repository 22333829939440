import { reactive } from 'vue';
import { defineStore } from 'pinia';

import { Cart } from '@/shared/interfaces/cart';
import { GenericProduct } from '@/shared/interfaces/genericProduct';
import { EventTicketType } from '@/shared/interfaces/eventTicketType';
import { EventComboTicketType } from '@/shared/interfaces/eventComboTicketType';
import { Product } from '@/shared/interfaces/tableSpot';

export const useCartStore = defineStore('cart', {
	state: () => ({
		cart: [] as Cart[],
	}),

	getters: {
		// getTotalPrice: Used in checkout with no category filter
		getTotalPrice(state): number {
			return state.cart.reduce((total, item) => {
				const product = new GenericProduct(item.product);
				const price = product.discount ? product.discountedPrice()! : item.price;
				return total + price * item.quantity;
			}, 0);
		},
		// getCartSize: Used in checkout with no category filter
		getCartSize(state) {
			const totalQuantity = state.cart.reduce((total, item) => total + item.quantity, 0);
			return totalQuantity;
		},
	},
	actions: {
		// Add product to cart
		addToCart(product: GenericProduct, category: string, additionalInfo?: any): void {
			const existingCartItem = this.cart.find((item) => item.product.id === product.id);
			if (!existingCartItem) {
				this.cart.push({
					additionalInfo,
					category,
					quantity: 1,
					price: +product.price,
					product,
				});
			} else {
				this.increaseProductQuantity(product);
			}
		},

		// Add product to cart duplicated
		addToCartDuplicated(product: GenericProduct, category: string, additionalInfo?: any): void {
			this.cart.push({
				additionalInfo,
				category,
				quantity: 1,
				price: +product.price,
				product,
			});
		},

		calculateTotalsPerProductCurrency() {
			const groups = this.cart.reduce(
				(acc, obj) => {
					const key = obj.product.currency?.name ? obj.product.currency?.name : '';
					const curGroup = acc[key] ?? [];
					return { ...acc, [key]: [...curGroup, obj] };
				},
				{} as { [key: string]: Cart[] },
			);

			const totals = Object.keys(groups).map((key: string) => {
				return {
					currencyPrefix:
						groups[key][0].product.currency && groups[key][0].product.currency!.prefix
							? groups[key][0].product.currency!.prefix
							: '',
					currencyName:
						groups[key][0].product.currency && groups[key][0].product.currency!.name
							? groups[key][0].product.currency!.name
							: '',
					total: groups[key].reduce((a, b: Cart) => {
						return a + b.price;
					}, 0),
				};
			});

			return totals.reduce(
				(acc: string, obj: { currencyPrefix: string; currencyName: string; total: number }) => {
					return `${acc} ${obj.currencyPrefix} ${obj.total}`;
				},
				'' as string,
			);
		},

		// clearCart
		clearCart(categoryToKeep?: string) {
			// Keep category if requested
			if (categoryToKeep) {
				this.cart = this.cart.filter((item) => item.category === categoryToKeep);
			} else {
				this.cart = [];
			}
		},

		// Get cart item
		getCartItem(productId: number): Cart | undefined {
			return this.cart.find((item) => item.product.id === productId);
		},

		// Get cart total items
		getCartTotalItemsByCategory(category: string, countPerItem = false): number {
			if (countPerItem) {
				return this.cart.reduce((accumulator, item) => accumulator + item.quantity, 0);
			} else {
				const cartSize = this.cart.filter((item) => item.category === category);
				return cartSize ? cartSize.length : 0;
			}
		},

		getCartTotalPrice(category: string) {
			return this.cart
				.filter((item) => item.category === category)
				.reduce((total, item) => {
					const product = new GenericProduct(item.product);
					const price = product.discount ? product.discountedPrice()! : item.price;
					return total + price * item.quantity;
				}, 0);
		},

		// Calculate the count of a specific product in the cart
		getProductCount(productId: number): number {
			const cartItem = this.cart.find((item) => item.product.id === productId);
			return cartItem ? cartItem.quantity : 0;
		},

		// Calculate the count of a specific product in the cart
		getProductCountWithDuplicates(product: EventTicketType | EventComboTicketType): number {
			const cartItems = this.cart.filter((item) => {
				return item.product.name === product.name && item.product.id === product.id;
			});
			return cartItems ? cartItems.length : 0;
		},

		removeFromCart(product: EventTicketType | EventComboTicketType | Product | GenericProduct) {
			const updatedCart = reactive([...this.cart]);

			// Find the index of the item to remove based on productId
			const index = updatedCart.findIndex(
				(item) => item.product.name === product.name && item.product.id === product.id,
			);

			if (index !== -1) {
				// Remove the item from the copy
				updatedCart.splice(index, 1);

				// Update the cart with the reactive copy
				this.cart = updatedCart;
			}
		},

		// Increase the quantity of a specific product in the cart
		increaseProductQuantity(product: EventTicketType | EventComboTicketType | Product | GenericProduct): void {
			const existingCartItem = this.cart.find(
				(item) => item.product.name === product.name && item.product.id === product.id,
			);
			if (existingCartItem) {
				existingCartItem.quantity++;
			}
		},

		// Check if a product is already in the cart
		isProductInCart(productId: number): boolean {
			return this.cart.some((item) => item.product.id === productId);
		},

		// Decrease the quantity of a specific product in the cart
		decreaseProductQuantity(product: EventTicketType | EventComboTicketType | Product): void {
			const cartItem = this.cart.find(
				(item) => item.product.name === product.name && item.product.id === product.id,
			);
			if (cartItem && cartItem.quantity > 1) {
				cartItem.quantity--;
			} else {
				this.removeFromCart(product);
			}
		},

		// updateCartItemQuantity
		updateCartItemQuantity(product: EventTicketType | EventComboTicketType | Product, quantity: number) {
			// Find the cart item based on productId
			const cartItem = this.cart.find(
				(item) => item.product.name === product.name && item.product.id === product.id,
			);

			if (cartItem) {
				// Ensure the quantity is a positive integer
				const newQuantity = Math.max(0, Math.floor(quantity));

				// Update the cart item's quantity
				cartItem.quantity = newQuantity;

				// If the quantity becomes zero, remove the item from the cart
				if (newQuantity === 0) {
					this.removeFromCart(cartItem.product);
				}
			}
		},

		// updateCartAdditionalInfoByIndex
		updateCartAdditionalInfoByIndex(cartItemIndex: number, additionalInfo: any) {
			// Find the cart item based on cartItemIndex
			const cartItem = this.cart[cartItemIndex];

			if (cartItem) {
				// Update the cart's item
				cartItem.additionalInfo = {
					...cartItem.additionalInfo,
					...additionalInfo,
				};
			}
		},

		// updateCartAdditionalInfoByProductId
		updateCartAdditionalInfoByProductId(productId: number, additionalInfo: any) {
			// Find the cart item based on cartItemIndex
			const cartItem = this.cart.find((item) => item.product.id === productId);

			if (cartItem) {
				// Update the cart's item
				cartItem.additionalInfo = {
					...cartItem.additionalInfo,
					...additionalInfo,
				};
			}
		},
	},
	persist: true,
});
