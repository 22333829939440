import { useAssetsStore } from '@/stores/shared/assetsStore';
import { pinia } from '@/pinia';

export const useStyles = () => {
	const assetStore = useAssetsStore(pinia);

	// setStyles
	const setStyles = (tenantDetails: any) => {
		window.primary_color = tenantDetails.primary_color;
		window.secondary_color = tenantDetails.secondary_color;
		window.tertiary_color = tenantDetails.tertiary_color;
		window.tenantName = tenantDetails.tenant_name;
		window.logoUrl = tenantDetails.logo_url;
		window.backgroundUrl = tenantDetails.bg_image_url;
		window.apiUrl = tenantDetails.api_url;
		window.centralUrl = tenantDetails.central_url;

		// Update the store so that propagates
		assetStore.updateAssetsValues({
			logoUrl: tenantDetails.logo_url,
			backgroundUrl: tenantDetails.bg_image_url,
		});
	};

	return {
		setStyles,
	};
};
