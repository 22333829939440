<script setup lang="ts">
import { onBeforeMount, ref, watch } from 'vue';
import { RouterLink } from 'vue-router';
import { IonContent, IonIcon } from '@ionic/vue';
import { chevronBackOutline, chevronDownOutline, closeOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import { DateTime } from 'luxon';
import CheckboxInput from '@/shared/components/sign-up-forms/CheckboxInput.vue';
import countries from '@/statics/countries.json';
import DateInputMask from '@/shared/components/sign-up-forms/DateInputMask.vue';
import FormStep from '@/shared/components/sign-up-forms/FormStep.vue';
import FormWizard from '@/shared/components/sign-up-forms/FormWizard.vue';
import hexRgb from 'hex-rgb';
import ImageInput from '@/shared/components/sign-up-forms/ImageInput.vue';
import ModalThree from '@/shared/components/ui/ModalThree.vue';
import RadioInput from '@/shared/components/sign-up-forms/RadioInput.vue';
import rgbHex from 'rgb-hex';
import SearchInput from '@/shared/components/sign-up-forms/SearchInput.vue';
import TextInput from '@/shared/components/sign-up-forms/TextInput.vue';
import TopBar from '@/shared/components/TopBar.vue';
import useAuth from '@/modules/auth/composables/useAuth';
import useRegistrationForm from '@/modules/auth/composables/useRegistrationForm';
import useStepChanger from '@/shared/composables/useStepChanger';
import useTenant from '@/shared/composables/useTenant';
import useUtils from '@/shared/composables/useUtils';

// Init
const { t } = useI18n();
const { getTenantConfigAndStyles } = useTenant();
const { tenantConfig } = getTenantConfigAndStyles();
const { getRegistrationForm } = useRegistrationForm();
const { registrationForm, validationSchema } = getRegistrationForm();
const { nextStep, previousStep, stepIndex } = useStepChanger();
const { registerUser, isErrorRegister } = useAuth();
const { calculateShade } = useUtils();
const primary_color = window.primary_color;
const primaryRgb = hexRgb(window.primary_color, { format: 'array' });
const primaryShade = '#' + rgbHex(calculateShade(primaryRgb.toString(), 0.2));
const disableSubmitButton = ref(false);

// Modals
const modalState = ref(false);
const modalType = ref('');
const setModal = (state: boolean, type?: string) => {
	modalState.value = state;
	modalType.value = type === 'tos' ? 'tos' : 'pp';
};

// Reset step
onBeforeMount(() => {
	stepIndex.value = 0;
});

// Submit form
const onSubmit = async (form: any) => {
	const formData: any = {};
	disableSubmitButton.value = true;

	Object.keys(form).forEach((key) => {
		if (form[key] !== undefined && form[key] !== null) {
			if (key === 'date_of_birth') {
				if (form.date_of_birth == '') {
					// Remove date of birth if it was set with incorrect data
					delete form.date_of_birth;
				} else {
					formData['date_of_birth'] = DateTime.fromFormat(form.date_of_birth, 'dd-MM-yyyy').toFormat(
						'yyyy-MM-dd',
					);
				}
			} else {
				formData[key] = form[key];
			}
		}
	});

	// Send data using api
	await registerUser({
		...formData,
	});
};

watch(isErrorRegister, async () => {
	if (isErrorRegister.value) {
		disableSubmitButton.value = false;
	}
});
</script>

<template>
	<ion-content :style="`background: linear-gradient(${primary_color}, ${primaryShade});`" class="flex">
		<ModalThree :is-open="modalState">
			<div class="flex w-full items-center justify-center mb-4">
				<div class="flex items-center justify-center w-[60px] h-[60px] rounded-full bg-anykrowd-gray/30 mb-4">
					<ion-icon
						class="text-[38px] text-black cursor-pointer"
						:icon="closeOutline"
						@click="setModal(false)"></ion-icon>
				</div>
			</div>
			<h1 class="font-sourcesanspro-semibold text-lg">
				{{ modalType === 'tos' ? 'Terms and conditions' : 'Privacy Policy' }}
			</h1>
			<div
				v-dompurify-html="
					modalType === 'tos' ? tenantConfig!.terms_and_conditions : tenantConfig!.privacy_policy
				"
				class="text-anykrowd-dark"></div>
		</ModalThree>

		<div class="flex flex-col h-full mb-[300px]">
			<TopBar class="px-[34px]">
				<template #left>
					<div v-if="stepIndex === 0" class="flex items-center justify-start cursor-pointer">
						<router-link to="/dashboard" class="flex items-center justify-center">
							<ion-icon :icon="chevronBackOutline" class="text-white"></ion-icon>
						</router-link>
					</div>
					<div
						v-if="stepIndex > 0"
						class="flex items-center justify-start cursor-pointer"
						@click="previousStep">
						<ion-icon :icon="chevronBackOutline" class="text-white"></ion-icon>
					</div>
				</template>

				<template #center>
					<button
						type="button"
						v-for="(step, index) in validationSchema"
						class="step-line bg-white mx-1"
						:class="[{ 'background-white-15': index > stepIndex }]"
						:key="index"></button>
				</template>

				<template #right>
					<div
						v-if="
							registrationForm?.length &&
							!registrationForm[stepIndex].filter((field) => field.required).length
						"
						class="font-sourcesanspro-regular text-base">
						<router-link to="" @click="nextStep">Skip</router-link>
					</div>
				</template>
			</TopBar>

			<div v-if="validationSchema && registrationForm" class="h-full px-[34px]">
				<FormWizard
					class="height-inherit mb-4"
					:validation-schema="validationSchema"
					:last-step-index="validationSchema.length - 1"
					:currentStep="stepIndex"
					@on-go-next-step="nextStep"
					@submit="onSubmit"
					v-slot="slotProps"
					:disable-submit-button="disableSubmitButton">
					<FormStep
						v-for="(fields, index) in registrationForm"
						:key="index"
						:index="index"
						:currentStep="stepIndex"
						class="flex flex-col height-inherit mb-2 pt-4">
						<div v-for="(field, indexField) in fields" :key="indexField">
							<div
								v-if="
									field.field_name !== 'terms_and_conditions_accepted' &&
									field.field_name !== 'privacy_policy_accepted'
								"
								class="mb-3">
								<ion-label class="font-sourcesanspro-regular text-white/50">
									{{ field.field_label + (field.required ? '' : ' (optional)') }}
								</ion-label>
							</div>
							<div class="flex flex-col grow">
								<div>
									<TextInput
										wrapper-class="flex flex-wrap justify-left item-center mb-2 border border-solid focus-within:background-white-15"
										:name="field.field_name"
										:placeholder="field.field_label"
										:show-error-message="true"
										:show-input-success="true"
										:value="slotProps.values[field.field_name]"
										type="text"
										v-if="
											(field.type === 'text' || field.type === 'tel') &&
											!['country'].includes(field.field_name)
										" />

									<div v-if="field.type === 'password'">
										<TextInput
											wrapper-class="flex flex-wrap justify-left item-center mb-2 border border-solid focus-within:background-white-15"
											:name="field.field_name"
											:placeholder="field.field_label"
											:show-error-message="true"
											:show-input-success="true"
											:value="slotProps.values[field.field_name]"
											:manual-submit="slotProps.onSubmit"
											type="password" />
										<TextInput
											wrapper-class="flex flex-wrap justify-left item-center mb-2 border border-solid focus-within:background-white-15"
											name="password_confirmation"
											:placeholder="t('auth.sign_up.confirm_password')"
											:show-error-message="true"
											:show-input-success="true"
											:manual-submit="slotProps.onSubmit"
											:value="slotProps.values['password_confirmation']"
											type="password" />
									</div>

									<RadioInput
										:name="field.field_name"
										:values="field.options"
										:value="slotProps.values[field.field_name]"
										v-if="field.type === 'select'" />

									<div
										v-if="
											field.type === 'checkbox' &&
											field.field_name === 'terms_and_conditions_accepted'
										">
										<CheckboxInput
											container-class="items-center justify-start"
											id="terms_and_conditions_accepted"
											name="terms_and_conditions_accepted"
											type="checkbox">
											<label
												for="terms_and_conditions_accepted"
												class="font-sourcesanspro-regular text-small text-color-magenta text-left">
												{{ $t('auth.sign_up_additional_page.step_six.terms') }}
												<a
													@click="setModal(true, 'tos')"
													target="_blank"
													class="text-white underline cursor-pointer">
													{{ $t('auth.sign_in_home_page.terms_of_use') }}
												</a>
											</label>
										</CheckboxInput>
									</div>

									<div
										v-if="
											field.type === 'checkbox' && field.field_name === 'privacy_policy_accepted'
										"
										class="mb-[100px]">
										<CheckboxInput
											container-class="items-center justify-start"
											id="privacy_policy_accepted"
											name="privacy_policy_accepted"
											type="checkbox">
											<label
												for="privacy_policy_accepted"
												class="font-sourcesanspro-regular text-small text-color-magenta text-left">
												{{ $t('auth.sign_up_additional_page.step_six.terms') }}
												<a
													@click="setModal(true, 'pp')"
													target="_blank"
													class="text-white underline cursor_pointer">
													{{ $t('auth.sign_in_home_page.terms_privacy_policy') }}
												</a>
											</label>
										</CheckboxInput>
									</div>

									<DateInputMask
										wrapper-class="flex flex-wrap justify-left item-center mb-3 border border-solid focus-within:background-white-15"
										customInputClass="justify-start"
										:name="field.field_name"
										:value="slotProps.values[field.field_name]"
										type="date"
										v-if="field.type === 'date'"></DateInputMask>

									<SearchInput
										:name="field.field_name"
										:placeholder="$t('auth.sign_up_additional_page.step_four.placeholder')"
										:data="countries.map((country) => country.name)"
										:icon="chevronDownOutline"
										:value="slotProps.values[field.field_name]"
										v-if="field.field_name === 'country'" />

									<ImageInput
										wrapper-class="flex flex-col items-center justify-center"
										:name="field.field_name"
										:placeholder="field.field_label"
										:show-error-message="false"
										:show-input-success="false"
										:value="slotProps.values[field.field_name]"
										type="text"
										v-if="field.field_name === 'profile_image'" />
								</div>
								<!-- <p class="font-sourcesanspro-regular text-base text-color-magenta text-center">
                  <span>{{ $t('auth.sign_up_additional_page.step_one.message') }}</span>
                </p> -->
							</div>
						</div>
					</FormStep>
				</FormWizard>
			</div>
		</div>
	</ion-content>
</template>

<style lang="scss" scoped>
p,
h1 {
	width: 100%;
}

ion-checkbox {
	margin-right: 10px;
}

ion-list,
ion-item {
	--background: transparent;
	background: transparent !important;
}

ion-button.go-back-button {
	--background: transparent;
	--box-shadow: none;
	height: 100%;
	margin: 0;
	width: initial;
}

.step-line {
	height: 3px;
	width: 24px;
}
</style>
