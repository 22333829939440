import { detect } from 'detect-browser';
import { BrowserType } from '@/shared/interfaces/browser';

const detectBrowser = (): BrowserType => {
	const browser = detect();

	if (browser) {
		switch (browser.name) {
			case 'chrome':
				return BrowserType.CHROME;

			case 'chromium-webview':
				return BrowserType.CHROMIUM_WEBVIEW;

			case 'safari':
				return BrowserType.SAFARI;

			case 'fxios':
			case 'firefox':
				return BrowserType.FIREFOX;

			case 'edge-chromium':
			case 'edge-ios':
			case 'edge':
				return BrowserType.EDGE;

			case 'ie':
				return BrowserType.INTERNET_EXPLORER;

			case 'facebook':
				return BrowserType.FACEBOOK;

			case 'instagram':
				return BrowserType.INSTAGRAM;

			case 'ios-webview':
				return BrowserType.IOS_WEBVIEW;

			case 'samsung':
				return BrowserType.SAMSUNG;

			case 'ios':
				return BrowserType.IOS;

			default: {
				return BrowserType.OTHER;
			}
		}
	} else {
		return BrowserType.OTHER;
	}
};

const useBrowser = () => {
	return {
		detectBrowser,
	};
};

export default useBrowser;
