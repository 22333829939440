<script setup lang="ts">
import router from '@/router';
import { useRoute } from 'vue-router';
import { IonButton, IonContent, IonIcon } from '@ionic/vue';
import TopBar from '@/shared/components/TopBar.vue';
import { checkmarkCircle, chevronBackOutline, heartOutline, shareSocialOutline } from 'ionicons/icons';

const route = useRoute();
const bookingReservationId = +route.params.id;

// Go back
const goBack = () => {
	router.back();
};

const goTo = async (link: string) => {
	await router.push(link);
};
</script>

<template>
	<ion-content>
		<TopBar class="px-[34px]">
			<template #left>
				<div class="flex items-center justify-start cursor-pointer" @click="goBack">
					<ion-icon :icon="chevronBackOutline" class="text-white"></ion-icon>
				</div>
			</template>

			<template #center>
				<div class="flex justify-center w-full font-sourcesanspro-semibold text-l2">Bookings Details</div>
			</template>

			<template #right>
				<ion-icon class="mr-1" :icon="heartOutline"></ion-icon>
				<ion-icon class="mr-1" :icon="shareSocialOutline"></ion-icon>
			</template>
		</TopBar>
		<div class="flex overflow-scroll w-[100%] h-[182px] mb-4 invisible-scroller">
			<img
				:src="require('@/statics/svg-delete-after-implement/bookings-reservations-image-limo-1.svg')"
				class="w-[100%] ml-[34px] mr-4" />
			<img
				:src="require('@/statics/svg-delete-after-implement/bookings-reservations-image-limo-2.svg')"
				class="w-[100%] h-[182px]" />
		</div>

		<div class="px-[34px] mb-2">
			<div class="flex mb-4 font-sourcesanspro-semibold text-lg2">Limo Ride</div>

			<!-- TODO: Handle this logic -->
			<div v-if="bookingReservationId == 1" class="flex justify-between mb-4">
				<div class="flex flex-col">
					<span class="font-sourcesanspro-semibold text-l2">29</span>
					<span class="font-sourcesanspro-regular text-base font-color-text-50">December</span>
				</div>
				<div class="flex flex-col">
					<span class="font-sourcesanspro-semibold text-l2">Tuesday</span>
					<span class="font-sourcesanspro-regular text-base font-color-text-50">10:00 PM - End</span>
				</div>
				<div class="flex justify-right">
					<ion-icon :src="require('@/statics/svg/events/calendar-add.svg')" class="h-[54px] w-[54px]" />
				</div>
			</div>
			<div v-if="bookingReservationId == 2" class="flex items-center mb-4">
				<div class="mr-2">
					<span class="font-sourcesanspro-semibold text-l2 line-through text-white/30">€79.99</span>
				</div>
				<span class="font-sourcesanspro-bold text-lg2">€49.99</span>
			</div>

			<div class="mb-4">
				<div class="font-sourcesanspro-semibold text-l2">About Limo Ride:</div>
				<p class="text-base2 font-sourcesanspro-regular font-color-text-50">
					Morbi in lectus suscipit, blandit magna sit amet, ornare nulla. Praesent vestibulum lorem nibh, et
					mattis lorem accumsan eget. Morbi eu mi quis justo tempus tempus. Pellentesque eu orci nibh.
					Suspendisse consequat enim ut mi rutrum, ac ultrices est rutrum. Donec faucibus velit ac erat
					fermentum, vitae imperdiet magna semper.
				</p>
			</div>

			<div class="font-sourcesanspro-semibold mb-8">
				<div class="mb-2 text-l2">What is included</div>
				<div class="flex items-center mb-2">
					<ion-icon :icon="checkmarkCircle" class="w-[17px] text-white mr-2"></ion-icon>
					<span class="font-sourcesanspro-regular text-base font-color-text-50">
						Oliver Tree singing is Dec 29th at 10:00pm
					</span>
				</div>
				<div class="flex items-center">
					<ion-icon :icon="checkmarkCircle" class="w-[17px] text-white mr-2"></ion-icon>
					<span class="font-sourcesanspro-regular text-base font-color-text-50">
						Meeet and great with Oliver Tree on Dec 30th
					</span>
				</div>
			</div>
		</div>

		<div class="fixed button-container h-[150px] px-[34px] bottom-0 w-full">
			<div class="relative z-10 w-full h-full">
				<div class="absolute w-full bottom-0 mb-[34px]">
					<!-- TODO: Handle this logic -->
					<ion-button
						v-if="bookingReservationId == 1"
						class="w-full"
						expand="full"
						size="large"
						shape="round">
						<div class="font-sourcesanspro-semibold text-m2 text-center">Book more</div>
					</ion-button>
					<div class="flex" v-if="bookingReservationId == 2">
						<ion-button
							class="w-full"
							expand="full"
							size="large"
							shape="round"
							@click="goTo('/bookings-reservations/book')">
							<div class="font-sourcesanspro-semibold text-m2 text-center">Book Now</div>
						</ion-button>
					</div>
				</div>
			</div>
		</div>
	</ion-content>
</template>

<style lang="scss" scoped>
.button-container {
	z-index: 1;
}

.button-container::after {
	background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(23, 22, 30, 1));
	bottom: 0;
	content: '';
	height: 150px;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 0;
}
</style>
