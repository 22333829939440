import { watch } from 'vue';

import useUserProfile from '@/modules/profile/composables/useUserProfile';
import useNotifications from '@/shared/composables/useNotifications';
import { useQueryClient } from '@tanstack/vue-query';

const useProfileAdditionalConfig = () => {
	// Init
	const queryClient = useQueryClient();
	const { updateProfileFields } = useUserProfile();
	const { updateProfileFieldsData, updateProfileFieldsMutate } = updateProfileFields();

	// useNotifications
	const { requestPushNotificationsPermission } = useNotifications();

	// On notification change
	const onNotificationChange = async (
		type: 'push' | 'smsNotifications' | 'emailNotifications',
		toggleValueIsChecked: boolean,
	) => {
		switch (type) {
			case 'push': {
				if (toggleValueIsChecked) {
					requestPushNotificationsPermission();
				}
				await updateProfileFieldsMutate({ notification_in_app: toggleValueIsChecked ? 1 : 0 });
				break;
			}
			case 'emailNotifications': {
				await updateProfileFieldsMutate({ notification_email: toggleValueIsChecked ? 1 : 0 });
				break;
			}
			case 'smsNotifications': {
				await updateProfileFieldsMutate({ notification_sms: toggleValueIsChecked ? 1 : 0 });
				break;
			}
		}
	};

	// On cellphone number change
	const onCellPhoneNumberChange = async (cellPhoneNumber: string | null | undefined, success: any): Promise<any> => {
		return await updateProfileFieldsMutate(
			{
				notification_cellphone_number: cellPhoneNumber,
			},
			{ onSuccess: success },
		);
	};

	// Watch for changes on profile fields data
	watch(updateProfileFieldsData, () => {
		if (updateProfileFieldsData.value) {
			// Find user cache and fetch it again
			const query = queryClient.getQueryCache().find(['userProfile']);
			query?.fetch();
		}
	});

	// Return
	return {
		onNotificationChange,
		onCellPhoneNumberChange,
	};
};

export default useProfileAdditionalConfig;
