<script setup lang="ts">
const props = defineProps({
	class: { type: String },
	label: { type: String, required: true },
	labelContainerClass: { type: String },
});
</script>

<template>
	<div
		:class="props.class"
		class="absolute left-0 top-0 w-0 h-0 border-solid border-b-[70px] border-l-[70px] border-transparent rounded-tl-md z-10"></div>
	<div :class="props.labelContainerClass" class="absolute -rotate-[45deg] z-20">
		<span class="font-sourcesanspro-semibold text-xs2">{{ props.label }}</span>
	</div>
</template>

<style scoped></style>
