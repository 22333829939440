<script setup lang="ts">
import router from '@/router';
import { IonContent, IonIcon } from '@ionic/vue';
import TicketItem from '@/modules/tickets/components/TicketItem.vue';
import TopBar from '@/shared/components/TopBar.vue';
import BottomGradient from '@/shared/components/ui/BottomGradient.vue';
import { chevronBackOutline } from 'ionicons/icons';

// Go back
const goBack = () => {
	router.back();
};
</script>

<template>
	<ion-content>
		<TopBar class="px-[34px]">
			<template #left>
				<div class="flex items-center justify-start cursor-pointer" @click="goBack">
					<ion-icon :icon="chevronBackOutline" class="text-white"></ion-icon>
				</div>
			</template>

			<template #center>
				<div class="flex justify-center w-full font-sourcesanspro-semibold text-l2">Le Noël de Monsieur</div>
			</template>
		</TopBar>

		<div class="px-[34px] mb-2">
			<div class="flex justify-between mb-4">
				<div class="flex flex-col">
					<span class="font-sourcesanspro-semibold text-l2">29</span>
					<span class="font-sourcesanspro-regular text-base font-color-text-50">December</span>
				</div>
				<div class="flex flex-col">
					<span class="font-sourcesanspro-semibold text-l2">Tuesday</span>
					<span class="font-sourcesanspro-regular text-base font-color-text-50">10:00 PM - End</span>
				</div>
				<div class="flex justify-right">
					<ion-icon :src="require('@/statics/svg/events/calendar-add.svg')" class="h-[54px] w-[54px]" />
				</div>
			</div>
		</div>

		<div class="flex flex-col px-[34px] items-center">
			<TicketItem
				link="/tickets/1"
				name="Derek McLean"
				type="Coper"
				venue="Antwerps Sportpalies, BE"
				class="mb-4" />

			<hr class="mb-4 h-px bg-white/10 border-0" />

			<TicketItem
				link="/tickets/2"
				name="Christophe Stemberg"
				type="Coper"
				venue="Antwerps Sportpalies, BE"
				class="mb-2" />
			<TicketItem
				link="/tickets/3"
				name="Stijn DeBeker"
				type="Coper"
				venue="Antwerps Sportpalies, BE"
				class="mb-2" />
			<TicketItem
				link="/tickets/4"
				name="Stijn DeBrauwer"
				type="Coper"
				venue="Antwerps Sportpalies, BE"
				class="mb-2" />
			<TicketItem
				link="/tickets/5"
				name="Kim De Jong"
				type="Coper"
				venue="Antwerps Sportpalies, BE"
				class="mb-2" />
			<TicketItem
				link="/tickets/6"
				name="Elma Klose"
				type="Coper"
				venue="Antwerps Sportpalies, BE"
				class="mb-2" />
		</div>

		<BottomGradient />
	</ion-content>
</template>

<style lang="scss" scoped></style>
