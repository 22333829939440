<script setup lang="ts">
import { computed } from 'vue';
import { IonButton, IonIcon } from '@ionic/vue';
import { useForm } from 'vee-validate';
import { arrowForwardOutline } from 'ionicons/icons';

const props = defineProps({
	bottomClass: {
		type: String,
	},
	currentStep: {
		type: Number,
		required: true,
	},
	lastStepIndex: {
		type: Number,
		required: true,
	},
	validationSchema: {
		type: Array,
		required: true,
	},
	disableSubmitButton: {
		type: Boolean,
		default: false,
	},
});

const emit = defineEmits(['onGoNextStep', 'submit']);

// if this is the last step
const isLastStep = computed(() => {
	return props.currentStep === props.lastStepIndex;
});

// extracts the indivdual step schema
const currentSchema = computed(() => {
	return props.validationSchema[props.currentStep];
});

const { values, handleSubmit, isFieldValid, meta } = useForm({
	// vee-validate will be aware of computed schema changes
	validationSchema: currentSchema,
	// turn this on so each step values won't get removed when you move back or to the next step
	keepValuesOnUnmount: true,
});

// We are using the "submit" handler to progress to next steps
// and to submit the form if its the last step
const onSubmit = handleSubmit((values) => {
	if (!isLastStep.value) {
		emit('onGoNextStep');

		return;
	}

	// Let the parent know the form was filled across all steps
	emit('submit', values);
});
</script>

<template>
	<form @submit="onSubmit">
		<div class="flex flex-col height-inherit">
			<slot :values="values" :onSubmit="onSubmit" />

			<div
				class="fixed flex justify-center margin-auto w-full mb-6"
				:class="props.bottomClass ? props.bottomClass : 'bottom-0 left-0 px-[32px]'">
				<ion-button
					class="max-w-[532px]"
					:disabled="!meta.valid || props.disableSubmitButton"
					type="submit"
					color="primary"
					size="large"
					shape="round">
					<div class="flex w-full max-w-[532px] items-center justify-between">
						{{ isLastStep ? "Let's finish" : 'Continue' }}
						<ion-icon :icon="arrowForwardOutline" slot="end"></ion-icon>
					</div>
				</ion-button>
			</div>
		</div>
	</form>
</template>

<style lang="scss" scoped>
ion-button {
	width: 100%;

	ion-icon {
		color: var(--clientx-color-button-text);
	}
}
</style>
