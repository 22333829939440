import { ref } from 'vue';
import { Capacitor } from '@capacitor/core';
import { Camera, CameraResultType } from '@capacitor/camera';
import { loadingController, isPlatform } from '@ionic/vue';
import jsQR from 'jsqr';
import { useI18n } from 'vue-i18n';

import useUtils from '@/shared/composables/useUtils';
import useToast from '@/shared/composables/useToast';

// Init
const { getDataURLFileSize } = useUtils();
const { presentToast } = useToast();

// useCamera
const useCamera = () => {
	// Init
	const cameraPermission = ref();
	const photo = ref();
	const canClose = ref(true);
	const callOnStopScan: any = ref();
	const canvasElement: any = ref();
	let canvasContext: any;
	let isReversingCamera = false;
	let facingMode = 'environment';
	const dismissOnCodeRead = true;
	const fileinputElement: any = ref();
	const iosAndStandaloneMode = ref(false);
	let loading: HTMLIonLoadingElement | null;
	const scanActive = ref(false);
	const scanResult = ref('');
	const videoElement: any = ref();
	const isInStandaloneMode = () => 'standalone' in window.navigator && window.navigator['standalone'];
	const { t } = useI18n();

	if (isPlatform('ios') && isInStandaloneMode()) {
		iosAndStandaloneMode.value = true;
	}

	// checkPermission
	const checkPermission = async () => {
		if (Capacitor.isNativePlatform()) {
			const nativePermission = await Camera.checkPermissions();

			return nativePermission.camera;
		}

		try {
			const permission: globalThis.PermissionStatus = await navigator.permissions.query({
				name: 'camera' as PermissionName,
			});

			return permission.state;
		} catch (e) {
			return 'denied';
		}
	};

	// requestPermission
	const requestPermission = async () => {
		if (Capacitor.isNativePlatform()) {
			await Camera.requestPermissions({ permissions: ['camera'] });
		}

		try {
			await navigator.mediaDevices.getUserMedia({
				video: { facingMode: 'environment', advanced: [{}] },
			});
		} catch (e) {
			return 'denied';
		}
	};

	// reverseCamera
	const reverseCamera = async () => {
		if (isReversingCamera) {
			return;
		}
		isReversingCamera = true;

		if (facingMode === 'environment') {
			facingMode = 'user';
		} else {
			facingMode = 'environment';
		}

		if (callOnStopScan.value !== null) {
			callOnStopScan.value();
		}

		await startScan();
		isReversingCamera = false;
	};

	// reverseCameraPresent
	const reverseCameraPresent = async (): Promise<boolean> => {
		return (await navigator.mediaDevices.enumerateDevices()).filter((device) => device?.deviceId !== '').length > 1;
	};

	// startScan
	const startScan = async () => {
		canClose.value = false;
		scanActive.value = true;

		loading = await loadingController.create({});
		await loading.present();

		try {
			canvasContext = canvasElement.value.getContext('2d', { willReadFrequently: true });

			// const currentMediaTrack = mediaStream.value.getVideoTracks()[0];
			// const torchIsPresent = !!currentMediaTrack?.getCapabilities()?.torch;

			videoElement.value.srcObject =
				videoElement.value.srcObject ??
				(await navigator.mediaDevices.getUserMedia({
					video: { facingMode, advanced: [{}] },
				}));
			videoElement.value.setAttribute('playsinline', true);
			await videoElement.value.play();

			requestAnimationFrame(scan.bind(this));

			callOnStopScan.value = () => {
				if (videoElement.value && videoElement.value.srcObject) {
					videoElement.value.srcObject.getTracks().forEach((mediaTrack: MediaStreamTrack) => {
						mediaTrack.stop();
						videoElement.value.srcObject = null;
					});
				}
			};
		} catch (e) {
			stopScan();
		}
		canClose.value = true;
	};

	const stopScan = () => {
		scanActive.value = false;
		if (loading) {
			loading.dismiss();
		}

		if (callOnStopScan.value !== null) {
			callOnStopScan.value();

			callOnStopScan.value = null;
		}
	};

	const handleCodeReadSuccessfully = () => {
		if (dismissOnCodeRead) {
			if (callOnStopScan.value !== null) {
				callOnStopScan.value();
				callOnStopScan.value = null;
			}
		}
	};

	const scanQr = async () => {
		if (cameraPermission.value === 'granted') {
			startScan();
		} else if (cameraPermission.value !== 'granted') {
			await requestPermission();
			cameraPermission.value = await checkPermission();

			if (cameraPermission.value === 'granted') {
				startScan();
			}
		}
	};

	const scan = async () => {
		if (videoElement.value && videoElement.value.readyState === videoElement.value.HAVE_ENOUGH_DATA) {
			if (loading) {
				await loading.dismiss();
				loading = null;
				scanActive.value = true;
			}

			canvasElement.value.height =
				videoElement.value.videoHeight && videoElement.value.videoHeight > 0
					? videoElement.value.videoHeight
					: 100;
			canvasElement.value.width =
				videoElement.value.videoWidth && videoElement.value.videoWidth > 0
					? videoElement.value.videoWidth
					: 100;
			canvasContext.drawImage(videoElement.value, 0, 0, canvasElement.value.width, canvasElement.value.height);

			const imageData = canvasContext.getImageData(0, 0, canvasElement.value.width, canvasElement.value.height);

			const code = jsQR(imageData.data, imageData.width, imageData.height, {
				inversionAttempts: 'attemptBoth',
			});

			if (code) {
				scanActive.value = false;
				scanResult.value = code.data;

				handleCodeReadSuccessfully();
			} else {
				requestAnimationFrame(scan.bind(this));
			}
		} else {
			requestAnimationFrame(scan.bind(this));
		}
	};

	const captureImage = () => {
		fileinputElement.value.click();
	};

	const handleFile = (files: FileList) => {
		const file = files.item(0);

		const img = new Image();
		img.onload = () => {
			canvasContext.drawImage(img, 0, 0, canvasElement.value.width, canvasElement.value.height);
			const imageData = canvasContext.getImageData(0, 0, canvasElement.value.width, canvasElement.value.height);

			const code = jsQR(imageData.data, imageData.width, imageData.height, {
				inversionAttempts: 'attemptBoth',
			});

			if (code) {
				scanActive.value = false;
				scanResult.value = code.data;
				handleCodeReadSuccessfully();
			}
		};
		img.src = URL.createObjectURL(file!);
	};

	const cameraGetPhoto = () => {
		Camera.getPhoto({
			quality: 40,
			resultType: CameraResultType.DataUrl,
			width: 400,
			height: 400,
			allowEditing: false,
		})
			.then(async (originalPhoto) => {
				if (originalPhoto && originalPhoto.dataUrl) {
					// If size > 100mb
					if (getDataURLFileSize(originalPhoto.dataUrl) >= 100) {
						await presentToast('top', t('common.file_too_big'), 5000, 'warning');
					} else {
						photo.value = originalPhoto.dataUrl;
					}
				}
			})
			.catch((e) => {
				console.log(e);
				canClose.value = true;
			});
	};

	return {
		cameraGetPhoto,
		cameraPermission,
		canClose,
		captureImage,
		canvasElement,
		checkPermission,
		fileinputElement,
		handleFile,
		iosAndStandaloneMode,
		requestPermission,
		photo,
		reverseCamera,
		reverseCameraPresent,
		scanActive,
		scanResult,
		scanQr,
		stopScan,
		videoElement,
	};
};

export default useCamera;
