<script setup lang="ts">
import { PropType } from 'vue';
import { timeOutline } from 'ionicons/icons';
import { IonIcon } from '@ionic/vue';
import { DateTime } from 'luxon';

const props = defineProps({
	timelineDetails: { type: Object as PropType<(DateTime | null)[]>, required: true },
});
</script>

<template>
	<div></div>

	<template v-for="(date, idx) in props.timelineDetails" :key="idx">
		<div :id="`day-${date!.day.toString()}`" class="flex justify-center font-sourcesanspro-semibold text-base">
			{{ date?.hour }}:{{ date?.minute.toString().padStart(2, '0') }}h
		</div>
		<div
			v-for="dot in ['·', '·', '·']"
			:key="dot"
			class="flex justify-center font-sourcesanspro-semibold text-base">
			{{ dot }}
		</div>
	</template>
</template>

<style scoped></style>
