import { defineStore } from 'pinia';
import { TableSpot } from '@/shared/interfaces/tableSpot';

export const useTableSpotLocationStore = defineStore('selfOrdering', {
	state: () => ({
		qrCode: '',
		tableSpot: {
			id: 0,
			friendly_id: '',
			manual_location: false,
			manual_location_type: '',
			manual_location_values: '',
			table_number: '',
		},
		location: '',
	}),

	actions: {
		removeLocation() {
			this.location = '';
		},
		reset() {
			this.$reset();
		},
		saveLocation(location: string) {
			this.location = location;
		},
		saveTableSpot(tableSpot: TableSpot, qrCode: string) {
			this.qrCode = qrCode;
			this.tableSpot = tableSpot;
			return this.$state;
		},
	},
	persist: true,
});
