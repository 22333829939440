<script setup lang="ts">
import { onMounted, ref, watchEffect } from 'vue';
import { useRoute } from 'vue-router';
import { IonButton, IonContent, IonIcon } from '@ionic/vue';
import { chevronBackOutline, closeOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import { TransactionType } from '@/enums/wallet.enum';
import { useCart } from '@/modules/shop/composables/useCart';
import { useCheckout } from '@/shared/composables/useCheckout';
import EventsItemMemberAdder from '@/modules/events/components/EventsItemMemberAdder.vue';
import ModalOne from '@/shared/components/ui/ModalOne.vue';
import TopBar from '@/shared/components/TopBar.vue';
import useConvertValuta from '@/shared/composables/useConvertValuta';
import useEventsTicketsAddMembers from '@/modules/events/composables/useEventsTicketsAddMembers';
import useTenant from '@/shared/composables/useTenant';
import useEvents from '@/modules/events/composables/useEvents';
import useUserProfile from '@/modules/profile/composables/useUserProfile';

// Init
const { t } = useI18n();
const route = useRoute();
const eventId = +route.params.id;
const isModalOpen = ref(false);
const { calculateTotalsPerProductCurrency, cartTotal, getCartItems } = useCart();
const { processCheckout } = useCheckout();
const { updateTicketData } = useEventsTicketsAddMembers();
const { getTenantConfigAndStyles } = useTenant();
const { tenantConfig } = getTenantConfigAndStyles();
const { convertValutaSync } = useConvertValuta();
const { getEvent } = useEvents();
const { event } = getEvent(eventId);
const { getUserProfile } = useUserProfile();
const { isFetchedUserProfile, userProfile } = getUserProfile();

// onMountedHook
onMounted(async () => {
	if (event.value) {
		updateTicketData(event.value);
	}
});

const showHideModal = (openState: boolean) => {
	isModalOpen.value = openState;
};

const canStartCheckout = () => {
	const isValid = !getCartItems().filter((cartItem) => !cartItem.additionalInfo.name).length;
	if (!isValid) {
		showHideModal(true);
	} else {
		processCheckout(TransactionType.PAYMENT, eventId);
	}
};
</script>

<template>
	<ion-content>
		<ModalOne :title="`${t('ticket.add_members.please_complete_title')}`" :is-open="isModalOpen">
			<template #content>
				<div class="font-sourcesanspro-regular text-sm text-center text-white/50 leading-[18px]">
					{{ t('ticket.add_members.please_complete_description') }}
				</div>
			</template>

			<template #actions>
				<div class="flex items-center justify-center">
					<div class="flex items-center justify-center w-[60px] h-[60px] rounded-full bg-white">
						<ion-icon
							class="text-[38px] text-black cursor-pointer"
							:icon="closeOutline"
							@click="showHideModal(false)"></ion-icon>
					</div>
				</div>
			</template>
		</ModalOne>

		<div class="flex flex-col h-full justify-between px-[34px]">
			<div>
				<TopBar>
					<template #left>
						<div class="flex items-center justify-start cursor-pointer">
							<router-link
								:to="`/events/${eventId}/tickets/buy`"
								class="flex items-center justify-center">
								<ion-icon :icon="chevronBackOutline" class="text-white"></ion-icon>
							</router-link>
						</div>
					</template>

					<template #center>
						<div class="flex grow items-center justify-center w-full">
							<div class="mr-2">
								<span class="font-sourcesanspro-semibold text-l2">{{
									t('ticket.add_members.ticket_details')
								}}</span>
							</div>
						</div>
					</template>
				</TopBar>

				<div class="flex flex-col items-center justify-center mb-8">
					<h1 class="font-sourcesanspro-semibold text-lg2">{{ t('ticket.add_members.checkout') }}</h1>
					<span class="font-sourcesanspro-regular text-base2 text-white/50">
						{{ getCartItems().length }}
						{{ t('ticket.add_members.tickets') }}
					</span>
				</div>

				<div v-if="isFetchedUserProfile" class="flex flex-col w-[100%] items-start overflow-scroll">
					<EventsItemMemberAdder
						v-for="(cartItem, idx) in getCartItems()"
						:key="idx"
						:should-block-changes="cartItem.additionalInfo.email === userProfile?.email"
						:cartItemIndex="idx"
						:cartItemProductId="cartItem.product.id"
						:cart-item="cartItem"
						:eventId="eventId"
						class="w-full" />
				</div>
			</div>

			<div class="mb-8" @click.prevent="canStartCheckout">
				<ion-button
					class="w-full hover:rounded-full hover:bg-white"
					:disabled="!!getCartItems().filter((cartItem) => !cartItem.additionalInfo.name).length"
					expand="full"
					size="large"
					shape="round">
					<div class="flex w-full items-center justify-between">
						<span class="text-m2">{{ t('events.checkout') }}</span>
						<span>{{ calculateTotalsPerProductCurrency() }}</span>
					</div>
				</ion-button>
			</div>
		</div>
	</ion-content>
</template>

<style lang="scss" scoped></style>
