<script setup lang="ts">
import router from '@/router';
import { useRoute } from 'vue-router';
import { IonButton, IonContent, IonIcon, IonSpinner } from '@ionic/vue';
import { closeOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import BottomGradient from '@/shared/components/ui/BottomGradient.vue';
import PersonItem from '@/shared/components/ui/PersonItem.vue';
import useFriendsSelect from '@/shared/composables/useFriendsSelect';

// Init
const { t } = useI18n();
const route = useRoute();
const type = route.query.type?.toString() || '';
const id = route.query.id?.toString() || '';
const eventId = route.query.eventId?.toString();

// Prepare params to query for friends
const params: any = {};
if (type === 'ticket' || type === 'ticket-creation') {
	params.without_ticket_at_event = eventId;
}

// Get friends
const { getFriends, onPersonSelected } = useFriendsSelect(type, id, { eventId });
const { isFetchingFriends, friends } = getFriends(params);

// Go back
const goBack = () => {
	router.back();
};
</script>

<template>
	<ion-content>
		<div class="flex flex-col h-full px-[34px]">
			<div class="flex flex-col mt-12 mb-10 items-center">
				<ion-button
					class="rounded ion-no-padding ion-no-margin w-[60px] h-[60px] mb-4"
					:routerLink="`/events/${eventId}`"
					@click="goBack">
					<ion-icon class="text-[38px] text-white cursor-pointer" :icon="closeOutline"></ion-icon>
				</ion-button>
				<span class="font-sourcesanspro-regular text-base2 text-white/50">
					{{ t('friends.select.title') }}
				</span>
			</div>

			<div class="mb-4">
				<!-- <ion-searchbar
          class="mb-4 backdrop-blur bg-white/20 rounded-full font-sourcesanspro-semibold text-l"
          shape="round"></ion-searchbar> -->
			</div>

			<div v-if="isFetchingFriends" class="flex items-center justify-center mb-4">
				<ion-spinner name="dots"></ion-spinner>
			</div>

			<div v-if="friends && !isFetchingFriends">
				<PersonItem
					v-for="(friend, idx) in friends"
					@on-person-selected="onPersonSelected(friend)"
					:key="idx"
					:name="friend.full_name"
					:location="friend.city"
					:image="friend.profile_image_url" />
			</div>
		</div>

		<BottomGradient />
	</ion-content>
</template>

<style lang="scss" scoped></style>
