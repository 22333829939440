<script setup lang="ts">
import { ref } from 'vue';
import router from '@/router';
import { storeToRefs } from 'pinia';
import { useQuizStore } from '@/stores/auth/quizStore';
import { IonButton, IonContent, IonIcon } from '@ionic/vue';
import { arrowForward, chevronBackOutline } from 'ionicons/icons';
import * as yup from 'yup';

import RadioInput from '@/modules/quiz/components/RadioInput.vue';
import TopBar from '@/shared/components/TopBar.vue';

// break down the validation steps into multiple schemas
const validationSchema = [
	yup.object({
		question1: yup.string().required().oneOf(['Anykrowd', 'Krowd', 'Any-KroWd'], 'Question'),
	}),
	yup.object({
		question2: yup.string().required().oneOf(['Easy', 'Medium', 'Heavy'], 'Question'),
	}),
	yup.object({
		question3: yup.string().required().oneOf(['1-2', '2-3', '3-5'], 'Question'),
	}),
];

const showResults = ref(false);
const quizStore = useQuizStore();
const { currentStep } = storeToRefs(quizStore);
const { decrementStep, incrementStep } = quizStore;
const goBackOneStep = () => {
	if (currentStep.value > 0) {
		decrementStep();
	}
};
const goForwardOneStep = () => {
	incrementStep();
};

const onSelect = () => {
	if (currentStep.value + 1 < validationSchema.length) {
		goForwardOneStep();
	} else {
		showResults.value = true;
	}
};

const goTo = async (link: string) => {
	await router.push(link);
};

// /**
//  * Only Called when the last step is submitted
//  */
// async function onSubmit(formData: FormData) {
//   console.log(JSON.stringify(formData, null, 2));
//   await router.push('/auth/signinhome');
// }
</script>

<template>
	<ion-content>
		<div class="flex flex-col justify-between h-full">
			<div>
				<TopBar class="mb-12 px-[34px]">
					<template v-if="!showResults" #left>
						<ion-button class="go-back-button" @click="goBackOneStep">
							<div></div>
							<ion-icon :icon="chevronBackOutline" class="text-white"></ion-icon>
						</ion-button>
					</template>

					<template v-if="!showResults" #center>
						<div class="flex grow justify-left w-full font-sourcesanspro-semibold text-l2">
							Quiz question {{ currentStep + 1 }}
						</div>
					</template>
				</TopBar>

				<div v-if="!showResults" class="flex items-center justify-center mb-14 px-[34px]">
					<button
						type="button"
						v-for="(step, index) in validationSchema"
						class="step-line bg-white mx-1"
						:class="[{ 'background-white-15': index > currentStep }]"
						:key="index"></button>
				</div>

				<!-- Step 1 -->
				<div v-if="currentStep === 0 && !showResults" class="flex flex-col height-inherit px-[34px]">
					<div class="flex justify-center mb-10">
						<span class="font-sourcesanspro-semibold text-lg">What's name of this app?</span>
					</div>
					<div class="flex flex-col grow justify-start">
						<div class="mb-2">
							<RadioInput
								@onSelect="onSelect"
								name="question1"
								:values="[
									{ name: 'Anykrowd', option: 'A' },
									{ name: 'Krowd', option: 'B' },
									{ name: 'Any-KroWd', option: 'C' },
								]" />
						</div>
						<p
							class="max-w-[95%] mx-auto font-sourcesanspro-regular text-base text-color-magenta text-center">
							<span>
								Etiam iaculis risus a dolor ultrices, dignissim aliquam mauris elementum. Morbi blandit
								luctus purus. Aenean fringilla aliquet sagittis.
							</span>
						</p>
					</div>
				</div>

				<!-- Step 2 -->
				<div v-if="currentStep === 1 && !showResults" class="flex flex-col height-inherit px-[34px]">
					<div class="flex justify-center mb-10">
						<span class="font-sourcesanspro-semibold text-lg">How easy is using the app?</span>
					</div>
					<div class="flex flex-col grow justify-start">
						<div class="mb-2">
							<RadioInput
								@onSelect="onSelect"
								name="question2"
								:values="[
									{ name: 'Easy', option: 'A' },
									{ name: 'Medium', option: 'B' },
									{ name: 'Heavy', option: 'C' },
								]" />
						</div>
						<p
							class="max-w-[95%] mx-auto font-sourcesanspro-regular text-base text-color-magenta text-center">
							<span>
								Etiam iaculis risus a dolor ultrices, dignissim aliquam mauris elementum. Morbi blandit
								luctus purus. Aenean fringilla aliquet sagittis.
							</span>
						</p>
					</div>
				</div>

				<!-- Step 3 -->
				<div v-if="currentStep === 2 && !showResults" class="flex flex-col height-inherit px-[34px]">
					<div class="flex justify-center mb-10">
						<span class="font-sourcesanspro-semibold text-lg">Rate the app?</span>
					</div>
					<div class="flex flex-col grow justify-start">
						<div class="mb-2">
							<RadioInput
								@onSelect="onSelect"
								name="question3"
								:values="[
									{ name: '1-2', option: 'A' },
									{ name: '2-3', option: 'B' },
									{ name: '3-5', option: 'C' },
								]" />
						</div>
						<p
							class="max-w-[95%] mx-auto font-sourcesanspro-regular text-base text-color-magenta text-center">
							<span>
								Etiam iaculis risus a dolor ultrices, dignissim aliquam mauris elementum. Morbi blandit
								luctus purus. Aenean fringilla aliquet sagittis.
							</span>
						</p>
					</div>
				</div>

				<div v-if="showResults" class="flex quiz-results flex-col items-center h-[316px]">
					<div class="flex flex-col items-center mb-4 px-[34px]">
						<div class="mb-2">
							<span class="font-sourcesanspro-semibold text-lg3">Congratulations!</span>
						</div>
						<div class="mb-2">
							<span class="font-sourcesanspro-semibold text-base text-[#7E738C]"
								>You did a great job in the quiz</span
							>
						</div>
						<span class="font-sourcesanspro-semibold text-lg">6/8</span>
					</div>

					<div class="flex items-center justify-center">
						<div class="progress">
							<div class="barOverflow">
								<div class="bar"></div>
							</div>
							<div class="flex flex-col absolute top-10 left-0 right-0">
								<div class="mb-2">
									<span class="font-sourcesanspro-semibold text-[52px] leading-none">184</span>
								</div>
								<span class="font-sourcesanspro-regular text-base text-[#7E738C]">Excellent</span>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div>
				<div v-if="showResults" class="flex w-full px-[34px] align-center mb-[34px]">
					<ion-button class="w-full" expand="full" size="large" shape="round" @click="goTo('/quiz')">
						<div class="flex w-full justify-between items-center">
							Start New Quiz
							<ion-icon :icon="arrowForward" class="text-dark-blue" slot="end"></ion-icon>
						</div>
					</ion-button>
				</div>
			</div>
		</div>
	</ion-content>
</template>

<style lang="scss" scoped>
.progress {
	position: relative;
	margin: 4px;
	float: left;
	text-align: center;
}
.barOverflow {
	/* Wraps the rotating .bar */
	position: relative;
	overflow: hidden; /* Comment this line to understand the trick */
	width: 228px;
	height: 114px; /* Half circle (overflow) */
	margin-bottom: -14px; /* bring the numbers up */
}
.bar {
	position: absolute;
	top: 0;
	left: 0;
	width: 228px;
	height: 228px; /* full circle! */
	border-radius: 50%;
	box-sizing: border-box;
	border: 5px solid rgba(255, 255, 255, 0.2); /* half gray, */
	border-bottom-color: white; /* half azure */
	border-right-color: white;
	transform: rotate(calc(45deg + 100deg));
}

.quiz-results {
	background: url('@/statics/svg/background-success.svg') no-repeat center center / cover;
	background-position: top;
}

p,
h1 {
	width: 100%;
}

ion-button.go-back-button {
	--background: transparent;
	--box-shadow: none;
	height: 100%;
	margin: 0;
	width: initial;
}

.step-line {
	height: 3px;
	width: 24px;
}
</style>
