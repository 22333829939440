<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { DateTime } from 'luxon';

// Init
const { t } = useI18n();
</script>

<template>
	<div class="flex items-center justify-between">
		<span class="font-sourcesanspro-semibold text-lg2">
			{{ t('news.header.title') }}
		</span>
	</div>
</template>

<style scoped></style>
