<script setup lang="ts">
import { ref, watch } from 'vue';
import { IonAccordionGroup, IonAccordion, IonItem, IonIcon, IonSpinner, IonToggle } from '@ionic/vue';
import { checkmarkOutline, chevronDown } from 'ionicons/icons';

import useTagCollections from '@/shared/composables/useTagCollections';

const props = defineProps({
	accordionClass: { type: String, required: false },
	accordionContentClass: { type: String, required: false },
	userTags: {
		type: Array<number>,
		required: false,
		default: function () {
			return [];
		},
	},
});

// Init
const openAccordions = ref<string[]>();
const {
	childTagsSelected,
	error,
	isError,
	isLoading,
	isSelected,
	selectChildTag,
	selectParentTag,
	tagsList,
	userTags,
} = useTagCollections(props.userTags);

watch(tagsList, () => {
	if (tagsList.value) {
		openAccordions.value = tagsList.value
			.filter((tagParent) => {
				return childTagsSelected(tagParent).length;
			})
			.map((tagParent) => {
				return tagParent.id.toString();
			});
	}
});
</script>

<template>
	<div>
		<div v-if="isLoading">
			<ion-spinner name="dots"></ion-spinner>
		</div>
		<h1 v-if="isError">{{ error }}</h1>

		<ion-accordion-group :multiple="true" :value="openAccordions">
			<ion-accordion
				:class="props.accordionClass ? props.accordionClass : 'ion-padding'"
				v-for="tagParent of tagsList"
				:key="tagParent.id"
				:value="tagParent.id.toString()"
				:toggleIcon="tagParent.children && tagParent.children.length ? chevronDown : ''"
				toggleIconSlot="start">
				<ion-item slot="header">
					<div class="flex w-full items-center justify-between">
						<div>{{ tagParent.name }}</div>
						<div @click="($event: MouseEvent) => $event.stopPropagation()">
							<ion-toggle
								:disabled="!!childTagsSelected(tagParent).length"
								:v-model="userTags.includes(tagParent.id)"
								:checked="userTags.includes(tagParent.id)"
								@ionChange="selectParentTag($event, tagParent)"></ion-toggle>
						</div>
					</div>
				</ion-item>
				<div
					v-if="tagParent.children && tagParent.children.length"
					:class="props.accordionContentClass ? props.accordionContentClass : 'ion-no-padding'"
					class="flex flex-wrap justify-between pt-4"
					slot="content">
					<button
						class="flex h-[30px] justify-center items-center background-white-15 border border-solid border-white-15 rounded-full mb-4 px-3 normal-case text-m"
						:class="[
							{
								'background-primary': userTags.includes(tag.id),
								'text-color-button-text': userTags.includes(tag.id),
							},
						]"
						:name="tag.name"
						v-for="tag of tagParent.children"
						:key="tag.id"
						@click="selectChildTag(tag, tagParent)">
						<span class="font-sourcesanspro-semibold">{{ tag.name }}</span>
						<ion-icon
							class="text-color-button-text"
							:icon="checkmarkOutline"
							slot="end"
							v-if="isSelected(tag)"></ion-icon>
					</button>
				</div>
			</ion-accordion>
		</ion-accordion-group>
	</div>
</template>

<style lang="scss" scoped>
ion-accordion {
	background-color: transparent;
	ion-item {
		--border-color: var(--clientx-color-white-5);
		--min-height: 60px;
	}
}
</style>
