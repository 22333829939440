<script setup lang="ts">
import { onMounted, ref, watchEffect } from 'vue';
import router from '@/router';
import { useRoute } from 'vue-router';
import { IonContent, IonIcon, IonRefresher, IonRefresherContent, IonSpinner } from '@ionic/vue';
import { chevronBackOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';
import { DateTime } from 'luxon';

import AccordionList from '@/shared/components/ui/accordionlist/AccordionList.vue';
import AccordionListItem from '@/shared/components/ui/accordionlist/AccordionListItem.vue';
import AccordionListItemAction from '@/shared/components/ui/accordionlist/AccordionListItemAction.vue';
import TopBar from '@/shared/components/TopBar.vue';
import TransactionOverview from '../components/TransactionOverview.vue';
import type { Order, WalletTransaction } from '@/shared/interfaces/wallet';
import useTenant from '@/shared/composables/useTenant';
import useWallet from '@/modules/wallet/composables/useWallet';

// Init
const route = useRoute();
const walletId = route.params.wallet_id?.toString();
const encryptedRfidTag = route.query.id?.toString();
const { getWalletTransactions, getTransactionItemName } = useWallet();
const { t, te } = useI18n();
const mappedWalletTransactions = ref<any>([]);
const { getTenantConfigAndStyles } = useTenant();
const { tenantConfig } = getTenantConfigAndStyles();
const { refetchGetWalletTransactions, removeGetWalletTransactionsResultsFromCache, walletTransactions } =
	getWalletTransactions(encryptedRfidTag, walletId);

// Refresh
const handleRefresh = async (event: any) => {
	removeGetWalletTransactionsResultsFromCache();
	mappedWalletTransactions.value = [];
	await refetchGetWalletTransactions();
	event.target.complete();
};

// Watch for walletTransactions then map the result
watchEffect(() => {
	if (
		walletTransactions.value &&
		walletTransactions.value &&
		walletTransactions.value.data &&
		walletTransactions.value.data.length
	) {
		mappedWalletTransactions.value = walletTransactions.value.data.map((order: Order) => {
			return {
				id: order.id,
				title: order.reference === 'Wallet refund' ? 'Refund' : order.type.replaceAll('_', ' '),
				type: order.type,
				subtitle: '',
				iconClass: 'text-white',
				price: order.order_price ? +order.order_price : 0,
				currency: order.currency,
				date: order.created_at,
				transactionType: order.direction,
				first_virtual_wallet_transaction: order.first_virtual_wallet_transaction,
			};
		});
	}
});

// Go back
const goBack = () => {
	router.back();
};
</script>

<template>
	<ion-content>
		<ion-refresher class="mt-8" slot="fixed" @ionRefresh="handleRefresh($event)">
			<ion-refresher-content></ion-refresher-content>
		</ion-refresher>

		<TopBar class="px-[34px]">
			<template #left>
				<div class="flex items-center justify-start cursor-pointer" @click="goBack">
					<ion-icon :icon="chevronBackOutline" class="text-white"></ion-icon>
				</div>
			</template>

			<template #center>
				<div class="flex justify-center w-full font-sourcesanspro-semibold text-l2">
					{{ t('wallet.history') }}
				</div>
			</template>
		</TopBar>

		<div class="px-[34px]">
			<AccordionList title="All transactions" class="mb-4">
				<template #items>
					<div
						v-if="!mappedWalletTransactions.length && mappedWalletTransactions.length !== 0"
						class="flex p-4 items-center justify-center">
						<ion-spinner name="dots"></ion-spinner>
					</div>
					<div
						v-if="!mappedWalletTransactions.length && mappedWalletTransactions.length === 0"
						class="flex p-4 items-center justify-center font-sourcesanspro-regular">
						{{ t('wallet.history_no_transactions') }}
					</div>

					<!-- Father item -->
					<AccordionListItem
						v-for="(item, idx) in mappedWalletTransactions"
						:key="idx"
						:key-value="item.id"
						:icon="require('@/statics/svg/arrows-left-right.svg')"
						:iconClass="item.iconClass"
						:subtitle="item.subtitle"
						:title="te(item.title) ? t(item.title) : item.title"
						:transaction-type="item.transactionType">
						<template v-slot:actions>
							<AccordionListItemAction
								v-if="tenantConfig"
								:date="DateTime.fromISO(item.date).toFormat('dd LLLL yyyy HH:mm:ss')"
								:price="item.price"
								:currency="item.currency"
								:transaction-type="item.transactionType"
								:payment-type="item.type"
								:tenant-config="tenantConfig" />
						</template>
						<template v-slot:content="slotProps">
							<TransactionOverview
								v-if="tenantConfig && item.type !== 'wallet_merge'"
								:transaction-id="
									item.first_virtual_wallet_transaction
										? item.first_virtual_wallet_transaction?.id
										: item.id
								"
								:encryptedRfidTag="encryptedRfidTag"
								:should-load="slotProps.shouldLoad"
								:tenant-config="tenantConfig" />
						</template>
					</AccordionListItem>
				</template>
			</AccordionList>
		</div>
	</ion-content>
</template>

<style lang="scss" scoped></style>
