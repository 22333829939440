/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
import { Discount, Discountable, DiscountableContract } from '@/shared/interfaces/discount';
import { Currency } from '@/shared/interfaces/balance';

export class EventTicketType extends Discountable implements TicketTypeContract {
	color?: string;
	custom_datetime: string;
	description: string;
	door_sales: boolean;
	event_id?: number;
	id: number;
	max_cap: number;
	name: string;
	price: string;
	currency_id: number;
	currency: Currency;
	quantity: number;
	remaining_tickets: number;
	ticket_limit_per_user: number;
	tickets_purchased_count: number;
	value_only: boolean;
	vat_percentage: string;

	constructor(
		data: {
			custom_datetime: string;
			description: string;
			door_sales: boolean;
			discount?: Discount;
			event_id?: number;
			id: number;
			max_cap: number;
			name: string;
			price: string;
			currency_id: number;
			currency: Currency;
			quantity: number;
			remaining_tickets: number;
			ticket_limit_per_user: number;
			tickets_purchased_count: number;
			value_only: boolean;
			vat_percentage: string;
		} = {
			custom_datetime: '',
			description: '',
			door_sales: false,
			discount: undefined,
			event_id: 0,
			id: 0,
			max_cap: -1,
			name: '',
			price: '0.00',
			currency_id: 0,
			currency: new Currency(),
			quantity: 0,
			remaining_tickets: -1,
			ticket_limit_per_user: -1,
			tickets_purchased_count: 0,
			value_only: false,
			vat_percentage: '0.00',
		},
	) {
		super(data);
		this.custom_datetime = data.custom_datetime;
		this.description = data.description;
		this.door_sales = data.door_sales;
		this.id = data.id;
		this.max_cap = data.max_cap;
		this.name = data.name;
		this.price = data.price;
		this.currency_id = data.currency_id;
		this.currency = data.currency;
		this.quantity = 0;
		this.remaining_tickets = data.remaining_tickets;
		this.ticket_limit_per_user = data.ticket_limit_per_user ? data.ticket_limit_per_user : -1;
		this.tickets_purchased_count = data.tickets_purchased_count ? data.tickets_purchased_count : 0;
		this.value_only = data.value_only;
		this.vat_percentage = data.vat_percentage;
	}

	getFullPrice(): number {
		return Number(this.price);
	}
}

export interface TicketTypeContract extends DiscountableContract {
	id: number;
	name: string;
	price: string;
	quantity: number;
	vat_percentage: string;
	max_cap: number;
	discount?: Discount;
	description: string;
	remaining_tickets: number;
	ticket_limit_per_user?: number;
	tickets_purchased_count?: number;
}
