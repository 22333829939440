import { anykrowdApi } from '@/api/anykrowdApi';

const toggleFavorite = async (type: string, id: number): Promise<{ success: string }> => {
	try {
		const response = await anykrowdApi.post<{ success: string }>('/favorites/toggle', { type, id }, true);
		return response.data;
	} catch (e) {
		return new Promise((resolve, reject) => {
			resolve({ success: 'false' });
		});
	}
};

const useFavorite = () => {
	return {
		toggleFavorite,
	};
};

export default useFavorite;
