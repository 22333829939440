<script setup lang="ts">
import router from '@/router';
import { IonContent, IonFooter, IonIcon, IonItem, IonModal } from '@ionic/vue';
import NavigationBar from '@/shared/components/ui/NavigationBar.vue';
import PersonItemBig from '@/shared/components/ui/PersonItemBig.vue';
import { addCircle, peopleOutline, personOutline, search } from 'ionicons/icons';

// Go back
const goBack = () => {
	router.back();
};

const goTo = async (link: string) => {
	await router.push(link);
};

const friendsCount = 4;
</script>

<template>
	<ion-content>
		<ion-modal ref="modal" trigger="open-modal" class="items-start">
			<div class="px-[34px] pt-16">
				<div class="flex items-center justify-end mb-3">
					<div>
						<span class="font-sourcesanspro-semibold test-base2 mr-2">Add</span>
					</div>
					<ion-icon :icon="addCircle" class="w-[20px] h-[20px]"></ion-icon>
				</div>
				<div class="flex justify-end">
					<div class="w-[200px] rounded-xl bg-white">
						<ion-item
							@click="goTo('/friends/add')"
							class="flex ion-no-padding px-4 py-2 border-b border-b-anykrowd-dark-two cursor-pointer"
							lines="none">
							<ion-icon :icon="personOutline" class="text-anykrowd-dark-purple mr-2"></ion-icon>
							<span class="font-sourcesanspro-semibold text-base2 text-anykrowd-dark-purple"
								>Add new friend</span
							>
						</ion-item>
						<ion-item
							@click="goTo('/friends/group/add')"
							class="flex ion-no-padding px-4 py-2 text-anykrowd-dark-purple cursor-pointer"
							lines="none">
							<ion-icon :icon="peopleOutline" class="text-anykrowd-dark-purple mr-2"></ion-icon>
							<span class="font-sourcesanspro-semibold text-base2 text-anykrowd-dark-purple"
								>Add new group</span
							>
						</ion-item>
					</div>
				</div>
			</div>
		</ion-modal>

		<div
			:class="[
				{
					'justify-between': friendsCount === 0,
				},
			]"
			class="flex flex-col h-full px-[34px] pt-16">
			<div class="mb-2">
				<div class="flex justify-between">
					<div class="w-[20px]">
						<ion-icon :icon="search" class="w-[20px] h-[20px]"></ion-icon>
					</div>
					<div></div>
					<div class="flex items-center cursor-pointer" id="open-modal">
						<div>
							<span class="font-sourcesanspro-semibold test-base2 mr-2">Add</span>
						</div>
						<ion-icon :icon="addCircle" class="w-[20px] h-[20px]"></ion-icon>
					</div>
				</div>

				<div class="flex items-center justify-center">
					<ion-icon :icon="peopleOutline" class="w-[20px] h-[20px] mr-1"></ion-icon>
					<span class="font-sourcesanspro-semibold text-l2">Friends</span>
				</div>
			</div>

			<!-- <div class="flex flex-col items-center">
        <div class="flex flex-col items-center mb-4">
          <ion-icon :icon="personAddOutline" class="w-[40px] h-[40px] mb-4"></ion-icon>
          <span class="font-sourcesanspro-semibold text-m2">You don't have any friends</span>
          <span class="font-sourcesanspro-regular text-sm2 text-white/60">Feel free to add new friend</span>
        </div>
        <ion-button
          class="w-[280px]"
          expand="full"
          size="large"
          shape="round"
          fill="outline">
          <div class="flex items-center justify-center text-white">
            <span class="text-m2">Add new friend</span>
          </div>
        </ion-button>
      </div> -->

			<div class="flex flex-col items-start">
				<PersonItemBig
					@onClick="goTo"
					type="group"
					id="1"
					name="BullPuppy"
					description="Aenean tempor, diam volutpat tempor varius, enim ligula.."
					:image="require('@/statics/svg-delete-after-implement/person-8.svg')"
					last-time-online="Today" />
				<PersonItemBig
					@onClick="goTo"
					id="2"
					name="April Curtis"
					description="Vivamus rhoncus, quam sed tempor."
					:image="require('@/statics/svg-delete-after-implement/person-9.svg')"
					last-time-online="Yesterday" />
				<PersonItemBig
					@onClick="goTo"
					id="3"
					name="Angela Bower"
					description="Vivamus rhoncus, quam sed tempor."
					:image="require('@/statics/svg-delete-after-implement/person-10.svg')"
					last-time-online="Today" />
				<PersonItemBig
					@onClick="goTo"
					id="4"
					name="Thomas Magnum"
					description="Aenean tempor, diam volutpat tempor varius, enim ligula.."
					:image="require('@/statics/svg-delete-after-implement/person-11.svg')"
					last-time-online="01/21/2023" />
				<PersonItemBig
					@onClick="goTo"
					id="5"
					name="Johnathan Higgins"
					description="Vivamus rhoncus, quam sed tempor."
					:image="require('@/statics/svg-delete-after-implement/person-12.svg')"
					last-time-online="01/17/2023" />
				<PersonItemBig
					@onClick="goTo"
					id="6"
					name="Willie Tanner"
					description="Aenean tempor, diam volutpat tempor varius, enim ligula.."
					:image="require('@/statics/svg-delete-after-implement/person-13.svg')"
					last-time-online="12/22/2022" />
				<PersonItemBig
					@onClick="goTo"
					id="7"
					name="Sarrah Williams"
					description="Aenean tempor, diam volutpat tempor varius, enim ligula.."
					:image="require('@/statics/svg-delete-after-implement/person-14.svg')"
					last-time-online="12/22/2022" />
			</div>

			<div class="h-[77px] mb-4">&nbsp;</div>
		</div>
	</ion-content>

	<ion-footer>
		<NavigationBar item-active="friends" />
	</ion-footer>
</template>

<style lang="scss" scoped></style>
