import vue, { onMounted, ref, Ref, watch } from 'vue';
import { alertController, loadingController } from '@ionic/vue';
import { Capacitor } from '@capacitor/core';
import { AppLauncher } from '@capacitor/app-launcher';
import { Preferences } from '@capacitor/preferences';

import { useSharedStore } from '@/stores/shared/sharedStore';
import { ValuesEnum } from '@/enums/values.enum';
import type { MinimumTopUpAmount } from '@/shared/interfaces/balance';
import type { TenantConfig } from '@/shared/interfaces/tenant';
import useConvertValuta from '@/shared/composables/useConvertValuta';
import useSessionVariable from '@/shared/composables/useSessionVariable';
import useToast from '@/shared/composables/useToast';
import useUrlHelpers from '@/shared/composables/useUrlHelpers';
import useUtils from '@/shared/composables/useUtils';
import useWallet from '@/modules/wallet/composables/useWallet';
import { Wallet } from '@/shared/interfaces/balance';

// Init
const minTopUp = 1;
const { convertValutaSync } = useConvertValuta();
const { buildRedirectUrl } = useUrlHelpers();
const { guestTopUp, guestTopUpWalletPackage, topUp, topUpWalletPackage } = useWallet();
const { presentToast } = useToast();
const { setSessionVariable } = useSessionVariable();
const { openURL, paymentMethodCountries } = useUtils();
const topUpType = ref('');

const checkIsLatestAmountGreaterThanMinimumAmount = (
	latestAmount: string | number,
	minimumAmount: string | number,
): boolean => {
	try {
		const latestAmountValue = typeof latestAmount === 'string' ? parseInt(latestAmount, 10) : latestAmount;
		const latestMinimumAmountValue =
			typeof minimumAmount === 'string' ? parseInt(minimumAmount, 10) : minimumAmount;
		return latestAmountValue > latestMinimumAmountValue;
	} catch (e) {
		return true;
	}
};

const useTopUp = (
	tenantConfig: Ref<TenantConfig | undefined>,
	minimumTopUp: Ref<MinimumTopUpAmount | undefined>,
	wallet: Ref<Wallet | undefined>,
) => {
	// Init
	const enableTopUpSubmitButton = ref(false);
	const minimumAmountError = ref(false);
	const formValue = ref(0);
	const selectedPackageId = ref(0);
	let minimumAmount = 0;
	const paymentMethodCountry = ref();
	const paymentMethodCountryObject = ref();
	const defaultPaymentMethodCountry = ref();

	const sharedStore = useSharedStore();

	const availablePaymentCountries = paymentMethodCountries.filter((country) => {
		return tenantConfig.value!.payment_countries.includes(country.value);
	});

	watch(
		tenantConfig,
		(newValue) => {
			defaultPaymentMethodCountry.value =
				availablePaymentCountries.find((country) => {
					return country.value === newValue?.default_payment_country;
				}) ?? null;
		},
		{ immediate: true },
	);

	onMounted(() => {
		Preferences.get({ key: ValuesEnum.StoreSelectedPaymentCountry }).then((paymentCountry) => {
			// Overwrite only if value exists and is included on available payment countries from backend
			if (
				paymentCountry.value &&
				availablePaymentCountries.find((country) => country.value === paymentCountry.value)
			) {
				// the payment country was already set, so let's update our internal state accordingly
				paymentMethodCountry.value = paymentCountry.value;
			} else {
				console.log('no payment country was found in storage, will just use default country.');
				countrySelectionValidated.value = false;
			}
		});
	});

	const persistDefaultCountryIfNoneWasSet = async () => {
		if (!paymentMethodCountry.value && !countrySelectionValidated.value) {
			console.log('setting default country', getApplicablePaymentCountry());
			await persistPaymentCountry(getApplicablePaymentCountry());
		}
	};

	const persistPaymentCountry = async (country: string) => {
		await Preferences.set({ key: ValuesEnum.StoreSelectedPaymentCountry, value: country });
	};

	const setCountryObject = (selectedCountry: string | null) => {
		paymentMethodCountryObject.value = availablePaymentCountries.find((country) => {
			return country.value === selectedCountry;
		});
	};

	watch(paymentMethodCountry, async (newValue, oldValue) => {
		if (oldValue !== newValue) {
			setCountryObject(newValue);
			countrySelectionValidated.value = true;
			await persistPaymentCountry(newValue);
		}
	});

	/**
	 * Fetches the appropriate payment country to use for the payment method once top up is initiated
	 */
	const getApplicablePaymentCountry = () => {
		console.log('getApplicablePaymentCountry', availablePaymentCountries, paymentMethodCountry.value);
		if (!availablePaymentCountries?.length) {
			return null;
		} else if (availablePaymentCountries.length === 1) {
			return availablePaymentCountries[0].value;
		} else {
			return paymentMethodCountry.value ?? defaultPaymentMethodCountry.value?.value ?? null;
		}
	};

	// we indicate the user has never really validated their country selection, so we know to prompt them if needed just to make sure
	const countrySelectionValidated = ref(true);

	const onAmountChange = (formValid: vue.ComputedRef<boolean>, fieldName: any, event: CustomEvent) => {
		if (event.type !== 'ion-blur') {
			onAmountInputChange(event.detail.value);
		}
	};

	const onAmountInputChange = (amount: number) => {
		// check if we have an amount, OR if the amount has been completely removed
		if (amount || (formValue.value && !amount)) {
			// default to zero if the amount was removed
			formValue.value = amount ?? 0;

			if (minimumTopUp && minimumTopUp.value && tenantConfig && tenantConfig.value) {
				enableTopUpSubmitButton.value = !!formValue.value && formValue.value >= minTopUp;

				minimumAmount = Number(minimumTopUp.value.amount / (wallet?.value?.currency.exchange_rate ?? 1.0));

				if (tenantConfig.value.custom_valuta_enabled) {
					minimumAmount = Number(convertValutaSync(tenantConfig.value, minimumAmount, true));
				}

				if (minimumTopUp.value.amount && minimumAmount > formValue.value) {
					enableTopUpSubmitButton.value = false;
					minimumAmountError.value = true;
				} else {
					minimumAmountError.value = false;
				}
			}
		}
	};

	const selectTopUp = (
		walletPackageId: number,
		paymentMethodCountry: string,
		isPayconiqTopUp: boolean,
		encryptedRfidTag?: string,
	) => {
		selectedPackageId.value = walletPackageId;
		startPackageTopUp(paymentMethodCountry, isPayconiqTopUp, encryptedRfidTag);
	};

	const startTopUp = async (
		paymentMethodCountry: string,
		isPayconiqTopUp: boolean,
		encryptedRfidTag?: string,
		walletId?: string,
		currencyId?: number,
	) => {
		// Show loader
		const loading = await loadingController.create({});
		await loading.present();

		// Build redirect url
		const redirectUrl = buildRedirectUrl();

		// Convert amount if needed
		const amount = Number(formValue.value);

		// Create request body
		const data: any = {
			amount: amount.toFixed(2),
			payconiq: isPayconiqTopUp,
			redirectUrl,
		};

		if (paymentMethodCountry) {
			data.country = paymentMethodCountry;
		}

		// if it is guest top up, add encryptedRfidTag
		if (encryptedRfidTag) {
			data.encrypted_rfid_tag = encryptedRfidTag;
			data.currency_id = currencyId;
		}

		data.wallet_id = walletId;

		const badConnectionTimeout = setTimeout(async () => {
			// if we reach this, we don't have a response from the server yet
			await loading.dismiss();
			await (
				await alertController.create({
					header: 'Bad connection',
					message: `It seems we're unable to connect to the payment service - You can try again or use the local top up desks if available`,
					buttons: ['OK'],
				})
			).present();
		}, 15000);

		// Execute call
		try {
			const { redirectUrl } = encryptedRfidTag ? await guestTopUp(data) : await topUp(data);

			clearTimeout(badConnectionTimeout);
			setSessionVariable(ValuesEnum.SessionTopUpSuccessData, JSON.stringify(data));
			await loading.dismiss();

			if (Capacitor.isNativePlatform()) {
				try {
					await AppLauncher.openUrl({ url: redirectUrl });
					return;
				} catch (e) {
					console.log(e);
				}
			}

			await openURL(redirectUrl);
		} catch (error: any) {
			await loading.dismiss();
			await presentToast('top', error, 5000, 'danger');
		}
	};

	const startPackageTopUp = async (
		paymentMethodCountry: string,
		isPayconiqTopUp: boolean,
		encryptedRfidTag?: string,
	) => {
		// Show loader
		const loading = await loadingController.create({});
		await loading.present();

		// Build redirect url
		const redirectUrl = buildRedirectUrl();

		// Create request body
		const data: any = {
			walletPackageId: selectedPackageId.value,
			redirectUrl,
			payconiq: isPayconiqTopUp,
		};

		if (paymentMethodCountry) {
			data.country = paymentMethodCountry;
		}

		// if it is guest top up, add encryptedRfidTag
		if (encryptedRfidTag) {
			data.encrypted_rfid_tag = encryptedRfidTag;
		}

		const badConnectionTimeout = setTimeout(async () => {
			// if we reach this, we don't have a response from the server yet
			await loading.dismiss();
			await (
				await alertController.create({
					header: 'Bad connection',
					'message':
						"It seems we're unable to connect to the payment service - You can try again or use the local top up desks if available",
					buttons: ['OK'],
				})
			).present();
		}, 15000);

		// Execute call
		try {
			const { redirectUrl } = encryptedRfidTag
				? await guestTopUpWalletPackage(data)
				: await topUpWalletPackage(data);

			clearTimeout(badConnectionTimeout);
			setSessionVariable(ValuesEnum.SessionTopUpSuccessData, JSON.stringify(data));
			await loading.dismiss();

			if (Capacitor.isNativePlatform()) {
				try {
					await AppLauncher.openUrl({ url: redirectUrl });
					return;
				} catch (e) {
					console.log(e);
				}
			}

			window.location.href = redirectUrl;
		} catch (error: any) {
			await loading.dismiss();
			await presentToast('top', error, 5000, 'danger');
		}
	};

	const switchPayconiqMode = () => {
		sharedStore.shouldUsePayconiq = !sharedStore.shouldUsePayconiq;
	};

	return {
		availablePaymentCountries,
		checkIsLatestAmountGreaterThanMinimumAmount,
		enableTopUpSubmitButton,
		formValue,
		minimumAmountError,
		onAmountChange,
		onAmountInputChange,
		paymentMethodCountry,
		paymentMethodCountryObject,
		selectTopUp,
		setFlag: setCountryObject,
		startTopUp,
		switchPayconiqMode,
		topUpType,
		countrySelectionValidated,
		getApplicablePaymentCountry,
		defaultPaymentMethodCountry,
		persistDefaultCountryIfNoneWasSet,
	};
};

export default useTopUp;
