<script setup lang="ts">
import router from '@/router';
import { IonContent, IonIcon } from '@ionic/vue';
import { chevronBackOutline, cloudUploadOutline } from 'ionicons/icons';
import PersonItemMini from '@/shared/components/ui/PersonItemMini.vue';
import TopBar from '@/shared/components/TopBar.vue';

// Go back
const goBack = () => {
	router.back();
};

const goTo = async (link: string) => {
	await router.push(link);
};

const friendsCount = 4;
</script>

<template>
	<ion-content>
		<div class="flex flex-col items-center justify-between h-full px-[34px]">
			<div>
				<TopBar>
					<template #left>
						<div class="flex items-center justify-start cursor-pointer" @click="goBack">
							<ion-icon :icon="chevronBackOutline" class="text-white"></ion-icon>
						</div>
					</template>

					<template #center>
						<div class="flex grow justify-center w-full font-sourcesanspro-semibold text-l2">New Group</div>
					</template>
				</TopBar>

				<div class="flex justify-center mb-2">
					<div
						class="flex flex-col w-full max-w-[325px] h-[200px] items-center justify-center backdrop-blur-2xl bg-white/10 border border-dashed border-white/20 rounded-md">
						<ion-icon :icon="cloudUploadOutline" class="w-[40px] h-[40px] mb-4"></ion-icon>
						<div class="font-sourcesanspro-semibold text-base2">
							<span class="text-[#32BCE8]">Click here </span>
							<span>to upload image</span>
						</div>
						<span class="font-sourcesanspro-semibold text-sm text-white/50">20MB is max file size</span>
					</div>
				</div>

				<div class="flex justify-center mb-2">
					<div class="">
						<ion-input
							id="input-field"
							class="w-[325px] h-[52px] ion-padding-horizontal py-4 rounded-full border border-anykrowd-gray/20 font-sourcesanspro-regular text-base text-white"
							type="text"
							placeholder="Group name*" />
					</div>
				</div>

				<div class="flex justify-center mb-2">
					<ion-button class="w-[325px] h-[52px]" expand="full" shape="round">
						<div class="flex w-full justify-between items-center font-sourcesanspro-semibold text-base">
							<span>Select friends to group</span>
							<ion-icon
								:icon="require('@/statics/svg/people-add-2.svg')"
								@click="goTo('/friends/select')"
								class="w-[26px] h-[26px] text-anykrowd-dark-purple cursor-pointer"></ion-icon>
						</div>
					</ion-button>
				</div>

				<div class="flex flex-wrap max-w-[325px] justify-between">
					<PersonItemMini
						class="mb-2"
						name="Allien M."
						:image="require('@/statics/svg-delete-after-implement/person-1.svg')" />
					<PersonItemMini
						class="mb-2"
						name="Amanda B."
						:image="require('@/statics/svg-delete-after-implement/person-3.svg')" />
					<PersonItemMini
						class="mb-2"
						name="Adebayor M"
						:image="require('@/statics/svg-delete-after-implement/person-6.svg')" />
				</div>
			</div>

			<div class="flex w-full align-center mb-[34px]">
				<ion-button class="w-full" expand="full" size="large" shape="round">
					<div class="flex w-full justify-center items-center">Confirm</div>
				</ion-button>
			</div>
		</div>
	</ion-content>
</template>

<style lang="scss" scoped></style>
