<script setup lang="ts">
import vue, { PropType, ref } from 'vue';
import * as yup from 'yup';
import { useForm } from 'vee-validate';
import { IonAccordionGroup, IonAccordion, IonButton, IonIcon } from '@ionic/vue';
import {
	checkmarkOutline,
	chevronForwardOutline,
	chevronDownOutline,
	closeOutline,
	ticketOutline,
} from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import { Cart } from '@/shared/interfaces/cart';
import { useSharedStore } from '@/stores/shared/sharedStore';
import TextInput from '@/shared/components/sign-up-forms/TextInput.vue';
import { useCart } from '@/modules/shop/composables/useCart';

// Props
const props = defineProps({
	shouldBlockChanges: { type: Boolean, default: false },
	cartItem: { type: Object as PropType<Cart>, required: true },
	cartItemProductId: { type: Number, required: true },
	cartItemIndex: { type: Number, required: true },
	eventId: { type: Number, required: true },
});

// Init
const { t } = useI18n();
const isOpen = ref(false);
const isFormValid = ref(false);
const sharedStore = useSharedStore();
const { updateCartAdditionalInfoByIndex } = useCart();

// Schema
const validationSchema = yup.object({
	memberEmail: yup
		.string()
		.required()
		.label(t('events.tickets.add_members.email_label'))
		.matches(sharedStore.EMAIL_REGX, t('events.tickets.add_members.errors.email')),
	memberFullName: yup.string().label(t('events.tickets.add_members.full_name_label')).required(),
});

const { meta, setFieldValue, validate, validateField, values, resetForm } = useForm({
	validationSchema,
});

// Remove member
const removeMember = () => {
	updateCartAdditionalInfoByIndex(props.cartItemIndex, {
		...props.cartItem.additionalInfo,
		name: null,
		email: null,
		user_id: null,
	});
	resetForm();
};

// Validating form
const onInputChange = async (formValid: vue.ComputedRef<boolean>, fieldName: any) => {
	await validateField(fieldName.value);
	isFormValid.value = meta.value.valid;
};

// Handle submit
const onSubmit = (event: Event) => {
	validate();
	event.preventDefault();
	updateCartAdditionalInfoByIndex(props.cartItemIndex, {
		...props.cartItem.additionalInfo,
		name: values.memberFullName,
		email: values.memberEmail,
		user_id: null,
	});
	isFormValid.value = meta.value.valid;
};
</script>

<template>
	<ion-accordion-group>
		<ion-accordion class="mb-4 items-start justify-between backdrop-blur-3xl bg-[#3f3e4a] rounded-lg" value="first">
			<div
				class="flex w-full justify-between h-[70px] px-4 py-2 cursor-pointer"
				slot="header"
				@click="isOpen = !isOpen">
				<div class="flex items-center">
					<div class="flex items-center justify-center mr-2">
						<ion-icon
							:class="
								!props.cartItem.additionalInfo.name ? 'text-white' : 'text-anykrowd-ticket-check-green'
							"
							:icon="!props.cartItem.additionalInfo.name ? ticketOutline : checkmarkOutline"
							class="w-[22px] h-[22px] py-2 rounded-full"></ion-icon>
					</div>
					<div class="flex flex-col justify-center w-full">
						<span class="font-sourcesanspro-semibold text-sm text-white/50"
							>{{ cartItem?.product.name }}:</span
						>
						<div class="flex items-center">
							<div class="mr-1">
								<span class="font-sourcesanspro-semibolditalic text-m2">{{
									props.cartItem.additionalInfo.name
								}}</span>
							</div>
							<span
								v-if="!props.cartItem.additionalInfo.name"
								class="font-sourcesanspro-semibolditalic text-sm2 text-white/50"
								>({{ t('components.events_item_member_adder.empty') }})</span
							>
						</div>
					</div>
				</div>
				<div class="flex items-center font-sourcesanspro-semibold text-lg24">
					<ion-icon :icon="isOpen ? chevronDownOutline : chevronForwardOutline" class="text-white"></ion-icon>
				</div>
			</div>
			<div
				:class="[{ 'flex': props.cartItem.additionalInfo.name }]"
				class="ion-padding border-t border-t-white/10 font-sourcesanspro-regular text-base2 font-color-text-50"
				slot="content">
				<div
					v-show="!props.cartItem.additionalInfo.name || !props.cartItem.additionalInfo.email"
					class="w-full">
					<form
						:id="`form-${cartItemIndex}`"
						@submit="(event: Event) => onSubmit(event)"
						:validation-schema="validationSchema"
						class="w-full">
						<div class="relative mb-4">
							<div class="flex items-start">
								<div class="flex grow items-center mr-2">
									<TextInput
										wrapper-class="flex flex-wrap justify-left item-center mb-2 border border-solid focus-within:background-white-15"
										name="memberFullName"
										:value="props.cartItem.additionalInfo.name"
										type="text"
										:placeholder="t('components.events_item_member_adder.memberFullName')"
										@inputChanged="onInputChange" />
								</div>
								<!-- <ion-button
									class="rounded ion-no-padding ion-no-margin transparent"
									:routerLink="`/friends/select?type=ticket-creation&id=${props.cartItemIndex}&eventId=${props.eventId}`">
									<ion-icon
										:icon="require('@/statics/svg/people-add.svg')"
										class="w-[50px] h-[50px] cursor-pointer"></ion-icon>
								</ion-button> -->
							</div>
						</div>
						<TextInput
							wrapper-class="flex flex-wrap justify-left item-center mb-2 border border-solid focus-within:background-white-15"
							name="memberEmail"
							:value="props.cartItem.additionalInfo.email"
							type="email"
							:placeholder="t('components.events_item_member_adder.memberEmail')"
							@inputChanged="onInputChange" />
						<ion-button
							type="submit"
							:disabled="!isFormValid"
							shape="round"
							class="hover:rounded-full hover:bg-white">
							{{ t('components.events_item_member_adder.save') }}
						</ion-button>
					</form>
				</div>

				<template v-if="props.cartItem.additionalInfo.name && props.cartItem.additionalInfo.email">
					<div
						class="flex grow items-center justify-between h-[55px] mr-2 px-4 py-2 rounded-full border border-anykrowd-gray/20 background-anykrowd-gray mb-4">
						<div class="flex">
							<ion-icon class="cursor-pointer mr-2" :icon="ticketOutline" slot="end"></ion-icon>
							<span class="font-sourcesanspro-semibold text-base2 text-white">{{
								props.cartItem.additionalInfo.name
							}}</span>
						</div>

						<ion-button
							v-if="!shouldBlockChanges"
							class="transparent ion-no-margin ion-no-padding"
							shape="round"
							@click="removeMember">
							<ion-icon class="cursor-pointer" :icon="closeOutline"></ion-icon>
						</ion-button>
					</div>
					<!-- <ion-button
						v-if="!shouldBlockChanges"
						class="rounded ion-no-padding ion-no-margin transparent"
						:routerLink="`/friends/select?type=ticket-creation&id=${props.cartItemIndex}&eventId=${props.eventId}`">
						<ion-icon
							:icon="require('@/statics/svg/people-add.svg')"
							class="w-[50px] h-[50px] cursor-pointer"></ion-icon>
					</ion-button> -->
				</template>
			</div>
		</ion-accordion>
	</ion-accordion-group>
</template>

<style scoped></style>
