import { computed, ref } from 'vue';

// Init
const stepIndex = ref(0);

const previousStep = () => {
	if (stepIndex.value > 0) {
		_decrementStep();
	}
};

const _decrementStep = () => {
	stepIndex.value -= 1;
};

const _incrementStep = () => {
	stepIndex.value += 1;
};

const nextStep = () => {
	_incrementStep();
};

// useStepChanger
const useStepChanger = (totalSteps?: number | null) => {
	// Calculate last step if total is provided
	const isLastStep = computed(() => {
		return stepIndex.value === totalSteps;
	});

	return {
		isLastStep,
		nextStep,
		previousStep,
		stepIndex,
	};
};

export default useStepChanger;
