import { Discountable } from '@/shared/interfaces/discount';
import { Pagination } from '@/shared/interfaces/pagination';

/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
export class TableSpot {
	public id: number;
	public friendly_id: string;
	public manual_location: boolean;
	public manual_location_type: string;
	public manual_location_values: string;
	public table_number: string;

	constructor(data: {
		id: number;
		friendly_id: string;
		manual_location: 0 | 1 | boolean;
		manual_location_type: string;
		manual_location_values: string;
		table_number: string;
	}) {
		this.id = data.id;
		this.friendly_id = data?.friendly_id || '';
		this.manual_location = !!data?.manual_location || false;
		this.manual_location_type = data?.manual_location_type || '';
		this.manual_location_values = data?.manual_location_values || '';
		this.table_number = data?.table_number || '';
	}

	/**
	 * Array representation of pre defined manual locations
	 */
	get manualLocationValues(): string[] {
		if (this.manual_location_values?.trim() !== '') {
			return this.manual_location_values?.split(',').map((location) => location.trim());
		}

		return [];
	}
}

export class TableSpotLocation {
	public table_number: string;
	constructor(data: { table_number: string }) {
		this.table_number = data.table_number;
	}
}

export class TableSpotCartItem {
	constructor(
		public product: Product,
		public quantity = 0,
		public comments: string[] = [],
	) {}
}

export class TableSpotCart {
	constructor(public items: TableSpotCartItem[] = []) {}

	protected modifyQuantity(product: Product, modifier: number): number | false {
		const tableSpotCartItem = this.items.find((i) => i.product.id === product.id);

		if (!tableSpotCartItem) {
			return false;
		}

		tableSpotCartItem.quantity += modifier;

		return tableSpotCartItem.quantity;
	}

	sumQuantity(product: Product, amount = 1) {
		const newQuantity = this.modifyQuantity(product, amount);

		if (newQuantity === false) {
			// Wasn't modified because it isn't present so we need to add it
			return this.addToCart(product, amount);
		}

		return this;
	}

	subQuantity(product: Product, amount = 1): this {
		const newQuantity = this.modifyQuantity(product, -amount);

		if (newQuantity === false) {
			return this; // isn't present so we just skip
		} else if (newQuantity === 0) {
			return this.removeFromCart(product);
		}

		return this;
	}

	addToCart(product: Product, amount = 1): this {
		// First we check if the item already exists, and if it does we will instead just sum the quantity
		if (this.items.find((i) => i.product.id === product.id)) {
			return this.sumQuantity(product, amount);
		}

		// if it doesn't we add it as a new item
		this.items.push(new TableSpotCartItem(product, amount));

		return this;
	}

	removeFromCart(product: Product): this {
		const indexToRemove = this.items.findIndex((i) => i.product.id === product.id);

		if (indexToRemove !== -1) {
			this.items.splice(indexToRemove, 1);
		}

		return this;
	}

	updateComments(product: Product, comments: string[] = []): this {
		// First we need to find the item
		const tableSpotCartItem = this.items.find((i) => i.product.id === product.id);

		if (!tableSpotCartItem) {
			return this;
		}

		tableSpotCartItem.comments = comments;

		return this;
	}
}

export class ProductCategory {
	public id: number;
	public sale_catalogue_id: number;
	public order: number;
	public name: string;
	public vat_percentage: string;
	public image_url: string;
	public icon: string;
	public icon_color: string;

	constructor(data: {
		id: number;
		sale_catalogue_id: number;
		order: number;
		name: string;
		vat_percentage: string;
		image_url: string;
		icon: string;
		icon_color: string;
	}) {
		this.id = data.id;
		this.sale_catalogue_id = data.sale_catalogue_id;
		this.order = data.order;
		this.name = data.name;
		this.vat_percentage = data.vat_percentage;
		this.image_url = data.image_url;
		this.icon = data.icon;
		this.icon_color = data.icon_color;
	}
}

export class Product extends Discountable {
	public id: number;
	public product_category_id: number;
	public order: number;
	public name: string;
	public description: string;
	public image_url: string;
	public price: string;
	public quantity: number;
	public vat_percentage: string;
	public is_warranty_product: boolean;
	public stock: number;
	public stock_type: string;
	public default_stock: number;
	public linked_warranty_product_price?: Product;
	public disable_sale_catalogue_payments: boolean;

	constructor(data: any) {
		super(data);
		this.id = data.id;
		this.product_category_id = data.product_category_id;
		this.order = data.order;
		this.name = data.name;
		this.description = data.description;
		this.image_url = data.image_url;
		this.price = data.price;
		this.quantity = 0;
		this.vat_percentage = data.vat_percentage;
		this.is_warranty_product = data.is_warranty_product;
		this.stock = data.stock;
		this.stock_type = data.stock_type;
		this.default_stock = data.default_stock;
		this.linked_warranty_product_price = data.linked_warranty_product_price;
		this.disable_sale_catalogue_payments = data.disable_sale_catalogue_payments;
	}

	public getFullPrice(): number {
		return Number(this.price);
	}
}

export class Products {
	current_page: number;
	data: Product[];
	first_page_url: string;
	from: number;
	last_page: number;
	last_page_url: string;
	links: Pagination[];
	next_page_url: string;
	path: string;
	per_page: number;
	prev_page_url: string;
	to: number;
	total: number;
	constructor() {
		this.current_page = 0;
		this.data = [];
		this.first_page_url = '';
		this.from = 0;
		this.last_page = 0;
		this.last_page_url = '';
		this.links = [];
		this.next_page_url = '';
		this.path = '';
		this.per_page = 0;
		this.prev_page_url = '';
		this.to = 0;
		this.total = 0;
	}
}
