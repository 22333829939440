import { ref } from 'vue';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { PushNotifications, Token } from '@capacitor/push-notifications';
import { getMessaging, getToken } from 'firebase/messaging';

import { anykrowdApi } from '@/api/anykrowdApi';

// Init
const PUBLIC_PUSH_VAPID_KEY = process.env.VUE_APP_PUBLIC_PUSH_VAPID_KEY;
const notificationToken = ref('');

const requestPushNotificationsPermission = async () => {
	if (Capacitor.isNativePlatform()) {
		PushNotifications.addListener('registration', async (token: Token) => {
			notificationToken.value = token.value;
			await _registerToken();
		});

		PushNotifications.requestPermissions().then(async (result) => {
			// this.notificationPermission = result.receive;
			if (result.receive === 'granted') {
				// Register with Apple / Google to receive push via APNS/FCM
				await PushNotifications.register();
			}
		});
	} else if (typeof Notification !== 'undefined') {
		Notification.requestPermission().then(async (permission) => {
			if (permission === 'granted') {
				const messaging = getMessaging();
				console.log('Notification permission granted.');
				// Get registration token
				await getToken(messaging, { vapidKey: PUBLIC_PUSH_VAPID_KEY }).then(async (currentToken) => {
					if (currentToken) {
						notificationToken.value = currentToken;
						await _registerToken();
						// Send token to server or use it to send notifications
					} else {
						console.log('No registration token available.');
					}
				});
			} else {
				console.log('Unable to get permission to notify.');
			}
		});
	}
};

const _registerToken = async () => {
	if (notificationToken.value) {
		const deviceInfo = await Device.getInfo();

		return anykrowdApi.post(
			'/notifications/register-push-token',
			{
				token: notificationToken.value,
				device_name: deviceInfo.name,
				device_model: deviceInfo.model,
				device_id: (await Device.getId()).identifier,
			},
			false,
		);
	}
};

const useAdditionalConfig = () => {
	return {
		notificationToken,
		requestPushNotificationsPermission,
	};
};

export default useAdditionalConfig;
