<script setup lang="ts">
import { IonAccordion, IonIcon, IonItem, IonLabel } from '@ionic/vue';
const props = defineProps({
	cssClass: { type: String },
	icon: { type: String, required: true },
	id: { type: Number, required: true },
	title: { type: String, required: true },
	show: { type: Boolean, default: true },
});
</script>

<template>
	<ion-accordion v-if="props.show" :class="props.cssClass" :value="props.id.toString()">
		<ion-item class="border-b border-white/5" slot="header" lines="none">
			<div class="flex items-center py-4">
				<ion-icon class="text-[20px] mr-2" :src="props.icon"></ion-icon>
				<ion-label class="font-sourcesanspro-semibold text-m2">{{ props.title }}</ion-label>
			</div>
		</ion-item>
		<div slot="content">
			<slot />
		</div>
	</ion-accordion>
</template>

<style lang="scss" scoped>
ion-accordion {
	background-color: transparent;
}
</style>
