<script setup lang="ts">
import { computed } from 'vue';
import router from '@/router';
import { IonContent, IonIcon, IonButton } from '@ionic/vue';
import { closeOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import { CartCategoriesEnum } from '@/enums/values.enum';
import { TransactionType } from '@/enums/wallet.enum';
import { useCart } from '@/modules/shop/composables/useCart';
import { useCheckout } from '@/shared/composables/useCheckout';
import hexRgb from 'hex-rgb';
import ItemAdder from '@/shared/components/ui/ItemAdder.vue';
import rgbHex from 'rgb-hex';
import TopAlert from '@/shared/components/ui/TopAlert.vue';
import useUtils from '@/shared/composables/useUtils';

// Init
const { t } = useI18n();
const { cartTotal, cartCount, clearCart, decreaseProductQuantity, getCartItems, increaseProductQuantity } = useCart();
const { processCheckout } = useCheckout();
const { calculateShade, convertToCurrency, paymentMethodCountries, replaceTopUpGeneralMessageVariables } = useUtils();
const primary_color = window.primary_color;
const primaryRgb = hexRgb(window.primary_color, { format: 'array' });
const primaryShade = '#' + rgbHex(calculateShade(primaryRgb.toString(), 0.2));

// Clear cart but keep current category items
clearCart(CartCategoriesEnum.shop);

// Compute the total price for products in the cart
const totalPrice = computed(() => cartTotal.value);

// Go back
const goBack = () => {
	router.back();
};
</script>

<template>
	<ion-content :style="`background: linear-gradient(${primary_color}, ${primaryShade});`">
		<div class="flex flex-col h-full px-[34px]">
			<div class="flex flex-col mt-12 mb-4 items-center">
				<div class="flex items-center justify-center mb-4 w-[60px] h-[60px] rounded-full bg-anykrowd-gray/30">
					<ion-icon
						class="text-[38px] text-white cursor-pointer"
						:icon="closeOutline"
						@click="goBack()"></ion-icon>
				</div>
			</div>

			<div>
				<div class="mb-8">
					<div>
						<top-alert :class="'alert-danger text-center'" v-if="!cartCount">
							{{ t('shop.cart.empty') }}
						</top-alert>
						<ItemAdder
							v-else
							v-for="cartItem in getCartItems()"
							@onDecreaseProductQuantity="decreaseProductQuantity"
							@onIncreaseProductQuantity="increaseProductQuantity"
							:expandable="false"
							:key="cartItem.product.id"
							:id="cartItem.product.id"
							:productId="cartItem.product.id"
							class="mb-4"
							:title="cartItem.product.name"
							:discount="cartItem.product.discount"
							:price="+cartItem.product.price"
							:priceWithDiscount="+cartItem.product.discountedPrice()!"
							:quantity="cartItem.quantity"
							:can-add-more="true" />
					</div>
				</div>
			</div>
		</div>

		<div class="fixed button-container h-[150px] px-[34px] left-1/2 -translate-x-1/2 bottom-0 w-full">
			<div class="relative z-10 w-full h-full">
				<div class="absolute w-full bottom-0 mb-[34px]">
					<ion-button
						class="w-full h-[60px]"
						expand="full"
						size="large"
						shape="round"
						:disabled="!cartCount"
						@click.prevent="processCheckout(TransactionType.SHOP_ORDER)">
						<div class="flex w-full justify-between items-center font-sourcesanspro-semibold text-l2">
							<span>{{ t('shop.cart.checkout') }}</span>
							<span>€{{ totalPrice.toFixed(2) }}</span>
						</div>
					</ion-button>
				</div>
			</div>
		</div>
	</ion-content>
</template>

<style lang="scss" scoped>
ion-searchbar {
	--box-shadow: none;
}
</style>
