import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { anykrowdApi } from '@/api/anykrowdApi';
import { GroupedShopProducts, ShopProduct } from '@/shared/interfaces/shopProduct';
import { useQuery } from '@tanstack/vue-query';
import { ShopCategory } from '@/shared/interfaces/shopCategory';

// Get products api call
const _getProducts = async (params: any | null) => {
	const { data } = await anykrowdApi.get<ShopProduct[]>('/shop/products', false, { params });
	return data;
};

const getProducts = (params: any) => {
	// Init
	const products = ref<ShopProduct[]>()!;

	const { data: productsData } = useQuery({
		queryKey: ['shop'],
		queryFn: () => _getProducts(params),
		retry: 3,
	});

	// Watch userProfile and registrationFormData
	watch(
		productsData,
		() => {
			if (productsData.value) {
				products.value = [...productsData.value];
			}
		},
		{ immediate: true },
	);

	// Return
	return {
		products,
	};
};

// Get products details api call
const _getProductDetails = async (productId: number) => {
	const { data } = await anykrowdApi.get<ShopProduct>(`/shop/products/${productId}`, false);
	return data;
};

// Get product details
const getProductDetails = (productId: number) => {
	// Init
	const { t } = useI18n();
	const product = ref<ShopProduct>()!;
	const loadingData = ref<boolean>()!;

	const {
		data: productDetails,
		isLoading: loading,
		isError,
	} = useQuery(['shopProduct', productId], () => _getProductDetails(productId), {
		keepPreviousData: true,
		retry: 3,
		refetchOnMount: true,
	});

	watch(
		productDetails,
		() => {
			if (productDetails.value) {
				product.value = new ShopProduct({ ...productDetails.value });
			}
		},
		{ immediate: true },
	);

	watch(
		loading,
		() => {
			if (loading.value) {
				loadingData.value = loading.value;
			}
		},
		{ immediate: true },
	);

	return { product, loadingData, isError };
};

// Get categories api call
const _getCategories = async () => {
	try {
		const { data } = await anykrowdApi.get<ShopCategory[]>(`/shop/product-categories`, false);
		return data;
	} catch (err) {
		return err;
	}
};

// Get categories
const getCategories = () => {
	const {
		data: shopCategories,
		isLoading: isLoadingShopCategories,
		isError,
	} = useQuery(['shopCategory'], async () => await _getCategories(), {
		keepPreviousData: true,
		retry: 3,
	});
	return { shopCategories, isLoadingShopCategories, isError };
};

// Get products and categories
const _getProductsAndCategories = async (id: number | null = null) => {
	const categories: any = await _getCategories();
	const groupedProducts: GroupedShopProducts[] = [];

	// Addign spotlight category to show all products
	if (!id) {
		categories.unshift({ id: 'spotlight', name: 'spotlight' });
	}

	await Promise.all(
		categories.map(async (category: ShopCategory) => {
			const params =
				category.name !== 'spotlight'
					? {
							query: '',
							web_shop_product_category_id: category.id,
						}
					: {
							query: '',
							spotlight: 1,
							register_interaction: 1,
						};

			const products: ShopProduct[] = await _getProducts(params);
			groupedProducts.push({
				category_id: category.id,
				category_name: category.name,
				products,
			});
		}),
	);

	// TODO: Categories should not be loaded all again and filtered.
	if (id) {
		return groupedProducts.filter((category) => category.category_id === Number(id));
	}

	return groupedProducts;
};

// Get categorized products
const getCategorizedProducts = (id: number | null = null) => {
	// Init
	const categoriesWithProducts = ref<GroupedShopProducts[]>()!;

	const {
		data: categorizedData,
		isLoading,
		isError,
	} = useQuery(
		['shopCategoriesWithProducts', id === 0 ? 'all' : id],
		async () => await _getProductsAndCategories(id),
		{
			keepPreviousData: true,
			retry: 3,
		},
	);

	watch(
		categorizedData,
		() => {
			if (categorizedData.value) {
				categoriesWithProducts.value = categorizedData.value
					.map((category) => {
						return {
							...category,
							products: category.products.map((product) => {
								return new ShopProduct({ ...product });
							}),
						};
					})
					.sort((categoryA, categoryB) => {
						return categoryA.category_name === 'spotlight'
							? -1
							: categoryB.category_name === 'spotlight'
								? 1
								: categoryA.category_name.localeCompare(categoryB.category_name);
					});
			}
		},
		{ immediate: true },
	);

	return { categoriesWithProducts, isLoading, isError };
};

const useShop = () => {
	return {
		getCategories,
		getCategorizedProducts,
		getProducts,
		getProductDetails,
	};
};

export default useShop;
