<script setup lang="ts">
import router from '@/router';
import { IonButton, IonContent, IonIcon } from '@ionic/vue';
import TopBar from '@/shared/components/TopBar.vue';
import {
	chevronBackOutline,
	downloadOutline,
	enterOutline,
	locationOutline,
	lockClosedOutline,
	qrCodeOutline,
	shareSocialOutline,
} from 'ionicons/icons';

// Go back
const goBack = () => {
	router.back();
};
</script>

<template>
	<ion-content>
		<div class="flex flex-col h-full justify-between">
			<div>
				<TopBar class="px-[34px]">
					<template #left>
						<div class="flex items-center justify-start cursor-pointer" @click="goBack">
							<ion-icon :icon="chevronBackOutline" class="text-white"></ion-icon>
						</div>
					</template>

					<template #center>
						<div class="flex grow justify-center w-full font-sourcesanspro-semibold text-l2">Locker</div>
					</template>

					<template #right>
						<div class="flex grow items-center justify-end cursor-pointer">
							<ion-icon :icon="downloadOutline" class="mr-2"></ion-icon>
							<ion-icon :icon="shareSocialOutline"></ion-icon>
						</div>
					</template>
				</TopBar>

				<div class="flex justify-center mt-10 px-[34px]">
					<div class="flex items-center justify-center flex-col">
						<ion-icon :icon="lockClosedOutline" class="w-[48px] h-[48px] mb-4 text-white"></ion-icon>
						<div class="mb-4">
							<span class="font-sourcesanspro-semibold text-m2 font-color-text-50">Locker NO.</span>
						</div>
						<span class="font-sourcesanspro-light text-[80px]"> 2G7A </span>
						<div class="flex items-center mb-3 font-color-text-50">
							<ion-icon :icon="locationOutline" class="text-base2 mr-1"></ion-icon>
							<span class="font-sourcesanspro-regular text-l2">area 2B</span>
						</div>
						<div
							class="w-[250px] text-center font-sourcesanspro-regular font-color-text-50 text-base2 leading-[26px]">
							Donec gravida ipsum ex, porttitor sagittis elit pharetra eget.
						</div>
					</div>
				</div>
			</div>

			<div class="flex flex-col w-full px-[34px] align-center mb-[34px]">
				<ion-button class="w-full mb-4" expand="full" size="large" shape="round">
					<div class="flex items-center justify-center">
						<ion-icon :icon="enterOutline" class="text-dark-blue mr-2"></ion-icon>
						<span class="text-m2">Open Manual</span>
					</div>
				</ion-button>
				<ion-button class="w-full" expand="full" size="large" shape="round" fill="outline">
					<div class="flex items-center justify-center text-white">
						<ion-icon :icon="qrCodeOutline" class="mr-2"></ion-icon>
						<span class="text-m2">Open QR Code</span>
					</div>
				</ion-button>
			</div>
		</div>
	</ion-content>
</template>

<style lang="scss" scoped></style>
