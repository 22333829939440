<script setup lang="ts">
import { useAuthStore } from '@/stores/auth/authStore';
import { captureMessage } from '@/sentry';
import { storeToRefs } from 'pinia';
import { onMounted } from 'vue';
import { useRoute } from 'vue-router';
import router from '@/router';
import { ValuesEnum } from '@/enums/values.enum';
import { set } from '@/shared/composables/useStorageService';

const route = useRoute();
const token = route.params.token.toString();

onMounted(async () => {
	if (token) {
		const redirectUrl = route.query?.redirect_url;
		const authStore = useAuthStore();

		const user = await authStore.saveToken({ token });

		if (user && user.token) {
			if (redirectUrl) {
				window.location.href = redirectUrl as string;
			} else {
				await router.push('/dashboard');
			}
		} else {
			captureMessage(`Error magic login saving token: ${user}`);
		}
	}
});
</script>

<template>
	<div>
		<div v-if="!token" class="flex w-full items-center justify-center mb-4 mt-4 text-white">
			There was an error with the magic link. Please contact support.
		</div>
	</div>
</template>
