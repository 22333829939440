<script setup lang="ts">
import { defineProps } from 'vue';

const props = defineProps({
	alt: { type: String },
	border: { type: String },
	borderClass: { type: String },
	imageClass: { type: String },
	containerClass: { type: String },
	src: { type: String, required: true },
	showStatus: { type: Boolean, default: true },
});
</script>

<template>
	<div
		class="relative flex items-center justify-center"
		:class="props.containerClass ? props.containerClass : 'w-[70px] h-[70px]'">
		<div class="avatar-container relative">
			<img
				class="object-cover rounded-full"
				:alt="props.alt"
				:class="props.imageClass ? props.imageClass : 'w-[42px] h-[42px]'"
				:src="props.src" />
			<div v-if="showStatus" class="dot"></div>
		</div>
		<img
			v-if="props.border"
			:src="props.border"
			class="absolute m-auto"
			:class="props.borderClass ? props.borderClass : 'w-[70px] h-[70px]'" />
	</div>
</template>

<style lang="scss" scoped>
.avatar-container {
	align-items: center;
	display: flex;
	border-radius: 100px;
	justify-content: center;

	img {
		border-radius: 50%;
	}

	.dot {
		background-color: var(--clientx-color-dot-status-green);
		border-radius: 50%;
		bottom: 0px;
		position: absolute;
		right: 0;
		height: 10px;
		width: 10px;
	}
}
</style>
