import { loadingController } from '@ionic/vue';

const useLoading = () => {
	const presentLoading = async (message: string) => {
		const loading = await loadingController.create({
			message,
		});

		await loading.present();
		return loading;
	};

	return {
		presentLoading,
	};
};

export default useLoading;
