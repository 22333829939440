import { computed, Ref, ref } from 'vue';

import { useCartStore } from '@/stores/shop/cartStore';
import { GenericProduct } from '@/shared/interfaces/genericProduct';
import { EventTicketType } from '@/shared/interfaces/eventTicketType';
import { EventComboTicketType } from '@/shared/interfaces/eventComboTicketType';
import { Product } from '@/shared/interfaces/tableSpot';

export function useCart() {
	// Access the cart data from the cartStore
	const cartStore = useCartStore();
	const cart = computed(() => cartStore.cart);

	// Create reactive refs for cart total and count
	const cartTotal: Ref<number> = ref(cartStore.getTotalPrice);
	const cartCount: Ref<number> = ref(cartStore.getCartSize);

	// Define a ref to store the productId to remove
	const productIdToRemove = ref<number | null>(null);

	// Method to increase the quantity of a specific product in the cart
	const addToCart = (product: GenericProduct, category: string, additionalInfo?: any): void => {
		// Clear cart but keep current category items
		clearCart(category);

		// Add item to cart
		cartStore.addToCart(product, category, additionalInfo);
	};

	// Add to cart item and allow duplicated
	const addToCartDuplicated = (product: GenericProduct, category: string, additionalInfo?: any): void => {
		// Clear cart but keep current category items
		clearCart(category);

		// Add item to cart
		cartStore.addToCartDuplicated(product, category, additionalInfo);
	};

	const calculateTotalsPerProductCurrency = () => {
		return cartStore.calculateTotalsPerProductCurrency();
	};

	// Method to clear the entire cart
	const clearCart = (categoryToKeep?: string) => {
		cartStore.clearCart(categoryToKeep);
	};

	// Method to decrease the quantity of a specific product in the cart
	const decreaseProductQuantity = (product: EventTicketType | EventComboTicketType | Product): void => {
		cartStore.decreaseProductQuantity(product);
	};

	// Get cart item
	const getCartItem = (productId: number) => {
		return cartStore.getCartItem(productId);
	};

	// getCartItems
	const getCartItems = () => {
		return cartStore.cart
			.sort((a, b) => {
				const nameA = a.product.name.toUpperCase(); // ignore upper and lowercase
				const nameB = b.product.name.toUpperCase(); // ignore upper and lowercase
				if (nameA < nameB) {
					return -1;
				}
				if (nameA > nameB) {
					return 1;
				}

				// names must be equal
				return 0;
			})
			.map((item) => {
				return {
					...item,
					product: new GenericProduct({ ...item.product }),
				};
			});
	};

	// getCartSize
	const getCartSize = () => {
		return cartStore.getCartSize;
	};

	// Get cart total items
	const getCartTotalItemsByCategory = (category: string, countPerItem?: boolean) => {
		return cartStore.getCartTotalItemsByCategory(category, countPerItem);
	};

	// Get cart total price
	const getCartTotalPrice = (category: string) => {
		return cartStore.getCartTotalPrice(category);
	};

	// Method to get the count of a specific product in the cart
	const getProductCount = (productId: number): number => cartStore.getProductCount(productId);

	// Method to get the count of a specific product in the cart
	const getProductCountWithDuplicates = (product: EventTicketType | EventComboTicketType): number =>
		cartStore.getProductCountWithDuplicates(product);

	// Method to increase the quantity of a specific product in the cart
	const increaseProductQuantity = (
		product: EventTicketType | EventComboTicketType | Product | GenericProduct,
	): void => {
		cartStore.increaseProductQuantity(product);
	};

	// Method to check if a product is already in the cart
	const isProductInCart = (productId: number) => cartStore.isProductInCart(productId);

	// Method to remove the item from the cart
	// const removeFromCart = () => {
	// 	if (productIdToRemove.value !== null) {
	// 		cartStore.removeFromCart(productIdToRemove.value);
	// 		productIdToRemove.value = null; // Reset the productId to null after removal
	// 	}
	// };

	// Update cart additional info
	const updateCartAdditionalInfoByIndex = (cartItemIndex: number, additionalInfo: any) => {
		cartStore.updateCartAdditionalInfoByIndex(cartItemIndex, additionalInfo);
	};

	// Update cart additional info
	const updateCartAdditionalInfoByProductId = (productId: number, additionalInfo: any) => {
		cartStore.updateCartAdditionalInfoByProductId(productId, additionalInfo);
	};

	// Method to update the quantity of an item in the cart
	const updateCartItemQuantity = (product: EventTicketType | EventComboTicketType | Product, quantity: number) => {
		cartStore.updateCartItemQuantity(product, quantity);
	};

	// Watch the cart store's getters and update the reactive refs
	cartStore.$subscribe(() => {
		cartTotal.value = cartStore.getTotalPrice;
	});

	// Return
	return {
		addToCart,
		addToCartDuplicated,
		calculateTotalsPerProductCurrency,
		cart,
		cartCount,
		cartTotal,
		clearCart,
		decreaseProductQuantity,
		getCartItem,
		getCartItems,
		getCartSize,
		getCartTotalItemsByCategory,
		getCartTotalPrice,
		getProductCount,
		getProductCountWithDuplicates,
		increaseProductQuantity,
		isProductInCart,
		updateCartAdditionalInfoByIndex,
		updateCartAdditionalInfoByProductId,
		updateCartItemQuantity,
	};
}
