<script setup lang="ts">
import router from '@/router';
import { IonButton } from '@ionic/vue';

export interface Props {
	buttonText?: string;
	text?: string;
	link?: string;
}

const props = withDefaults(defineProps<Props>(), {
	buttonText: 'Text',
	link: '',
	text: 'This is a default value',
});

const goTo = async (link: string) => {
	await router.push(link);
};
</script>

<template>
	<div class="flex relative h-[80px] padding-app-left padding-app-right">
		<div
			class="flex h-[80px] padding-app-left padding-app-right justify-between items-center sign-in-sign-up-alert-container absolute left-0 bottom-0">
			<span class="font-sourcesanspro-regular text-base text-white">{{ $t(props.text) }}</span>
			<ion-button fill="outline" shape="round" @click="goTo(props.link)" color="primary">
				<span class="font-sourcesanspro-semibold text-base">{{ $t(props.buttonText) }}</span>
			</ion-button>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.sign-in-sign-up-alert-container {
	background-color: rgb(0, 0, 0, 0.35);
	height: 80px;
	width: 100%;

	ion-button {
		--border-width: 1px;
		height: 34px;
		width: 80px;

		&::part(native) {
			padding: 0 10px;
			width: 80px;
		}
	}
}
</style>
