<script setup lang="ts">
import { ref, toRef, watch } from 'vue';
import { IonButton, IonIcon } from '@ionic/vue';
import { heart, heartOutline } from 'ionicons/icons';

import useFavorite from '@/shared/composables/useFavorite';

// Props
const props = defineProps({
	class: { type: String },
	favorite: { type: Boolean, required: true, default: false },
	id: { type: Number as () => number | undefined, required: true },
	type: { type: String, required: true },
});

// Init
const emit = defineEmits(['onFavoriteChanged']);
const favorite = ref(props.favorite);
const isFavorite = toRef(props, 'favorite');
const { toggleFavorite } = useFavorite();

// Swap favorite
const swapFavorite = async (event: PointerEvent) => {
	event.preventDefault();
	event.stopPropagation();
	// Update local favorite state
	// Props will not change, but we have an isFavorite ref that is listening
	// If it changes, we will catch the new state in the watch and assign it to the local favorite
	favorite.value = !favorite.value;
	const feedItemType = props.type.includes('event') ? 'event' : props.type;
	await toggleFavorite(feedItemType, props.id!);
	emit('onFavoriteChanged', favorite.value);
};

// Watch favorite state
watch(isFavorite, () => {
	favorite.value = isFavorite.value;
});
</script>

<template>
	<ion-button
		class="transparent ion-no-padding"
		:class="[
			{
				'w-[30px]': !props.class,
			},
			props.class,
		]"
		@click="swapFavorite">
		<ion-icon
			class="cursor-pointer text-lg24"
			:class="[
				{
					'text-white': props.favorite,
				},
			]"
			:icon="favorite ? heart : heartOutline"></ion-icon>
	</ion-button>
</template>

<style scoped>
ion-button {
	--background-activated: transparent;
}
</style>
