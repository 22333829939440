import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useAuthStore } from '@/stores/auth/authStore';
import { storeToRefs } from 'pinia';
import router from '@/router';

import useToast from '@/shared/composables/useToast';

// Init
const { presentToast } = useToast();
const _anykrowdApi = axios.create();
export const anykrowdApiBase = axios.create();
export const anykrowdApiAnonym = axios.create();

// Add a request interceptor
anykrowdApiBase.interceptors.request.use(
	function (config) {
		// Init
		const authStore = useAuthStore();
		const { user } = storeToRefs(authStore);

		config.baseURL = window.apiUrl;

		if (user.value.token) {
			config.headers.Authorization = `Bearer ${user.value.token}`;
		}
		return config;
	},
	function (error) {
		// Do something with request error
		return Promise.reject(error);
	},
);

// Add a request interceptor
_anykrowdApi.interceptors.request.use(
	function (config) {
		// Init
		const authStore = useAuthStore();
		const { guestUser, user } = storeToRefs(authStore);

		config.baseURL = window.apiUrl;

		if (user.value.token) {
			config.headers.Authorization = `Bearer ${user.value.token}`;
		} else {
			config.headers['X-Guest-Id'] = `${guestUser.value.id}`;
		}
		return config;
	},
	function (error) {
		// Do something with request error
		return Promise.reject(error);
	},
);

// Add a response interceptor
_anykrowdApi.interceptors.response.use(
	function (response) {
		// Any status code that lie within the range of 2xx cause this function to trigger
		// Do something with response data
		return response;
	},
	async function (error) {
		// If error is not authorized, end the session immediately
		if (error.request.status === 401) {
			const { deleteToken } = useAuthStore();
			await deleteToken();
			await router.push({ path: '/dashboard' });
		}
		// Any status codes that falls outside the range of 2xx cause this function to trigger
		// Do something with response error
		if (error.request.status === 403) {
			await presentToast('top', "You're not authorized to view this.", 5000, 'danger');
		}

		return Promise.reject(error);
	},
);

export const anykrowdApi = {
	delete: async (url: string, authNeeded = true, config?: AxiosRequestConfig<any> | undefined) => {
		// Init
		const authStore = useAuthStore();
		const { user } = storeToRefs(authStore);

		if (authNeeded && !user.value.token) {
			await presentToast('top', 'Please login first', 5000, 'danger');
		}

		return _anykrowdApi.delete(url, config);
	},
	get: async <T = any, R = AxiosResponse<T, any>, D = any>(
		url: string,
		authNeeded = true,
		config?: AxiosRequestConfig<D> | undefined,
	): Promise<R> => {
		// Init
		const authStore = useAuthStore();
		const { user } = storeToRefs(authStore);

		if (authNeeded && !user.value.token) {
			await presentToast('top', 'Please login first', 5000, 'danger');
		}

		config = appendVersionToHeaders(config);

		return _anykrowdApi.get<T, R, D>(url, config);
	},
	post: async <T = any, R = AxiosResponse<T, any>, D = any>(
		url: string,
		data?: D | undefined,
		authNeeded = true,
		config?: AxiosRequestConfig<D> | undefined,
	): Promise<R> => {
		// Init
		const authStore = useAuthStore();
		const { user } = storeToRefs(authStore);

		if (authNeeded && !user.value.token) {
			await presentToast('top', 'Please login first', 5000, 'danger');
			return new Promise((resolve, reject) => {
				reject('failed');
			});
		} else {
			config = appendVersionToHeaders(config);

			return _anykrowdApi.post<T, R, D>(url, data, config);
		}
	},
};

// Helper function to append version to headers
function appendVersionToHeaders(config?: AxiosRequestConfig<any>): AxiosRequestConfig<any> {
	config = config || {};
	config.headers = config.headers || {};

	config.headers['version'] = 'v2';

	return config;
}
