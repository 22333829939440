import { useQueryClient } from '@tanstack/vue-query';

import { Event } from '@/shared/interfaces/event';
import { Feed } from '@/shared/interfaces/feed';
import { FeedTypeEnum } from '@/enums/values.enum';
import { Timetable } from '@/shared/interfaces/timeTables';
import { useAuthStore } from '@/stores/auth/authStore';
import { ValuesEnum } from '@/enums/values.enum';
import useCacheService from '@/shared/composables/useCacheService';
import useEvents from '@/modules/events/composables/useEvents';
import useFeed from '@/modules/dashboard/composables/useFeed';
import usePosts from '@/modules/news/composables/usePosts';
import useStorageService from '@/shared/composables/useStorageService';
import useTimeTables from '@/modules/timetables/composables/useTimeTables';
import useWallet from '@/modules/wallet/composables/useWallet';
import useMaps from '@/modules/maps/composables/useMaps';
import { ApiMap } from '@/shared/interfaces/map';
import { DateTime } from 'luxon';
import { array } from 'yup';

// Init
const { get, set } = useStorageService();
const { getTimestamps } = useCacheService();

const usePreload = () => {
	// Init
	const queryClient = useQueryClient();
	const { getFeed } = useFeed(false, false);
	const { getPostDetail } = usePosts();
	const { getEventApiCall, getEventParticipants, getEventTicketsApiCall, getEventsApiCall } = useEvents();
	const {
		getMinimumTopUpAmountApiCall,
		getPreviousTopUpAmountApiCall,
		getWalletPackagesApiCall,
		getWalletCurrentBalanceFromApi,
	} = useWallet();
	const { getTimeTableApiCall, getTimetablesApiCall } = useTimeTables();
	const { getMapApiCall, getMapsApiCall } = useMaps();
	const authStore = useAuthStore();

	// prefetch
	const prefetch = async (queryKey: any, queryFn: any, isInfinite?: boolean) => {
		if (!isInfinite) {
			await queryClient.prefetchQuery({
				queryKey,
				queryFn: async () => await queryFn,
				cacheTime: 1000 * 60 * 60 * 24 * 365,
			});
		} else {
			await queryClient.prefetchInfiniteQuery({
				queryKey,
				queryFn: async () => await queryFn,
				getNextPageParam: (lastPage: any) => {
					return lastPage.last_page >= lastPage.current_page + 1 ? lastPage.current_page + 1 : undefined;
				},
				cacheTime: 1000 * 60 * 60 * 24 * 365,
			});
		}

		// Save
		let keys = await get('cachedDataKeys');
		if (!keys) {
			keys = [queryKey.join('-')];
		} else {
			if (!keys.includes(queryKey.join('-'))) {
				keys.push(queryKey.join('-'));
			}
		}
		await set('cachedDataKeys', keys);

		const strKey = queryKey.join('-');

		// queryFn.then((data: any) => {
		// 	set(strKey, data);
		// })
		const queryCachedData = queryClient.getQueryCache().find(queryKey);
		set(strKey, queryCachedData?.state.data);
	};

	// Preload feed
	const preloadFeed = async () => {
		await prefetch(['feedDashboard'], getFeed(1), true);

		// Preload first 3 posts
		const feedDashboard = queryClient.getQueryCache().find(['feedDashboard']);
		if (feedDashboard?.state.data) {
			const feedList: any = feedDashboard?.state.data;
			await preloadPosts(
				feedList.pages[0]
					.filter((post: Feed) => post.type === FeedTypeEnum.post)
					.map((post: Feed) => post.data.id)
					.slice(0, 3),
			);
			await preloadEventsById(
				feedList.pages[0]
					.filter((post: Feed) => {
						return (
							post.type === FeedTypeEnum.event ||
							post.type === FeedTypeEnum.event_now ||
							post.type === FeedTypeEnum.event_soon
						);
					})
					.map((post: Feed) => post.data.id)
					.slice(0, 3),
			);
		}
	};

	// Preload posts
	const preloadPosts = async (postsIdsToPreload: number[]) => {
		postsIdsToPreload.forEach(async (postId) => {
			await prefetch(['post', postId], getPostDetail(postId));
		});
	};

	// Preload posts
	const preloadEventsById = async (eventsIdsToPreload: number[]) => {
		eventsIdsToPreload.forEach(async (eventId) => {
			await prefetch(['event', eventId], getEventApiCall(eventId));
		});
	};

	// Preaload events
	const preloadEvents = async () => {
		if (authStore.isAuthenticated()) {
			await prefetch(['events', '', 'going'], getEventsApiCall('', 1, 'going'), true);

			const events = queryClient.getQueryCache().find(['events', '', 'going']);
			if (events?.state.data) {
				const eventsList: any = events?.state.data;
				await fetchEventDetails(eventsList.pages[0].data, false);
			}

			await prefetch(['events', '', 'all'], getEventsApiCall('', 1, 'all'), true);

			const eventsAll = queryClient.getQueryCache().find(['events', '', 'all']);
			if (eventsAll?.state.data) {
				const eventsListAll: any = eventsAll?.state.data;
				await fetchEventDetails(eventsListAll.pages[0].data, false);
			}
		}
	};

	// fetchEventDetails
	const fetchEventDetails = async (events: Event[], tickets = false) => {
		if (!events?.length) {
			return;
		}

		events.forEach((event: Event) => {
			setTimeout(async () => {
				await prefetch(['event', event.id], getEventApiCall(event.id));

				setTimeout(async () => {
					await prefetch(['eventParticipants', event.id], getEventParticipants(event.id));
				}, 200);

				if (tickets) {
					await prefetch(['eventTickets', event.id], getEventTicketsApiCall(event.id));
				}
			}, 500);
		});
	};

	// Preload tickets
	const preloadTickets = async () => {
		if (await authStore.isAuthenticated()) {
			await prefetch(['events', '', 'going'], getEventsApiCall('', 1, 'going'), true);
			const events = queryClient.getQueryCache().find(['events', '', 'going']);
			if (events?.state.data) {
				const eventsList: any = events?.state.data;
				await fetchEventDetails(eventsList.pages[0].data, true);
			}
		}
	};

	// Preload wallet
	const preloadWallet = async () => {
		await prefetch(['walletMinimumTopUpAmount'], getMinimumTopUpAmountApiCall());

		if (await authStore.isAuthenticated()) {
			setTimeout(async () => {
				await prefetch(['walletPreviousTopUpAmount'], getPreviousTopUpAmountApiCall());
			}, 250);

			// setTimeout(async () => {
			// 	await prefetch(['userWallet'], getWalletCurrentBalanceFromApi());
			// }, 500);
		}
	};

	// Preload wallet packages
	const preloadWalletPackages = async () => {
		await prefetch(['walletPackages'], getWalletPackagesApiCall());
	};

	// Preload timetables
	const preloadTimetables = async () => {
		await prefetch(['timeTables'], getTimetablesApiCall());

		// Preload timeTables
		const timeTables = queryClient.getQueryCache().find(['timeTables']);
		if (timeTables?.state.data) {
			const timeTablesList: any = timeTables?.state.data;
			timeTablesList.forEach(async (timeTable: Timetable) => {
				await prefetch(['timeTable', timeTable.id], getTimeTableApiCall(timeTable.id));
			});
		}
	};

	// Preload maps
	const preloadMaps = async () => {
		await prefetch(['maps'], getMapsApiCall());

		// Preload maps
		const maps = queryClient.getQueryCache().find(['maps']);
		if (maps?.state.data) {
			const mapsList: any = maps?.state.data;
			if (mapsList.length) {
				mapsList.forEach(async (map: ApiMap) => {
					await prefetch(['map', map.id], getMapApiCall(map.id));
				});
			}
		}
	};

	// Preload cache
	const preloadCache = async () => {
		// Init
		const timestamps = await get(ValuesEnum.StoreCacheTimestamps);
		const timestampsAuth = await get(ValuesEnum.StoreAuthCacheTimestamps);

		try {
			const cacheTimestamps = await getTimestamps();

			// Save caches timestamps
			await set(ValuesEnum.StoreCacheTimestamps, cacheTimestamps);

			// If user is authenticated, get
			if (authStore.isAuthenticated()) {
				await set(ValuesEnum.StoreAuthCacheTimestamps, true);
			}

			if (!timestamps || !timestampsAuth) {
				await preloadAll();
			} else {
				if (DateTime.fromISO(timestamps.feed) < DateTime.fromJSDate(cacheTimestamps.feed)) {
					await preloadFeed();
				}

				if (DateTime.fromISO(timestamps.events) < DateTime.fromJSDate(cacheTimestamps.events)) {
					await preloadEvents();
				}

				if (DateTime.fromISO(timestamps.tickets) < DateTime.fromJSDate(cacheTimestamps.tickets)) {
					await preloadTickets();
				}

				if (DateTime.fromISO(timestamps.wallet) < DateTime.fromJSDate(cacheTimestamps.wallet)) {
					await preloadWallet();
				}

				if (
					DateTime.fromISO(timestamps.wallet_packages) < DateTime.fromJSDate(cacheTimestamps.wallet_packages)
				) {
					await preloadWalletPackages();
				}

				// if (DateTime.fromISO(timestamps.bookings) < DateTime.fromJSDate(cacheTimestamps.bookings)) {
				//   await this.preloadBookings();
				// }

				// if (DateTime.fromISO(timestamps.booking_items) < DateTime.fromJSDate(cacheTimestamps.booking_items)) {
				//   await this.preloadBookingItems();
				// }

				if (DateTime.fromISO(timestamps.timetables) < DateTime.fromJSDate(cacheTimestamps.timetables)) {
					await preloadTimetables();
				}

				if (DateTime.fromISO(timestamps.maps) < DateTime.fromJSDate(cacheTimestamps.maps)) {
					await preloadMaps();
				}
			}
		} catch (e: any) {
			console.log(e);
		}
	};

	const preloadAll = async () => {
		setTimeout(preloadFeed.bind(this), 0);
		setTimeout(preloadEvents.bind(this), 150);
		setTimeout(preloadTickets.bind(this), 300);
		setTimeout(preloadWallet.bind(this), 450);
		setTimeout(preloadWalletPackages.bind(this), 600);
		setTimeout(preloadTimetables.bind(this), 750);
		setTimeout(preloadMaps.bind(this), 900);
		// setTimeout(this.preloadBookings.bind(this), 1050);
		// setTimeout(this.preloadBookingItems.bind(this), 1200);
	};

	return {
		preloadCache,
	};
};

export default usePreload;
