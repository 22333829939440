/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
import { TicketTypeContract } from '@/shared/interfaces/eventTicketType';
import { Discount, Discountable } from '@/shared/interfaces/discount';
import { Currency } from '@/shared/interfaces/balance';

export class EventComboTicketType extends Discountable implements TicketTypeContract {
	id: number;
	event_combo_id?: number;
	name: string;
	price: string;
	currency_id: number;
	currency: Currency;
	quantity: number;
	vat_percentage: string;
	max_cap: number;
	description: string;
	remaining_tickets: number;
	ticket_limit_per_user: number;
	tickets_purchased_count: number;
	custom_datetime?: string;

	constructor(
		data: {
			id: number;
			event_id?: number;
			name: string;
			price: string;
			currency_id: number;
			currency: Currency;
			quantity: number;
			vat_percentage: string;
			max_cap: number;
			discount?: Discount;
			description: string;
			remaining_tickets: number;
			ticket_limit_per_user: number;
			tickets_purchased_count: number;
			custom_datetime?: string;
		} = {
			id: 0,
			event_id: 0,
			name: '',
			price: '0.00',
			currency_id: 0,
			currency: new Currency(),
			quantity: 0,
			vat_percentage: '0.00',
			max_cap: -1,
			description: '',
			remaining_tickets: -1,
			ticket_limit_per_user: -1,
			tickets_purchased_count: 0,
			custom_datetime: '',
		},
	) {
		super(data);
		this.id = data.id;
		this.name = data.name;
		this.price = data.price;
		this.currency_id = data.currency_id;
		this.currency = data.currency;
		this.quantity = 0;
		this.vat_percentage = data.vat_percentage;
		this.max_cap = data.max_cap;
		this.description = data.description;
		this.remaining_tickets = data.remaining_tickets;
		this.ticket_limit_per_user = data.ticket_limit_per_user ? data.ticket_limit_per_user : -1;
		this.tickets_purchased_count = data.tickets_purchased_count;
	}

	getFullPrice(): number {
		return Number(this.price);
	}
}
