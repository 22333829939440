import { LaravelResourcePaginator } from '@/shared/interfaces/laravelPaginator';

export type ModelId = string | number | null;
export enum RefundRequestStatus {
	PENDING = 'pending',
	APPROVED = 'approved',
	DENIED = 'denied',
	CANCELLED = 'cancelled',
	REFUNDED = 'refunded',
	FAILED = 'failed',
}

export class RefundRequestResource {
	id: ModelId = null;
	user_id: ModelId = null;
	amount = 0;
	fiat_amount = 0;
	status: RefundRequestStatus = RefundRequestStatus.PENDING;
	info: null | string = '';
	iban?: string;
	created_at = '';
}

export class RefundRequestResourcePaginator extends LaravelResourcePaginator {
	data: RefundRequestResource[] = [];
}
