<script setup lang="ts">
import { ref, toRefs } from 'vue';
import { useField } from 'vee-validate';
import { IonToggle } from '@ionic/vue';

const props = defineProps({
	name: { type: String, required: true },
	value: { type: Boolean },
});

const emit = defineEmits(['onStateChanged']);

// we are using toRefs to create reactive references to `name` and `value` props
// this is important because vee-validte needs to know if any of these props change
// https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
const { name, value } = toRefs(props);
const { handleChange } = useField(name);

const toggleState = ref(value.value);

const toggle = () => {
	handleChange(toggleState.value);
	emit('onStateChanged', props.name, toggleState.value);
};
</script>

<template>
	<div>
		<ion-toggle class="text-sm px-0 pr-1" v-model="toggleState" @ionChange="toggle"></ion-toggle>
	</div>
</template>

<style lang="scss" scoped>
ion-toggle {
	&::part(track) {
		border: 1px solid var(--ion-color-primary);
	}

	height: 34px;
	width: 70px;

	--background: transparent;
	--background-checked: transparent;
	--border-radius: 100px;

	--handle-background: var(--ion-color-primary);
	--handle-background-checked: var(--ion-color-primary);
	--handle-border-radius: 100px;
	--handle-width: 30px;
	--handle-height: 30px;
	--handle-spacing: 2px;
	--handle-box-shadow: none;

	/* Required for iOS handle to overflow the height of the track */
	overflow: visible;
	contain: none;
}

ion-toggle[aria-checked='false'] {
	&::part(track) {
		border: 1px solid var(--ion-color-primary);
	}

	&::before {
		content: 'Off';
		color: var(--ion-color-primary);
		position: absolute;
		right: 16px;
		top: 20px;
	}
}

.ios ion-toggle[aria-checked='false'] {
	&::before {
		top: calc(50% - 10px);
	}
}

ion-toggle[aria-checked='true'] {
	&::after {
		content: 'On';
		font-weight: bold;
		left: 14px;
		position: absolute;
		top: 19px;
	}
}

.ios ion-toggle[aria-checked='true'] {
	&::after {
		top: calc(50% - 10px);
	}
}
</style>
