import { toastController } from '@ionic/vue';

const useToast = () => {
	const presentToast = async (
		position: 'top' | 'middle' | 'bottom',
		message: string,
		duration: number,
		color?: string,
	) => {
		const toast = await toastController.create({
			message,
			duration: duration,
			position: position,
			color: color,
		});

		await toast.present();
		return toast;
	};

	return {
		presentToast,
	};
};

export default useToast;
