<script setup lang="ts">
import router from '@/router';
import { IonItem } from '@ionic/vue';

const props = defineProps({
	id: { type: String, required: true, default: '' },
	image: { type: String, required: true },
	lastTimeOnline: { type: String, required: true },
	description: { type: String, required: true },
	name: { type: String, required: true },
	type: { type: String, required: true, default: 'person' },
});

const emit = defineEmits(['onClick']);

const onClick = async (id: string) => {
	const url = props.type === 'group' ? `/friends/group/chat/${id}` : `/friends/chat/${id}`;
	emit('onClick', url);
};
</script>

<template>
	<ion-item
		@click="onClick(props.id)"
		class="flex justify-between w-full ion-no-padding py-3 border-b border-b-white/5 cursor-pointer"
		lines="none">
		<div class="w-[60px] min-w-[60px] h-[60px] mr-2">
			<img :src="props.image" class="rounded-full" />
		</div>
		<div class="flex flex-col grow">
			<div class="flex justify-between">
				<span class="font-sourcesanspro-semibold text-m2">{{ props.name }}</span>
				<span class="font-sourcesanspro-semibold text-sm">{{ props.lastTimeOnline }}</span>
			</div>
			<div class="max-w-[202px]">
				<p class="font-sourcesanspro-regular text-sm text-white/50 leading-normal">{{ props.description }}</p>
			</div>
		</div>
	</ion-item>
</template>

<style lang="scss" scoped>
ion-item {
	--inner-padding-end: 0;
}
</style>
