import { LocationQuery, RouteLocationNormalized } from 'vue-router';
import { defineStore } from 'pinia';

import { ValuesEnum } from '@/enums/values.enum';
import type { UserToken } from '@/shared/interfaces/user';
import useStorageServices from '@/shared/composables/useStorageService';

// Init
const { get, remove, set } = useStorageServices();

export const useAuthStore = defineStore('auth', {
	state: () => ({
		guestUser: {
			id: <string>'',
		},
		user: {
			token: <string | null | undefined>'',
			approval_required: false,
		},
		redirect: {
			callbackPage: <string>'',
			id: <string>'',
		},
	}),

	actions: {
		async authenticateWithSavedToken() {
			const token = await get(ValuesEnum.StoreAccessToken);
			if (token) {
				this.user.token = token.token;
			}
		},
		async signInWithSocialLoginQueryParams(route: RouteLocationNormalized) {
			const accessToken = route.query;
			return accessToken ? await this._handleSocialLoginQueryParams(accessToken) : false;
		},
		getGuestUserId() {
			return this.guestUser.id;
		},
		getRedirect() {
			return this.redirect;
		},
		resetGuestUser() {
			this.guestUser.id = '';
		},
		async saveToken(userToken: UserToken) {
			this.user.token = userToken.token;
			await set(ValuesEnum.StoreAccessToken, userToken);
			return this.user;
		},
		setGuestUserId(id: string) {
			this.guestUser.id = id;
		},
		setRedirect(redirect: { callbackPage: string; id: string }) {
			this.redirect = redirect;
		},
		async _handleSocialLoginQueryParams(params: LocationQuery) {
			if (params?.failed_url || params?.oauth === 'failed') {
				return false;
			}

			const token = params?.accessToken as string;
			if (token) {
				this.user.token = token as string;
				set(ValuesEnum.StoreAccessToken, { token: token });
				return true;
			} else {
				return false;
			}
		},
		async deleteToken() {
			this.user.token = undefined;
			await remove(ValuesEnum.StoreAccessToken);
			const event = new Event('logout');
			// Dispatch the event.
			window.document.dispatchEvent(event);
		},
		isAuthenticated() {
			return !!this.user.token;
		},
	},
	persist: true,
});
