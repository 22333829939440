<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { IonButton, IonIcon, isPlatform } from '@ionic/vue';
import { Capacitor } from '@capacitor/core';
import { Share } from '@capacitor/share';
import { shareOutline, shareSocialOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import useUtils from '@/shared/composables/useUtils';

const props = defineProps({
	id: { type: Number as () => number | undefined, required: true },
	title: { type: String, required: true },
	type: { type: String, required: true },
});

// Init
const { t } = useI18n();
const isShareAvailable = ref(false);
const { getCurrentWindowLocationOrigin } = useUtils();

// onMountedHook
onMounted(async () => {
	isShareAvailable.value = (await Share.canShare()).value;
});

// Share
const share = async () => {
	await Share.share({
		title: props.title,
		text: props.title,
		url: `${getCurrentWindowLocationOrigin()}/#/${props.type}/${props.id}`,
		dialogTitle: t('general.share_with_friends'),
	});
};
</script>

<template>
	<ion-button
		v-if="Capacitor.getPlatform() !== 'web' && isShareAvailable"
		class="transparent w-[30px] ion-no-padding"
		@click="share">
		<ion-icon
			:icon="isPlatform('android') ? shareSocialOutline : shareOutline"
			:class="[
				{
					'text-[24px] pb-[2px]': isPlatform('ios'),
				},
			]"></ion-icon>
	</ion-button>
</template>

<style scoped>
ion-button {
	--background-activated: transparent;
}
</style>
