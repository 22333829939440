<script setup lang="ts">
import { ref, watch } from 'vue';
import { IonModal } from '@ionic/vue';

// Props
const props = defineProps({
	isOpen: { type: Boolean, required: true, default: false },
	title: { type: String },
});

// Init
const isOpenRef = ref(props.isOpen);
const emit = defineEmits(['onDismiss']);

// Watch changes on the prop
watch(
	() => props.isOpen,
	(newValue) => {
		isOpenRef.value = newValue;
	},
);
</script>

<template>
	<ion-modal :is-open="isOpenRef" :backdrop-dismiss="false">
		<div class="flex flex-col justify-center h-full">
			<div class="rounded-lg mx-[34px] mb-4 px-6 py-6 modal-body-bg">
				<h2 v-if="props.title" class="mb-2 font-sourcesanspro-semibold text-m2 text-center text-white">
					{{ props.title }}
				</h2>
				<slot name="content" />
			</div>
			<slot name="actions" />
		</div>
	</ion-modal>
</template>

<style lang="scss" scoped>
.modal-body-bg {
	background-color: #3b3846;
}
</style>
