<script setup lang="ts">
import { ref } from 'vue';
import router from '@/router';
import { IonContent, IonIcon } from '@ionic/vue';
import VoucherItem from '@/modules/vouchers/components/VoucherItem.vue';
import TopBar from '@/shared/components/TopBar.vue';
import ModalOne from '@/shared/components/ui/ModalOne.vue';
import { chevronBackOutline, closeOutline, shareSocialOutline } from 'ionicons/icons';

// Init
const isModalOpen = ref(false);

// Go back
const goBack = () => {
	router.back();
};

const goTo = async (link: string) => {
	showHideModal(false);
	await router.push(link);
};

const showHideModal = (openState: boolean) => {
	isModalOpen.value = openState;
};

const share = (voucherId: number) => {
	showHideModal(true);
};
</script>

<template>
	<ion-content>
		<ModalOne :is-open="isModalOpen">
			<template #content>
				<div class="flex flex-col items-center justify-center">
					<ion-icon class="text-[48px] mb-2" :icon="shareSocialOutline"></ion-icon>
					<div class="flex flex-col items-center mb-2">
						<div class="mb-2">
							<span class="font-sourcesanspro-semibold text-m2">Share</span>
						</div>
						<div class="max-w-[188px] text-center">
							<span class="font-sourcesanspro-regular text-base text-[#7E738C]"
								>Share the voucher via email or with friends</span
							>
						</div>
					</div>
					<div class="w-full">
						<ion-button
							class="w-full mb-4"
							expand="full"
							size="large"
							shape="round"
							@click="goTo('/vouchers/share/email')">
							<div class="flex w-full items-center justify-center">
								<ion-icon :src="require('@/statics/svg/email.svg')" class="h-[24px] w-[24px] mr-2" />
								<span class="text-base text-dark-blue">Email</span>
							</div>
						</ion-button>

						<ion-button
							class="w-full mb-2"
							expand="full"
							size="large"
							shape="round"
							@click="goTo('/friends/select')">
							<div class="flex w-full items-center justify-center">
								<ion-icon :src="require('@/statics/svg/users.svg')" class="h-[24px] w-[24px] mr-2" />
								<span class="text-base text-dark-blue">Friends</span>
							</div>
						</ion-button>
					</div>
				</div>
			</template>

			<template #actions>
				<div class="flex items-center justify-center">
					<div class="flex items-center justify-center w-[60px] h-[60px] rounded-full bg-white">
						<ion-icon
							class="text-[38px] text-black cursor-pointer"
							:icon="closeOutline"
							@click="showHideModal(false)"></ion-icon>
					</div>
				</div>
			</template>
		</ModalOne>

		<div class="flex flex-col h-full">
			<div class="mb-8">
				<TopBar class="px-[34px]">
					<template #left>
						<div class="flex items-center justify-start cursor-pointer" @click="goBack">
							<ion-icon :icon="chevronBackOutline" class="text-white"></ion-icon>
						</div>
					</template>

					<template #center>
						<div class="flex grow justify-center w-full font-sourcesanspro-semibold text-l2">
							My Vouchers
						</div>
					</template>
				</TopBar>
			</div>

			<div class="flex flex-col items-center w-full px-[34px] align-center mb-8">
				<VoucherItem
					:id="1"
					class="mb-2"
					ticket-description="You got a free ticket"
					ticket-name="FREE TICKET"
					ticket-sub-title="1x VIP"
					ticket-title="Boum De Noël 2022" />
				<VoucherItem
					:id="2"
					:activated="true"
					:usable="true"
					@on-share="share"
					class="mb-2"
					status="Valid until 16 Apr 2023"
					statusClass="bg-[#6686F9]"
					ticket-description="You got a free drink"
					ticket-name="FREE DRINK"
					ticket-sub-title="1x"
					ticket-title="Coca Cola" />
				<VoucherItem
					:id="3"
					:activated="true"
					:shareable="true"
					@on-share="share"
					class="mb-2"
					status="17 h | 32 m | 59 s"
					statusClass="bg-[#BE0B97]"
					ticket-description="You got a free drink"
					ticket-name="FREE DRINK"
					ticket-sub-title="1x"
					ticket-title="Sprite" />
				<VoucherItem
					:id="4"
					:activated="true"
					:shareable="true"
					@on-share="share"
					class="mb-2"
					status="12 Apr 2023 | 12 May 2023"
					statusClass="bg-[#11B767]"
					ticket-description="You got a free drink"
					ticket-name="FREE DRINK"
					ticket-sub-title="1x"
					ticket-title="Tonic" />
			</div>
		</div>

		<div class="h-[100px]">&nbsp;</div>
	</ion-content>
</template>

<style lang="scss" scoped></style>
