<script setup lang="ts">
import vue, { onMounted, ref, watch, watchEffect } from 'vue';
import router from '@/router';
import { IonButton, IonContent, IonIcon, IonSpinner } from '@ionic/vue';
import { arrowForwardOutline, chevronBackOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import hexRgb from 'hex-rgb';
import ItemsDetails from '@/shared/components/ui/ItemsDetails.vue';
import rgbHex from 'rgb-hex';
import TextInput from '@/shared/components/sign-up-forms/TextInput.vue';
import TopBar from '@/shared/components/TopBar.vue';
import useCustomForm from '@/shared/composables/useCustomForm';
import useProfileRefunds from '@/modules/profile/composables/useProfileRefunds';
import useUtils from '@/shared/composables/useUtils';
import useWallet from '@/modules/wallet/composables/useWallet';
import { useRoute } from 'vue-router';
import { Wallet } from '@/shared/interfaces/balance';

// Init
const { t } = useI18n();
const route = useRoute();
const walletId = route.params.wallet_id?.toString();
const refundDetails: any = ref([]);
const { getRefundInfo, getWalletCurrentBalance, getWallet } = useWallet();
const { userWallet } = getWalletCurrentBalance(walletId, false, true);
const { refundInfo } = getRefundInfo(walletId);
const { amount, iban, schema } = useProfileRefunds(refundInfo);
const nonRefundableValue = ref(0);
const { calculateShade } = useUtils();
const primary_color = window.primary_color;
const primaryRgb = hexRgb(window.primary_color, { format: 'array' });
const primaryShade = '#' + rgbHex(calculateShade(primaryRgb.toString(), 0.2));
const amountErrorMessage = ref('');

const wallet = ref<Wallet>();
const { exchange } = useUtils();

// useCustomForm
const { isSuccess, isFormValid, onSubmit, onInputChange } = useCustomForm(
	schema,
	`/wallet/${walletId}/refund-request`,
	'post',
	'wallet.refund.requested',
	'wallet.refund.requested_error',
	5000,
);

const onAmountInputChange = (formValid: vue.ComputedRef<boolean>, fieldName: any, event: CustomEvent) => {
	if (event.type !== 'ion-blur') {
		amount.value = event.detail.value;
	}
};

onMounted(() => {
	wallet.value = getWallet(walletId).wallet.value;
});

// Watch refund success
watch(
	isSuccess,
	() => {
		if (isSuccess.value) {
			router.push(`/wallet/refunds/${walletId}`);
		}
	},
	{ immediate: true },
);

watchEffect(() => {
	if (userWallet.value && refundInfo.value) {
		// Reser data
		refundDetails.value = [];

		// Adding balance
		refundDetails.value.push({ title: t('wallet.refund.balance'), totalPrice: +userWallet.value.current_balance });

		// Adding non refundable info
		if (+userWallet.value.current_balance - refundInfo.value.total_amount > 0) {
			nonRefundableValue.value = +userWallet.value.current_balance - refundInfo.value.total_amount;

			refundDetails.value.push({
				title: t('wallet.refund.not_refundable'),
				totalPrice: +userWallet.value.current_balance - refundInfo.value.total_amount,
			});

			if (!refundInfo.value.manual_refund_enabled) {
				refundDetails.value.push({ title: `- ${t('wallet.refund.not_refundable_items.onsite')}` });
			}
			refundDetails.value.push({ title: `- ${t('wallet.refund.not_refundable_items.vouchers')}` });
			refundDetails.value.push({ title: `- ${t('wallet.refund.not_refundable_items.discounts')}` });
			refundDetails.value.push({ title: `- ${t('wallet.refund.not_refundable_items.warranty')}` });
			if (!refundInfo.value.online_cutoff_days) {
				refundDetails.value.push({
					title: t('wallet.refund.not_refundable_items.mollie', {
						online_cutoff_days: refundInfo.value.online_cutoff_days,
					}),
				});
			}
		}

		// Adding more details
		refundDetails.value.push(
			...[
				{ title: t('wallet.refund.amount'), totalPrice: +amount.value },
				{
					title: t('wallet.refund.fee_incl_vat'),
					totalPrice: -(refundInfo.value.cost || 0),
					currency: '&euro;',
				},
			],
		);
	}
});
</script>

<template>
	<ion-content :style="`background: linear-gradient(${primary_color}, ${primaryShade});`">
		<div class="flex flex-col h-full justify-between">
			<div class="px-[34px] h-full">
				<form
					id="refundsRequestForm"
					class="flex flex-col h-full"
					:validation-schema="schema"
					@submit="onSubmit">
					<TopBar>
						<template #left>
							<div class="flex items-center justify-start cursor-pointer">
								<router-link to="/dashboard" class="flex items-center justify-center">
									<ion-icon :icon="chevronBackOutline" class="text-white"></ion-icon>
								</router-link>
							</div>
						</template>

						<template #center>
							<div class="flex justify-center w-full font-sourcesanspro-semibold text-l2">
								{{ t('wallet.refund.request.title') }}
							</div>
						</template>
					</TopBar>

					<div v-if="!userWallet || !refundInfo || !refundDetails" class="flex items-center justify-center">
						<ion-spinner name="dots"></ion-spinner>
					</div>

					<div
						v-if="userWallet && +userWallet.current_balance <= nonRefundableValue"
						class="mt-4 text-center font-sourcesanspro-regular text-white/60">
						{{ t('wallet.refund.not_eligible') }}
					</div>
					<div
						v-if="
							userWallet &&
							refundInfo &&
							refundDetails &&
							+userWallet.current_balance > nonRefundableValue
						"
						class="flex flex-col justify-center">
						<div class="mb-4 text-center font-sourcesanspro-regular text-regular text-white/60">
							{{ t('wallet.refund.request.description') }}
						</div>
						<div class="flex items-center justify-center">
							<span class="font-sourcesanspro-regular text-white text-[40px] mr-2">{{
								wallet?.currency.prefix
							}}</span>
							<div class="mx-1">
								<TextInput
									:disabled="+userWallet.current_balance <= nonRefundableValue"
									wrapper-class="flex flex-wrap justify-left item-center border border-solid focus-within:background-white-15"
									input-class="text-lg"
									type="number"
									step="any"
									name="amount"
									placeholder="Amount"
									:value="amount"
									@input-changed="onAmountInputChange"
									:should-handle-blur="false" />
							</div>
							<div class="min-w-[25px] font-sourcesanspro-regular text-l2 font-color-text-50"></div>
						</div>
						<div
							v-if="amountErrorMessage"
							class="flex items-center justify-center mb-4 font-sourcesanspro-regular text-sm font-color-error">
							{{ amountErrorMessage }}
						</div>

						<div v-if="refundInfo?.manual_refund_enabled && refundInfo?.online_amount < +amount">
							{{ iban }}
							<TextInput
								wrapper-class="flex flex-wrap justify-left item-center mb-3 border border-solid focus-within:background-white-15"
								:value="iban"
								label="IBAN"
								name="iban"
								placeholder="IBAN"
								@input-changed="onInputChange" />
						</div>
					</div>
				</form>
			</div>

			<div
				v-if="userWallet && refundInfo && refundDetails && +userWallet.current_balance > nonRefundableValue"
				class="flex flex-col items-center justify-center px-[34px]">
				<ItemsDetails
					border-class="border-y border-y-white/5"
					class="w-full backdrop-blur-xl bg-anykrowd-gray/20 text-white rounded-xl mb-4"
					text-class="text-white"
					text-class-two="text-white/70"
					title="Info"
					:items="refundDetails"
					:total-title="t('wallet.refund.received')"
					:total-price="
						exchange(amount, wallet ? wallet.currency.exchange_rate : 1) - (refundInfo?.cost || 0)
					"
					:currency="wallet?.currency.prefix" />

				<ion-button
					class="w-full mb-[34px] hover:bg-white hover:rounded-full"
					expand="full"
					form="refundsRequestForm"
					size="large"
					shape="round"
					:disabled="!isFormValid || amount - refundInfo.cost < 0"
					type="submit">
					<div class="flex w-full items-center justify-between">
						Continue
						<ion-icon :icon="arrowForwardOutline" class="text-dark-blue mr-2"></ion-icon>
					</div>
				</ion-button>
			</div>
		</div>
	</ion-content>
</template>

<style lang="scss" scoped></style>
