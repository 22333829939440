import { computed, ref, Ref, watch } from 'vue';
import { anykrowdApi } from '@/api/anykrowdApi';
import { useQuery, useQueries } from '@tanstack/vue-query';

import { Post } from '@/shared/interfaces/post';
import useStorageService from '@/shared/composables/useStorageService';
import { ValuesEnum } from '@/enums/values.enum';

// Init
const { get, set } = useStorageService();

// Get post detail
const getPostDetail = async (postId: number): Promise<Post> => {
	const { data } = await anykrowdApi.get<Post>(`/post/detail/${postId}`, false);
	return data;
};

// getPost
const getPost = (postId: number, enabled = true) => {
	// Init
	const post = ref<Post>()!;

	const {
		data: postData,
		isFetching,
		isFetched,
	} = useQuery({
		queryKey: ['post', postId],
		queryFn: () => getPostDetail(postId),
		retry: 3,
		enabled,
	});

	watch(
		postData,
		() => {
			if (postData.value) {
				post.value = { ...postData.value };

				// Update cache
				set(`post-${postId}`, postData.value);
			}
		},
		{ immediate: true },
	);

	return {
		isFetchedPost: computed(() => isFetched.value),
		isFetchingPost: computed(() => isFetching.value),
		post,
	};
};

// getPosts
const getPosts = (postsIds: Ref<number[]>) => {
	// Get post
	const posts = useQueries({
		queries: postsIds.value.length
			? postsIds.value.map((id) => {
					return {
						queryKey: ['post', id],
						queryFn: () => getPost(id),
					};
				})
			: [],
	});

	return {
		posts,
	};
};

// usePosts
const usePosts = () => {
	const currentMainAlertPost = ref<Post | null>();

	// setCurrentMainAlert
	const setCurrentMainAlert = async (post: Post): Promise<Post | null> => {
		if (!post) {
			return null;
		}

		const mainAlertIDsPrompted = (await get<number[]>(ValuesEnum.StoreListOfMainAlertIDsPrompted)) || [];
		// if we already prompted this main alert we will ignore the main alert
		if (mainAlertIDsPrompted.includes(post.id)) {
			currentMainAlertPost.value = null;

			return null;
		}
		currentMainAlertPost.value = post;
		return post;
	};

	// markMainAlertAsSeenInCache
	const markMainAlertAsSeenInCache = async (post: Post) => {
		const currentListOfMainAlerts = (await get<number[]>(ValuesEnum.StoreListOfMainAlertIDsPrompted)) || [];

		currentMainAlertPost.value = null;

		if (currentListOfMainAlerts.includes(post.id)) {
			return;
		}
		await set(ValuesEnum.StoreListOfMainAlertIDsPrompted, [...currentListOfMainAlerts, post.id]);
	};

	// postSetMainAlertAsSeen
	const postSetMainAlertAsSeen = async (post: Post): Promise<any> => {
		markMainAlertAsSeenInCache(post);
		const { data } = await anykrowdApi.post(`${window.apiUrl}/post/main-alert/mark-as-seen/${post.id}`, {}, true);
		return data;
	};

	return {
		currentMainAlertPost,
		getPost,
		getPosts,
		getPostDetail,
		markMainAlertAsSeenInCache,
		postSetMainAlertAsSeen,
		setCurrentMainAlert,
	};
};

export default usePosts;
