<script setup lang="ts">
import { onBeforeMount, ref, watch } from 'vue';
import { Device } from '@capacitor/device';
import router from '@/router';
import { useRoute } from 'vue-router';
import { IonButton, IonContent, IonIcon } from '@ionic/vue';
import * as Yup from 'yup';
import { chevronBackOutline } from 'ionicons/icons';

import hexRgb from 'hex-rgb';
import rgbHex from 'rgb-hex';
import TextInput from '@/shared/components/sign-up-forms/TextInput.vue';
import TopBar from '@/shared/components/TopBar.vue';
import useCustomForm from '@/shared/composables/useCustomForm';
import useUtils from '@/shared/composables/useUtils';

// Init
const { calculateShade } = useUtils();
const primary_color = window.primary_color;
const primaryRgb = hexRgb(window.primary_color, { format: 'array' });
const primaryShade = '#' + rgbHex(calculateShade(primaryRgb.toString(), 0.2));

// Using yup to generate a validation schema
const schema = Yup.object().shape({
	password: Yup.string().min(6).required(),
	password_confirmation: Yup.string()
		.required()
		.oneOf([Yup.ref('password')], 'Passwords do not match'),
});

// Get uuid
const route = useRoute();
const userUuid = route.query.uuid?.toString();
const token = route.params.id;

const _getUUID = (): string => {
	const name = 'uuid';
	const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
	const results = regex.exec(decodeURIComponent(window.location.href));
	if (!results) {
		return '';
	}
	if (!results[2]) {
		return '';
	}
	return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

const uuid = userUuid || _getUUID();
const additionalData = ref({});

onBeforeMount(async () => {
	const deviceInfo = await Device.getInfo();
	additionalData.value = {
		token,
		uuid,
		device_name: `${deviceInfo.model}${deviceInfo.name ? ' ' + deviceInfo.name : ''}`,
	};
});

// useCustomForm
const { isFormValid, isSuccess, onSubmit, onInputChange } = useCustomForm(
	schema,
	'/auth/change-password',
	'post',
	'profile.password_reset.messages.password_changed',
	'profile.password_reset.errors.error',
	5000,
	additionalData,
);

watch(isSuccess, async () => {
	await router.push('/dashboard');
});

// Go back
const goBack = () => {
	router.back();
};
</script>

<template>
	<ion-content :style="`background: linear-gradient(${primary_color}, ${primaryShade});`">
		<div class="flex flex-col h-full justify-between">
			<div class="px-[34px] w-full">
				<TopBar>
					<template #left>
						<div class="flex items-center justify-start cursor-pointer" @click="goBack">
							<ion-icon :icon="chevronBackOutline" class="text-white"></ion-icon>
						</div>
					</template>

					<template #center>
						<div class="flex justify-center w-full font-sourcesanspro-semibold text-l2">
							{{ $t('profile.password_reset.title') }}
						</div>
					</template>
				</TopBar>

				<div class="relative mb-4">
					<form id="changePasswordForm" :validation-schema="schema" @submit="onSubmit">
						<TextInput
							wrapper-class="flex flex-wrap justify-left item-center mb-2 border border-solid focus-within:background-white-15"
							name="password"
							type="password"
							placeholder="New password*"
							@inputChanged="onInputChange" />

						<TextInput
							wrapper-class="flex flex-wrap justify-left item-center mb-2 border border-solid focus-within:background-white-15"
							name="password_confirmation"
							type="password"
							placeholder="Confirm password*"
							@inputChanged="onInputChange" />
					</form>
				</div>
			</div>

			<div class="px-[34px] mb-[34px]">
				<ion-button
					class="w-full hover:bg-white hover:rounded-full"
					:disabled="!isFormValid"
					expand="full"
					form="changePasswordForm"
					size="large"
					shape="round"
					type="submit">
					<div class="flex w-full items-center justify-center">
						{{ $t('profile.password_reset.change_password') }}
					</div>
				</ion-button>
			</div>
		</div>
	</ion-content>
</template>

<style lang="scss" scoped>
ion-list {
	background: transparent;
}
</style>
