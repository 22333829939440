<script setup lang="ts">
import { IonButton, IonIcon } from '@ionic/vue';
export interface Props {
	fill?: string;
	iconLeft?: string;
	iconRight?: string;
	text?: string;
}
const props = withDefaults(defineProps<Props>(), {
	fill: undefined,
	iconLeft: undefined,
	iconRight: undefined,
	text: 'This is a default value',
});

const emit = defineEmits(['onClick']);

const onClick = async () => {
	emit('onClick');
};
</script>

<template>
	<ion-button
		color="primary"
		class="flex justify-between items-center w-full normal-case text-base text-anykrowd-dark"
		shape="round"
		@click="onClick">
		<ion-icon v-if="props.iconLeft" :icon="props.iconLeft" class="mr-2" />
		<span class="justify-start font-sourcesanspro-semibold">{{ $t(props.text) }}</span>
		<ion-icon v-if="props.iconRight" :icon="props.iconRight" />
	</ion-button>
</template>

<style lang="scss" scoped>
button {
	height: 52px;
}

ion-icon {
	color: var(--clientx-color-button-text);
}
</style>
