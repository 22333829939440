<script setup lang="ts">
import { watchEffect } from 'vue';
import router from '@/router';
import { IonContent, IonFooter, IonIcon, IonRefresher, IonRefresherContent, IonSpinner } from '@ionic/vue';
import { arrowForwardOutline, mapOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import NavigationBar from '@/shared/components/ui/NavigationBar.vue';
import TopBar from '@/shared/components/TopBar.vue';
import useMaps from '@/modules/maps/composables/useMaps';

// Init
const { t } = useI18n();
const { getMaps } = useMaps();
const { isFetchedMaps, maps, refetchMaps, removeMapsDataFromCache } = getMaps();

watchEffect(() => {
	if (maps && maps.value && maps.value.length === 1) {
		router.push({ path: `/maps/${maps.value[0].id}` });
	}
});

// Refresh
const handleRefresh = async (event: any) => {
	removeMapsDataFromCache();
	maps.value = undefined;
	await refetchMaps();
	event.target.complete();
};
</script>

<template>
	<ion-content class="flex">
		<ion-refresher class="mt-8" slot="fixed" @ionRefresh="handleRefresh($event)">
			<ion-refresher-content></ion-refresher-content>
		</ion-refresher>

		<TopBar class="px-[34px]">
			<template #left><span></span></template>
			<template #center>
				<div class="flex justify-center w-full font-sourcesanspro-semibold text-l2">{{ t('maps.title') }}</div>
			</template>
		</TopBar>

		<div class="flex items-center justify-center" v-if="!isFetchedMaps">
			<ion-spinner name="dots"></ion-spinner>
		</div>
		<div v-if="maps" class="flex flex-col px-[34px] mb-4">
			<router-link v-for="(map, idx) in maps" :key="idx" :to="`/maps/${map.id}`">
				<div
					class="flex items-center justify-between mb-4 p-3 backdrop-blur-xl bg-white/10 rounded-lg cursor-pointer">
					<div class="flex items-center justify-start">
						<div class="flex items-center mr-3">
							<ion-icon :icon="mapOutline" class="text-white"></ion-icon>
						</div>
						<span class="font-sourcesanspro-semibold">{{ map.name }}</span>
					</div>
					<div class="flex items-end justify-center">
						<ion-icon :icon="arrowForwardOutline" class="text-white"></ion-icon>
					</div>
				</div>
			</router-link>
		</div>
	</ion-content>

	<ion-footer>
		<NavigationBar item-active="maps" />
	</ion-footer>
</template>

<style lang="scss" scoped></style>
