<script setup lang="ts">
import { ref } from 'vue';
import { IonContent, IonIcon, IonSpinner } from '@ionic/vue';
import { useRoute } from 'vue-router';
import { chevronBackOutline, timeOutline } from 'ionicons/icons';
import { DateTime } from 'luxon';
import { useI18n } from 'vue-i18n';

import FavoriteButton from '@/shared/components/FavoriteButton.vue';
import FullScreenImage from '@/shared/components/ui/FullScreenImage.vue';
import RelatedContent from '@/shared/components/ui/RelatedContent.vue';
import ShareButton from '@/shared/components/ShareButton.vue';
import TopBar from '@/shared/components/TopBar.vue';
import usePosts from '@/modules/news/composables/usePosts';
import useUtils from '@/shared/composables/useUtils';
import useTenant from '@/shared/composables/useTenant';

// Init
const { t } = useI18n();
const route = useRoute();
const postId = +route.params.id;
const { getPost } = usePosts();
const { isFetchedPost, post } = getPost(postId);
const { transformOembedToIframe } = useUtils();
const { getTenantConfigAndStyles } = useTenant();
const { showLoggedData } = getTenantConfigAndStyles();

// Image handling
const fullScreenImage = ref('');
const openInFullScreen = ref(false);
const viewFullScreen = (image: string) => {
	fullScreenImage.value = image;
	openInFullScreen.value = true;
};

const onCloseImage = () => {
	openInFullScreen.value = false;
};
</script>

<template>
	<ion-content>
		<FullScreenImage v-if="openInFullScreen" :fullScreenImage="fullScreenImage" @onCloseImage="onCloseImage" />
		<TopBar class="px-[34px]">
			<template #left>
				<div class="flex items-center justify-start cursor-pointer">
					<router-link to="/dashboard" class="flex items-center justify-center">
						<ion-icon :icon="chevronBackOutline" class="text-white"></ion-icon>
					</router-link>
				</div>
			</template>

			<template #center>
				<div v-if="post" class="flex justify-center w-full font-sourcesanspro-semibold text-l2">
					{{ post.title }}
				</div>
			</template>

			<template v-if="post" #right>
				<FavoriteButton
					v-if="showLoggedData()"
					:id="post.id"
					:favorite="post.is_favorite"
					:type="post.type"></FavoriteButton>
				<ShareButton :id="post.id" :title="post.title" type="news"></ShareButton>
			</template>
		</TopBar>

		<div class="flex items-center justify-center" v-if="!isFetchedPost">
			<ion-spinner name="dots"></ion-spinner>
		</div>

		<div v-if="post && isFetchedPost">
			<div class="flex overflow-scroll w-[100%] h-[182px] mb-4 invisible-scroller">
				<div class="flex items-center min-w-[326px] mr-4 rounded-xl overflow-hidden ml-[34px]">
					<img
						:src="post.main_image_url"
						@click="viewFullScreen(post.main_image_url)"
						class="object-fit w-[100%]" />
				</div>
				<div
					v-for="(image, idx) in post.images"
					:key="idx"
					class="flex items-center min-w-[326px] mr-4 rounded-xl overflow-hidden">
					<img :src="image.image_url" @click="viewFullScreen(image.image_url)" class="object-fit w-[100%]" />
				</div>
			</div>

			<div class="px-[34px] mb-2">
				<div class="flex font-sourcesanspro-regular text-xs2 font-color-text-50 mb-4">
					<div class="mr-2">
						{{ DateTime.fromISO(post?.created_at).toFormat('dd MMMM yyyy') }}
					</div>
					<div class="flex items-center">
						<ion-icon class="mr-1 text-m2 font-color-text-50" :icon="timeOutline"></ion-icon>
						{{ DateTime.fromISO(post?.created_at).toFormat('HH:mm') }}
					</div>
				</div>
				<div
					v-dompurify-html="transformOembedToIframe(post.content)"
					class="font-sourcesanspro-regular text-base2 mb-4 font-white"></div>
			</div>

			<div v-if="post.events.length" class="mb-2 font-sourcesanspro-semibold">
				<RelatedContent
					:title="t('components.related_content.title_events')"
					:contents="
						post.events.map((event) => {
							return {
								'link': `/events/${event.id}`,
								'src': event.main_image_url,
								'title': event.name,
								'subtitle': '',
							};
						})
					" />
			</div>

			<div class="h-[100px] mb-4">&nbsp;</div>
		</div>
	</ion-content>
</template>

<style lang="scss" scoped></style>
