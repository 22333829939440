import { ref, watch } from 'vue';
import { useQueryClient } from '@tanstack/vue-query';
import router from '@/router';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

import { anykrowdApi } from '@/api/anykrowdApi';
import { Order } from '@/shared/interfaces/order';
import { PaymentStatusEnum } from '@/enums/values.enum';
import { TopUpType } from '@/shared/interfaces/topUp';
import { TransactionType, TransactionSubtype } from '@/enums/wallet.enum';
import { useCart } from '@/modules/shop/composables/useCart';
import { useTransactionStore } from '@/stores/transaction/transactionStore';
import type { TopUpTransactionMetadata } from '@/shared/interfaces/topUp';
import type { Transaction } from '@/shared/interfaces/transaction';
import { useAuthStore } from '@/stores/auth/authStore';

// Get order details
const getOrderDetails = async (orderId: string): Promise<Order> => {
	const { data } = await anykrowdApi.get<Order>(`orders/${orderId}/payment-status`, false);
	return data;
};

// useTransaction
const useTransaction = () => {
	// Init
	const { t } = useI18n();
	const route = useRoute();
	const { getItemsAndTotal, clear } = useTransactionStore();
	const transaction: Transaction = getItemsAndTotal();
	const { clearCart } = useCart();
	const queryClient = useQueryClient();
	const { isAuthenticated } = useAuthStore();

	const transactionStatus = ref<{
		errorSubTitle: string;
		errorTitle: string;
		finished?: boolean;
		subTitle: string;
		success: boolean;
		totalPrice: number;
	}>({
		errorSubTitle: '',
		errorTitle: '',
		finished: false,
		subTitle: '',
		success: false,
		totalPrice: 0,
	});

	// handleTransaction
	const handleTransaction = (
		topUpMetadata: TopUpTransactionMetadata | undefined,
		{ subTitle, errorSubTitle, errorTitle }: { subTitle: string; errorSubTitle: string; errorTitle: string },
	) => {
		// Init
		const { paymentAmount, paymentStatus } = topUpMetadata!;
		// Remove queries
		queryClient.removeQueries({ queryKey: ['rfidWallet'] });

		// Handle payment status
		if (paymentStatus === PaymentStatusEnum.paid || paymentStatus === 'no_latest_topup') {
			return {
				errorSubTitle: '',
				errorTitle: '',
				finished: true,
				subTitle,
				success: true,
				totalPrice: paymentAmount,
			};
		} else {
			return {
				errorSubTitle,
				errorTitle,
				finished: true,
				subTitle: '',
				success: false,
				totalPrice: 0,
			};
		}
	};

	const handleTopUpMetadata = (topUpMetadata: any) => {
		// Handle transaction per type
		switch (topUpMetadata.transactionType) {
			case TransactionType.TOP_UP: {
				transactionStatus.value = handleTransaction(topUpMetadata, {
					subTitle:
						transaction.subtype === TransactionSubtype.PACKAGE
							? 'top_up.success.subtitle_variation'
							: 'top_up.success.subtitle',
					errorSubTitle: 'transaction.error.subtitle',
					errorTitle: 'transaction.error.title',
				});
				break;
			}
			case TransactionType.VOUCHER_TOP_UP: {
				transactionStatus.value = handleTransaction(topUpMetadata, {
					subTitle: 'top_up.redeem_voucher.voucher_redeemed',
					errorSubTitle: 'transaction.error.subtitle',
					errorTitle: 'transaction.error.title',
				});
				break;
			}
			case TransactionType.SHOP_ORDER: {
				topUpMetadata.paymentAmount = transaction.totalPriceNumber;
				transactionStatus.value = handleTransaction(topUpMetadata, {
					subTitle: 'shop.order.success.subtitle',
					errorSubTitle: 'transaction.error.subtitle',
					errorTitle: 'transaction.error.title',
				});
				break;
			}
			case TransactionType.PAYMENT: {
				topUpMetadata.paymentAmount = transaction.totalPriceNumber;
				transactionStatus.value = handleTransaction(topUpMetadata, {
					subTitle: 'ticket.success',
					errorSubTitle: 'transaction.error.subtitle',
					errorTitle: 'transaction.error.title',
				});
				break;
			}
			case TransactionType.SELF_ORDER: {
				topUpMetadata.paymentAmount = transaction.totalPriceNumber;
				transactionStatus.value = handleTransaction(topUpMetadata, {
					subTitle: 'self_ordering.success.subtitle',
					errorSubTitle: 'transaction.error.subtitle',
					errorTitle: 'transaction.error.title',
				});
				break;
			}
			case TransactionType.WALLET_MERGE: {
				topUpMetadata.paymentAmount = topUpMetadata.paymentAmountTotal!;
				transactionStatus.value = handleTransaction(topUpMetadata, {
					subTitle: 'rfid.guest.merged_wallet.balance',
					errorSubTitle: 'transaction.error.subtitle',
					errorTitle: 'transaction.error.title',
				});
				break;
			}
		}
	};

	const mapTopUpType = (orderType: string) => {
		switch (orderType) {
			case 'topup': {
				return TransactionType.TOP_UP;
			}
			default: {
				return TransactionType.TOP_UP;
			}
		}
	};

	// Handle order
	if (route.query && route.query.orderId) {
		const orderId = route.query.orderId!.toString();
		getOrderDetails(orderId).then((order) => {
			const topUpType = mapTopUpType(order.type);
			const topUpMetadata = {
				transactionId: orderId,
				transactionType: topUpType,
				paymentAmount: order.fiat_paid_price,
				paymentStatus: order.payment_status,
			};

			if (!transaction.items.find((item) => item.description === t('transaction.administrative_fee'))) {
				if (+order.app_service_cost > 0) {
					transaction.items.push({
						description: t('transaction.administrative_fee'),
						itemPrice: +order.app_service_cost,
						quantity: 1,
					});
				}
			}

			handleTopUpMetadata(topUpMetadata);
		});
	} else {
		const topUpMetadata = {
			transactionId: '',
			transactionType: transaction.type,
			paymentAmount: transaction.totalPriceNumber,
			paymentStatus: 'paid',
		};
		handleTopUpMetadata(topUpMetadata);
	}

	// goTo
	const goTo = async () => {
		const redirectPath =
			transaction.additionalInfo && transaction.additionalInfo.redirectPath
				? transaction.additionalInfo.redirectPath
				: '/dashboard';
		if (transactionStatus.value!.success) {
			clearCart();
			clear();
			useTransactionStore().$reset();
		}
		await router.push(redirectPath);
	};

	// Return
	return {
		goTo,
		transaction,
		transactionStatus,
	};
};

export default useTransaction;
