<script setup lang="ts">
import router from '@/router';
import { IonButton, IonContent, IonIcon, IonInput } from '@ionic/vue';
import TopBar from '@/shared/components/TopBar.vue';
import { arrowForwardOutline, chevronBackOutline, qrCodeOutline } from 'ionicons/icons';
import TextInput from '@/shared/components/sign-up-forms/TextInput.vue';

// Go back
const goBack = () => {
	router.back();
};

const goTo = async (link: string) => {
	await router.push({ path: link, query: { callbackPage: 'dashboard' } });
};
</script>

<template>
	<ion-content>
		<div class="flex flex-col h-full justify-between">
			<TopBar class="px-[34px]">
				<template #left>
					<div class="flex items-center justify-start cursor-pointer" @click="goBack">
						<ion-icon :icon="chevronBackOutline" class="text-white"></ion-icon>
					</div>
				</template>

				<template #center>
					<div class="flex grow justify-center w-full font-sourcesanspro-semibold text-l2">
						Share with friends
					</div>
				</template>
			</TopBar>

			<div class="flex grow flex-col items-center justify-center px-[34px]">
				<TextInput
					wrapper-class="flex flex-wrap justify-left item-center mb-2 border border-solid focus-within:background-white-15"
					name="email"
					type="email"
					placeholder="Email" />
				<span class="font-sourcesanspro-regular text-sm2 text-white/50">Enter your friend's email.</span>
			</div>

			<div class="flex flex-col w-full px-[34px] align-center mb-8">
				<ion-button class="w-full" expand="full" size="large" shape="round" @click="goTo('/vouchers/success')">
					<div class="flex w-full items-center justify-between">
						Continue
						<ion-icon :icon="arrowForwardOutline" class="text-dark-blue mr-2"></ion-icon>
					</div>
				</ion-button>
			</div>
		</div>
	</ion-content>
</template>

<style lang="scss" scoped></style>
