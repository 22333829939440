import vue, { ref, watch } from 'vue';
import * as Yup from 'yup';
import { useForm } from 'vee-validate';
import { AxiosResponse } from 'axios';
import { useI18n } from 'vue-i18n';

import { anykrowdApiBase } from '@/api/anykrowdApi';
import useLoading from '@/shared/composables/useLoading';
import useToast from '@/shared/composables/useToast';

const useCustomForm = (
	schema: Yup.ObjectSchema<any>,
	url: string,
	method = 'get',
	successMessage: string,
	errorMessage: string,
	timeout = 5000,
	additionalData?: any,
) => {
	// Init
	const apiError = ref();
	const apiResponse = ref<AxiosResponse>();
	const isError = ref(false);
	const isFormValid = ref(false);
	const isSuccess = ref(false);
	const { presentToast } = useToast();
	const { presentLoading } = useLoading();
	const { t } = useI18n();

	const { errors, handleSubmit, meta, setFieldValue, validate, validateField, values } = useForm({
		validationSchema: schema,
	});

	// Validating form
	const onInputChange = (formValid: vue.ComputedRef<boolean>) => {
		isFormValid.value = formValid.value;
	};

	const submitData = async (formData: any) => {
		const loading = await presentLoading(t('general.please_wait'));
		await anykrowdApiBase({
			url,
			method,
			data: additionalData && additionalData.value ? { ...formData, ...additionalData.value } : formData,
		})
			.then(async (response) => {
				apiResponse.value = response;

				if (response.data.success || response.data) {
					if (additionalData?.checkError) {
						if (additionalData.error == response.data.error) {
							presentToast('top', t(additionalData.errorTranslation), timeout);
						} else {
							presentToast('top', t(successMessage), timeout);
						}
					} else if (additionalData?.overwriteDefaultResponseMessage) {
						presentToast('top', response.data[additionalData?.responseField], timeout);
					} else {
						presentToast('top', t(successMessage), timeout);
					}
					isSuccess.value = true;
				} else if (response.data.error) {
					isSuccess.value = false;
					if (additionalData?.overwriteDefaultResponseMessage) {
						presentToast('top', response.data[additionalData?.responseField], timeout);
					} else {
						presentToast('top', t(errorMessage), timeout);
					}
				}
				await loading.dismiss();
			})
			.catch(async (error) => {
				isError.value = true;
				apiError.value = error;
				await loading.dismiss();
				if (additionalData?.overwriteDefaultResponseMessage) {
					presentToast('top', error.response.data[additionalData?.responseField], timeout);
				} else {
					if (error.response?.data.error) {
						presentToast('top', t(`error.${error.response.data.error}`), timeout, 'danger');
					} else {
						presentToast('top', t(errorMessage), timeout, 'danger');
					}
				}
			});
	};

	// Send form
	const onSubmit = handleSubmit(async (formData: any) => {
		submitData(formData);
	});

	const customOnSubmit = async (formData: any) => {
		submitData(formData);
	};

	// Watch changes on meta
	watch(meta, () => {
		isFormValid.value = meta.value.valid;
	});

	return {
		apiError,
		apiResponse,
		customOnSubmit,
		errors,
		formValues: values,
		isError,
		isFormValid,
		isSuccess,
		meta,
		setFieldValue,
		onSubmit,
		onInputChange,
		validate,
		validateField,
	};
};

export default useCustomForm;
