<script setup lang="ts">
import { ref } from 'vue';
import router from '@/router';
import { IonContent, IonFooter, IonIcon } from '@ionic/vue';
import { chevronBackOutline, heartOutline, shareSocialOutline } from 'ionicons/icons';

import FullScreenImage from '@/shared/components/ui/FullScreenImage.vue';
import TopBar from '@/shared/components/TopBar.vue';
import NavigationBar from '@/shared/components/ui/NavigationBar.vue';
import TagsList from '../components/TagsList.vue';
import { useAssetsStore } from '@/stores/shared/assetsStore';

// Init
const assetStore = useAssetsStore();

// Go back
const goBack = () => {
	router.back();
};

// Image handling
const fullScreenImage = ref('');
const openInFullScreen = ref(false);
const viewFullScreen = (image: string) => {
	fullScreenImage.value = image;
	openInFullScreen.value = true;
};

const onCloseImage = () => {
	openInFullScreen.value = false;
};
</script>

<template>
	<FullScreenImage v-if="openInFullScreen" :fullScreenImage="fullScreenImage" @onCloseImage="onCloseImage" />
	<ion-content>
		<TopBar class="px-[34px]">
			<template #left>
				<div class="flex items-center justify-start cursor-pointer" @click="goBack">
					<ion-icon :icon="chevronBackOutline" class="text-white"></ion-icon>
				</div>
			</template>

			<template #center>
				<div class="flex justify-center w-full font-sourcesanspro-semibold text-l2">Event map</div>
			</template>

			<template #right>
				<ion-icon class="mr-1" :icon="heartOutline"></ion-icon>
				<ion-icon class="mr-1" :icon="shareSocialOutline"></ion-icon>
			</template>
		</TopBar>
		<div class="flex overflow-scroll w-[100%] h-[182px] mb-4 invisible-scroller">
			<div class="flex items-center min-w-[326px] ml-[34px] mr-4 rounded-xl overflow-hidden">
				<img
					:src="require('@/statics/png-delete-after-implement/img-1.png')"
					@click="viewFullScreen(require('@/statics/png-delete-after-implement/img-1.png'))"
					class="object-fit w-[100%]" />
			</div>
			<div class="flex items-center min-w-[326px] mr-4 rounded-xl overflow-hidden">
				<img
					:src="require('@/statics/png-delete-after-implement/img-2.png')"
					@click="viewFullScreen(require('@/statics/png-delete-after-implement/img-2.png'))"
					class="object-fit w-[100%]" />
			</div>
		</div>

		<div class="flex flex-col px-[34px] mb-4">
			<div class="flex items-center mb-6">
				<img :src="assetStore.logoUrl" class="max-w-[70%]" />
				<span class="font-sourcesanspro-semibold text-l2">Map of the Event</span>
			</div>
			<div class="mb-4">
				<img :src="require('@/statics/svg-delete-after-implement/event-map-image.svg')" />
			</div>
			<div>
				<div class="font-sourcesanspro-regular text-base2 mb-4 font-color-text-50">
					<p class="mb-4">
						Aenean in aliquam purus, eget molestie diam. Etiam velit ante, dictum vitae orci sed, mollis
						tincidunt felis. Proin quis ipsum et odio rutrum facilisis quis a est.
					</p>
					<p class="">
						Curabitur et magna vel nisi tincidunt placerat sed id felis. Nam varius tempor quam, at
						hendrerit massa fermentum quis.
					</p>
				</div>
			</div>
		</div>

		<div class="flex flex-row justify-left flex-wrap px-[34px] mb-4">
			<TagsList
				name="interests"
				:checks="[
					{ name: 'lifeimprovement' },
					{ name: 'knowledge', selected: true },
					{ name: 'freedrinks' },
					{ name: 'nightparty' },
					{ name: 'music' },
					{ name: 'eventproofs' },
					{ 'icon': '⠇' },
				]" />
		</div>

		<div class="h-[100px] mb-10">&nbsp;</div>
	</ion-content>

	<ion-footer>
		<NavigationBar item-active="news" />
	</ion-footer>
</template>

<style lang="scss" scoped></style>
