export enum FeedTypeEnum {
	event = 'event',
	event_now = 'event_now',
	event_soon = 'event_soon',
	post = 'post',
	video = 'video',
	quiz = 'quiz',
	config = 'config',
	main_alert = 'main_alert',
}

export enum PaymentStatusEnum {
	refunded = 'refunded',
	pending = 'pending',
	failed = 'failed',
	paid = 'paid',
	open = 'open',
	expired = 'expired',
	canceled = 'canceled',
}

export enum RefundTagsColors {
	APPROVED = '#40D0FD',
	FAILED = '#F96666',
	REFUNDED = '#00D971',
	PENDING = '#E4F966',
}

export enum SelfLinkingStatus {
	OWNED_DEVICE_SCANNED = 'owned_device_scanned',
	GUEST_WALLET_MERGED = 'guest_wallet_merged',
	GUEST_DEVICE_TOPPED_UP = 'guest_device_topped_up',
}

export const SELF_LINKING_STATUS_QUERY_PARAM_KEY = 'self_linking_status';
export const SELF_LINKING_LINK_AFTER_AUTH_INTENT_PARAM_KEY = 'link_rfid';

// Borders generated using
// https://kovart.github.io/dashed-border-generator/
export enum UserAvatarBorder {
	dashedWhite = "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='white' stroke-width='6' stroke-dasharray='1' stroke-dashoffset='100' stroke-linecap='butt'/%3e%3c/svg%3e\")",
	dashedWhite2 = "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='white' stroke-width='6' stroke-dasharray='16' stroke-dashoffset='6' stroke-linecap='square'/%3e%3c/svg%3e\")",
	solidGreen = "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%231DE9B6FF' stroke-width='6' stroke-dasharray='0' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e\")",
	solidYellow = "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%23ECBD16FF' stroke-width='6' stroke-dasharray='0' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e\")",
}

export enum ValuesEnum {
	// Store
	StoreAccessToken = 'StoreAccessToken',
	StoreCart = 'StoreCart',
	StoreRefreshToken = 'StoreRefreshToken',
	StoreProfile = 'StoreProfile',
	StoreRememberMe = 'StoreRememberMe',
	StoreCartProdcuts = 'StoreCartProdcuts',
	StoreTenantOptions = 'StoreTenantOptions',
	StoreLatestTransaction = 'StoreLatestTransaction',
	StoreLatestTransactionData = 'StoreLatestTransactionData',
	StoreChatProfile = 'StoreChatProfile',
	StoreActiveBooking = 'StoreActiveBooking',
	StoreWalletBalance = 'StoreWalletBalance',
	StoreAvailableTags = 'StoreAvailableTags',
	StoreAddFundsLatestTransaction = 'StoreAddFundsLatestTransaction',
	StoreTenantConfig = 'StoreTenantConfig',
	StoreEmailVerified = 'StoreEmailVerified',
	StoreFeed = 'StoreFeed',
	StoreEventsGoing = 'StoreEventsGoing',
	StoreEventsInvited = 'StoreEventsInvited',
	StoreEventsPast = 'StoreEventsPast',
	StoreBookingsAccepted = 'StoreBookingsAccepted',
	StoreBookingsPending = 'StoreBookingsPending',
	StoreBookingsPast = 'StoreBookingsPast',
	StoreBookingsDenied = 'StoreBookingsDenied',
	StoreSelectedPaymentCountry = 'StoreSelectedPaymentCountry',
	StoreShopAll = 'StoreShopAll',
	StoreShopPrevious = 'StoreShopPrevious',
	StoreShopDefault = 'StoreShopDefault',
	StoreListOfMainAlertIDsPrompted = 'StoreListOfMainAlertIDsPrompted',
	StorePushNotificationToken = 'StorePushNotificationToken',
	StoreTimetables = 'StoreTimetables',
	StoreCacheTimestamps = 'StoreCacheTimestamps',
	StoreAuthCacheTimestamps = 'StoreAuthCacheTimestamps',

	// Errors
	CommonError = 'An error has occurred',
	CommonWarning = 'Warning!',
	// Others
	// PasswordPattern = '(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$',
	PasswordPattern = '(?=^.{6,}$).*$',

	// Events
	eventLangChanged = 'langChanged',
	eventProfileChanged = 'profile:changed',
	eventTenantOptionsChanged = 'tenant:changed',
	eventLogout = 'user:logout',
	eventLogin = 'user:login',
	eventUserGetsAuthenticated = 'user:userGetsAuthenticated',
	eventUserApprovalRequired = 'userApprovalRequired',
	eventCartAdd = 'cart:add',
	eventCartRemove = 'cart:remove',
	eventPageLoaded = 'page:loaded',
	eventBalanceChanged = 'balance:changed',
	eventPublicWalletNavigated = 'wallet:publicWalletHandlerNavigated',

	eventWebShopCartCleared = 'webShopCart:cleared',
	eventCartReload = 'cart:reload',

	// Wallet
	WalletQrCode = 'wallet:qr_code',

	// Onboarding
	OnboardingAlreadyShown = 'onboarding:already-shown',

	// Deeplink
	DeeplinkSpotId = 'deeplink:spotid',
	DeeplinkVoucherId = 'deeplink:voucherid',
	DeeplinkOnboarding = 'deeplink:onboarding',

	// Session Variables
	SessionAlreadyShownInstallationBanner = 'session:already-shown-installation-banner',
	SessionTopUpSuccessData = 'session:top-up-success-data',
}

export enum CartCategoriesEnum {
	shop = 'shop',
	ticket = 'ticket',
	selfOrder = 'selfOrder',
}

export enum TopUp {
	TopUpTypePackages = 'packages',
	TopUpTypeFreeAmount = 'free-amount',
}
