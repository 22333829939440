<script setup lang="ts">
import { watchEffect } from 'vue';
import router from '@/router';
import { IonContent, IonFooter, IonIcon, IonRefresher, IonRefresherContent, IonSpinner } from '@ionic/vue';
import { arrowForwardOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import NavigationBar from '@/shared/components/ui/NavigationBar.vue';
import TopBar from '@/shared/components/TopBar.vue';
import useTimeTables from '@/modules/timetables/composables/useTimeTables';

// Init
const { t } = useI18n();
const { getTimeTables } = useTimeTables();
const { isFetchedTimeTables, removeTimeTablesDataFromCache, refetchTimeTables, timeTables } = getTimeTables();

watchEffect(() => {
	if (timeTables && timeTables.value && timeTables.value.length === 1) {
		router.push({ path: `/timeTables/detail/${timeTables.value[0].id}` });
	}
});

// Refresh
const handleRefresh = async (event: any) => {
	removeTimeTablesDataFromCache();
	timeTables.value = undefined;
	await refetchTimeTables();
	event.target.complete();
};
</script>

<template>
	<ion-content class="flex">
		<ion-refresher class="mt-8" slot="fixed" @ionRefresh="handleRefresh($event)">
			<ion-refresher-content></ion-refresher-content>
		</ion-refresher>

		<TopBar class="px-[34px]">
			<template #left><span></span></template>
			<template #center>
				<div class="flex justify-center w-full font-sourcesanspro-semibold text-l2">
					{{ t('timetables.title') }}
				</div>
			</template>
		</TopBar>

		<div class="flex items-center justify-center" v-if="!isFetchedTimeTables">
			<ion-spinner name="dots"></ion-spinner>
		</div>
		<div v-if="timeTables" class="flex flex-col px-[34px] mb-4">
			<router-link v-for="(timeTable, idx) in timeTables" :key="idx" :to="`/timetables/detail/${timeTable.id}`">
				<div
					class="flex items-center justify-between mb-4 p-3 backdrop-blur-xl bg-white/10 rounded-lg cursor-pointer">
					<div class="flex flex-col items-start justify-start">
						<span class="font-sourcesanspro-semibold">{{ timeTable.name }}</span>
						<div class="flex items-center font-sourcesanspro-regular">
							<span class="text-white/50"> {{ timeTable.startAt.toFormat('ccc dd/MM/yyyy') }}</span>
							<div
								v-if="
									timeTable.startAt.toFormat('ccc dd/MM/yyyy') !==
									timeTable.endAt.toFormat('ccc dd/MM/yyyy')
								">
								<span class="mx-1 text-white/50">→</span>
								<span class="text-white/50">{{ timeTable.endAt.toFormat('ccc dd/MM/yyyy') }}</span>
							</div>
						</div>
					</div>
					<div class="flex items-end justify-center">
						<ion-icon :icon="arrowForwardOutline" class="text-white"></ion-icon>
					</div>
				</div>
			</router-link>
		</div>
	</ion-content>

	<ion-footer>
		<NavigationBar item-active="timetables" />
	</ion-footer>
</template>

<style lang="scss" scoped></style>
