/** ----------------------- API INTERFACES ----------------------- */

/**
 * Interface that represents an Map response
 */
export interface ApiMap {
	id: number;
	name: string;
	overlay?: string;
	background_color?: string;
	background_image?: string;
	initialPosition: ApiInitialPosition;
	boundries: ApiBound;
	markers?: ApiMarker[];
}

export interface ApiInitialPosition {
	altitude: number;
	lat: number;
	lng: number;
	zoom: number;
}

export interface ApiBound {
	east: number;
	west: number;
	north: number;
	south: number;
}

export interface ApiMarker {
	id: number;
	icon?: string;
	position: ApiPosition;
	image?: string;
	color?: string;
	poi?: ApiPoi;
}

export interface ApiPosition {
	lat: number;
	lng: number;
}

export interface ApiPoi {
	id: number;
	name: string;
	description: string;
	description_short: string;
	image: string;
}

/**
 * The custom overlay object contains the image,
 * the bounds of the image, and a reference to the map.
 */
// pattern: https://stackoverflow.com/a/59000980
export class mapCustomOverlay {
	private customOverlayView = class extends google.maps.OverlayView {
		private bounds: google.maps.LatLngBounds;
		private image: string;
		private div?: HTMLElement;

		constructor(bounds: google.maps.LatLngBounds, image: string) {
			super();

			this.bounds = bounds;
			this.image = image;
		}

		// onAdd is called when the map's panes are ready and the overlay has been added to the map.
		onAdd() {
			this.div = document.createElement('div');
			this.div.style.borderStyle = 'none';
			this.div.style.borderWidth = '0px';
			this.div.style.position = 'absolute';

			// Create the img element and attach it to the div.
			const img = document.createElement('img');

			img.src = this.image;
			img.style.width = '100%';
			img.style.height = '100%';
			img.style.position = 'absolute';
			this.div.appendChild(img);

			// Add the element to the "overlayLayer" pane.
			const panes = this.getPanes()!;
			panes.overlayLayer.appendChild(this.div);
		}

		draw() {
			// We use the south-west and north-east
			// coordinates of the overlay to peg it to the correct position and size.
			// To do this, we need to retrieve the projection from the overlay.
			const overlayProjection = this.getProjection();

			// Retrieve the south-west and north-east coordinates of this overlay
			// in LatLngs and convert them to pixel coordinates.
			// We'll use these coordinates to resize the div.
			const sw = overlayProjection.fromLatLngToDivPixel(this.bounds.getSouthWest())!;
			const ne = overlayProjection.fromLatLngToDivPixel(this.bounds.getNorthEast())!;

			// Resize the image's div to fit the indicated dimensions.
			if (this.div) {
				this.div.style.left = sw.x + 'px';
				this.div.style.top = ne.y + 'px';
				this.div.style.width = ne.x - sw.x + 'px';
				this.div.style.height = sw.y - ne.y + 'px';
			}
		}

		/**
		 * The onRemove() method will be called automatically from the API if
		 * we ever set the overlay's map property to 'null'.
		 */
		onRemove() {
			if (this.div) {
				(this.div.parentNode as HTMLElement).removeChild(this.div);
				delete this.div;
			}
		}
	};

	createMapTextOverlay(bounds: google.maps.LatLngBounds, image: string) {
		return new this.customOverlayView(bounds, image);
	}
}
