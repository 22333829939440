<script lang="ts" setup>
import { onBeforeUnmount, ref } from 'vue';
import router from '@/router';
import { IonButton, IonContent, IonIcon, IonItem, IonList, IonPopover, IonSpinner } from '@ionic/vue';
import { addOutline, chevronDownOutline, chevronBackOutline, chevronForwardOutline } from 'ionicons/icons';
import { QueryClient, QueryObserver, useQueryClient } from '@tanstack/vue-query';
import { useI18n } from 'vue-i18n';

import { useAuthStore } from '@/stores/auth/authStore';
import { Wallet } from '../interfaces/balance';
import useTenant from '@/shared/composables/useTenant';
import useToast from '@/shared/composables/useToast';
import useUtils from '@/shared/composables/useUtils';
import useWallet from '@/modules/wallet/composables/useWallet';
import { captureMessage } from '@/sentry';
import { DateTime } from 'luxon';

const props = defineProps({
	user: { type: String, default: '' },
	walletId: { type: String, default: '' },
});

// Init
const { t } = useI18n();
const emit = defineEmits(['onWalletSet']);
const { presentToast } = useToast();
const authStore = useAuthStore();
const { getWallets, getWalletsFromApi, selectedWallet } = useWallet();
const { getTenantConfigAndStyles } = useTenant();
const { tenantConfig } = getTenantConfigAndStyles();
const { refetchWalletsData, wallets, walletUpdatedAt } = getWallets();
const { convertToCurrency, toRelativeTime } = useUtils();
const queryClient = useQueryClient();
const walletsObserver = new QueryObserver(queryClient, { queryKey: ['wallets'], queryFn: () => getWalletsFromApi() });
const unsubscribe = ref();
const isOnline = ref(navigator.onLine);
const startTime = ref(new Date());
const showWarningMessage = ref(false);

if (authStore.isAuthenticated()) {
	refetchWalletsData();
}

setInterval(() => {
	const seconds = Math.round(DateTime.now().diff(DateTime.fromJSDate(startTime.value)).milliseconds / 1000);
	if (seconds > 5 && !selectedWallet.value) {
		showWarningMessage.value = true;
	} else {
		showWarningMessage.value = false;
	}
}, 1000);

// Subscribe to updates on styles
if (authStore.isAuthenticated()) {
	unsubscribe.value = walletsObserver.subscribe((result) => {
		const walletsData = ref<Wallet[]>(result.data as Wallet[]);
		if (walletsData.value) {
			if (walletsData?.value?.length && walletsData?.value?.length > 0) {
				selectedWallet.value = walletsData.value?.find((wallet) => {
					return wallet.currency.default;
				});
				// if not default wallet, then select first
				if (props.walletId) {
					selectedWallet.value = walletsData.value?.find((wallet) => {
						return wallet.id == +props.walletId;
					});
				} else if (!selectedWallet.value) {
					selectedWallet.value = walletsData.value[0];
				}
			}
			emit('onWalletSet', selectedWallet.value);
		}
	});
}

onBeforeUnmount(() => {
	if (unsubscribe.value) {
		unsubscribe.value();
	}
});

const selectWallet = (wallet: Wallet | undefined) => {
	if (!wallet) {
		captureMessage(`Attempted to select a wallet that does not exist`);
	}
	selectedWallet.value = wallet;
	emit('onWalletSet', wallet);
};

const getWalletAtIndex = (index: number) => {
	if (index >= wallets.value!.length) {
		index = 0;
	} else if (index < 0) {
		index = wallets.value!.length - 1;
	}
	return wallets.value?.[index];
};

const currentWalletIndex = () => {
	return wallets.value!.findIndex((wallet) => wallet.id === selectedWallet.value!.id);
};

const getPreviousWallet = () => {
	return getWalletAtIndex(currentWalletIndex() - 1);
};

const getNextWallet = () => {
	return getWalletAtIndex(currentWalletIndex() + 1);
};

const selectPreviousWallet = () => {
	selectWallet(getPreviousWallet());
};

const selectNextWallet = () => {
	selectWallet(getNextWallet());
};

const hideOfflineMessage = () => {
	isOnline.value = true;
};

const showOfflineMessage = () => {
	isOnline.value = false;
};

window.addEventListener('offline', (e) => {
	showOfflineMessage();
});

window.addEventListener('online', (e) => {
	hideOfflineMessage();
});

const goTo = async (link: string) => {
	// If the user is not authenticated, we will first ask him to sign in
	if (!authStore.isAuthenticated()) {
		await presentToast('top', t('general.please_login_first'), 5000, 'danger');
		await router.push('/auth/signuphome');
	} else {
		await router.push(link);
	}
};
</script>

<template>
	<div
		v-if="!tenantConfig?.hide_wallet"
		:class="[
			{
				'mb-2': !tenantConfig?.viva_wallet_payconiq_preferred_mode,
				'mb-8': tenantConfig?.viva_wallet_payconiq_preferred_mode,
			},
		]">
		<div class="flex">
			<div
				v-if="isOnline && authStore.isAuthenticated()"
				class="flex w-[34px] min-w-[34px] items-center justify-center cursor-pointer">
				<ion-icon
					v-if="wallets && wallets?.length > 1"
					:icon="chevronBackOutline"
					class="text-white active:bg-white/30 active:rounded-full active:p-1"
					@click="selectPreviousWallet()"></ion-icon>
			</div>
			<div
				:class="[
					{
						'bg-white-image': !tenantConfig?.viva_wallet_payconiq_preferred_mode,
						'payconiq': tenantConfig?.viva_wallet_payconiq_preferred_mode,
						'text-white': tenantConfig?.viva_wallet_payconiq_preferred_mode,
						'font-color-dark': !tenantConfig?.viva_wallet_payconiq_preferred_mode,
						'mx-[34px]': !isOnline || !authStore.isAuthenticated(),
					},
				]"
				class="flex justify-between rounded-md p-4 w-full">
				<div
					class="flex flex-col justify-between w-full"
					:class="[
						{
							'px-4': !authStore.isAuthenticated(),
						},
					]">
					<div
						v-if="isOnline && authStore.isAuthenticated() && !selectedWallet"
						class="flex w-full items-center justify-center">
						<ion-spinner name="dots" class="text-anykrowd-dark"></ion-spinner>
					</div>
					<div v-if="authStore.isAuthenticated() && (!isOnline || showWarningMessage)" class="text-base">
						{{ t('general.connection.offline_long_message') }}
					</div>
					<div v-if="isOnline" class="flex flex-col">
						<div v-if="authStore.isAuthenticated()" class="flex flex-row justify-between mb-6">
							<div v-if="selectedWallet" class="flex flex-col">
								<div class="font-sourcesanspro-semibold text-anykrowd-dark/50 text-sm">
									{{ t('components.wallet_box.your') }} {{ selectedWallet.currency.name }}
									{{ t('components.wallet_box.balance') }}
								</div>

								<span class="font-sourcesanspro-black text-lg3">
									{{ selectedWallet.currency.prefix }}
									{{ convertToCurrency(+selectedWallet.current_balance) }}
								</span>
								<!-- <span class="font-sourcesanspro-regular text-lg"> + 2</span> -->
							</div>
							<div class="flex justify-between mb-2">
								<div></div>
								<div v-if="selectedWallet">
									<div
										id="popover-button"
										class="flex items-center justify-center rounded-lg font-sourcesanspro-bold text-anykrowd-dark text-md cursor-pointer">
										<div class="mr-2">{{ selectedWallet.currency.prefix }}</div>
										<div class="mr-2">{{ selectedWallet?.currency.name }}</div>
										<ion-icon
											v-if="wallets && wallets?.length > 1"
											:icon="chevronDownOutline"
											class="text-anykrowd-dark text-md"></ion-icon>
									</div>
									<ion-popover trigger="popover-button" :dismiss-on-select="true">
										<ion-content>
											<ion-list>
												<ion-item
													v-for="wallet in wallets"
													class="flex items-center justify-center ion-no-padding ion-no-margin"
													:key="wallet.id"
													:button="true"
													@click="selectWallet(wallet)"
													:detail="false">
													<div class="flex flex-col px-3 py-2 font-sourcesanspro-regular">
														<div class="mb-1">
															<span>
																{{ wallet.currency.prefix }}
																{{ wallet.current_balance }}
																{{ wallet.currency.name }}
															</span>
														</div>
														<!-- <span class="text-sm text-white/60">+2 BONUS COOKIES</span> -->
													</div>
												</ion-item>
											</ion-list>
										</ion-content>
									</ion-popover>
								</div>
							</div>
						</div>
					</div>
					<div class="flex items-center justify-between">
						<ion-button
							v-if="
								isOnline &&
								authStore.isAuthenticated() &&
								selectedWallet &&
								selectedWallet.currency.topupable
							"
							:class="[
								{
									'black': !tenantConfig?.viva_wallet_payconiq_preferred_mode,
								},
							]"
							:shape="!tenantConfig?.viva_wallet_payconiq_preferred_mode ? 'round' : undefined"
							class="w-full h-[40px] font-sourcesanspro-semibold text-sm2 ion-no-margin hover:bg-white hover:rounded"
							@click="goTo(`/top-up/${selectedWallet!.id}`)">
							<img
								v-if="tenantConfig?.viva_wallet_payconiq_preferred_mode"
								:src="require('@/statics/png/payconiq/payconiq-horizontal.png')"
								class="mr-1" />
							{{
								!tenantConfig?.viva_wallet_payconiq_preferred_mode
									? t('wallet.add')
									: `${t('wallet.add')} ${selectedWallet.currency.name} ${t('wallet.pay_with_payconiq')}`
							}}
						</ion-button>
					</div>
				</div>
			</div>
			<div
				v-if="isOnline && authStore.isAuthenticated()"
				class="flex w-[34px] min-w-[34px] items-center justify-center cursor-pointer">
				<ion-icon
					v-if="wallets && wallets?.length > 1"
					:icon="chevronForwardOutline"
					class="text-white active:bg-white/30 active:rounded-full active:p-1"
					@click="selectNextWallet()"></ion-icon>
			</div>
		</div>

		<div
			v-if="isOnline && walletUpdatedAt"
			class="flex px-[34px] mb-3 mt-[2px] justify-end font-sourcesanspro-regular text-xs">
			{{ t('wallet.updated_at') }}
			{{ toRelativeTime(t, walletUpdatedAt) }}
		</div>

		<div
			v-if="isOnline && tenantConfig?.viva_wallet_payconiq_preferred_mode"
			class="flex px-[34px] mx-auto items-center justify-between w-full">
			<span class="font-sourcesanspro-semibold drop-shadow-customtext-xs">Powered by payconiq</span>
			<img
				:src="require('@/statics/svg/payconiq/payconiq-horizontal-neg.svg')"
				class="w-[74px] h-[13px]drop-shadow-custom" />
		</div>
	</div>
</template>

<style lang="scss" scoped>
.bg-white-image {
	background: url('@/statics/png/wallet/wallet-bg.png') no-repeat;
	background-size: cover;
}

.payconiq {
	background: url('@/statics/png/payconiq/bg.png') no-repeat;
	background-size: cover;
}

ion-item {
	&::part(native) {
		padding-inline-start: 0 !important;
	}
}
</style>
