<script setup lang="ts">
import { toRefs, PropType } from 'vue';
import { useField } from 'vee-validate';
import type { QuizOptionItem } from '@/shared/interfaces/quizOption';

const props = defineProps({
	modelValue: { type: null },
	name: String,
	values: {
		type: Array as PropType<QuizOptionItem[]>,
		default: function () {
			return [];
		},
	},
});

const emit = defineEmits(['onSelect']);

// we are using toRefs to create reactive references to `name` and `value` props
// this is important because vee-validte needs to know if any of these props change
// https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
const { name } = toRefs(props);
const { value, handleChange } = useField(name);

const selectItem = (event: MouseEvent, radio: QuizOptionItem) => {
	handleChange(radio.name);
	event.preventDefault();
	emit('onSelect', radio.name);
};
</script>

<template>
	<button
		v-for="radio in props.values"
		:key="radio"
		class="flex justify-between items-center w-full bg-white border border-solid rounded-full mb-4 px-5 normal-case"
		:class="[
			{
				'bg-white/90': radio.name === value,
			},
		]"
		@click="($event) => selectItem($event, radio)">
		<span class="font-sourcesanspro-semibold text-base text-dark-blue">{{ radio.name }}</span>
		<span class="font-sourcesanspro-semibold text-base text-dark-blue">{{ radio.option }}</span>
	</button>
</template>

<style lang="scss" scoped>
button {
	height: 52px;
}
</style>
