<script setup lang="ts">
import { ref } from 'vue';
import router from '@/router';
import { IonContent, IonIcon, IonInput } from '@ionic/vue';
import TopBar from '@/shared/components/TopBar.vue';
import { addCircle, chevronBackOutline } from 'ionicons/icons';
import ChatMessage from '../components/ChatMessage.vue';

// Init
const isModalOpen = ref(false);

// Go back
const goBack = () => {
	router.back();
};
</script>

<template>
	<ion-content>
		<TopBar class="mb-6 px-[34px]">
			<template #left>
				<div class="flex items-center justify-start cursor-pointer" @click="goBack">
					<ion-icon :icon="chevronBackOutline" class="text-white"></ion-icon>
				</div>
			</template>

			<template #center>
				<div class="flex w-full">
					<div class="relative flex items-left h-[42px] mr-2">
						<img
							:src="require('@/statics/svg-delete-after-implement/person-15.svg')"
							class="w-[42px] h-[42px] rounded-full" />
						<div
							class="absolute w-[10px] h-[10px] left-[33px] bottom-[3px] rounded-full border-[2px] border-anykrowd-dark-purple bg-anykrowd-online-dot"></div>
					</div>
					<div class="flex flex-col grow">
						<span class="font-sourcesanspro-semibold text-l2">Angela Young</span>
						<span class="font-sourcesanspro-semibold text-sm text-anykrowd-online-dot">Online</span>
					</div>
				</div>
			</template>
		</TopBar>

		<div class="px-[34px]">
			<ChatMessage
				type="received"
				:sender-avatar="require('@/statics/svg-delete-after-implement/person-15.svg')"
				sender-status-class="bg-anykrowd-online-dot"
				time="08:22 am"
				>Hello, how can I help you</ChatMessage
			>

			<ChatMessage type="sent">Oh, Hello Angela Young</ChatMessage>
			<ChatMessage type="sent" time="09:24 am"
				>I feeling better than before. Should I need a masssage?</ChatMessage
			>

			<ChatMessage
				type="received"
				:sender-avatar="require('@/statics/svg-delete-after-implement/person-15.svg')"
				sender-status-class="bg-anykrowd-online-dot"
				time="09:26 am"
				>I will check-in this evening at 7:30 pm. It is ok for you?</ChatMessage
			>

			<ChatMessage
				type="sent"
				time="09:24 am"
				:read-by-avatars="[require('@/statics/svg-delete-after-implement/person-15.svg')]">
				Yeah sure i will be there at 7 pm with my brother
			</ChatMessage>

			<div class="h-[100px]">&nbsp;</div>
		</div>

		<div class="fixed button-container px-[34px] bottom-0 w-full">
			<div class="relative z-10 w-full h-full">
				<div class="absolute w-full bottom-0 mb-[34px]">
					<div class="flex flex-wrap justify-left item-center p-2 rounded-full bg-anykrowd-zinc mb-4">
						<div class="flex w-full items-center px-2">
							<ion-icon
								class="w-[41px] h-[41px] mr-2 text-[#C8C8C8] cursor-pointer"
								:icon="addCircle"
								slot="start"></ion-icon>
							<ion-input
								class="font-sourcesanspro-regular text-sm2 text-dark-blue"
								name="qr-code"
								type="text"
								placeholder="Write a message">
							</ion-input>
							<ion-icon
								class="text-dark-blue cursor-pointer"
								:src="require('@/statics/svg/friends/send.svg')"
								slot="end"></ion-icon>
						</div>
					</div>
				</div>
			</div>
		</div>
	</ion-content>
</template>

<style lang="scss" scoped></style>
