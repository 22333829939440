<script setup lang="ts">
import { ref, watch, watchEffect } from 'vue';
import router from '@/router';
import {
	IonButton,
	IonContent,
	IonIcon,
	IonInput,
	IonLabel,
	IonRefresher,
	IonRefresherContent,
	IonSpinner,
} from '@ionic/vue';
import { useI18n } from 'vue-i18n';

import { addOutline, chevronBackOutline, trashOutline } from 'ionicons/icons';
import AccordionList from '@/shared/components/ui/accordionlist/AccordionList.vue';
import AccordionListItem from '@/shared/components/ui/accordionlist/AccordionListItem.vue';
import TopBar from '@/shared/components/TopBar.vue';
import useAlert from '@/shared/composables/useAlert';
import useRfid from '@/modules/rfid/composables/useRfid';
import useToast from '@/shared/composables/useToast';
import type { RFID } from '@/shared/interfaces/rfid';

// Init
const isModalOpen = ref(false);
const { disconnectTag, getRfidTags } = useRfid();
const { isLoadingRfidTags, isRefetchingRfidTags, refetchRfidTags, removeRfidTags, rfidTags } = getRfidTags();
const { disconnectTagData, disconnectTagMutate } = disconnectTag();
const { t } = useI18n();
const { presentToast } = useToast();
const { presentAlert } = useAlert();
const mappedRfidTags = ref<any>([]);

// Refresh
const handleRefresh = async (event: any) => {
	removeRfidTags();
	await refetchRfidTags();
	event.target.complete();
};

// Confirm disconnect tag
const confirmDisconnect = (id: number) => {
	presentAlert({
		header: t('general.continue_question'),
		buttons: [
			{
				text: t('general.cancel'),
				role: 'cancel',
			},
			{
				text: t('general.confirm'),
				role: 'confirm',
				handler: async () => {
					await disconnectTagMutate(id);
				},
			},
		],
	});
};

// Watch disconnect status
watch(disconnectTagData, async () => {
	if (disconnectTagData.value && disconnectTagData.value.code === 'success') {
		mappedRfidTags.value = [];
		// Refetch tags
		await refetchRfidTags();
		await presentToast('top', t('components.transaction.rfid_tag_detached_successfully'), 5000);
	}
});

// Watch for rfidTags then map the result
watchEffect(() => {
	if (rfidTags.value && rfidTags.value.length) {
		mappedRfidTags.value = rfidTags.value.map((rfId: RFID) => {
			return {
				id: rfId.id,
				uuid: rfId.uuid,
			};
		});
	} else if (rfidTags.value && rfidTags.value.length === 0) {
		mappedRfidTags.value = [];
	}
});

const scanQr = () => {
	router.push('/rfid/link-bracelet');
};
</script>

<template>
	<ion-content>
		<ion-refresher class="mt-8" slot="fixed" @ionRefresh="handleRefresh($event)">
			<ion-refresher-content></ion-refresher-content>
		</ion-refresher>

		<TopBar class="px-[34px]">
			<template #left>
				<div class="flex items-center justify-start cursor-pointer">
					<router-link to="/dashboard" class="flex items-center justify-center">
						<ion-icon :icon="chevronBackOutline" class="text-white"></ion-icon>
					</router-link>
				</div>
			</template>

			<template #center>
				<div class="flex justify-center w-full font-sourcesanspro-semibold text-l2">
					{{ t('rfid.title') }} ({{ rfidTags.length }})
				</div>
			</template>
		</TopBar>

		<div class="px-[34px]">
			<div class="flex flex-col items-start justify-center py-4 font-sourcesanspro-regular">
				{{ t('rfid.description') }}
			</div>
			<AccordionList
				:title="t('rfid.linked_bracelets')"
				:headerActionIcon="addOutline"
				@onHeaderActionClick="scanQr()">
				<template #items>
					<div class="flex p-4 items-center justify-center" v-if="isLoadingRfidTags || isRefetchingRfidTags">
						<ion-spinner name="dots"></ion-spinner>
					</div>

					<div
						v-if="!rfidTags.length && !isLoadingRfidTags"
						class="flex p-4 items-center justify-center font-sourcesanspro-regular">
						{{ t('components.transaction.no_devices_found') }}
					</div>

					<!-- Father item -->
					<AccordionListItem
						v-for="item in mappedRfidTags"
						:key="item.id"
						:key-value="item.id.toString()"
						iconClass="bg-white"
						:custom-subtitle="t('components.transaction.bracelet_rfid')"
						:icon="require('@/statics/svg/rfid/bracelet.svg')"
						:title="item.uuid.toString()">
						<template #actions>
							<div class="flex grow justify-end">
								<ion-icon
									:icon="trashOutline"
									class="text-white"
									@click="confirmDisconnect(item.id)"></ion-icon>
							</div>
						</template>
					</AccordionListItem>
				</template>
			</AccordionList>
		</div>
	</ion-content>
</template>

<style lang="scss" scoped>
ion-input {
	--padding-start: 20px;
	--padding-end: 20px;
	--padding-top: 14px;
	--padding-bottom: 14px;
}
</style>
