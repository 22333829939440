<script setup lang="ts">
import { ref, watch } from 'vue';
import { IonButton, IonContent, IonIcon, IonSpinner } from '@ionic/vue';
import { chevronBackOutline, closeOutline, pencil } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';
import { DateTime } from 'luxon';

import AccordionListItemTwo from '@/shared/components/ui/accordionlisttwo/AccordionListItemTwo.vue';
import AccordionListTwo from '@/shared/components/ui/accordionlisttwo/AccordionListTwo.vue';
import ModalOne from '@/shared/components/ui/ModalOne.vue';
import ModalThree from '@/shared/components/ui/ModalThree.vue';
import NotificationsSettings from '@/modules/profile/components/NotificationsSettings.vue';
import TagCollections from '@/shared/components/TagCollections.vue';
import TopBar from '@/shared/components/TopBar.vue';
import type { Tag } from '@/shared/interfaces/tag';
import UserAvatar from '@/shared/components/user-ui/UserAvatar.vue';
import useUserProfile from '@/modules/profile/composables/useUserProfile';
import useTenant from '@/shared/composables/useTenant';

import { buildTimestamp } from '@/config/build';
import { version } from '../../../../package.json';
import { useDebugStore } from '@/stores/debug/debugStore';

// Init
const isModalOpen = ref(false);
const userTags = ref<number[]>([]);
const { t } = useI18n();
const { getUserProfile, getUserProfileDetails, deleteAccount, logoutUser, openLink, showInstallAppButton } =
	useUserProfile();
const { isFetchedUserProfile, isSuccessUserProfile, userProfile } = getUserProfile();
const { userProfileDetails } = getUserProfileDetails();
const { getTenantConfigAndStyles } = useTenant();
const { isSuccessTenantConfig, tenantConfig } = getTenantConfigAndStyles();

const showHideModal = (openState: boolean) => {
	isModalOpen.value = openState;
};

// Modals
const modalState = ref(false);
const modalType = ref('');
const setModal = (state: boolean, type?: string) => {
	modalState.value = state;
	modalType.value = type === 'tos' ? 'tos' : 'pp';
};

const { incrementDebugClicks, debugEnabled } = useDebugStore();

watch(
	isSuccessUserProfile,
	() => {
		if (userProfile.value) {
			const tags = userProfile.value.tags.map((tag: Tag) => tag.id);
			userTags.value = tags;
		}
	},
	{ immediate: true },
);
</script>

<template>
	<ion-content>
		<ModalOne :title="`Hey ${userProfile?.full_name}!`" :is-open="isModalOpen">
			<template #content>
				<div class="flex flex-col items-center">
					<div class="font-sourcesanspro-regular text-base text-white/60 mb-2">
						{{ t('notifications.request') }}
					</div>
					<ion-button class="w-full mb-2" expand="full" size="large" shape="round">
						<div class="flex items-center justify-center">
							<span class="text-m2">{{ t('general.confirm') }}</span>
						</div>
					</ion-button>
					<ion-button
						class="w-full"
						expand="full"
						size="large"
						shape="round"
						fill="outline"
						@click="showHideModal(false)">
						<div class="flex items-center justify-center text-white">
							<span class="text-m2">{{ t('general.cancel') }}</span>
						</div>
					</ion-button>
				</div>
			</template>
		</ModalOne>

		<ModalThree :is-open="modalState">
			<div class="flex w-full items-center justify-center mb-4">
				<div class="flex items-center justify-center w-[60px] h-[60px] rounded-full bg-anykrowd-gray/30 mb-4">
					<ion-icon
						class="text-[38px] text-black cursor-pointer"
						:icon="closeOutline"
						@click="setModal(false)"></ion-icon>
				</div>
			</div>
			<h1 class="font-sourcesanspro-semibold text-lg">
				{{ modalType === 'tos' ? 'Terms and conditions' : 'Privacy Policy' }}
			</h1>
			<div
				v-dompurify-html="
					modalType === 'tos' ? tenantConfig!.terms_and_conditions : tenantConfig!.privacy_policy
				"
				class="text-anykrowd-dark"></div>
		</ModalThree>

		<TopBar class="px-[34px]">
			<template #left>
				<div class="flex items-center justify-start cursor-pointer">
					<router-link to="/dashboard" class="flex items-center justify-center">
						<ion-icon :icon="chevronBackOutline" class="text-white"></ion-icon>
					</router-link>
				</div>
			</template>

			<template #center>
				<div class="flex justify-center w-full font-sourcesanspro-semibold text-l2">
					{{ t('profile.title') }}
				</div>
			</template>

			<template #right>
				<span></span>
			</template>
		</TopBar>

		<div class="px-[34px] w-full">
			<div
				class="relative flex flex-col items-center justify-center w-full mt-4 mb-2 pt-[52px] pb-6 px-6 backdrop-blur-xl bg-white/20 rounded-lg">
				<ion-spinner v-if="!isFetchedUserProfile" name="dots"></ion-spinner>
				<div
					v-if="userProfile && userProfileDetails && isFetchedUserProfile"
					class="flex justify-center items-center absolute -top-[25px] left-0 right-0">
					<UserAvatar
						:container-class="'w-[70px] h-[70px]'"
						:image-class="'w-[70px] h-[70px]'"
						:src="
							userProfile.profile_image_url
								? userProfile.profile_image_url
								: require('@/statics/img/no_profile_pic.jpg')
						"
						alt="User icon"
						:showStatus="false" />
				</div>

				<div class="flex flex-col items-center justify-center mb-6">
					<span class="font-sourcesanspro-semibold text-m2">{{ userProfile?.full_name }}</span>
					<span class="font-sourcesanspro-semibold text-sm2 text-white/60">{{ userProfile?.email }}</span>
				</div>

				<div class="flex w-full justify-evenly font-sourcesanspro-semibold text-sm2">
					<div v-if="userProfile?.gender" class="flex flex-col items-center">
						<span class="text-white/60 uppercase">{{ t('profile.gender') }}</span>
						<span class="text-white">{{ userProfile?.gender }}</span>
					</div>

					<div v-if="userProfile?.date_of_birth" class="flex flex-col items-center">
						<span class="text-white/60 uppercase">{{ t('profile.date_of_birth') }}</span>
						<span class="text-white">
							{{ userProfile?.date_of_birth }}
							({{
								DateTime.now()
									.diff(DateTime.fromFormat(userProfile?.date_of_birth, 'dd-MM-yyyy'))
									.as('years')
									.toFixed(0)
							}}
							{{ t('profile.years') }})
						</span>
					</div>
					<div v-if="userProfile?.city" class="flex flex-col items-center">
						<span class="text-white/60 uppercase">{{ t('profile.city') }}</span>
						<span class="text-white">{{ userProfile?.city }}</span>
					</div>
				</div>
			</div>

			<div class="flex mb-2 w-full items-center justify-end">
				<ion-button class="w-full hover:bg-white hover:rounded" router-link="/profile/edit">
					<ion-icon class="text-anykrowd-dark" :icon="pencil"></ion-icon>
					{{ t('profile.edit_profile') }}
				</ion-button>
			</div>

			<div class="flex mb-2">
				<AccordionListTwo>
					<AccordionListItemTwo
						v-for="item in [
							{
								id: 1,
								class: 'rounded-t-lg',
								title: t('profile.interests'),
								icon: require('@/statics/svg/profile/price-tags.svg'),
								show: userProfile && userProfile.tags && userProfile.tags.length > 0,
							},
							{
								id: 2,
								class: 'rounded-b-lg',
								title: t('profile.notifications'),
								icon: require('@/statics/svg/profile/notifications.svg'),
								show: true,
							},
						]"
						:key="item.id"
						:id="item.id"
						:css-class="item.class"
						:title="item.title"
						:icon="item.icon"
						:show="item.show">
						<div v-if="item.id === 1">
							<TagCollections
								v-if="userProfile && userProfile.tags"
								:accordion-class="'ion-no-padding'"
								:accordion-content-class="'ion-padding'"
								:userTags="userTags" />
						</div>
						<div v-if="item.id === 2">
							<NotificationsSettings></NotificationsSettings>
						</div>
					</AccordionListItemTwo>
				</AccordionListTwo>
			</div>

			<div>
				<h1 class="font-sourcesanspro-semibold text-l2">{{ t('profile.legal_consents') }}</h1>
				<div class="flex mb-8 font-sourcesanspro-regular text-sm2 underline">
					<div class="mr-4">
						<a
							v-if="isSuccessTenantConfig"
							@click="setModal(true, 'tos')"
							target="_blank"
							class="text-white underline cursor-pointer">
							{{ $t('profile.terms') }}
						</a>
					</div>
					<a
						v-if="isSuccessTenantConfig"
						@click="setModal(true, 'pp')"
						target="_blank"
						class="text-white underline cursor-pointer">
						{{ $t('profile.privacy_policy') }}
					</a>
				</div>
				<div class="flex flex-col mb-2">
					<ion-button
						v-if="showInstallAppButton()"
						class="w-full h-[60px] mb-2"
						expand="full"
						size="large"
						shape="round"
						@click="openLink()">
						<div class="flex items-center justify-center">
							<span class="text-m2">{{ t('profile.install') }}</span>
						</div>
					</ion-button>

					<ion-button
						class="w-full h-[60px] mb-2"
						expand="full"
						size="large"
						shape="round"
						fill="outline"
						router-link="/profile/password-reset">
						<div class="flex items-center justify-center text-white">
							<span class="text-m2">{{ t('profile.change_password') }}</span>
						</div>
					</ion-button>

					<ion-button
						class="w-full h-[60px]"
						expand="full"
						size="large"
						shape="round"
						fill="outline"
						@click="logoutUser(t('general.please_wait'))">
						<div class="flex items-center justify-center text-white">
							<span class="text-m2">{{ t('general.logout') }}</span>
						</div>
					</ion-button>
				</div>
				<div class="flex justify-center mb-2">
					<ion-button
						class="ion-no-margin font-sourcesanspro-semibold text-base text-white"
						fill="clear"
						@click="deleteAccount">
						{{ t('profile.delete_account') }}
					</ion-button>
				</div>

				<div class="flex justify-center mb-8">
					<ion-button @click="incrementDebugClicks" fill="clear" class="cursor-default">
						<p class="text-center text-sm text-white">Version {{ version }}</p>
						<p class="text-center text-sm text-white" v-if="debugEnabled()">
							&nbsp; - {{ buildTimestamp }} - Debug Mode Enabled
						</p>
					</ion-button>
				</div>
			</div>
		</div>
	</ion-content>
</template>

<style lang="scss" scoped></style>
