<script setup lang="ts">
import { RouterLink } from 'vue-router';
import { IonContent, IonFooter, IonIcon, IonSpinner } from '@ionic/vue';
import { DateTime } from 'luxon';
import { useI18n } from 'vue-i18n';

import { ShopCategory } from '@/shared/interfaces/shopCategory';
import NavigationBar from '@/shared/components/ui/NavigationBar.vue';
import useShop from '@/modules/shop/composables/useShop';
import { useAssetsStore } from '@/stores/shared/assetsStore';

// Init
const { t } = useI18n();
const assetStore = useAssetsStore();

const { getCategories } = useShop();
const now = DateTime.now();
const currDate = now.toFormat('EEEE, LLLL dd');
const { shopCategories, isLoadingShopCategories } = getCategories();
</script>

<template>
	<ion-content>
		<div class="flex flex-col items-center px-[34px] pt-14">
			<div class="flex justify-between w-full mb-8">
				<div class="flex items-start">
					<router-link to="/shop/history">
						<ion-icon
							:icon="require('@/statics/svg/shop/history-recent.svg')"
							class="w-[28px] h-[28px]"></ion-icon>
					</router-link>
				</div>
				<div>
					<img :src="assetStore.logoUrl" class="max-w-[70%]" />
				</div>
				<div class="w-[28px]"></div>
			</div>

			<div class="flex flex-col items-center justify-center mb-6">
				<h2 class="font-sourcesanspro-semibold text-l2">{{ t('shop.pages.title.heading') }}</h2>
			</div>

			<div v-if="isLoadingShopCategories" class="flex items-center justify-center">
				<ion-spinner name="dots"></ion-spinner>
			</div>
			<div class="grid grid-cols-2 gap-2">
				<template v-if="shopCategories">
					<router-link to="/shop/category" class="flex justify-center">
						<div
							class="flex flex-col items-center justify-center w-[155px] h-[155px] rounded-lg backdrop-blur-3xl bg-white/10">
							<img :src="require('@/statics/svg/shop/all.svg')" class="w-[56px] h-[56px]" />
							<span class="font-sourcesanspro-semibold text-m2">{{ t('shop.pages.title.all') }}</span>
						</div>
					</router-link>
					<router-link
						v-for="category in shopCategories as ShopCategory[]"
						:key="category.id"
						:to="`/shop/category/${category.id}`"
						class="flex justify-center">
						<div
							class="flex flex-col items-center justify-center w-[155px] h-[155px] rounded-lg backdrop-blur-3xl bg-white/10">
							<img :src="require('@/statics/svg/shop/merchant.svg')" class="w-[56px] h-[56px]" />
							<span class="font-sourcesanspro-semibold text-m2 capitalize">{{
								category.name.toLowerCase()
							}}</span>
						</div>
					</router-link>
					<router-link to="/shop/history" class="flex justify-center">
						<div
							class="flex flex-col items-center justify-center w-[155px] h-[155px] rounded-lg backdrop-blur-3xl bg-white/10">
							<img :src="require('@/statics/svg/shop/bought-history.svg')" class="w-[56px] h-[56px]" />
							<span class="font-sourcesanspro-semibold text-m2">{{
								t('shop.pages.title.bought_history')
							}}</span>
						</div>
					</router-link>
				</template>
			</div>
		</div>
	</ion-content>

	<ion-footer>
		<NavigationBar item-active="shop" />
	</ion-footer>
</template>

<style lang="scss" scoped></style>
