const firebaseConfig = {
	apiKey: 'AIzaSyCMqzQl_LXWyZprTr-ijq5qVGBksCcMW1s',
	authDomain: 'fbase-anykrowd2.firebaseapp.com',
	projectId: 'fbase-anykrowd2',
	storageBucket: 'fbase-anykrowd2.appspot.com',
	messagingSenderId: '973504283832',
	appId: '1:973504283832:web:ae0002b931e7e40585604d',
	measurementId: 'G-VWFBCXTDYS',
};

export default firebaseConfig;
