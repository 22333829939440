<script setup lang="ts">
import { PropType } from 'vue';

import type { RelatedContent } from '@/shared/interfaces/relatedContent';

// Props
const props = defineProps({
	contents: {
		type: Array as PropType<RelatedContent[]>,
		default: function () {
			return [];
		},
		required: true,
	},
	subtitle: { type: String },
	title: { type: String, required: true },
});
</script>

<template>
	<div class="flex justify-left px-[34px] w-full text-l2">{{ props.title }}</div>
	<div class="flex justify-between overflow-scroll invisible-scroller">
		<div
			:class="idx === 0 ? 'min-w-[150px] ml-[34px] mr-4' : 'min-w-[150px] mr-4'"
			v-for="(content, idx) in contents"
			:key="idx">
			<router-link :to="content.link" class="flex flex-col items-center">
				<img :src="content.src" class="h-[150px] object-cover rounded-xl" />
				<div class="mt-2 text-center">
					<span class="text-m2">{{ content.title }}</span>
				</div>
				<div class="mt-2">
					<span :v-if="content.subtitle" class="text-base2">{{ content.subtitle }}</span>
				</div>
			</router-link>
		</div>
	</div>
</template>

<style scoped></style>
