<script setup lang="ts">
import router from '@/router';
import { IonButton, IonContent, IonIcon } from '@ionic/vue';
import { arrowForwardOutline } from 'ionicons/icons';

const goTo = async (link: string) => {
	await router.push({ path: link, query: { callbackPage: 'dashboard' } });
};
</script>

<template>
	<ion-content>
		<div class="flex flex-col h-full justify-between">
			<div></div>

			<div class="relative flex results flex-col items-center justify-center h-[316px]">
				<div class="absolute -top-14 flex flex-col items-center mb-4 px-[34px]">
					<div class="mb-2">
						<span class="font-sourcesanspro-semibold text-lg3">Successful Activated!</span>
					</div>
					<div class="mb-2">
						<span class="font-sourcesanspro-semibold text-base text-[#7E738C]"
							>You have successfully activated voucher</span
						>
					</div>
				</div>

				<div>
					<img :src="require('@/statics/svg/vouchers/success.svg')" class="w-[180px] h-[180px]" />
				</div>
			</div>

			<div class="flex flex-col w-full px-[34px] align-center mb-8">
				<ion-button class="w-full" expand="full" size="large" shape="round" @click="goTo('/vouchers')">
					<div class="flex w-full items-center justify-between">
						Back to My Voucher
						<ion-icon :icon="arrowForwardOutline" class="text-dark-blue mr-2"></ion-icon>
					</div>
				</ion-button>
			</div>
		</div>
	</ion-content>
</template>

<style lang="scss" scoped>
.results {
	background: url('@/statics/svg/background-success.svg') no-repeat center center / cover;
	background-position: top;
}
</style>
