<script setup lang="ts">
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { IonContent, IonButton, IonIcon, IonSpinner } from '@ionic/vue';
import { chevronBackOutline } from 'ionicons/icons';

import FavoriteButton from '@/shared/components/FavoriteButton.vue';
import FullScreenImage from '@/shared/components/ui/FullScreenImage.vue';
import TopBar from '@/shared/components/TopBar.vue';
import useFavorite from '@/shared/composables/useFavorite';
import useTimeTables from '@/modules/timetables/composables/useTimeTables';
import useUserProfile from '@/modules/profile/composables/useUserProfile';
import useTenant from '@/shared/composables/useTenant';

// Init
const route = useRoute();
const { getTimeTable } = useTimeTables();
const { getUserProfile } = useUserProfile();
const { userProfile } = getUserProfile();
const timeTableId = +route.query.timeTableId!;
const trackId = +route.query.trackId!;
const activityId = +route.params.id!;
const { isFetchedTimeTable, timeTable } = getTimeTable(timeTableId, userProfile.value?.id);
const { toggleFavorite } = useFavorite();
const { getTenantConfigAndStyles } = useTenant();
const { showLoggedData } = getTenantConfigAndStyles();

const activity = timeTable.value?.tracks
	.find((track) => track.id === trackId)
	?.activities.find((activity) => activity.id === activityId);

// Image handling
const fullScreenImage = ref('');
const openInFullScreen = ref(false);
const viewFullScreen = (image: string) => {
	fullScreenImage.value = image;
	openInFullScreen.value = true;
};

const onCloseImage = () => {
	openInFullScreen.value = false;
};
</script>

<template>
	<ion-content>
		<FullScreenImage v-if="openInFullScreen" :fullScreenImage="fullScreenImage" @onCloseImage="onCloseImage" />
		<TopBar class="px-[34px]">
			<template #left>
				<div class="flex items-center justify-start cursor-pointer">
					<router-link :to="`/timetables/detail/${timeTableId}`" class="flex items-center justify-center">
						<ion-icon :icon="chevronBackOutline" class="text-white"></ion-icon>
					</router-link>
				</div>
			</template>
			<template #center>
				<div class="flex justify-center w-full font-sourcesanspro-semibold text-l2">{{ activity?.name }}</div>
			</template>
			<template #right>
				<div v-if="showLoggedData()">
					<FavoriteButton
						v-if="activity"
						@on-favorite-clicked="toggleFavorite"
						:id="activity.pivot_id"
						:favorite="activity.isFavorite"
						:type="'slot'"
						:class="'w-[20px] h-[20px] ion-no-margin'"></FavoriteButton>
				</div>
			</template>
		</TopBar>
		<div class="flex items-center justify-center" v-if="!isFetchedTimeTable">
			<ion-spinner name="dots"></ion-spinner>
		</div>

		<div v-if="timeTable && isFetchedTimeTable && activity" class="px-[34px]">
			<div class="flex overflow-scroll w-[100%] h-[182px] mb-4 invisible-scroller">
				<div class="flex items-center rounded-xl overflow-hidden">
					<img
						:src="activity.imageUrl ? activity.imageUrl : require('@/statics/img/event_bg.png')"
						@click="
							viewFullScreen(
								activity.imageUrl ? activity.imageUrl : require('@/statics/img/event_bg.png'),
							)
						"
						class="object-fit w-[100%]" />
				</div>
			</div>

			<div class="p-2">
				<div class="flex font-sourcesanspro-semibold text-l2">
					{{ activity.description_short || activity.name }}
				</div>
				<div
					v-dompurify-html="activity.description"
					class="font-sourcesanspro-regular text-base2 font-color-text-50"></div>
			</div>

			<div class="h-[100px] mb-4">&nbsp;</div>
		</div>
	</ion-content>
</template>

<style scoped></style>
