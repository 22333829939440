<script setup lang="ts">
import { Capacitor } from '@capacitor/core';
import { isPlatform, loadingController } from '@ionic/vue';
import { logoGoogle, logoApple, logoFacebook } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import { SocialProvider } from '@/shared/interfaces/user';
import CustomButton from '@/shared/components/CustomButton.vue';
import useAuth from '@/modules/auth/composables/useAuth';
import useTenant from '@/shared/composables/useTenant';

// Init
const { t } = useI18n();
const { getTenantConfigAndStyles } = useTenant();
const { tenantConfig, tenantStyles, anySocialLoginEnabled } = getTenantConfigAndStyles();
const emit = defineEmits(['onLoginSuccess', 'onOAuthError']);

// oAuthLogin
const { oAuthLogin } = useAuth();
const login = async (provider: SocialProvider) => {
	if (tenantStyles.value) {
		const loading = await loadingController.create({
			message: t('general.please_wait'),
		});
		await loading.present();
		try {
			const user = await oAuthLogin(provider, tenantStyles.value);
			if (user.value.token) {
				emit('onLoginSuccess');
			}
			await loading.dismiss();
		} catch (e) {
			await loading.dismiss();
		}
	}
};
</script>

<template>
	<div v-if="tenantConfig && tenantStyles">
		<div
			v-if="tenantConfig!.enable_apple_login && Capacitor.isNativePlatform() && isPlatform('ios')"
			class="flex justify-center mb-4">
			<CustomButton @on-click="login('apple')" text="auth.continueApple" :iconLeft="logoApple" />
		</div>
		<div v-if="tenantConfig!.enable_facebook_login" class="flex justify-center mb-4">
			<CustomButton @on-click="login('facebook')" text="auth.continueFacebook" :iconLeft="logoFacebook" />
		</div>
		<div v-if="tenantConfig!.enable_google_login" class="flex justify-center mb-4">
			<CustomButton @on-click="login('google')" text="auth.continueGoogle" :iconLeft="logoGoogle" />
		</div>
	</div>
</template>

<style scoped></style>
