<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import router from '@/router';
import { useRoute } from 'vue-router';
import {
	IonButton,
	IonContent,
	IonIcon,
	IonLabel,
	IonSegment,
	IonSegmentButton,
	IonSelect,
	IonSelectOption,
	IonSpinner,
} from '@ionic/vue';
import { TopUp } from '@/enums/values.enum';
import { arrowForwardOutline, chevronBackOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import { Wallet } from '@/shared/interfaces/balance';
import { TransactionType, TransactionSubtype } from '@/enums/wallet.enum';
import { useSharedStore } from '@/stores/shared/sharedStore';
import { useTransactionStore } from '@/stores/transaction/transactionStore';
import { WalletPackage } from '@/shared/interfaces/wallet';
import hexRgb from 'hex-rgb';
import ItemWithContentStandalone from '@/shared/components/ui/ItemWithContentStandalone.vue';
import rgbHex from 'rgb-hex';
import TextInput from '@/shared/components/sign-up-forms/TextInput.vue';
import TopBar from '@/shared/components/TopBar.vue';
import type { Transaction } from '@/shared/interfaces/transaction';
import useRfidGuest from '@/modules/rfid/composables/useRfidGuest';
import useTenant from '@/shared/composables/useTenant';
import useTopUp from '@/modules/top-up/composables/useTopUp';
import useUserProfile from '@/modules/profile/composables/useUserProfile';
import useUtils from '@/shared/composables/useUtils';
import useWallet from '@/modules/wallet/composables/useWallet';
import useCurrencies from '@/shared/composables/useCurrencies';

// Init
const { t } = useI18n();
const route = useRoute();
const encryptedRfidTag = route.query.id?.toString();
const walletId = route.params.wallet_id?.toString();
const backToDashboard = route.query.backToDashboard?.toString();
const transactionStore = useTransactionStore();
const { setItemsAndTotal } = transactionStore;
const { getPackages, getMinimumTopUpAmount, getPreviousTopUpAmount } = useWallet();
const { minimumTopUp, isLoading } = getMinimumTopUpAmount();
const { previousTopUp } = getPreviousTopUpAmount(walletId, encryptedRfidTag);
const { refetchPackagesData, walletPackages } = getPackages();
refetchPackagesData();
const { getTenantConfigAndStyles } = useTenant();
const { tenantConfig } = getTenantConfigAndStyles();
const { calculateShade, convertToCurrency, replaceTopUpGeneralMessageVariables } = useUtils();
const primary_color = window.primary_color;
const primaryRgb = hexRgb(window.primary_color, { format: 'array' });
const primaryShade = '#' + rgbHex(calculateShade(primaryRgb.toString(), 0.2));
const selectedWalletPackage = ref();
const currentWallet = ref<Wallet>();
const { getWalletBalance } = useRfidGuest();
const { refetchWalletBalanceData, walletBalanceData } = getWalletBalance(encryptedRfidTag);
const { fiatCurrency } = useCurrencies();

const {
	availablePaymentCountries,
	checkIsLatestAmountGreaterThanMinimumAmount,
	enableTopUpSubmitButton,
	formValue,
	minimumAmountError,
	onAmountChange,
	paymentMethodCountry,
	paymentMethodCountryObject,
	selectTopUp,
	startTopUp,
	switchPayconiqMode,
	topUpType,
	defaultPaymentMethodCountry,
	countrySelectionValidated,
	getApplicablePaymentCountry,
} = useTopUp(tenantConfig, minimumTopUp, currentWallet);
const { getUserProfile } = useUserProfile();
const { userProfile } = getUserProfile();
const sharedStore = useSharedStore();
const { getWallet } = useWallet();

onMounted(async () => {
	// Reset state
	sharedStore.shouldUsePayconiq = true;

	if (tenantConfig && tenantConfig.value && tenantConfig.value.wallet_enable_packages) {
		topUpType.value = TopUp.TopUpTypePackages;
	} else {
		topUpType.value = TopUp.TopUpTypeFreeAmount;
	}

	if (!encryptedRfidTag) {
		currentWallet.value = getWallet(walletId).wallet.value;
	} else if (encryptedRfidTag) {
		await refetchWalletBalanceData();
		currentWallet.value = walletBalanceData.value?.wallets.find((wallet) => wallet.id === +walletId);
	}
});

// selectPackage
const selectPackage = (walletPackage: WalletPackage) => {
	if (selectedWalletPackage.value === undefined || selectedWalletPackage.value !== walletPackage) {
		// Prepare items
		const walletItems: any = walletPackage.contents.map((item) => {
			return {
				description: `${item.currency.prefix} ${item.amount} ${item.currency.name}`,
				itemPrice: item.amount * +item.currency.exchange_rate,
				quantity: 1,
				hideQuantiy: true,
				hidePrice: true,
			};
		});

		// Calculate fee
		let totalPriceString = '';
		let totalPriceNumber = 0;

		if (walletPackage.clientx_price_incl_fee > +walletPackage.clientx_price) {
			totalPriceString = `€${walletPackage.clientx_price_incl_fee.toFixed(2)}`;
			totalPriceNumber = walletPackage.clientx_price_incl_fee;
			walletItems.push({
				description: t('transaction.administrative_fee'),
				currency: '€',
				itemPrice: walletPackage.clientx_price_incl_fee - +walletPackage.clientx_price,
				quantity: 1,
				hideQuantiy: true,
				hidePrice: false,
			});
		} else {
			totalPriceString = `€${(+walletPackage.clientx_price).toFixed(2)}`;
			totalPriceNumber = +walletPackage.clientx_price;
		}

		// Prepare transaction
		const transaction: Transaction = {
			additionalInfo: {
				isGuestTopUp: !!encryptedRfidTag,
				encryptedRfidTag,
				redirectPath: encryptedRfidTag ? `/wallet/wallet-handler/${encryptedRfidTag}` : '/dashboard',
			},
			items: [...walletItems],
			totalDescription: `${t('transaction.total')}`,
			totalPriceString: totalPriceString,
			totalPriceNumber: totalPriceNumber,
			totalPriceWithoutFee: walletPackage.clientx_price,
			type: TransactionType.TOP_UP,
			subtype: TransactionSubtype.PACKAGE,
		};

		setItemsAndTotal(transaction);
		selectedWalletPackage.value = walletPackage;
	} else {
		selectedWalletPackage.value = undefined;
	}
};

// addTopUpPackage
const addTopUpPackage = () => {
	const paymentMethodCountryToSend = getApplicablePaymentCountry();
	const isPayconiqTopUp = tenantConfig.value!.viva_wallet_payconiq_preferred_mode && sharedStore.shouldUsePayconiq;

	selectTopUp(selectedWalletPackage.value.id, paymentMethodCountryToSend, isPayconiqTopUp, encryptedRfidTag);
};

// addTopUp
const addTopUp = () => {
	// Update value if custom valuta is enabled
	const amount = Number(formValue.value);
	const paymentMethodCountryToSend = getApplicablePaymentCountry();

	// Prepare transaction
	const transaction: Transaction = {
		additionalInfo: {
			isGuestTopUp: !!encryptedRfidTag,
			encryptedRfidTag,
			redirectPath: encryptedRfidTag ? `/wallet/wallet-handler/${encryptedRfidTag}` : '/dashboard',
		},
		items: [
			{
				description: `${currentWallet.value!.currency.prefix} ${amount} ${currentWallet.value!.currency.name}`,
				itemPrice: amount * currentWallet.value!.currency.exchange_rate,

				quantity: 1,
			},
		],
		totalDescription: `1 ${t('transaction.items_total')}`,
		totalPriceString: `€ ${(amount * currentWallet.value!.currency.exchange_rate).toFixed(2)}`,
		totalPriceNumber: amount,
		totalPriceWithoutFee: amount,
		type: TransactionType.TOP_UP,
		walletId,
	};

	// isPayconiqTopUp
	const isPayconiqTopUp = tenantConfig.value!.viva_wallet_payconiq_preferred_mode && sharedStore.shouldUsePayconiq;
	setItemsAndTotal(transaction);
	startTopUp(
		paymentMethodCountryToSend,
		isPayconiqTopUp,
		encryptedRfidTag,
		walletId,
		currentWallet.value!.currency.id,
	);
};

// Go back
const goBack = () => {
	if (backToDashboard === 'true') {
		router.push('/dashboard');
	} else {
		router.back();
	}
};
</script>

<template>
	<ion-content
		:style="`background: linear-gradient(${primary_color}, ${primaryShade});`"
		:class="[
			{
				'payconiq': tenantConfig?.viva_wallet_payconiq_preferred_mode && sharedStore.shouldUsePayconiq,
			},
		]">
		<div class="flex flex-col items-center w-full max-w-none h-full">
			<TopBar class="max-w-[600px] px-[34px]">
				<template #left>
					<div class="flex items-center justify-start cursor-pointer" @click="goBack">
						<ion-icon :icon="chevronBackOutline" class="text-white"></ion-icon>
					</div>
				</template>

				<template #center>
					<div class="flex grow justify-center w-full font-sourcesanspro-semibold text-l2">
						{{ t('topup.title') }}
					</div>
				</template>

				<template #right>
					<div
						v-show="
							availablePaymentCountries.length > 1 &&
							tenantConfig &&
							tenantConfig!.payment_countries.length > 0
						">
						<div>
							<ion-select
								interface="popover"
								aria-label="select"
								v-bind:placeholder="defaultPaymentMethodCountry?.flag"
								:selected-text="paymentMethodCountryObject ? paymentMethodCountryObject.flag : ''"
								v-model="paymentMethodCountry"
								:value="paymentMethodCountry">
								<ion-select-option
									v-for="country in availablePaymentCountries"
									:value="country.value"
									:key="country.value">
									{{ country.label }}
								</ion-select-option>
							</ion-select>
						</div>
					</div>
				</template>
			</TopBar>

			<div v-if="tenantConfig" class="flex flex-col items-center justify-between w-full h-full">
				<div class="w-full max-w-[600px] px-[34px]">
					<div class="w-full mb-2">
						<ion-segment
							v-if="tenantConfig.wallet_enable_packages && walletPackages && topUpType"
							class="h-[50px] mb-2 ion-no-margin"
							:value="topUpType"
							mode="ios">
							<ion-segment-button
								v-if="tenantConfig.wallet_enable_packages"
								class="ion-no-margin"
								:value="TopUp.TopUpTypePackages"
								@click="topUpType = TopUp.TopUpTypePackages">
								<ion-label class="font-sourcesanspro-semibold text-base2"
									>{{ t('top_up.packages') }}
								</ion-label>
							</ion-segment-button>
							<ion-segment-button
								v-if="tenantConfig.wallet_enable_free_input"
								class="ion-no-margin"
								:value="TopUp.TopUpTypeFreeAmount"
								@click="topUpType = TopUp.TopUpTypeFreeAmount">
								<ion-label class="font-sourcesanspro-semibold text-base2"
									>{{ t('top_up.free_amount') }}
								</ion-label>
							</ion-segment-button>
						</ion-segment>
						<!-- TODO: this message should disappear on successful topup (or successful country selection) -->
						<div
							class="mb-6 flex flex-col items-start justify-center p-4 font-sourcesanspro-regular rounded-md bg-white/10 small font-sourcesanspro-regular text-base2 text-white"
							v-if="
								!countrySelectionValidated &&
								availablePaymentCountries.length > 1 &&
								tenantConfig &&
								tenantConfig!.payment_countries.length > 0 &&
								!(tenantConfig?.viva_wallet_payconiq_preferred_mode && sharedStore.shouldUsePayconiq)
							">
							<span class="mb-1 font-extrabold">{{ t('top_up.first_country_selection_title') }}</span>
							{{ t('top_up.first_country_selection') }}
						</div>
						<div
							v-if="userProfile && tenantConfig.top_up_general_message"
							v-dompurify-html="
								replaceTopUpGeneralMessageVariables(tenantConfig.top_up_general_message, userProfile)
							"
							class="p-4 rounded-md bg-white/5 text-white/60"></div>
					</div>

					<div
						class="flex p-4 items-center justify-center max-w-[600px] h-full"
						v-if="!tenantConfig || !minimumTopUp">
						<ion-spinner name="dots"></ion-spinner>
					</div>

					<div
						v-if="topUpType === TopUp.TopUpTypeFreeAmount"
						class="flex flex-col items-center justify-start max-w-[600px]">
						<div v-if="tenantConfig.wallet_enable_free_input" class="flex items-center justify-center">
							<span class="font-sourcesanspro-regular text-[40px] mr-2">
								{{ currentWallet?.currency.prefix }}
							</span>

							<div class="mx-1">
								<TextInput
									wrapper-class="flex flex-wrap justify-left item-center border border-solid focus-within:background-white-15"
									input-class="text-lg"
									input-mode="decimal"
									name="amount"
									placeholder="0"
									type="number"
									step="any"
									@input-changed="onAmountChange"
									:should-validate="false" />
							</div>
						</div>

						<div class="flex flex-col items-center justify-start font-color-text-50">
							<div class="mb-2" v-if="formValue > 0">
								<span v-if="currentWallet" class="font-sourcesanspro-regular text-l2">
									{{ currentWallet?.currency.prefix }} {{ formValue }}
									{{ tenantConfig.custom_valuta.suffix }} = {{ fiatCurrency.prefix }}
									{{
										formValue * +currentWallet?.currency.exchange_rate! + +tenantConfig.top_up_cost
									}}
								</span>
							</div>
							<p
								v-if="minimumAmountError"
								class="w-[249px] px-1 text-center font-sourcesanspro-regular text-base2 leading-[26px]">
								{{ t('balance.errorMinimumAmount') }}
								<span>{{ fiatCurrency.prefix }} {{ minimumTopUp?.amount }}</span>
							</p>
						</div>
					</div>
					<div
						v-if="minimumTopUp && topUpType === TopUp.TopUpTypePackages"
						class="flex flex-col w-full max-w-[600px] align-center">
						<div
							v-if="
								previousTopUp &&
								previousTopUp.amount &&
								minimumAmountError &&
								checkIsLatestAmountGreaterThanMinimumAmount(minimumTopUp.amount, previousTopUp.amount)
							"
							class="flex flex-col items-center mb-8">
							<div class="mb-4">
								<span class="font-sourcesanspro-regular text-sm2">{{
									t('balance.minimum_amount')
								}}</span>
							</div>
							<div class="flex mb-4">
								<ion-button
									class="rounded-full bg-white/10 small font-sourcesanspro-regular text-base2 text-white">
									<div>
										{{ currentWallet?.currency.prefix }}{{ convertToCurrency(minimumTopUp.amount) }}
									</div>
								</ion-button>
							</div>
						</div>

						<div v-if="walletPackages">
							<ItemWithContentStandalone
								v-for="(walletPackage, index) in walletPackages"
								:key="index"
								:has-description="!!walletPackage.description"
								:icon="require('@/statics/svg/icons/box.svg')"
								:image="walletPackage.image ? walletPackage.image : undefined"
								:title="walletPackage.name"
								:price="fiatCurrency.prefix + walletPackage.clientx_price_incl_fee"
								:is-selected="selectedWalletPackage && selectedWalletPackage.id == walletPackage.id"
								@on-select-item="selectPackage(walletPackage)">
								{{ walletPackage.description }}
							</ItemWithContentStandalone>
						</div>
					</div>
				</div>

				<div class="flex flex-col w-full p-4 items-center justify-center w-full backdrop-blur bg-white/20">
					<div class="w-full max-w-[600px]">
						<div v-if="!encryptedRfidTag" class="flex flex-col items-center mb-4">
							<div class="font-sourcesanspro-regular text-base2">
								<router-link to="/top-up/redeem">
									<span class="font-sourcesanspro-semibold text-base text-white/50 underline">{{
										t('top_up.redeem_voucher.redeem')
									}}</span>
								</router-link>
							</div>
						</div>

						<ion-button
							v-if="tenantConfig.viva_wallet_payconiq_preferred_mode && sharedStore.shouldUsePayconiq"
							class="flex items-center justify-center mb-2 w-full font-sourcesanspro-regular text-white text-sm2 cursor-pointer"
							shape="round"
							fill="outline"
							@click="switchPayconiqMode()">
							{{ t('balance.different_payment_options') }}
						</ion-button>

						<ion-button
							class="w-full max-w-none hover:bg-white hover:rounded-full"
							expand="full"
							size="large"
							shape="round"
							:disabled="
								(topUpType === TopUp.TopUpTypeFreeAmount && !enableTopUpSubmitButton) ||
								(topUpType === TopUp.TopUpTypePackages && !selectedWalletPackage)
							"
							@click="topUpType === TopUp.TopUpTypeFreeAmount ? addTopUp() : addTopUpPackage()">
							<div
								v-if="
									!tenantConfig.viva_wallet_payconiq_preferred_mode || !sharedStore.shouldUsePayconiq
								"
								class="flex w-full items-center justify-between">
								<span>{{ t('general.continue') }}</span>
								<ion-icon :icon="arrowForwardOutline" class="text-dark-blue mr-2"></ion-icon>
							</div>
							<div
								v-if="tenantConfig.viva_wallet_payconiq_preferred_mode && sharedStore.shouldUsePayconiq"
								class="flex w-full items-center justify-between">
								<span>{{ t('balance.pay_with') }}</span>
								<img
									class="w-[94px] h-[31px]"
									:src="require('@/statics/png/payconiq/payconiq-logo-dark.png')" />
							</div>
						</ion-button>
					</div>
				</div>
			</div>
		</div>
	</ion-content>
</template>

<style lang="scss" scoped>
.custom-disabled {
	--background: #717171 !important;
	opacity: 1;
}

.payconiq {
	background: #ff4785 !important;
	background-color: #ff4785 !important;
}

div {
	ion-select {
		--placeholder-opacity: 1;
		height: 52px;
		width: 100%;
		justify-content: center;
	}
}

ion-segment-button {
	--indicator-color: white;

	&.segment-button-checked {
		color: black !important;
	}
}
</style>
