import { Preferences, KeysResult } from '@capacitor/preferences';
import { CachedItem } from '@/shared/interfaces/api';
import { DateTime } from 'luxon';

import { ValuesEnum } from '@/enums/values.enum';

const get = async <T = any>(key: string): Promise<T> => {
	try {
		const item = await getCachedItem(key);

		return item?.value;
	} catch (err: any) {
		return err;
	}
};

const getCachedItem = async <T = any>(key: string): Promise<CachedItem<T> | null> => {
	try {
		const data = await Preferences.get({ key });

		if (!data) {
			return null;
		}

		const item = parse(data.value);

		if (!item) {
			return null;
		}

		if (isExpired(item) || (await isUnauthenticatedData(item))) {
			await remove(key);

			return null;
		}

		return item;
	} catch (err: any) {
		return err;
	}
};

const getCurrentUserId = async (): Promise<number | undefined> => {
	const data = await Preferences.get({ key: ValuesEnum.StoreProfile });

	if (!data) {
		return undefined;
	}

	const item = parse(data.value);

	if (!item) {
		return undefined;
	}

	return item?.value?.id;
};

/**
 * Helper function that will check if the current user can read the cached entry or not
 *
 * @param item cached entry
 * @returns boolean that indicates if user has the rights to read cache entry
 */
const isUnauthenticatedData = async (item: CachedItem<any>): Promise<boolean | number | undefined> => {
	if (!item.userID) {
		return false;
	}

	const currentUserID = await getCurrentUserId();

	return currentUserID && item.userID !== currentUserID;
};

/**
 * Helper function that will check if the cached entry is expired or not
 *
 * @param item cached entry
 * @returns boolean that indicates if cachd entry has expired or not
 */
const isExpired = (item: CachedItem<any>): boolean => {
	if (!item?.expiryDate) {
		return false;
	}

	const now = DateTime.now();
	const storedTime = DateTime.fromMillis(item.expiryDate);

	return now >= storedTime;
};

const remove = async (key: string): Promise<void> => {
	try {
		const data = await Preferences.remove({
			key,
		});
		return data;
	} catch (err: any) {
		console.error('REMOVE', err);
		return err;
	}
};

const parse = (value: any) => {
	try {
		return JSON.parse(value);
	} catch (e) {
		return value;
	}
};

/**
 * Adds/sets entry in cache
 *
 * @param key Unique identifier of cached entry
 * @param value Data that has to be cached
 * @param isUserRelatedData Id that indicates that this kind of data is related to a specific user
 * @param expiryInSeconds Time to live
 *
 */
export const set = async <T = any>(
	key: string,
	value: T,
	isUserRelatedData = false,
	expiryInSeconds?: number,
): Promise<void> => {
	try {
		let item: CachedItem<T>;
		let userID: number | undefined;

		item = {
			value,
		};

		if (expiryInSeconds) {
			item = {
				...item,
				updatedAt: DateTime.now().valueOf(),
				expiryDate: DateTime.now().plus({ seconds: expiryInSeconds }).valueOf(),
			};
		}

		if (isUserRelatedData) {
			userID = await getCurrentUserId();
			item = {
				...item,
				userID,
			};
		}

		const confirmation = await Preferences.set({
			key,
			value: JSON.stringify(item),
		});

		return confirmation;
	} catch (err: any) {
		console.error('SET', err);
		return err;
	}
};

const useStorageService = () => {
	return {
		get,
		remove,
		set,
	};
};

export default useStorageService;
