<script setup lang="ts">
import { toRefs } from 'vue';
import { useField } from 'vee-validate';
import type { Option } from '@/shared/interfaces/registrationForm';

const props = defineProps({
	modelValue: { type: null },
	name: String,
	values: { type: Array<Option> },
});

// we are using toRefs to create reactive references to `name` and `value` props
// this is important because vee-validte needs to know if any of these props change
// https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
const { name } = toRefs(props);
const { value, handleChange } = useField(name);

const selectItem = (event: MouseEvent, radio: Option) => {
	handleChange(radio.value);
	event.preventDefault();
};
</script>

<template>
	<button
		v-for="radio in props.values"
		:key="radio.value"
		class="flex justify-center items-center w-full border border-solid border-white-15 rounded-full mb-4 px-5 normal-case text-base"
		:class="[
			{
				'background-white-15': radio.value === value,
			},
		]"
		@click="($event) => selectItem($event, radio)">
		<span class="font-sourcesanspro-semibold">{{ radio.label }}</span>
	</button>
</template>

<style lang="scss" scoped>
button {
	height: 52px;
}
</style>
