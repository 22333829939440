import { ref } from 'vue';

const useCurrencies = () => {
	const fiatCurrency = ref({
		name: 'EURO',
		prefix: '€',
		suffix: 'EUR',
	});

	return {
		fiatCurrency,
	};
};

export default useCurrencies;
