<script setup lang="ts">
import { ref, toRef, PropType, watch } from 'vue';
import router from '@/router';
import { IonProgressBar } from '@ionic/vue';

import { ActivityDuration } from '@/shared/interfaces/activityDuration';
import { Track, TrackActivity } from '@/shared/interfaces/timeTables';
import FavoriteButton from '@/shared/components/FavoriteButton.vue';

const props = defineProps({
	activity: { type: TrackActivity, required: true },
	backgroundColor: { type: String },
	backgroundColorOpacity: { type: String, required: true },
	barPercent: { type: Number, required: true },
	durationData: { type: Object as PropType<ActivityDuration>, required: true },
	time: { type: String, required: true },
	timeTableId: { type: Number, required: true },
	title: { type: String, required: true },
	track: { type: Track, required: true },
	showLoggedData: { type: Boolean, required: true, default: true },
});

// Init
const favorite = ref(props.activity.isFavorite);
const isFavorite = toRef(props.activity, 'isFavorite');

// goToDetails
const goToDetails = async () => {
	await router.push({
		path: `/timetables/activity-detail/${props.activity.id}`,
		query: {
			timeTableId: props.timeTableId,
			trackId: props.track.id,
		},
	});
};

const onFavoriteChanged = (isFavorite: boolean) => {
	favorite.value = isFavorite;
};
</script>

<template>
	<div class="relative pb-4 h-[85px] cursor-pointer" :style="props.durationData.duration" @click="goToDetails()">
		<div
			class="absolute flex flex-col w-full h-full justify-between p-3 rounded-xl bg-center bg-no-repeat bg-cover"
			:style="[props.durationData.startFineOffset, `background-image: url(${props.activity.imageUrl})`]"></div>
		<div
			class="absolute flex flex-col w-full h-full justify-between p-3 rounded-xl bg-center bg-no-repeat bg-cover"
			:style="[
				props.durationData.startFineOffset,
				`background-color: ${props.activity.backgroundColor}`,
				`opacity: ${+backgroundColorOpacity / 100}`,
			]"></div>
		<div
			class="absolute flex flex-col w-full h-full items-start justify-between p-3 overflow-hidden"
			:class="[{ 'border-solid border-2 border-orange-300 rounded-xl': favorite }]"
			:style="[props.durationData.startFineOffset]">
			<div class="flex justify-between w-full">
				<div class="flex flex-col font-sourcesanspro-semibold">
					<span class="text-sm whitespace-nowrap">{{ props.time }}</span>
					<span class="text-base2 whitespace-nowrap">{{ props.title }}</span>
				</div>

				<div v-if="props.showLoggedData" class="flex">
					<FavoriteButton
						@on-favorite-changed="onFavoriteChanged"
						:id="props.activity.pivot_id"
						:favorite="props.activity.isFavorite"
						:type="'slot'"
						:class="'absolute top-2 right-2 w-[20px] h-[20px] ion-no-margin z-30'"></FavoriteButton>
				</div>
			</div>
			<div class="w-full">
				<ion-progress-bar :value="props.barPercent"></ion-progress-bar>
			</div>
		</div>
	</div>
</template>

<style scoped></style>
