<script setup lang="ts">
import { ref } from 'vue';
import { RouterLink } from 'vue-router';
import { IonContent, IonFooter, IonIcon } from '@ionic/vue';
import NavigationBar from '@/shared/components/ui/NavigationBar.vue';

const showSearch = ref(false);
</script>

<template>
	<ion-content>
		<div class="flex flex-col items-center px-[34px] pt-16">
			<div class="flex justify-between w-full">
				<div class="w-[20px]"></div>
				<div>
					<ion-icon
						:icon="require('@/statics/svg/dashboard/anykrowd-logo.svg')"
						class="w-[100px] h-[100px]"></ion-icon>
				</div>
				<div class="w-[20px]"></div>
			</div>

			<div class="flex flex-col items-center justify-center mb-6">
				<span class="font-sourcesanspro-regular text-base text-white/50">Saturday, December 22th</span>
				<h2 class="font-sourcesanspro-semibold text-l2">My quizzes</h2>
			</div>

			<div class="grid grid-cols-2 gap-4 w-full">
				<router-link to="/quiz/questions">
					<div
						class="flex flex-col items-center justify-center w-[155px] h-[155px] rounded-lg backdrop-blur-3xl bg-white/10">
						<img :src="require('@/statics/svg/quiz/quiz.svg')" class="w-[56px] h-[56px]" />
						<span class="font-sourcesanspro-semibold text-m2">Quiz Name 1</span>
					</div>
				</router-link>
				<router-link to="/quiz/questions">
					<div
						class="flex flex-col items-center justify-center w-[155px] h-[155px] rounded-lg backdrop-blur-3xl bg-white/10">
						<img :src="require('@/statics/svg/quiz/quiz.svg')" class="w-[56px] h-[56px]" />
						<span class="font-sourcesanspro-semibold text-m2">Opening Feedback</span>
					</div>
				</router-link>
				<router-link to="/quiz/questions">
					<div
						class="flex flex-col items-center justify-center w-[155px] h-[155px] rounded-lg backdrop-blur-3xl bg-white/10">
						<img :src="require('@/statics/svg/quiz/quiz.svg')" class="w-[56px] h-[56px]" />
						<span class="font-sourcesanspro-semibold text-m2">Quiz Name 2</span>
					</div>
				</router-link>
			</div>
		</div>
	</ion-content>

	<ion-footer>
		<NavigationBar item-active="quiz" />
	</ion-footer>
</template>

<style lang="scss" scoped></style>
