<script setup lang="ts">
import router from '@/router';
import { IonContent, IonFooter, IonIcon, IonSpinner } from '@ionic/vue';
import { chevronBackOutline } from 'ionicons/icons';
import { DateTime } from 'luxon';

import AccordionList from '@/shared/components/ui/accordionlist/AccordionList.vue';
import AccordionListItem from '@/shared/components/ui/accordionlist/AccordionListItem.vue';
import AccordionListItemAction from '@/shared/components/ui/accordionlist/AccordionListItemAction.vue';
import NavigationBar from '@/shared/components/ui/NavigationBar.vue';
import TopBar from '@/shared/components/TopBar.vue';
import useTenant from '@/shared/composables/useTenant';
import { useI18n } from 'vue-i18n';
import { ref, watchEffect } from 'vue';
import { WalletTransaction } from '@/shared/interfaces/wallet';
import useWallet from '@/modules/wallet/composables/useWallet';
import TransactionOverview from '@/modules/wallet/components/TransactionOverview.vue';

// Init
const { getWalletTransactions, getTransactionItemName } = useWallet();
const { isLoading, refetchGetWalletTransactions, removeGetWalletTransactionsResultsFromCache, walletTransactions } =
	getWalletTransactions();
const { t, te } = useI18n();
const mappedWalletTransactions = ref<any>([]);
const { getTenantConfigAndStyles } = useTenant();
const { tenantConfig } = getTenantConfigAndStyles();

// Refresh
const handleRefresh = async (event: any) => {
	removeGetWalletTransactionsResultsFromCache();
	mappedWalletTransactions.value = [];
	await refetchGetWalletTransactions();
	event.target.complete();
};

// Watch for walletTransactions then map the result
watchEffect(() => {
	if (walletTransactions && walletTransactions.value && walletTransactions.value.data.length) {
		mappedWalletTransactions.value = walletTransactions.value.data.map((transaction: WalletTransaction) => {
			return {
				id: transaction.id,
				title: t(getTransactionItemName(transaction)),
				subtitle: transaction.direction,
				iconClass: 'text-white',
				price: +transaction.amount,
				priceCurrency: '€',
				date: transaction.created_at,
				transactionType: transaction.direction,
			};
		});
	}
});
// Go back
const goBack = () => {
	router.back();
};
</script>

<template>
	<ion-content>
		<ion-spinner v-if="!mappedWalletTransactions" name="dots"></ion-spinner>
		<div v-else>
			<TopBar class="px-[34px]">
				<template #left>
					<div class="flex items-center justify-start cursor-pointer" @click="goBack">
						<ion-icon :icon="chevronBackOutline" class="text-white"></ion-icon>
					</div>
				</template>

				<template #center>
					<div class="flex justify-center w-full font-sourcesanspro-semibold text-l2">
						{{ t('shop.pages.title.bought_history') }}
					</div>
				</template>
			</TopBar>

			<div class="px-[34px]">
				<AccordionList title="Last transactions">
					<template #items>
						<!-- Father item -->
						<AccordionListItem
							v-for="item in mappedWalletTransactions"
							:icon="require('@/statics/svg/arrows-left-right.svg')"
							:iconClass="item.iconClass"
							:key="item.id"
							:key-value="item.id"
							:price="item.price"
							:subtitle="item.subtitle"
							:title="item.title"
							:transaction-type="item.transactionType">
							<template #actions>
								<AccordionListItemAction
									v-if="tenantConfig"
									:date="DateTime.fromISO(item.date).toFormat('dd LLLL yyyy • HH:mm')"
									:price="item.price"
									:priceCurrency="item.priceCurrency"
									:transaction-type="item.transactionType"
									:tenant-config="tenantConfig" />
							</template>
							<template v-slot:content="slotProps">
								<TransactionOverview
									v-if="tenantConfig"
									:transaction-id="item.id"
									:should-load="slotProps.shouldLoad"
									:tenant-config="tenantConfig" />
							</template>
						</AccordionListItem>
					</template>
				</AccordionList>
			</div>

			<div class="h-[100px]">&nbsp;</div>
		</div>
	</ion-content>

	<ion-footer>
		<NavigationBar item-active="shop" />
	</ion-footer>
</template>

<style lang="scss" scoped></style>
