<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
	currentStep: {
		type: Number,
		required: true,
	},
	index: {
		type: Number,
		required: true,
	},
	values: Object,
});

// If this step should be shown
const shouldShow = computed(() => {
	return props.index === props.currentStep;
});
</script>

<template>
	<div v-if="shouldShow">
		<slot />
	</div>
</template>
