<script setup lang="ts">
import { watch } from 'vue';
import router from '@/router';
import { useRoute } from 'vue-router';
import { IonButton, IonContent, IonIcon } from '@ionic/vue';
import * as Yup from 'yup';
import { chevronBackOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import { useSharedStore } from '@/stores/shared/sharedStore';
import hexRgb from 'hex-rgb';
import rgbHex from 'rgb-hex';
import TextInput from '@/shared/components/sign-up-forms/TextInput.vue';
import TopBar from '@/shared/components/TopBar.vue';
import useCustomForm from '@/shared/composables/useCustomForm';
import useUtils from '@/shared/composables/useUtils';

// Init
const { t } = useI18n();
const sharedStore = useSharedStore();
const { calculateShade } = useUtils();
const primary_color = window.primary_color;
const primaryRgb = hexRgb(window.primary_color, { format: 'array' });
const primaryShade = '#' + rgbHex(calculateShade(primaryRgb.toString(), 0.2));
const route = useRoute();
const email = route.query.email ? route.query.email.toString() : '';

// Using yup to generate a validation schema
const schema = Yup.object().shape({
	email: Yup.string().matches(sharedStore.EMAIL_REGX, t('auth.sign_in_email_page.errors.email')).required(),
});

// useCustomForm
const { isFormValid, isSuccess, onSubmit, onInputChange } = useCustomForm(
	schema,
	'/auth/forgot-password',
	'post',
	'profile.password_reset.messages.check_email',
	'profile.password_reset.errors.error',
	5000,
	{
		checkError: true,
		error: 'This password reset user token is invalid.',
		errorTranslation: 'common.email_error',
	},
);

watch(isSuccess, async () => {
	await router.push('/dashboard');
});

// Go back
const goBack = () => {
	router.back();
};
</script>

<template>
	<ion-content :style="`background: linear-gradient(${primary_color}, ${primaryShade});`">
		<div class="flex flex-col h-full justify-between">
			<div class="px-[34px] w-full">
				<TopBar>
					<template #left>
						<div class="flex items-center justify-start">
							<ion-button class="ion-no-margin ion-no-padding" fill="clear" @click="goBack()">
								<ion-icon :icon="chevronBackOutline" class="text-white"></ion-icon>
							</ion-button>
						</div>
					</template>

					<template #center>
						<div class="flex justify-center w-full font-sourcesanspro-semibold text-l2">
							{{ $t('profile.password_reset.forgot_password') }}
						</div>
					</template>
				</TopBar>

				<div class="mb-4">
					<span class="text-color-magenta text-base">
						{{ $t('profile.password_reset.forgot_password_description') }}
					</span>
				</div>

				<div class="relative mb-4">
					<form id="resetPasswordForm" :validation-schema="schema" @submit="onSubmit">
						<TextInput
							wrapper-class="flex flex-wrap justify-left item-center mb-2 border border-solid focus-within:background-white-15"
							name="email"
							type="text"
							placeholder="Email"
							:value="email"
							@inputChanged="onInputChange" />
					</form>
				</div>
			</div>

			<div class="px-[34px] mb-[34px]">
				<ion-button
					class="w-full hover:bg-white hover:rounded-full"
					:disabled="!isFormValid"
					expand="full"
					form="resetPasswordForm"
					size="large"
					shape="round"
					type="submit">
					<div class="flex w-full items-center justify-center">
						{{ $t('profile.password_reset.forgot_password_submit') }}
					</div>
				</ion-button>
			</div>
		</div>
	</ion-content>
</template>

<style lang="scss" scoped>
ion-list {
	background: transparent;
}
</style>
