<script setup lang="ts">
import router from '@/router';
import { IonButton, IonContent, IonIcon } from '@ionic/vue';

import TopBar from '@/shared/components/TopBar.vue';
import { callOutline, chevronBackOutline, locationOutline, timeOutline } from 'ionicons/icons';
import { useAssetsStore } from '@/stores/shared/assetsStore';

// Init
const assetStore = useAssetsStore();

// Go back
const goBack = () => {
	router.back();
};
</script>

<template>
	<ion-content>
		<TopBar class="px-[34px]">
			<template #left>
				<div class="flex items-center justify-start cursor-pointer" @click="goBack">
					<ion-icon :icon="chevronBackOutline" class="text-white"></ion-icon>
				</div>
			</template>

			<template #center>
				<div class="flex justify-center w-full font-sourcesanspro-semibold text-l2">Contact Info</div>
			</template>
		</TopBar>

		<div class="px-[34px]">
			<div class="flex flex-col items-center justify-center mb-14">
				<img :src="assetStore.logoUrl" class="max-w-[70%]" />
				<span class="font-sourcesanspro-semibold text-l">Club Ampere</span>
			</div>

			<div class="flex items-center justify-between mb-8" slot="header">
				<ion-icon
					class="w-[34px] h-[34px] mr-2 p-2 bg-white/20 rounded-full"
					:icon="locationOutline"></ion-icon>
				<div class="flex flex-col grow pl-2 justify-center">
					<div>
						<span class="font-sourcesanspro-regular text-sm2 font-color-text-50 leading-none">Address</span>
					</div>
					<div>
						<span class="font-sourcesanspro-semibold text-base2 leading-none"
							>Ancienne Belgieque, Brussels</span
						>
					</div>
				</div>
			</div>

			<div class="flex items-start justify-between mb-8" slot="header">
				<ion-icon class="w-[34px] h-[34px] mr-2 p-2 bg-white/20 rounded-full" :icon="timeOutline"></ion-icon>
				<div class="flex flex-col pt-[2px] grow pl-2 justify-center">
					<div>
						<span class="font-sourcesanspro-regular text-sm2 font-color-text-50 leading-none"
							>Opening hours</span
						>
					</div>
					<div class="flex">
						<div class="mr-4">
							<span class="font-sourcesanspro-semibold text-xs2">MON - FRI</span>
						</div>
						<div class="mr-4">
							<span class="font-sourcesanspro-semibold text-base2 leading-none">09:30am - 07:30pm</span>
						</div>
					</div>
					<div class="flex">
						<div class="mr-4">
							<span class="font-sourcesanspro-semibold text-xs2">SAT - SUN</span>
						</div>
						<div>
							<span class="font-sourcesanspro-semibold text-base2 leading-none">11:30am - 09:30pm</span>
						</div>
					</div>
				</div>
			</div>

			<div class="flex items-center justify-between mb-8" slot="header">
				<ion-icon
					class="w-[34px] h-[34px] mr-2 p-2 bg-white/20 rounded-full"
					:icon="locationOutline"></ion-icon>
				<div class="flex flex-col grow pl-2 justify-center">
					<div>
						<span class="font-sourcesanspro-regular text-sm2 font-color-text-50 leading-none">Email</span>
					</div>
					<div>
						<span class="font-sourcesanspro-semibold text-base2 leading-none">info@bullypoint.com</span>
					</div>
				</div>
				<ion-button class="w-[79px] h-[40px]" expand="full" size="large" shape="round" fill="outline">
					<div class="flex px-2 items-center justify-center text-sm2 text-white">Send</div>
				</ion-button>
			</div>

			<div class="flex items-center justify-between mb-8" slot="header">
				<ion-icon class="w-[34px] h-[34px] mr-2 p-2 bg-white/20 rounded-full" :icon="callOutline"></ion-icon>
				<div class="flex flex-col grow pl-2 justify-center">
					<div>
						<span class="font-sourcesanspro-regular text-sm2 font-color-text-50 leading-none">Phone</span>
					</div>
					<div>
						<span class="font-sourcesanspro-semibold text-base2 leading-none">+34 182 49 4829</span>
					</div>
				</div>
				<ion-button class="w-[79px] h-[40px]" expand="full" size="large" shape="round" fill="outline">
					<div class="flex px-2 items-center justify-center text-sm2 text-white">Call</div>
				</ion-button>
			</div>
		</div>
	</ion-content>
</template>

<style lang="scss" scoped></style>
