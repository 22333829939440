import { ref, watch } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import * as yup from 'yup';
import { useI18n } from 'vue-i18n';
import { DateTime } from 'luxon';

import { anykrowdApi } from '@/api/anykrowdApi';
import type { RegistrationForm, RegistrationFormData } from '@/shared/interfaces/registrationForm';
import useUserProfile from '@/modules/profile/composables/useUserProfile';
import { useSharedStore } from '@/stores/shared/sharedStore';
import { parse } from 'path';

const groups = [
	['email', 'password', 'password_confirmation'],
	['firstname', 'lastname', 'notification_cellphone_number', 'nickname', 'gender', 'date_of_birth', 'company'],
	['country', 'city', 'postal_code', 'address_street', 'address_number'],
	['profile_image', 'terms_and_conditions_accepted', 'privacy_policy_accepted'],
];

// Get Registration Form
const _getRegistrationForm = async (): Promise<RegistrationForm> => {
	const { data } = await anykrowdApi.get<RegistrationForm>('/auth/registration-form', false);
	return data;
};

// useRegistrationForm
const useRegistrationForm = () => {
	// Init
	const { t } = useI18n();

	// Create validation schema, multiple steps for the registration form
	const createValidationSchema = (registrationForm: RegistrationForm) => {
		// Init
		const initialValue: { [key: string]: any }[] = [];
		const sharedStore = useSharedStore();

		// Create the validation schema
		const validationSchema = registrationForm.data.reduce((acc, field) => {
			const config: { [key: string]: any } = {};

			// additional configuration for scheme
			if (field.field_name === 'email') {
				config[field.field_name] = yup
					.string()
					.matches(sharedStore.EMAIL_REGX, t('auth.sign_in_email_page.errors.email'));
			} else if (
				field.field_name === 'terms_and_conditions_accepted' ||
				field.field_name === 'privacy_policy_accepted'
			) {
				config[field.field_name] = yup.bool().oneOf([true]);
			} else if (field.field_name === 'password') {
				config['password_confirmation'] = yup
					.string()
					.min(6)
					.oneOf([yup.ref('password')], t('auth.sign_up.password_must_match'))
					.required(t('auth.sign_up.password_confirmation_required'));
				config[field.field_name] = yup.string().min(6);
			} else if (field.field_name === 'date_of_birth') {
				config[field.field_name] = yup
					.string()
					.test('test-date', t('auth.sign_in_email_page.errors.valid_date'), (date) => {
						if (date) {
							const parsedDate = DateTime.fromFormat(date, 'dd-MM-yyyy');
							if (parsedDate.isValid) {
								return true;
							} else {
								return false;
							}
						} else {
							return field.required ? false : true;
						}
					});
				if (field.required) {
					config[field.field_name].required(t('auth.sign_in_email_page.errors.valid_date'));
				}
			} else {
				config[field.field_name] = yup.string().trim();
			}

			// field.required = must be filled
			if (field.required) {
				config[field.field_name] = config[field.field_name].required();
			} else {
				config[field.field_name] = config[field.field_name].nullable();
			}

			acc.push(yup.object(config));

			return acc;
		}, initialValue);

		return validationSchema;
	};

	// Create validation schema, multiple steps for the registration form
	const createValidationSchemaGrouped = (registrationForm: RegistrationForm) => {
		// Init
		const initialValue: any = [];
		const sharedStore = useSharedStore();

		// Create the validation schema
		const validationSchemaArray = registrationForm.data
			.reduce((acc, field) => {
				const config: { [key: string]: any } = {};

				// additional configuration for scheme
				if (field.field_name === 'email') {
					config[field.field_name] = yup
						.string()
						.matches(sharedStore.EMAIL_REGX, t('auth.sign_in_email_page.errors.email'));
				} else if (
					field.field_name === 'terms_and_conditions_accepted' ||
					field.field_name === 'privacy_policy_accepted'
				) {
					config[field.field_name] = yup.bool().oneOf([true]);
				} else if (field.field_name === 'password') {
					config['password_confirmation'] = yup
						.string()
						.min(6)
						.oneOf([yup.ref('password')], t('auth.sign_up.password_must_match'))
						.required(t('auth.sign_up.password_confirmation_required'));
					config[field.field_name] = yup.string().min(6);
				} else if (field.field_name === 'date_of_birth') {
					config[field.field_name] = yup
						.string()
						.test('test-date', t('auth.sign_in_email_page.errors.valid_date'), (date) => {
							if (date) {
								const parsedDate = DateTime.fromFormat(date, 'dd-MM-yyyy');
								if (parsedDate.isValid) {
									return true;
								} else {
									return false;
								}
							} else {
								return field.required ? false : true;
							}
						});
					if (field.required) {
						config[field.field_name].required(t('auth.sign_in_email_page.errors.valid_date'));
					}
				} else {
					config[field.field_name] = yup.string().trim();
				}

				// field.required = must be filled
				if (field.required) {
					config[field.field_name] = config[field.field_name].required();
				} else {
					config[field.field_name] = config[field.field_name].nullable();
				}

				const groupIndex = getGroupIndex(field.field_name);
				if (!acc[groupIndex]) {
					acc[groupIndex] = [];
				}
				acc[groupIndex].push(config);
				return acc;
			}, initialValue)
			.filter((group: any) => !!group.length); // remove empty steps

		const validationSchema = validationSchemaArray.map((group: Array<{ [key: string]: any }>) => {
			const obj = Object.assign({}, ...group);
			return yup.object(obj);
		});

		return validationSchema;
	};

	const getGroupIndex = (fieldName: string) => {
		return groups.findIndex((item) => {
			return item.includes(fieldName);
		});
	};

	const groupRegistrationForm = (registrationForm: RegistrationFormData[]) => {
		const form = registrationForm.reduce(
			(acc, obj) => {
				const groupIndex = getGroupIndex(obj.field_name);
				if (!acc[groupIndex]) {
					acc[groupIndex] = [];
				}
				acc[groupIndex].push(obj);
				return acc;
			},
			<any>[],
		);
		// in some cases it is possible some steps of the registration form turn out empty (no fields at all).
		// we need to remove these empty steps before returning the array.

		return form.filter((f: any) => !!f.length);
	};

	// Create validation schema, one step
	const createValidationSchemaOneStep = (registrationForm: RegistrationForm) => {
		// Init
		const initialValue: { [key: string]: yup.StringSchema<string | null | undefined> } = {};

		// Create the validation schema
		const validationSchema = registrationForm.data.reduce((acc, field) => {
			const config: { [key: string]: any } = {};
			if (field.field_name === 'date_of_birth') {
				config[field.field_name] = yup
					.string()
					.test('test-date', t('auth.sign_in_email_page.errors.valid_date'), (date) => {
						if (date) {
							const parsedDate = DateTime.fromFormat(date, 'dd-MM-yyyy');
							if (parsedDate.isValid) {
								return true;
							} else {
								return false;
							}
						} else {
							return field.required ? false : true;
						}
					});
				if (field.required) {
					config[field.field_name].required(t('auth.sign_in_email_page.errors.valid_date'));
				}
			} else {
				config[field.field_name] = yup.string().trim();
			}

			return {
				...acc,
				[field.field_name]: field.required
					? config[field.field_name].required()
					: config[field.field_name].nullable(),
			};
		}, initialValue);

		return yup.object(validationSchema);
	};

	// getRegistrationForm
	const getRegistrationForm = () => {
		// Init
		const registrationForm = ref<[RegistrationFormData[]]>()!;
		const registrationFormOriginal = ref<RegistrationForm>()!;
		const validationSchema = ref();

		const { data: registrationFormData, isSuccess: isSuccessRegistrationForm } = useQuery({
			queryKey: ['registrationForm'],
			queryFn: () => _getRegistrationForm(),
			retry: 3,
		});

		// Watch userProfile and registrationFormData
		watch(
			registrationFormData,
			() => {
				if (registrationFormData.value) {
					registrationFormOriginal.value = { data: registrationFormData.value.data };

					// Filter registration form and remove fields that are already completed
					const initialValue: RegistrationFormData[] = [];
					const filteredRegistrationFormOriginal = registrationFormOriginal.value.data.reduce(
						(acc, field) => {
							// field.is_active = show / hide
							if (field.is_active) {
								acc.push(field);
							}
							return acc;
						},
						initialValue,
					);

					const filteredRegistrationFormOriginalGrouped = groupRegistrationForm(
						filteredRegistrationFormOriginal,
					);

					// Finally save the form and create the validation schema using the filtered form array
					registrationForm.value = filteredRegistrationFormOriginalGrouped;
					const validationSchemaGrouped = createValidationSchemaGrouped({
						data: filteredRegistrationFormOriginal,
					});
					validationSchema.value = validationSchemaGrouped;
				}
			},
			{ immediate: true },
		);

		// Return
		return {
			registrationForm,
			isSuccessRegistrationForm,
			validationSchema,
		};
	};

	// getRegistrationForm
	const getRegistrationFormForAdditionalData = () => {
		// Init
		const { getUserProfile } = useUserProfile();
		const { userProfile, refetchUserProfile, removeUserProfile, isFetchedUserProfile, isSuccessUserProfile } =
			getUserProfile();

		const registrationForm = ref<RegistrationForm>()!;
		const registrationFormOriginal = ref<RegistrationForm>()!;
		const validationSchema = ref();
		const validationSchemaOneStep = ref();

		const { data: registrationFormData, isSuccess: isSuccessRegistrationForm } = useQuery({
			queryKey: ['registrationForm'],
			queryFn: () => _getRegistrationForm(),
			retry: 3,
		});

		// Watch userProfile and registrationFormData
		watch(
			[userProfile, registrationFormData],
			() => {
				if (registrationFormData.value && userProfile && userProfile.value) {
					registrationFormOriginal.value = { data: registrationFormData.value.data };

					// Filter registration form and remove fields that are already completed
					const initialValue: RegistrationFormData[] = [];
					const filteredRegistrationFormOriginal = registrationFormOriginal.value.data.reduce(
						(acc, field) => {
							// field.is_active = show / hide
							if (field.is_active) {
								// Check if profile completed for this field
								const completed = !!userProfile.value?.[field.field_name];

								// If field is not completed, add it to the filtered form array
								if (!completed) {
									acc.push(field);
								}
							}
							return acc;
						},
						initialValue,
					);

					// Finally save the form and create the validation schema using the filtered form array
					registrationForm.value = { data: filteredRegistrationFormOriginal };
					validationSchema.value = createValidationSchema({
						data: filteredRegistrationFormOriginal,
					});
					validationSchemaOneStep.value = createValidationSchemaOneStep({
						data: registrationFormOriginal.value.data,
					});
				}
			},
			{ immediate: true },
		);

		// Return
		return {
			registrationForm,
			registrationFormOriginal,
			isSuccessRegistrationForm,
			validationSchema,
			validationSchemaOneStep,

			userProfile,
			refetchUserProfile,
			removeUserProfile,
			isFetchedUserProfile,
			isSuccessUserProfile,
		};
	};

	// Return
	return {
		getRegistrationForm,
		getRegistrationFormForAdditionalData,
	};
};

export default useRegistrationForm;
