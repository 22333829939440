<script setup lang="ts">
import { IonAvatar, IonButton, IonItem } from '@ionic/vue';
import { useI18n } from 'vue-i18n';

// Props
const props = defineProps({
	image: { type: String, required: true },
	location: { type: String, required: true },
	name: { type: String, required: true },
});

// Init
const { t } = useI18n();
const emit = defineEmits(['onPersonSelected']);

// selectPerson
const selectPerson = () => {
	emit('onPersonSelected');
};
</script>

<template>
	<ion-item class="flex justify-between ion-no-padding pb-2 mb-6 border-b border-b-white/5" lines="none">
		<ion-avatar slot="start">
			<img :src="props.image" />
		</ion-avatar>
		<div class="grow">
			<div class="flex flex-col">
				<span class="font-sourcesanspro-semibold text-m2">{{ props.name }}</span>
				<span class="font-sourcesanspro-regular text-sm2 text-white/50">{{ props.location }}</span>
			</div>
		</div>
		<ion-button class="h-[40px]" shape="round" fill="outline" @click="selectPerson()">
			<div class="flex items-center justify-center px-4 text-white">
				<span class="font-sourcesanspro-semibold text-sm2">{{ t('components.person_item.select') }}</span>
			</div>
		</ion-button>
	</ion-item>
</template>

<style lang="scss" scoped>
ion-item {
	--inner-padding-end: 0;
}
</style>
