<script setup lang="ts">
import router from '@/router';
import { useRoute } from 'vue-router';
import { IonContent, IonIcon, IonSpinner } from '@ionic/vue';
import { chevronBackOutline, imageOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import useShop from '@/modules/shop/composables/useShop';
import TopBar from '@/shared/components/TopBar.vue';
import ProductQuantityBottomBar from '@/shared/components/shop/ProductQuantityBottomBar.vue';
import ShoppingCartIcon from '@/shared/components/shop/ShoppingCartIcon.vue';

// Init
const { t } = useI18n();
const { params } = useRoute();
const { getProductDetails } = useShop();
const productId = Number(params.id);
const { product } = getProductDetails(productId);

const goBack = () => {
	router.back();
};
</script>

<template>
	<ion-content>
		<div class="relative flex flex-col px-[34px]">
			<TopBar>
				<template #left>
					<div class="flex items-center justify-start cursor-pointer" @click="goBack">
						<ion-icon :icon="chevronBackOutline" class="text-white"></ion-icon>
					</div>
				</template>

				<template #center>
					<div class="flex justify-center w-full font-sourcesanspro-semibold text-l2">
						{{ t('shop.product.title') }}
					</div>
				</template>

				<template #right>
					<shopping-cart-icon />
				</template>
			</TopBar>
		</div>
		<div v-if="!product" class="flex items-center justify-center">
			<ion-spinner name="dots"></ion-spinner>
		</div>
		<div v-else class="relative flex flex-col px-[34px]">
			<div class="relative flex flex-col">
				<div class="flex w-[100%] h-[182px] overflow-hidden mb-4 rounded-2xl p-0">
					<img
						v-if="product.image_url"
						:src="product.image_url"
						class="img-fluid object-fit-cover w-[100%]" />
					<div
						v-else
						class="img-fluid object-fit-cover h-[182px] w-[100%] flex justify-center text-center backdrop-blur-3xl bg-black/60">
						<ion-icon :icon="imageOutline" size="large" class="text-white my-auto"></ion-icon>
					</div>
				</div>
				<div class="mb-2">
					<div class="flex mb-4 font-sourcesanspro-semibold text-lg2">
						<span class="capitalize">{{ product.name.toLowerCase() }}</span>
					</div>

					<div v-if="product.discount" class="flex items-center mb-4">
						<div class="mr-2">
							<span class="font-sourcesanspro-semibold text-base2 line-through text-white/30">
								&euro; {{ product.price }}
							</span>
						</div>
						<span class="font-sourcesanspro-bold text-lg"
							>€{{ product.discountedPrice()?.toFixed(2) }}</span
						>
					</div>

					<div v-if="!product.discount" class="flex items-center mb-4">
						<span class="font-sourcesanspro-bold text-lg">€{{ product.price }}</span>
					</div>

					<div class="mb-4">
						<div class="font-sourcesanspro-semibold text-l2">
							About <span class="capitalize">{{ product.name.toLowerCase() }}</span>
						</div>
						<p
							v-dompurify-html="product.description"
							class="mb-4 text-base2 font-color-text-50 first-letter:capitalize"></p>
					</div>
				</div>
			</div>
		</div>
		<div class="h-[100px] mb-10">&nbsp;</div>
		<product-quantity-bottom-bar v-if="product" :product="product" />
	</ion-content>
</template>

<style lang="scss" scoped></style>
