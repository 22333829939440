import { computed, ref, watch } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { DateTime, Interval } from 'luxon';

import { Timetable } from '@/shared/interfaces/timeTables';
import { TrackActivity } from '@/shared/interfaces/timeTables';
import { anykrowdApi } from '@/api/anykrowdApi';
import useStorageService from '@/shared/composables/useStorageService';

const { get, set } = useStorageService();

// Get duration data
const calculateDurationData = (activity: TrackActivity, timelineDetails: (DateTime | null)[]) => {
	// This uses a table system
	// - Each hour is divided in 4 cells
	// - Each cell uses a width of 40px
	// - Each cell represents 15 minutes
	// - So 40 / 15 = 2.6 px for each
	const pixelsPerMinute = 40 / 15;

	// Get start days, hours and minutes
	const timelineMonths = [...new Set(timelineDetails.map((d) => d!.month))];
	const startMonth = activity.startAt.month;
	const monthIndex = timelineMonths.indexOf(startMonth);
	const startDay = activity.startAt.day;
	const startHours = activity.startAt.hour;
	const startMinutes = Math.trunc(activity.startAt.minute / 15);

	// Get offset or starting hour
	// For instance, if first event is at 6, we get it and multiply by 4 so we get the number of
	// cells to be subtracted later to know what cell the activity must start
	const offset = timelineDetails[0]!.day * (4 * 24) + timelineDetails[0]!.hour * 4;

	// Calculate the duration expressed in pixels
	const interval = Interval.fromDateTimes(activity.startAt, activity.endAt);
	const durationInPixels = (interval.toDuration().milliseconds / 1000 / 60) * pixelsPerMinute;

	// Calculate fine offset for start (setted in the inner element)
	// We need a fine grain here, as the grid system is divided in chunks of 15 minutes
	// and there are events that starts at times like 16:20
	// So, we get decimal part from the startAt and then
	// - Multiply by the total width of the grid cell 40px to get the offset pixels
	// - Then 20px as it is the center of grid cell that is the starting point
	const minuteInteger = Math.trunc(activity.startAt.minute / 15);
	const minuteDecimal = activity.startAt.minute / 15;
	const startFineOffset = `margin-left:${Math.trunc((minuteDecimal - minuteInteger) * 40) + 20}px`;

	// Calculate the final start of the activity by:
	// - startHours * 4
	// - plus 2 as the grid uses first column for title
	// - minus the offset
	const monthOffset = monthIndex > 0 ? monthIndex * 31 : 1;
	const activityStart = monthOffset * startDay * (4 * 24) + startHours * 4 + 2 + startMinutes - offset;

	return {
		// duration: `grid-column-start:${activityStart - offset };grid-column-end:${activityStart - offset + duration};`,
		duration: `grid-column-start:${activityStart};width:${durationInPixels}px;`,
		startFineOffset,
	};
};

// Calculate progress percent
const calculateProgressPercent = (activity: TrackActivity) => {
	// Init
	const now = DateTime.now();
	const interval = Interval.fromDateTimes(activity.startAt, activity.endAt);

	// Interval's end date is before than now
	// Activity already ended
	if (interval.isBefore(now)) {
		return 1;
	}

	// Interval's start date is after than now
	// Activity has not started yet
	if (interval.isAfter(now)) {
		return 0;
	}

	// Total duration of activity
	const totalActivityDuration = interval.length('milliseconds');

	// Duration from start to now
	const currentDuration = Interval.fromDateTimes(activity.startAt, now).length('milliseconds');

	// Calculate percentage
	const currentDurationPercentage = currentDuration / totalActivityDuration;

	return currentDurationPercentage;
};

// Get Timetable api call
const getTimeTableApiCall = async (timetableId: number, userId?: number | undefined): Promise<Timetable> => {
	const { data } = await anykrowdApi.get<Timetable>(`/timetables/${timetableId}?user_id=${userId}`, false);
	return data;
};

// Get Timetable
const getTimeTable = (timeTableId: number, userId: number | undefined) => {
	// Init
	const timeTable = ref<Timetable>()!;

	const {
		data: timeTableData,
		isFetched,
		refetch: refetchTimeTable,
		remove: removeTimeTableDataFromCache,
	} = useQuery({
		queryKey: ['timeTable', timeTableId],
		queryFn: () => getTimeTableApiCall(timeTableId, userId),
		retry: 3,
	});

	// Watch timeTableData
	watch(
		timeTableData,
		() => {
			if (timeTableData.value) {
				timeTable.value = new Timetable(timeTableData.value);

				// Update cache
				const key = ['timeTable', timeTableId].join('-');
				set(key, timeTableData.value);
			}
		},
		{ immediate: true },
	);

	return {
		timeTable,
		isFetchedTimeTable: computed(() => isFetched.value),
		refetchTimeTable,
		removeTimeTableDataFromCache,
	};
};

// getTimetablesApiCall
const getTimetablesApiCall = async (): Promise<Timetable[]> => {
	const { data } = await anykrowdApi.get<any>('/timetables', false);
	return data.data;
};

// Get Timetable
const getTimeTables = () => {
	// Init
	const timeTables = ref<Timetable[]>()!;

	const {
		data: timeTablesData,
		isFetched,
		refetch: refetchTimeTables,
		remove: removeTimeTablesDataFromCache,
	} = useQuery({
		queryKey: ['timeTables'],
		queryFn: () => getTimetablesApiCall(),
		retry: 3,
	});

	// Watch timeTablesData
	watch(
		timeTablesData,
		() => {
			if (timeTablesData.value) {
				timeTables.value = [...timeTablesData.value.map((timeTable) => new Timetable(timeTable))];

				// Update cache
				set('timeTables', timeTablesData.value);
			}
		},
		{ immediate: true },
	);

	return {
		timeTables,
		isFetchedTimeTables: computed(() => isFetched.value),
		refetchTimeTables,
		removeTimeTablesDataFromCache,
	};
};

const scrollToNextDay = (nextDay: DateTime) => {
	document.getElementById(`day-${nextDay.day}`)!.scrollIntoView({
		behavior: 'smooth',
		inline: 'start',
	});
};

const scrollToPreviousDay = (previousDay: DateTime) => {
	document.getElementById(`day-${previousDay.day}`)!.scrollIntoView({
		behavior: 'smooth',
		inline: 'start',
	});
};

const useTimeTables = () => {
	return {
		calculateDurationData,
		calculateProgressPercent,
		getTimeTable,
		getTimeTableApiCall,
		getTimeTables,
		getTimetablesApiCall,
		scrollToNextDay,
		scrollToPreviousDay,
	};
};

export default useTimeTables;
