<script setup lang="ts">
import { PropType } from 'vue';

import type { TenantConfig } from '@/shared/interfaces/tenant';
import useConvertValuta from '@/shared/composables/useConvertValuta';
import useUtils from '@/shared/composables/useUtils';
import { Currency } from '@/shared/interfaces/balance';
import useCurrencies from '@/shared/composables/useCurrencies';

const props = defineProps({
	date: { type: String, required: true },
	hideTransactionSign: { type: Boolean },
	price: { type: Number, required: true },
	currency: { type: Object as PropType<Currency>, required: false },
	transactionType: { type: String },
	paymentType: { type: String },
	tenantConfig: { type: Object as PropType<TenantConfig>, required: true },
});

// Init
const { convertValutaSync } = useConvertValuta();
const { convertToCurrency } = useUtils();
const { fiatCurrency } = useCurrencies();

// Get converted price
const convertedPrice = convertValutaSync(props.tenantConfig, props.price, true);
</script>

<template>
	<div class="flex grow flex-col items-end">
		<div
			class="font-sourcesanspro-regular text-base leading-tight"
			:class="props.transactionType === 'CREDIT' ? 'text-anykrowd-green' : 'text-anykrowd-red'">
			<span v-if="!props.hideTransactionSign">
				{{ props.transactionType === 'CREDIT' ? '+ ' : '- ' }}
			</span>
			<span v-if="props.currency" class="mr-1">€</span>
			<span v-if="!props.currency" class="mr-1">{{ fiatCurrency.prefix }}</span>
			<span>
				{{ convertToCurrency(+convertedPrice) }}
			</span>
		</div>
		<span class="font-sourcesanspro-regular text-xs font-color-text-50 leading-none whitespace-nowrap">
			{{ props.date }}
		</span>
	</div>
</template>

<style scoped></style>
