<script setup lang="ts">
import { PropType } from 'vue';
import { Capacitor } from '@capacitor/core';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { FileOpener } from '@capacitor-community/file-opener';
import { IonButton, IonContent, modalController } from '@ionic/vue';
import { useI18n } from 'vue-i18n';

import { anykrowdApi } from '@/api/anykrowdApi';
import { DownloadableItem, DownloadType } from '@/shared/interfaces/download';
import useLoading from '@/shared/composables/useLoading';
import useToast from '@/shared/composables/useToast';

const props = defineProps({
	title: { type: String },
	downloadItem: { type: Object as PropType<DownloadableItem> },
	downloadType: { type: String, required: true },
});

// Init
const { t } = useI18n();
const { presentLoading } = useLoading();
const { presentToast } = useToast();
let loader: any;

const downloadFile = () => {
	_downloadFile();
	closeModal();
};

const _downloadFile = async () => {
	if (Capacitor.isNativePlatform()) {
		loader = await presentLoading(t('general.please_wait'));

		if (!props.downloadItem!.data) {
			await anykrowdApi
				.get(props.downloadItem!.href, false, { responseType: 'blob', method: 'get' })
				.then(async (response) => {
					await saveFile(response.data);
				});
			return;
		}

		await saveFile(props.downloadItem!.data);
		return;
	}

	const strWindowFeatures = 'menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes';
	window.open(props.downloadItem!.href, '_blank', strWindowFeatures);
};

const blobToBase64 = (blob: Blob): Promise<string> => {
	return new Promise((resolve, _) => {
		const fileReader = new FileReader();
		// eslint-disable-next-line @typescript-eslint/dot-notation
		const zoneOriginalInstance = (fileReader as any)['__zone_symbol__originalInstance'];
		const reader = zoneOriginalInstance || fileReader;

		reader.onloadend = () => resolve(reader.result.toString());
		reader.readAsDataURL(blob);
	});
};

const saveFile = async (fileData: any) => {
	try {
		const blob: Blob = fileData;

		const base64 = await blobToBase64(blob);

		const extension = props.downloadItem!.extension ?? 'pdf';

		const savedFile = await Filesystem.writeFile({
			path: `${encodeURI(props.downloadItem!.linkName + '.' + extension)}`,
			data: base64,
			directory: Directory.Documents,
		});

		presentToast(
			'top',
			t('download_modal.file_stored_successfully', { file_name: props.downloadItem!.linkName }),
			4000,
		);

		let options: any = {
			filePath: savedFile.uri,
		};

		if (Object.prototype.hasOwnProperty.call(blob, 'type')) {
			options = {
				...options,
				contentType: blob.type,
			};
		}

		await FileOpener.open(options).catch(async (e: any) => {
			console.error(e);
			presentToast('top', t('download_modal.open_file_error'), 4000);
		});
	} catch (e) {
		console.error(e, e === 'FILE_NOTCREATED');
		presentToast('top', t('download_modal.open_file_error'), 4000);
	}

	await loader.dismiss();
};

const closeModal = async () => {
	await modalController.dismiss();
};
</script>

<template>
	<ion-content class="z-30">
		<div class="fixed bottom-0 w-full bg-white">
			<div class="flex flex-col mb-2 mx-4 pt-4 bg-white rounded-xl">
				<!-- Title container -->
				<div class="px-4 pb-4 text-center text-[#999999]">
					<h2 class="text-sm" v-dompurify-html="props.title"></h2>
				</div>

				<hr />

				<!-- Buttons -->
				<ion-button class="text-[#3880ff]" fill="clear" @click="downloadFile()">{{
					t(downloadType)
				}}</ion-button>
			</div>

			<div class="flex flex-col mb-4 mx-4 bg-white rounded-xl">
				<ion-button class="text-[#3880ff]" fill="clear" @click="closeModal()">{{
					t('general.cancel')
				}}</ion-button>
			</div>
		</div>
	</ion-content>
</template>

<style scoped></style>
