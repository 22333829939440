<script setup lang="ts">
import router from '@/router';
import { IonButton } from '@ionic/vue';
import { shareSocialOutline } from 'ionicons/icons';

const props = defineProps({
	activated: { type: Boolean, default: false },
	class: { type: String },
	id: { type: Number, required: true },
	link: { type: String, required: true, default: '/dashboard' },
	shareable: { type: Boolean, default: false },
	status: { type: String },
	statusClass: { type: String },
	ticketDescription: { type: String, required: true },
	ticketName: { type: String, required: true },
	ticketSubTitle: { type: String, required: true },
	ticketTitle: { type: String, required: true },
	usable: { type: Boolean, default: false },
});

const goTo = async (link: string) => {
	await router.push(link);
};

const emit = defineEmits(['onShare']);
const share = () => {
	emit('onShare', props.id);
};
</script>

<template>
	<div
		class="flex flex-col w-[325px] h-[130px] px-6 py-3"
		:class="[
			{
				'voucher-item': !props.activated,
				'voucher-item-2': props.activated,
			},
			props.class,
		]"
		@click="goTo('')">
		<div class="flex w-full justify-between mb-3">
			<div class="flex flex-col mb-2">
				<span
					class="font-sourcesanspro-semibold text-sm2"
					:class="[
						{
							'text-white': !props.activated,
							'text-dark-blue': props.activated,
						},
					]">
					{{ props.ticketName }}
				</span>
				<span
					class="font-sourcesanspro-regular text-sm"
					:class="[
						{
							'text-white/60': !props.activated,
							'text-dark-blue/60': props.activated,
						},
					]"
					>{{ props.ticketDescription }}</span
				>
			</div>
			<div>
				<ion-button
					v-if="!props.activated"
					class="w-[89px] h-[33px]"
					expand="full"
					size="large"
					shape="round"
					@click="goTo(`/vouchers/activate/manual/${props.id}`)">
					<div class="flex items-center justify-center">
						<span class="text-sm2 text-[#OD132A]">Activate</span>
					</div>
				</ion-button>

				<div v-if="props.activated">
					<div class="p-1 px-2 rounded-md font-sourcesanspro-regular text-sm" :class="props.statusClass">
						{{ props.status }}
					</div>
				</div>
			</div>
		</div>
		<div class="flex w-full justify-between">
			<div class="flex flex-col">
				<span
					class="font-sourcesanspro-semibold text-l2 leading-6"
					:class="[
						{
							'text-white': !props.activated,
							'text-dark-blue': props.activated,
						},
					]"
					>{{ props.ticketTitle }}</span
				>
				<span
					class="font-sourcesanspro-regular text-sm text-white/60"
					:class="[
						{
							'text-white/60': !props.activated,
							'text-dark-blue/60': props.activated,
						},
					]"
					>{{ props.ticketSubTitle }}</span
				>
			</div>
			<div class="flex items-center justify-center">
				<ion-button
					v-if="props.activated && props.usable"
					class="w-[74px] h-[33px] black font-sourcesanspro-semibold text-sm2 ion-no-margin"
					expand="full"
					size="large"
					shape="round"
					@click="goTo(`/vouchers/activate/manual/${props.id}`)">
					<div class="flex items-center justify-center">
						<span class="text-sm2">Use it</span>
					</div>
				</ion-button>

				<ion-icon
					v-if="props.activated && props.shareable"
					:icon="shareSocialOutline"
					@click="share"
					class="text-dark-blue"></ion-icon>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.voucher-item {
	background: url('@/statics/svg/vouchers/voucher-bg-1.svg') no-repeat;
	background-size: 100%;
	position: relative;
}

.voucher-item-2 {
	background: url('@/statics/svg/vouchers/voucher-bg-2.svg') no-repeat;
	background-size: 100%;
	position: relative;
}
</style>
