import { useI18n } from 'vue-i18n';

import useUtils from '@/shared/composables/useUtils';
import { UserProfileUpdate } from '@/shared/interfaces/user';
import type { UserProfile } from '@/shared/interfaces/user';
import useUserProfile from '@/modules/profile/composables/useUserProfile';
import { DateTime } from 'luxon';

const useProfileEdit = () => {
	// Init
	const { t } = useI18n();
	const { compressImage, dataURLtoBlob } = useUtils();
	const { store, updateProfile } = useUserProfile();
	const { updateProfileMutate } = updateProfile();

	// savePhoto
	const savePhoto = async (photo: string, userProfile: UserProfile) => {
		const blob = dataURLtoBlob(photo);
		const formData = new FormData();

		// compress image
		await compressImage(blob, { quality: 0.4, width: 600, height: 600 })
			.then((compressedImage) => {
				formData.delete('profile_image');
				formData.append('profile_image', compressedImage);
			})
			.catch(() => {
				formData.delete('profile_image');
				formData.append('profile_image', blob);
			})
			.finally(async () => {
				const profileUpdated: UserProfileUpdate = new UserProfileUpdate();
				for (const key in profileUpdated) {
					if (userProfile[key]) {
						formData.append(key, userProfile[key]);
					}
				}

				if (formData.get('date_of_birth')) {
					const dateOfBirthString = formData.get('date_of_birth')!.toString();
					const dateOfBirth = DateTime.fromFormat(dateOfBirthString, 'dd-MM-yyyy').toFormat('yyyy-MM-dd');
					formData.set('date_of_birth', dateOfBirth);
				}

				// If we have an image, first we need to store it on the temp folder of the server
				if (formData.get('profile_image')) {
					const fileStoreResult = await store(formData.get('profile_image'), {
						progress: (amount: string) => console.log('progress: ' + amount),
					});

					if (fileStoreResult.key) {
						formData.delete('profile_image');
						formData.append('profile_image_key', fileStoreResult.key);
					}
				}

				await updateProfileEdit(formData);
			});
	};

	const updateProfileEdit = async (formData: UserProfileUpdate | FormData) => {
		updateProfileMutate(formData);
	};

	return {
		savePhoto,
	};
};

export default useProfileEdit;
