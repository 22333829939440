<script setup lang="ts">
import { ref, watch } from 'vue';
import router from '@/router';
import { useRoute } from 'vue-router';
import {
	IonButton,
	IonContent,
	IonFooter,
	IonIcon,
	IonRefresher,
	IonRefresherContent,
	IonSpinner,
	loadingController,
} from '@ionic/vue';
import { bagHandleOutline, chevronBackOutline, locationOutline } from 'ionicons/icons';
import { GoogleMap, Marker } from 'vue3-google-map';
import { DateTime } from 'luxon';
import { Browser } from '@capacitor/browser';
import { useI18n } from 'vue-i18n';

import { DownloadType } from '@/shared/interfaces/download';
import { useAuthStore } from '@/stores/auth/authStore';
import { useCart } from '@/modules/shop/composables/useCart';
import FavoriteButton from '@/shared/components/FavoriteButton.vue';
import FullScreenImage from '@/shared/components/ui/FullScreenImage.vue';
import RelatedContent from '@/shared/components/ui/RelatedContent.vue';
import ShareButton from '@/shared/components/ShareButton.vue';
import TopBar from '@/shared/components/TopBar.vue';
import type { DownloadModalProps } from '@/shared/interfaces/download';
import useDownload from '@/shared/composables/useDownload';
import useEvents from '@/modules/events/composables/useEvents';
import useShop from '@/modules/shop/composables/useShop';
import useToast from '@/shared/composables/useToast';
import useUtils from '@/shared/composables/useUtils';
import useTenant from '@/shared/composables/useTenant';
import { useSharedStore } from '@/stores/shared/sharedStore';

// Init
const { t } = useI18n();
const GOOGLE_MAPS_API_KEY = process.env.VUE_APP_GOOGLE_MAPS_API_KEY;
const mapRef = ref();
const latLng = ref({ lat: 0, lng: 0 });
const route = useRoute();
const eventId = +route.params.id;
const {
	addToCalendar,
	doorSalesOnly,
	eventEnded,
	getEvent,
	isGoingToEventOrHasBoughtTickets,
	noTicketsAvailable,
	showTicketButton,
} = useEvents();
const { clearCart } = useCart();
const { event, isFetchedEvent, refetchEvent, removeEventFromCache } = getEvent(eventId);
const { getProducts } = useShop();
const { products } = getProducts({ upselling_event_id: eventId });
const { convertToCurrency } = useUtils();
const authStore = useAuthStore();
const { presentToast } = useToast();
const { presentDownloadAvailableModal } = useDownload();
const sharedStore = useSharedStore();
const { getTenantConfigAndStyles } = useTenant();
const { showLoggedData } = getTenantConfigAndStyles();

// Image handling
const fullScreenImage = ref('');
const openInFullScreen = ref(false);
const viewFullScreen = (image: string) => {
	fullScreenImage.value = image;
	openInFullScreen.value = true;
};

const onCloseImage = () => {
	openInFullScreen.value = false;
};

// Watch map state
watch(
	() => mapRef.value?.ready,
	(ready) => {
		if (!ready) return;
		// Get latitutde and longitude from event address
		const geocoder = new mapRef.value.api.Geocoder();
		geocoder.geocode({ 'address': event.value?.address }, function (results: any, status: string) {
			if (status == google.maps.GeocoderStatus.OK) {
				latLng.value = {
					lat: results[0].geometry.location.lat(),
					lng: results[0].geometry.location.lng(),
				};
			}
		});
	},
);

// shouldAuthenticate
const shouldAuthenticate = async () => {
	await presentToast('top', t('general.please_login_first'), 5000, 'danger');
	await router.push('/auth/signuphome');
};

// download
const download = async (path: string) => {
	const loader = await loadingController.create({
		message: t('general.please_wait'),
	});
	await loader.present();

	const data: DownloadModalProps = {
		downloadItem: {
			extension: 'ics',
			href: `${window.apiUrl}${path}`,
			linkName: `Calendar`,
		},
		title: t('download_modal.title', { file_name: `Calendar` }),
		downloadType: DownloadType.ICS,
	};

	await presentDownloadAvailableModal(data);
	await loader.dismiss();
};

// Refresh
const handleRefresh = async (event: any) => {
	removeEventFromCache();
	event.value = null;
	await refetchEvent();
	event.target.complete();
};

const clearCartAndContinue = (path: string) => {
	clearCart();
	router.push(path);
};

// Save previous visited page if is the first time the page is visited
// as go back is not always the same page
if (!sharedStore.customNavigation.find((item) => item.key === route.path) && router.options.history.state.back) {
	sharedStore.customNavigation.push({ key: route.path, value: router.options.history.state.back?.toString() });
}

// Handle go back
const goBack = async () => {
	const previousRoute = sharedStore.customNavigation.find((item) => item.key === route.path)?.value;
	const indexOfPreviousRoute = sharedStore.customNavigation.findIndex((item) => item.key === route.path);
	if (indexOfPreviousRoute != -1) {
		sharedStore.customNavigation.splice(indexOfPreviousRoute, 1);
		await router.push({ path: previousRoute! });
	} else {
		await router.push({ path: '/dashboard' });
	}
};
</script>

<template>
	<ion-content class="z-10">
		<FullScreenImage v-if="openInFullScreen" :fullScreenImage="fullScreenImage" @on-close-image="onCloseImage" />
		<ion-refresher class="mt-8" slot="fixed" @ionRefresh="handleRefresh($event)">
			<ion-refresher-content></ion-refresher-content>
		</ion-refresher>

		<TopBar class="px-[34px]">
			<template #left>
				<div class="flex items-center justify-start cursor-pointer">
					<div class="flex items-center justify-center" @click="goBack()">
						<ion-icon :icon="chevronBackOutline" class="text-white"></ion-icon>
					</div>
				</div>
			</template>

			<template #center>
				<div class="flex justify-left w-full font-sourcesanspro-semibold text-l2">
					{{ t('general.go_back') }}
				</div>
			</template>

			<template #right v-if="event">
				<div v-if="showLoggedData()">
					<FavoriteButton :id="event.id" :favorite="event.is_favorite" type="event"></FavoriteButton>
				</div>
				<ShareButton :id="event.id" :title="event.name" type="events"></ShareButton>
			</template>
		</TopBar>

		<div v-if="!event" class="flex items-center justify-center pt-3">
			<ion-spinner name="dots"></ion-spinner>
		</div>
		<div v-if="event">
			<div class="flex justify-center px-[34px] mb-2 w-full font-sourcesanspro-semibold text-l2">
				{{ event?.name }}
			</div>
			<div class="flex overflow-scroll w-[100%] h-[182px] mb-4 invisible-scroller">
				<div
					v-for="(image, idx) in event.images"
					:key="idx"
					class="flex items-center min-w-[326px] mr-4 rounded-xl overflow-hidden"
					:class="[{ 'ml-[34px]': idx === 0 }]">
					<img :src="image.image_url" @click="viewFullScreen(image.image_url)" class="object-fit w-[100%]" />
				</div>
			</div>

			<div class="px-[34px] mb-2">
				<div class="flex font-sourcesanspro-regular text-xs2 font-color-text-50 mb-4">
					<div class="flex items-center mr-2 text-base">
						<ion-icon class="h-[16px] mr-1 font-color-text-50" :icon="locationOutline"></ion-icon>
						{{ event?.address }}
					</div>
				</div>

				<div class="flex justify-between mb-4">
					<div class="flex flex-col">
						<span class="font-sourcesanspro-semibold text-l2">
							{{ DateTime.fromFormat(event.start_datetime, 'yyyy-MM-dd HH:mm:ss').toFormat('dd MMMM') }}
						</span>
						<span class="font-sourcesanspro-regular text-base font-color-text-50">
							{{ DateTime.fromFormat(event.start_datetime, 'yyyy-MM-dd HH:mm:ss').toFormat('hh:mm a') }}
						</span>
					</div>
					<div class="flex items-center">
						<span class="font-sourcesanspro-regular text-base font-color-text-50">{{
							t('events.details.to')
						}}</span>
					</div>
					<div class="flex flex-col">
						<span class="font-sourcesanspro-semibold text-l2">
							{{ DateTime.fromFormat(event.end_datetime, 'yyyy-MM-dd HH:mm:ss').toFormat('dd MMMM') }}
						</span>
						<span class="font-sourcesanspro-regular text-base font-color-text-50">
							{{ DateTime.fromFormat(event.end_datetime, 'yyyy-MM-dd HH:mm:ss').toFormat('hh:mm a') }}
						</span>
					</div>
					<div class="flex justify-right">
						<ion-button
							class="ion-no-margin ion-no-padding"
							fill="clear"
							@click="download(`/events/calendar-invite/${event.id}`)">
							<ion-icon
								:src="require('@/statics/svg/events/calendar-add.svg')"
								class="h-[54px] w-[54px]" />
						</ion-button>
					</div>
				</div>

				<div class="font-sourcesanspro-semibold mb-4 px-4 pt-2 pb-4 bg-black/30 rounded-xl">
					<div class="text-l2">{{ t('events.details.about_event') }}:</div>
					<p
						v-if="!event.active_now || !event.active_now_content"
						class="text-base2 font-sourcesanspro-regular font-white"
						v-dompurify-html="event.description"></p>
					<p
						v-if="event.active_now && event.active_now_content"
						class="text-base2 font-sourcesanspro-regular font-white"
						v-dompurify-html="event.active_now_content"></p>
				</div>

				<div v-if="event.address" class="flex flex-col mb-8">
					<div class="flex items-center justify-between font-sourcesanspro-semibold mb-2">
						<div>
							<div class="text-l2">{{ t('events.details.event_location') }}</div>
							<p class="text-base font-sourcesanspro-regular font-color-text-50">
								{{ event.address }}
							</p>
						</div>
					</div>
					<div>
						<GoogleMap
							:api-key="GOOGLE_MAPS_API_KEY"
							ref="mapRef"
							style="width: 100%; height: 150px"
							:center="latLng"
							:zoom="15"
							:disableDefaultUi="true">
							<Marker :options="{ position: latLng }" />
						</GoogleMap>
					</div>
				</div>
			</div>

			<div class="mb-4 font-sourcesanspro-semibold">
				<RelatedContent
					v-if="event?.posts.length"
					title="Related News"
					:contents="
						event?.posts.map((post) => {
							return {
								'link': `/news/${post.id}`,
								'src': post.main_image_url,
								'title': post.title,
								'subtitle': '',
							};
						})
					" />
			</div>

			<div class="mb-4 font-sourcesanspro-semibold">
				<RelatedContent
					v-if="products?.length"
					title="Related Products"
					:contents="
						products?.map((product) => {
							return {
								'link': `/shop/product/detail/${product.id}`,
								'src': product.image_url ? product.image_url : '',
								'title': product.name,
								'subtitle': convertToCurrency(product.price),
							};
						})
					" />
			</div>

			<div class="h-[100px]">&nbsp;</div>
		</div>
	</ion-content>
	<ion-footer
		class="flex items-center justify-center button-container"
		:class="[
			{
				'z-0': openInFullScreen,
			},
		]">
		<div v-if="showLoggedData() && event" class="fixed justify-center h-[150px] px-[34px] bottom-0 z-10 w-full">
			<div class="relative w-full h-full">
				<div class="absolute w-full bottom-0 mb-[34px]">
					<div v-if="!authStore.isAuthenticated()">
						<ion-button
							class="flex-grow"
							expand="full"
							size="large"
							shape="round"
							@click="shouldAuthenticate()">
							<div class="flex w-full justify-center items-center font-sourcesanspro-semibold text-m2">
								{{ t('events.details.buy_tickets') }}
							</div>
						</ion-button>
					</div>
					<div v-else>
						<div
							v-if="
								!noTicketsAvailable(event) ||
								showTicketButton(event) ||
								isGoingToEventOrHasBoughtTickets(event)
							">
							<div v-if="showTicketButton(event)" class="flex justify-between">
								<ion-button
									v-if="
										!event?.sold_out &&
										isGoingToEventOrHasBoughtTickets(event) &&
										!event?.is_past &&
										!noTicketsAvailable(event)
									"
									class="flex-grow hover:bg-white hover:rounded-full"
									expand="full"
									size="large"
									shape="round"
									@click="
										event?.tickets_link
											? Browser.open({ url: event.tickets_link })
											: clearCartAndContinue(`/events/${event.id}/tickets/buy`)
									">
									<div
										class="flex w-full justify-center items-center font-sourcesanspro-semibold text-m2">
										<ion-icon :icon="bagHandleOutline" class="mr-2 text-dark-blue"></ion-icon>
										{{
											event.event_tickets_count >= 1
												? t('events.details.buy_more')
												: t('events.details.buy_tickets')
										}}
									</div>
								</ion-button>

								<ion-button
									class="flex-grow hover:bg-white hover:rounded-full"
									expand="full"
									size="large"
									shape="round"
									@click="clearCartAndContinue(`/events/${event.id}/tickets`)">
									<div
										class="flex w-full justify-center items-center font-sourcesanspro-semibold text-m2">
										{{ event.event_tickets_count }} {{ t('events.details.tickets') }}
									</div>
								</ion-button>
							</div>

							<div class="flex" v-if="doorSalesOnly(event)">
								<div
									class="flex p-2 font-sourcesanspro-regular text-base backdrop-blur bg-white/20 rounded-xl">
									{{ t('events.details.tickets_purchased_door') }}
								</div>
							</div>

							<div
								v-if="
									(!event?.sold_out || event?.tickets_link) &&
									!showTicketButton(event) &&
									!doorSalesOnly(event) &&
									!eventEnded(event)
								">
								<ion-button
									v-if="event?.tickets_link"
									class="w-full ion-no-margin"
									expand="full"
									size="large"
									shape="round"
									@click="Browser.open({ url: event.tickets_link })">
									<div
										class="flex w-full justify-center items-center font-sourcesanspro-semibold text-m2">
										{{
											event?.is_free
												? t('events.details.friend_invites')
												: t('events.details.buy_tickets')
										}}
									</div>
								</ion-button>
								<ion-button
									v-else
									class="w-full ion-no-margin hover:bg-white hover:rounded-full"
									expand="full"
									size="large"
									shape="round"
									@click="clearCartAndContinue(`/events/${event.id}/tickets/buy`)">
									<div
										class="flex w-full justify-center items-center font-sourcesanspro-semibold text-m2">
										{{ event.event_tickets_count }} {{ t('events.details.tickets') }}
									</div>
								</ion-button>
							</div>
							<div
								class="relative h-full"
								v-if="event?.sold_out && !showTicketButton(event) && !event?.tickets_link">
								<ion-button disabled class="w-full" expand="full">
									{{ t('events.details.sold_out') }}
								</ion-button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</ion-footer>
</template>

<style lang="scss" scoped>
.button-container::after {
	background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(23, 22, 30, 1));
	bottom: 0;
	content: '';
	height: 150px;
	left: 0;
	position: absolute;
	right: 0;
	z-index: 0;
}
</style>
