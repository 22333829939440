<script setup lang="ts">
import { toRefs, PropType } from 'vue';
import { useField, useIsFormValid } from 'vee-validate';
import { IonLabel, IonSelect, IonSelectOption } from '@ionic/vue';

import type { Option } from '@/shared/interfaces/option';

const props = defineProps({
	label: { type: String },
	name: { type: String },
	value: { type: String },
	values: { type: Array as PropType<Option[]>, required: true },
});

const emit = defineEmits(['inputChanged']);

// we are using toRefs to create reactive references to `name` and `value` props
// this is important because vee-validte needs to know if any of these props change
// https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
const { name } = toRefs(props);
const { value, errorMessage, handleChange, meta } = useField(name, undefined, {
	initialValue: props.value,
});

const isFormValid = useIsFormValid();

const selectItem = (option: string) => {
	handleChange(option);
	// event.preventDefault();
	emit('inputChanged', isFormValid, option);
};
</script>

<template>
	<div>
		<ion-label v-if="props.label" class="font-sourcesanspro-regular text-xs2 text-white/50">{{
			props.label
		}}</ion-label>
		<div
			class="flex flex-wrap w-full border border-solid border-white-15 rounded-full mb-2 px-2 font-sourcesanspro-semibold text-base">
			<!-- <ion-checkbox :name="name" class="mr-2" @click="handleChange(value)" ></ion-checkbox>
      <div class="pt-3">
        <span class="text-color-magenta text-sm">{{ $t(label) }}</span>
      </div> -->
			<ion-select
				@ionChange="selectItem($event.detail.value)"
				:name="name"
				interface="popover"
				aria-label="select"
				placeholder="Select"
				:value="props.value">
				<ion-select-option v-for="(option, index) in props.values" :key="index" :value="option.value">{{
					option.label
				}}</ion-select-option>
			</ion-select>
		</div>
	</div>
</template>

<style lang="scss" scoped>
div {
	ion-select {
		--placeholder-opacity: 1;
		height: 52px;
		width: 100%;
		justify-content: center;
	}
}
</style>
