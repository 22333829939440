<script setup lang="ts">
import { toRefs } from 'vue';
import { useField } from 'vee-validate';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.css';

const props = defineProps({
	data: Array,
	icon: { type: String, default: '' },
	label: { type: String },
	name: { String, default: '' },
	placeholder: String,
	value: { type: String },
});

// we are using toRefs to create reactive references to `name` and `value` props
// this is important because vee-validte needs to know if any of these props change
// https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
const { name, value } = toRefs(props);
const { handleChange } = useField(name);

const selectItem = (option: string) => {
	handleChange(option);
};
</script>

<template>
	<div>
		<ion-label v-if="props.label" class="font-sourcesanspro-regular text-xs2 text-white/50">{{
			props.label
		}}</ion-label>
		<div class="flex flex-wrap w-full justify-left item-center border-white-15 mb-2">
			<div class="w-full items-center mb-2" lines="none">
				<multiselect
					placeholder="Select"
					v-model="value"
					:allow-empty="false"
					:input-class="'font-sourcesanspro-regular text-base'"
					:options="props.data"
					:option-class="{ 'my-highlight-class': true }"
					:show-labels="false"
					@select="selectItem">
				</multiselect>
			</div>
		</div>
	</div>
</template>

<style lang="scss">
.multiselect__content {
	width: 100%;

	.multiselect__element {
		width: 100%;
		overflow: hidden;

		span span {
			word-wrap: break-word;
			white-space: normal;
		}
	}
}
</style>
