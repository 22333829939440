<script setup lang="ts">
import { onMounted } from 'vue';
import { useRoute } from 'vue-router';
import router from '@/router';
import useRfidGuest from '@/modules/rfid/composables/useRfidGuest';

// Init
const { activateRfid } = useRfidGuest();
const route = useRoute();
const selfLinkingBraceletToken = route.params.id.toString();

// onMountedHook
onMounted(async () => {
	if (selfLinkingBraceletToken) {
		const data = await activateRfid(selfLinkingBraceletToken);
		if (data.encrypted_rfid_tag) {
			await router.push({ path: `/wallet/wallet-handler/${data.encrypted_rfid_tag}` });
		}
	}
});
</script>

<template>
	<div></div>
</template>

<style scoped></style>
