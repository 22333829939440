<script setup lang="ts">
const props = defineProps({
	readByAvatars: { type: Array },
	senderAvatar: { type: String },
	senderStatusClass: { type: String },
	time: { type: String },
	type: { type: String, required: true },
});
</script>

<template>
	<div v-if="props.type === 'received'" class="flex flex-col mb-3">
		<div class="grid grid-cols-[42px_1fr] gap-3">
			<div v-if="props.senderAvatar" class="relative flex justify-start h-[42px]">
				<img :src="props.senderAvatar" class="w-[42px] h-[42px] rounded-full" />
				<div
					:class="props.senderStatusClass"
					class="absolute w-[10px] h-[10px] left-[33px] bottom-[3px] rounded-full border-[2px] border-anykrowd-dark-purple"></div>
			</div>
			<div
				:class="[
					{
						'col-start-2': !props.senderAvatar,
					},
				]"
				class="flex flex-col items-start">
				<div>
					<div
						:class="[
							{
								'mt-6': props.senderAvatar,
							},
						]"
						class="min-w-[200px] max-w-[255px] px-5 py-3 backdrop-blur-xl bg-white/10 rounded-tr-lg rounded-br-lg rounded-bl-lg leading-none">
						<span class="font-sourcesanspro-regular text-sm2">
							<slot />
						</span>
					</div>
					<div v-if="!props.readByAvatars" class="text-right font-sourcesanspro-regular text-xs">
						{{ props.time }}
					</div>

					<div v-if="props.readByAvatars" class="flex justify-between">
						<div class="text-right font-sourcesanspro-regular text-xs">{{ props.time }}</div>
						<div class="flex">
							<img
								v-for="image in props.readByAvatars"
								:key="image as string"
								:src="image as string"
								class="w-[20px] h-[20px] mt-1 ml-1 rounded-full" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div v-if="props.type === 'sent'" class="flex flex-col mb-3">
		<div class="grid grid-cols-[42px_1fr] gap-3">
			<div class="col-start-2">
				<div class="flex flex-col items-end">
					<div>
						<div
							class="max-w-[255px] px-5 py-3 bg-anykrowd-zinc rounded-tl-lg rounded-br-lg rounded-bl-lg text-dark-blue leading-none">
							<span class="font-sourcesanspro-regular text-sm2">
								<slot />
							</span>
						</div>
						<div class="flex justify-between">
							<div v-if="props.time" class="text-left font-sourcesanspro-regular text-xs">
								{{ props.time }}
							</div>
							<div class="flex">
								<img
									v-for="image in props.readByAvatars"
									:key="image as string"
									:src="image as string"
									class="w-[20px] h-[20px] mt-1 ml-1 rounded-full" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped></style>
