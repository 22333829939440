import { computed, ref, watch } from 'vue';
import { useQuery, useMutation } from '@tanstack/vue-query';

import { anykrowdApi } from '@/api/anykrowdApi';
import { useAuthStore } from '@/stores/auth/authStore';
import type { RFID } from '@/shared/interfaces/rfid';

// Get rfid tags api call
const _getList = async (): Promise<RFID[]> => {
	const { data } = await anykrowdApi.get<RFID[]>('/rfid-tags', true);
	return data;
};

// Get rfid tags
const getRfidTags = () => {
	const rfidTags = ref<RFID[]>([]);
	const authStore = useAuthStore();
	const {
		data: rfidsData,
		isFetched: isFetchedRfidTags,
		isLoading: isLoadingRfidTags,
		isRefetching: isRefetchingRfidTags,
		refetch: refetchRfidTags,
		remove: removeRfidTags,
	} = useQuery({
		queryKey: ['rfidTags'],
		queryFn: () => _getList(),
		retry: 3,
		enabled: !!(authStore.user && authStore.user.token),
	});

	watch(
		rfidsData,
		() => {
			if (rfidsData.value) {
				rfidTags.value = [...rfidsData.value];
			}
		},
		{ immediate: true },
	);

	return {
		isFetchedRfidTags: computed(() => isFetchedRfidTags.value),
		isLoadingRfidTags: computed(() => isLoadingRfidTags.value),
		isRefetchingRfidTags: computed(() => isRefetchingRfidTags.value),
		refetchRfidTags,
		removeRfidTags,
		rfidTags,
	};
};

// Disconnect tag api call
const _disconnect = async (rfidTagId: string | number): Promise<{ code: string }> => {
	// Send DELETE
	const { data } = await anykrowdApi.delete(`/rfid-tags/${rfidTagId}`, true);
	return data;
};

// Disconnect tag
const disconnectTag = () => {
	const {
		data: disconnectTagData,
		isSuccess: isSuccessDisconnectRfid,
		mutate: disconnectTagMutate,
	} = useMutation(_disconnect);

	return {
		disconnectTagData,
		disconnectTagMutate,
		isSuccessDisconnectRfid: computed(() => isSuccessDisconnectRfid.value),
	};
};

const useRfid = () => {
	return {
		disconnectTag,
		getRfidTags,
	};
};

export default useRfid;
