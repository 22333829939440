/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
import { EventComboTicketType } from '@/shared/interfaces/eventComboTicketType';
import { Event, ModelID } from '@/shared/interfaces/event';

export class EventCombo {
	id: ModelID = null;
	name = '';
	description = '';
	ticket_types: EventComboTicketType[] = [];
	events: Event[] = [];

	constructor(
		data: {
			id: ModelID;
			name: string;
			description: string;
			ticket_types: EventComboTicketType[];
			events: Event[];
		} = {
			id: null,
			name: '',
			description: '',
			ticket_types: [],
			events: [],
		},
	) {
		Object.assign(this, data);

		if (data.ticket_types.length) {
			this.ticket_types = data.ticket_types.map(
				(ticketType): EventComboTicketType => new EventComboTicketType(ticketType),
			);
		}
	}
}
