<script setup lang="ts">
import { IonIcon } from '@ionic/vue';
const props = defineProps({
	icon: { type: String, required: true },
	price: { type: String, required: true },
	subtitle: { type: String, required: true },
	title: { type: String, required: true },
});
</script>

<template>
	<div
		class="flex items-center justify-between mb-4 px-4 py-2 h-[64px] backdrop-blur-xl bg-white/10 border border-white/10 rounded-lg">
		<div class="flex items-center">
			<div class="flex items-center justify-center mr-2">
				<ion-icon
					:icon="props.icon"
					class="w-[22px] h-[22px] p-2 border border-white/10 rounded-full text-white"></ion-icon>
			</div>
			<div class="flex flex-col w-[173px]">
				<span class="font-sourcesanspro-semibold text-base2">{{ props.title }}</span>
				<span class="font-sourcesanspro-semibold text-xs2 font-color-text-50">{{ props.subtitle }}</span>
			</div>
		</div>
		<div class="font-sourcesanspro-semibold text-lg24">
			{{ props.price }}
		</div>
	</div>
</template>

<style scoped></style>
