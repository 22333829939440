<script setup lang="ts">
import { watch, onBeforeMount } from 'vue';
import router from '@/router';
import { IonButton, IonContent, IonIcon } from '@ionic/vue';
import { arrowForwardOutline, chevronBackOutline } from 'ionicons/icons';

import hexRgb from 'hex-rgb';
import NotificationsSettings from '@/modules/profile/components/NotificationsSettings.vue';
import rgbHex from 'rgb-hex';
import TagCollections from '@/shared/components/TagCollections.vue';
import TopBar from '@/shared/components/TopBar.vue';
import useAuth from '@/modules/auth/composables/useAuth';
import useNotifications from '@/shared/composables/useNotifications';
import useStepChanger from '@/shared/composables/useStepChanger';
import useTagCollections from '@/shared/composables/useTagCollections';
import useUtils from '@/shared/composables/useUtils';

// Init
const { requestPushNotificationsPermission } = useNotifications();
const { tagsList } = useTagCollections([]);
const { isLastStep, nextStep, previousStep, stepIndex } = useStepChanger(
	tagsList && tagsList.value && tagsList.value.length ? 2 : 1,
);
const { getRedirect, setRedirect } = useAuth();
const { calculateShade } = useUtils();
const primary_color = window.primary_color;
const primaryRgb = hexRgb(window.primary_color, { format: 'array' });
const primaryShade = '#' + rgbHex(calculateShade(primaryRgb.toString(), 0.2));

// Reset step
onBeforeMount(() => {
	stepIndex.value = 0;
});

watch(stepIndex, () => {
	if (stepIndex.value === 1) {
		// Request push notifications permission
		requestPushNotificationsPermission();
	}
});

const goToDashboard = async () => {
	// Get redirection from store
	// If set, it can be used to go back to callback page
	const redirect = getRedirect();
	if (redirect.callbackPage && redirect.id) {
		// reset redirection and redirect
		setRedirect({ callbackPage: '', id: '' });
		router.push({ path: `/${redirect.callbackPage}/${redirect.id}`, query: { continueLinking: 'true' } });
	} else {
		router.push('/dashboard');
	}
};
</script>

<template>
	<ion-content :style="`background: linear-gradient(${primary_color}, ${primaryShade});`" class="flex">
		<div class="flex flex-col justify-between h-full">
			<TopBar class="px-[34px]">
				<template #left>
					<div class="flex items-center justify-start cursor-pointer" @click="previousStep">
						<div v-if="stepIndex === 0"></div>
						<ion-icon v-if="stepIndex > 0" :icon="chevronBackOutline" class="text-white"></ion-icon>
					</div>
				</template>

				<template #right>
					<div v-if="!isLastStep" class="font-sourcesanspro-regular text-base">
						<router-link to="" @click="nextStep">Skip</router-link>
					</div>
				</template>
			</TopBar>

			<div class="flex flex-col h-full p-[34px]">
				<!-- Step 1 -->
				<div v-if="stepIndex === 0" class="flex flex-col height-inherit">
					<div class="flex flex-col grow justify-center">
						<img src="@/statics/svg/dont-miss-out.svg" class="mb-6" />
						<h1 class="mb-4 font-sourcesanspro-semibold text-white text-lg text-center">
							{{ $t('auth.sign_up_additional_page.step_five.title') }}
						</h1>
						<p class="font-sourcesanspro-regular text-base text-color-magenta text-center">
							<span>{{ $t('auth.sign_up_additional_page.step_five.subtitle') }}</span>
						</p>
					</div>
				</div>

				<!-- Step 2 -->
				<div v-if="stepIndex === 1" class="flex flex-col height-inherit">
					<div class="mb-8">
						<h1 class="mb-2 font-sourcesanspro-semibold text-white text-lg text-center">
							{{ $t('auth.sign_up_additional_page.step_six.title') }}
						</h1>
						<p
							class="max-w-[65%] mx-auto font-sourcesanspro-regular text-base text-color-magenta text-center">
							<span>{{ $t('auth.sign_up_additional_page.step_six.subtitle') }}</span>
						</p>
					</div>
					<div class="flex flex-col grow">
						<NotificationsSettings></NotificationsSettings>
					</div>
				</div>

				<!-- Step 3 -->
				<div v-if="stepIndex === 2 && tagsList && tagsList.length" class="flex flex-col height-inherit">
					<div class="mb-8">
						<h1 class="mb-4 font-sourcesanspro-semibold text-white text-lg text-center">
							{{ $t('auth.sign_up_additional_page.step_seven.title') }}
						</h1>
						<p class="font-sourcesanspro-regular text-base text-color-magenta text-center">
							<span>{{ $t('auth.sign_up_additional_page.step_seven.subtitle') }}</span>
						</p>
					</div>

					<TagCollections
						:accordion-class="'ion-no-padding'"
						:accordion-content-class="'ion-padding'"
						:userTags="[]" />
				</div>
			</div>
			<div class="px-[34px]">
				<div class="flex justify-center next-button w-full mb-6">
					<ion-button
						class="continue-button w-full"
						type="submit"
						color="primary"
						size="large"
						shape="round"
						@click="isLastStep ? goToDashboard() : nextStep()">
						<div class="flex w-full items-center justify-between">
							{{ isLastStep ? "Let's finish" : 'Continue' }}
							<ion-icon :icon="arrowForwardOutline" slot="end"></ion-icon>
						</div>
					</ion-button>
				</div>
			</div>
		</div>
	</ion-content>
</template>

<style lang="scss" scoped>
ion-button {
	&.continue-button {
		ion-icon {
			color: var(--clientx-color-button-text);
		}
	}
	&.go-back-button {
		--background: transparent;
		--box-shadow: none;
		height: 100%;
		margin: 0;
		width: initial;

		&::part(native)::after {
			background: transparent;
		}
	}
}
</style>
