<script setup lang="ts">
import EventsItemMemberAdder from '@/modules/events/components/EventsItemMemberAdder.vue';
import router from '@/router';
import HorizontalDatePicker from '@/shared/components/ui/HorizontalDatePicker.vue';
import HorizontalTimePicker from '@/shared/components/ui/HorizontalTimePicker.vue';
import { IonButton, IonContent, IonIcon } from '@ionic/vue';
import { calendarClearOutline, checkmarkOutline, closeOutline, peopleOutline, timeOutline } from 'ionicons/icons';

// Go back
const goBack = () => {
	router.back();
};

const goTo = async (link: string) => {
	await router.push({ path: link, query: { callbackPage: 'bookings-reservations' } });
};
</script>

<template>
	<ion-content>
		<div class="flex flex-col">
			<div class="flex flex-col mt-12 mb-4 px-[34px] items-center">
				<div class="flex items-center justify-center mb-4 w-[60px] h-[60px] rounded-full bg-anykrowd-gray/30">
					<ion-icon
						class="text-[38px] text-white cursor-pointer"
						:icon="closeOutline"
						@click="goBack"></ion-icon>
				</div>
			</div>

			<div class="flex items-center mb-4 px-[34px]">
				<ion-icon class="mr-2 text-[24px] text-white cursor-pointer" :icon="calendarClearOutline"></ion-icon>
				<h2 class="font-sourcesanspro-semibold text-l2 inline">Select Date</h2>
			</div>
			<div class="flex w-[100%] mb-8 invisible-scroller overflow-x-auto">
				<div class="flex flex-1 pb-4">
					<HorizontalDatePicker
						:items="[
							{ day: '21', weekDay: 'Wed', month: 'Dec', enabled: false },
							{ day: '22', weekDay: 'Thu', month: 'Dec', enabled: true },
							{ day: '23', weekDay: 'Fri', month: 'Dec', enabled: true },
							{ day: '24', weekDay: 'Sat', month: 'Dec', enabled: true },
							{ day: '25', weekDay: 'Sun', month: 'Dec', enabled: true },
							{ day: '26', weekDay: 'Mon', month: 'Dec', enabled: true },
						]" />
				</div>
			</div>

			<div class="flex items-center mb-4 px-[34px]">
				<ion-icon class="mr-2 text-[24px] text-white cursor-pointer" :icon="timeOutline"></ion-icon>
				<h2 class="font-sourcesanspro-semibold text-l2 inline">Select Time</h2>
			</div>
			<div class="flex w-[100%] mb-8 invisible-scroller overflow-x-auto">
				<div class="flex flex-1 pb-4">
					<HorizontalTimePicker
						:items="[
							{ hour: '8:45', period: 'AM', enabled: false },
							{ hour: '9:00', period: 'AM', enabled: true },
							{ hour: '9:15', period: 'AM', enabled: true },
							{ hour: '9:30', period: 'AM', enabled: true },
						]" />
				</div>
			</div>

			<div class="mb-8 px-[34px]">
				<div class="flex items-center mb-4">
					<ion-icon class="mr-2 text-[24px] text-white cursor-pointer" :icon="peopleOutline"></ion-icon>
					<h2 class="font-sourcesanspro-semibold text-l2 inline">Book Members</h2>
				</div>
				<div>
					<EventsItemMemberAdder
						class="w-full"
						:icon="checkmarkOutline"
						subtitle="regular"
						title="Mario Gutze"
						member="Mario Gutze" />

					<ion-button class="w-[145px] h-[44px] ion-no-padding" color="primary" fill="outline" shape="round">
						<span class="font-sourcesanspro-semibold text-base">+ Add New Person</span>
					</ion-button>
				</div>
			</div>
		</div>

		<div class="fixed button-container px-[34px] bottom-0 w-full">
			<div class="relative z-10 w-full h-full">
				<div class="absolute w-full bottom-0 mb-[34px]">
					<ion-button class="w-full" expand="full" size="large" shape="round" @click="goTo('/transaction')">
						<div class="font-sourcesanspro-semibold text-m2 text-center">Pay & Book (€450)</div>
					</ion-button>
				</div>
			</div>
		</div>

		<div class="h-[100px]">&nbsp;</div>
	</ion-content>
</template>

<style lang="scss" scoped>
ion-searchbar {
	--box-shadow: none;
}
</style>
