import { AndroidSettings, IOSSettings, NativeSettings } from 'capacitor-native-settings';

const openAppSettings = async () => {
	await NativeSettings.open({ optionAndroid: AndroidSettings.Application, optionIOS: IOSSettings.App });
};

const useNativeSettings = () => {
	return {
		openAppSettings,
	};
};

export default useNativeSettings;
