<script setup lang="ts">
import { ref } from 'vue';
import { IonAccordion, IonIcon } from '@ionic/vue';
import { RefundTagsColors } from '@/enums/values.enum';
import { RefundRequestStatus } from '@/shared/interfaces/refundRequest';
import { useI18n } from 'vue-i18n';

const props = defineProps({
	customSubtitle: { type: String },
	icon: { type: String, required: true },
	iconClass: { type: String, required: true },
	keyValue: { type: String, required: true },
	subtitle: { type: String },
	tag: { type: String, required: false },
	title: { type: String, required: true },
	transactionType: { type: String },
});

// Init
const { t } = useI18n();
const isOpen = ref(false);

const getTagColor = (value: string | undefined) => {
	let color = '';
	switch (value) {
		case RefundRequestStatus.APPROVED:
			color = RefundTagsColors.APPROVED;
			break;
		case RefundRequestStatus.FAILED:
			color = RefundTagsColors.FAILED;
			break;
		case RefundRequestStatus.REFUNDED:
			color = RefundTagsColors.REFUNDED;
			break;
		case RefundRequestStatus.PENDING:
			color = RefundTagsColors.PENDING;
			break;
		default:
			color = RefundTagsColors.PENDING;
			break;
	}
	return color;
};

const tagColor = getTagColor(props.tag);
</script>

<template>
	<ion-accordion class="cursor-pointer" :value="props.keyValue">
		<div class="flex p-4 items-center justify-between" slot="header" @click="isOpen = !isOpen">
			<ion-icon
				:class="[
					{
						'bg-anykrowd-green': props.transactionType === 'CREDIT',
						'bg-anykrowd-red': props.transactionType === 'DEBIT',
					},
					iconClass,
				]"
				:icon="props.icon"
				class="w-[24px] h-[24px] min-w-[24px] p-2 rounded-full"></ion-icon>
			<div class="flex flex-col grow pl-2 justify-center">
				<span class="font-sourcesanspro-semibold text-base leading-tight capitalize">{{ props.title }}</span>
				<div class="leading-none">
					<span
						v-if="props.subtitle"
						class="font-sourcesanspro-regular text-xs font-color-text-50 leading-none">
						{{
							props.subtitle === 'DEBIT'
								? t('components.transaction.debit')
								: t('components.transaction.credit')
						}}
					</span>
					<span
						v-if="props.customSubtitle"
						class="font-sourcesanspro-regular text-xs font-color-text-50 leading-none">
						{{ props.customSubtitle }}
					</span>
					<span v-if="props.subtitle && props.tag" class="font-sourcesanspro-regular text-xs"> • </span>
					<span v-if="props.tag" class="tag font-sourcesanspro-regular text-xs capitalize">
						{{ props.tag }}
					</span>
				</div>
			</div>
			<slot name="actions" />
		</div>
		<div slot="content">
			<slot name="content" :should-load="isOpen" />
		</div>
	</ion-accordion>
</template>

<style lang="scss" scoped>
.tag {
	color: v-bind(tagColor);
}
</style>
