import { ref, Ref } from 'vue';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { Share } from '@capacitor/share';
import { useI18n } from 'vue-i18n';

import { DownloadType } from '@/shared/interfaces/download';
import type { DownloadModalProps } from '@/shared/interfaces/download';
import useDownload from '@/shared/composables/useDownload';
import useUtils from '@/shared/composables/useUtils';

// Init
const { convertBase64ToBlob, convertBlobToBase64 } = useUtils();

// Get object url
const getObjectUrl = (canvasImage: string) => {
	// converts base 64 encoded image to blobData
	const blobData = convertBase64ToBlob(canvasImage);

	// saves as image
	const blob = new Blob([blobData], { type: 'image/png' });
	return {
		blob,
		blobData,
		url: window.URL.createObjectURL(blob),
	};
};

// useYourId
const useYourId = (emit: any, isYourIdModalOpen: Ref<boolean>) => {
	// Init
	const { t } = useI18n();
	const qrcode = ref();
	const { presentDownloadAvailableModal } = useDownload();

	// Close
	const close = () => {
		isYourIdModalOpen.value = false;
		emit('onCloseModal', isYourIdModalOpen.value);
	};

	// Get QR code
	const getQrCode = () => {
		return qrcode.value.getElementsByTagName('canvas')[0].toDataURL('image/png');
	};

	// Share
	const share = async () => {
		const canvasImage = getQrCode();
		const { blob } = getObjectUrl(canvasImage);

		const base64Data = await convertBlobToBase64(blob);
		const fileName = 'QR-Code.png';

		return Filesystem.writeFile({
			path: fileName,
			data: base64Data,
			directory: Directory.Cache,
		})
			.then(() => {
				return Filesystem.getUri({
					directory: Directory.Cache,
					path: fileName,
				});
			})
			.then((uriResult) => {
				return Share.share({
					title: fileName,
					text: fileName,
					url: uriResult.uri,
				});
			});
	};

	// Save image as
	const saveImageAs = async () => {
		const canvasImage = getQrCode();
		const { url, blob } = getObjectUrl(canvasImage);

		if (canvasImage) {
			const data: DownloadModalProps = {
				downloadItem: {
					href: url,
					linkName: `QR-Code`,
					data: blob,
					extension: 'png',
				},
				title: t('download_modal.title', { file_name: `QR Code` }),
				downloadType: DownloadType.QRCODE,
			};

			await presentDownloadAvailableModal(data);
		}
	};

	return {
		close,
		getQrCode,
		qrcode,
		saveImageAs,
		share,
	};
};

export default useYourId;
