<script setup lang="ts">
import { PropType } from 'vue';
import { IonItem, IonList } from '@ionic/vue';

import type { ItemsDetailsItem, ItemsDetailsItemMessage } from '@/shared/interfaces/itemsDetails';
import useUtils from '@/shared/composables/useUtils';

// Props
const props = defineProps({
	borderClass: { type: String, required: true },
	class: { type: String, required: true },
	items: {
		type: Array as PropType<ItemsDetailsItem[]>,
		default: function () {
			return [];
		},
	},
	message: {
		type: Object as PropType<ItemsDetailsItemMessage>,
		required: false,
	},
	quantity: { type: Number, default: undefined, required: false },
	currency: { type: String, default: undefined, required: true },
	textClass: { type: String, required: true },
	textClassTwo: { type: String, required: true },
	title: { type: String, required: true },
	totalPrice: { type: Number, required: true },
	totalPriceString: { type: String },
	totalTitle: { type: String, required: true },
});

// Init
const { convertToCurrency } = useUtils();
</script>

<template>
	<ion-list :class="props.class">
		<div :class="props.textClass" class="flex px-6 py-4 items-center">
			<span class="font-sourcesanspro-semibold text-l2">{{ props.title }}</span>
		</div>
		<div :class="props.borderClass">
			<ion-item
				v-for="(item, idx) in props.items as ItemsDetailsItem[]"
				:key="idx"
				:class="props.textClassTwo"
				class="font-sourcesanspro-semibold ion-no-padding"
				lines="none">
				<div class="flex px-6 w-full justify-between">
					<div>
						<span v-if="item.quantity && item.hideQuantity" class="text-base">{{ item.quantity }} x </span>
						<span class="text-base capitalize">{{ item.title?.toLowerCase() }}</span>
						<div v-if="item.price" class="inline text-xs">
							<span :class="[{ 'line-through': !!item.discount }]">
								({{ convertToCurrency(item.price) }})
							</span>
						</div>
					</div>
					<span v-if="!item.hidePrice" class="text-m2">
						{{ item.totalPriceCurrency }}
						<span v-html="item.currency ?? currency"></span>
						{{ typeof item.totalPrice === 'number' ? `${convertToCurrency(item.totalPrice)}` : '' }}
					</span>
				</div>
			</ion-item>
		</div>
		<div class="flex px-6 py-4 items-center justify-between">
			<span :class="props.textClass" class="font-sourcesanspro-semibold text-l2">{{ props.totalTitle }}</span>
			<span v-if="!props.totalPriceString" :class="props.textClass" class="font-sourcesanspro-semibold text-l2">
				&euro;{{ convertToCurrency(props.totalPrice) }}
			</span>
			<span v-if="props.totalPriceString" :class="props.textClass" class="font-sourcesanspro-semibold text-l2">
				{{ props.totalPriceString }}
			</span>
		</div>
		<div v-if="props.message && props.message.text" class="flex flex-col mx-6 mb-6 px-3 py-2 bg-white/5 rounded-md">
			<span class="font-sourcesanspro-semibold text-sm2">
				{{ props.message.title }}
			</span>
			<span class="font-sourcesanspro-regular text-xs2">
				{{ props.message.text }}
			</span>
		</div>
	</ion-list>
</template>

<style lang="scss" scoped>
ion-item {
	--background: transparent;
	--inner-padding-end: 0;
	--min-height: 30px;
}
</style>
