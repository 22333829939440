<script setup lang="ts">
import { watch } from 'vue';
import router from '@/router';
import { IonButton, IonContent, IonIcon } from '@ionic/vue';
import * as Yup from 'yup';
import { chevronBackOutline, lockClosedOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import hexRgb from 'hex-rgb';
import rgbHex from 'rgb-hex';
import TextInput from '@/shared/components/sign-up-forms/TextInput.vue';
import TopBar from '@/shared/components/TopBar.vue';
import useCustomForm from '@/shared/composables/useCustomForm';
import useUtils from '@/shared/composables/useUtils';

// Init
const { t } = useI18n();
const { calculateShade } = useUtils();
const primary_color = window.primary_color;
const primaryRgb = hexRgb(window.primary_color, { format: 'array' });
const primaryShade = '#' + rgbHex(calculateShade(primaryRgb.toString(), 0.2));

// Using yup to generate a validation schema
const schema = Yup.object().shape({
	password: Yup.string().min(6).required(),
	new_password: Yup.string().min(6).required(),
	new_password_confirmation: Yup.string()
		.required()
		.oneOf([Yup.ref('new_password')], t('profile.password_reset.errors.passwords_do_not_match')),
});

// useCustomForm
const { isFormValid, isSuccess, onSubmit, onInputChange } = useCustomForm(
	schema,
	'/profile/change-password',
	'post',
	'profile.password_change.messages.password_changed',
	'profile.password_change.errors.error',
	5000,
);

watch(isSuccess, async () => {
	await router.push('/profile');
});
</script>

<template>
	<ion-content :style="`background: linear-gradient(${primary_color}, ${primaryShade});`">
		<div class="flex flex-col h-full justify-between">
			<div class="px-[34px] w-full">
				<TopBar>
					<template #left>
						<div class="flex items-center justify-start cursor-pointer">
							<router-link to="/profile" class="flex items-center justify-center">
								<ion-icon :icon="chevronBackOutline" class="text-white"></ion-icon>
							</router-link>
						</div>
					</template>

					<template #center>
						<div class="flex justify-center w-full font-sourcesanspro-semibold text-l2">
							{{ $t('profile.password_reset.title') }}
						</div>
					</template>
				</TopBar>

				<div class="relative mb-4">
					<form id="changePasswordForm" :validation-schema="schema" @submit="onSubmit">
						<TextInput
							wrapper-class="flex flex-wrap justify-left item-center mb-2 border border-solid focus-within:background-white-15"
							:iconLeft="lockClosedOutline"
							name="password"
							type="password"
							:placeholder="$t('profile.password_reset.current_password')"
							@inputChanged="onInputChange" />

						<TextInput
							wrapper-class="flex flex-wrap justify-left item-center mb-2 border border-solid focus-within:background-white-15"
							name="new_password"
							type="password"
							:placeholder="$t('profile.password_reset.new_password')"
							@inputChanged="onInputChange" />

						<TextInput
							wrapper-class="flex flex-wrap justify-left item-center mb-2 border border-solid focus-within:background-white-15"
							name="new_password_confirmation"
							type="password"
							:placeholder="$t('profile.password_reset.confirm_password')"
							@inputChanged="onInputChange" />
					</form>
				</div>
			</div>

			<div class="px-[34px] mb-[34px]">
				<ion-button
					class="w-full hover:bg-white hover:rounded-full"
					:disabled="!isFormValid"
					expand="full"
					form="changePasswordForm"
					size="large"
					shape="round"
					type="submit">
					<div class="flex w-full items-center justify-center">
						{{ $t('profile.password_reset.change_password') }}
					</div>
				</ion-button>
			</div>
		</div>
	</ion-content>
</template>

<style lang="scss" scoped>
ion-list {
	background: transparent;
}
</style>
