<script setup lang="ts"></script>

<template>
	<div class="fixed gradient-container h-[150px] px-[34px] bottom-0 w-full"></div>
</template>

<style lang="scss" scoped>
.gradient-container {
	z-index: 1;
}

.gradient-container::after {
	background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(23, 22, 30, 1));
	bottom: 0;
	content: '';
	height: 150px;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 0;
}
</style>
