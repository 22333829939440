<script setup lang="ts">
import { IonIcon } from '@ionic/vue';

const props = defineProps({
	iconUrl: { type: String },
	imageUrl: { type: String },
	title: { type: String, required: true },
});
</script>

<template>
	<div class="flex col-start-1 font-sourcesanspro-semibold text-sm relative" style="grid-column-end: -1">
		<div class="sticky flex items-center left-0 uppercase">
			<img
				v-if="iconUrl || imageUrl"
				class="max-w-[1rem] max-h-[1rem] mr-1 -rotate-90 invert"
				:src="iconUrl || imageUrl" />
			{{ props.title }}
		</div>
	</div>
</template>

<style scoped></style>
