import { SignInWithApple, SignInWithAppleResponse, SignInWithAppleOptions } from '@capacitor-community/apple-sign-in';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { FacebookLoginResponse, FacebookLogin } from '@capacitor-community/facebook-login';

import authApi from '@/api/authApi';
import type { SocialProvider } from '@/shared/interfaces/user';
import { TenantStyles } from '@/shared/interfaces/tenant';

const APPLE_CLIENT_ID = process.env.VUE_APP_APPLE_CLIENT_ID!;
const APPLE_REDIRECT_URL = process.env.VUE_APP_APPLE_REDIRECT_URL!;
const centralUrl = window.centralUrl;

const handleNativeSocialLogin = async (type: SocialProvider = 'facebook'): Promise<string | null | undefined> => {
	switch (type) {
		case 'facebook': {
			return await _signInWithFacebook();
		}
		case 'google': {
			return await _signInWithGoogle();
		}
		case 'apple': {
			return await _signInWithApple();
		}
	}
};

const _signInWithFacebook = async () => {
	const result: FacebookLoginResponse = await FacebookLogin.login({
		permissions: ['email', 'public_profile'],
	});

	if (result?.accessToken) {
		return await _nativeAppLogin('facebook', result.accessToken.token);
	} else {
		// this.utilsService.showCommonError('Something went wrong while authenticating with Facebook');
	}
};

const _signInWithGoogle = async () => {
	const { authentication } = await GoogleAuth.signIn();
	if (authentication.accessToken) {
		return await _nativeAppLogin('google', authentication.accessToken);
	} else {
		return null;
		// this.utilsService.showCommonError('Something went wrong while authenticating with Facebook');
	}
};

const _signInWithApple = async () => {
	const options: SignInWithAppleOptions = {
		clientId: APPLE_CLIENT_ID,
		redirectURI: APPLE_REDIRECT_URL,
		scopes: 'email, name',
		state: '12345',
		nonce: 'nonce',
	};

	return await SignInWithApple.authorize(options)
		.then(async (result: SignInWithAppleResponse) => {
			// Handle user information
			// Validate token with server and create new session
			return await _nativeAppLogin(
				'apple',
				result.response?.identityToken,
				null,
				result.response?.givenName,
				result?.response?.familyName,
			);
		})
		.catch((error) => {
			// Handle error
			return null;
		});
};

const _nativeAppLogin = async (
	type: SocialProvider,
	accessToken: string,
	secret?: string | null,
	firstname?: string | null,
	lastname?: string | null,
) => {
	try {
		const response = await _nativeSocialAppLogin(type, accessToken, secret, firstname, lastname);

		if (response?.accessToken) {
			return response.accessToken;
		}
	} catch (e) {
		// this.utilsService.showCommonApiError(e);
		return null;
	}
};

const _nativeSocialAppLogin = async (
	provider: SocialProvider,
	token: string,
	secret?: string | null,
	firstname?: string | null,
	lastname?: string | null,
): Promise<{ accessToken: string }> => {
	const url = `/auth/social/app/${provider}`;

	let body: { token: string; secret?: string; firstname?: string; lastname?: string } = { token };

	if (secret) {
		body = { ...body, secret };
	}

	if (firstname) {
		body = { ...body, firstname };
	}

	if (lastname) {
		body = { ...body, lastname };
	}

	const { data } = await authApi.post<{ accessToken: string }>(url, body);

	return data;
};

const handleWebSocialLogin = async (type: SocialProvider = 'facebook', tenantStyles: TenantStyles) => {
	// if (type === 'google' && this.browserService.isEmbeddedBrowser) {
	//   const alert = await this.alertController.create({
	//     subHeader: 'Your browser does not support Google Login',
	//     message: 'Please open this app in <b>Chrome</b> or <b>Safari</b>',
	//     buttons: [{ text: 'OK' }],
	//   });

	//   await alert.present();
	//   return;
	// }

	const redirectUrl = window.encodeURIComponent(window.location.href);
	const failedUrl = window.encodeURIComponent(window.location.href) + '?failed=true';

	location.href = `${centralUrl}/auth/social/${type}/${tenantStyles.id}?redirect_url=${redirectUrl}&failed_url=${failedUrl}`;
};

const useAuthHelpers = () => {
	return {
		handleNativeSocialLogin,
		handleWebSocialLogin,
	};
};

export default useAuthHelpers;
