import type { TenantConfig } from '@/shared/interfaces/tenant';

const _formatNumber = (value: number) => {
	const formatter = new Intl.NumberFormat('en-US', {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
	return formatter.format(value).replace(',', '');
};

const convertValutaSync = (tenantConfig: TenantConfig, value: any, euro_to_valuta: boolean, includeTopUp?: any) => {
	value = value.toString().replace(/,/, '.');
	let convertedValue = parseFloat(value);

	if (tenantConfig.custom_valuta_enabled && euro_to_valuta) {
		convertedValue = value / +tenantConfig.custom_valuta.conversion_rate;
	} else if (tenantConfig.custom_valuta_enabled) {
		convertedValue = value * +tenantConfig.custom_valuta.conversion_rate;
	}

	if (includeTopUp) {
		convertedValue = convertedValue + parseFloat(includeTopUp);
	}

	// formerly
	// const pipe = new DecimalPipe('en_US');
	// return pipe.transform(convertedValue, '1.2-2').toString().replace(/,/, '');

	const formattedNumber = _formatNumber(convertedValue);
	return formattedNumber;
};

const useConvertValuta = () => {
	return {
		convertValutaSync,
	};
};

export default useConvertValuta;
