import { useQuery } from '@tanstack/vue-query';

import { anykrowdApi } from '@/api/anykrowdApi';
import type { Tag } from '@/shared/interfaces/tag';

const getTags = async (): Promise<Tag[]> => {
	return anykrowdApi.get<Tag[]>('/tags/list?page=-1').then((res) => {
		return res.data;
	});
};

const updateTags = async (tags: { tags: number[] }): Promise<Tag[]> => {
	const url = '/tags/update-for-user';
	return await anykrowdApi.post<Tag[]>(url, tags, true).then((result) => {
		return result.data;
	});
};

const changeTagState = async (tags: { [key: number]: boolean }): Promise<Tag[]> => {
	return await anykrowdApi.post<Tag[]>('/tags/sync-present', { tags }, true).then((result) => {
		return result.data;
	});
};

const useTags = () => {
	const { isLoading, isError, data: tagsList, error } = useQuery(['tagsList'], getTags);

	return {
		changeTagState,
		error,
		getTags,
		isError,
		isLoading,
		tagsList,
		updateTags,
	};
};

export default useTags;
