<script setup lang="ts">
import { ref } from 'vue';
import {
	IonButton,
	IonContent,
	IonIcon,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonRefresher,
	IonRefresherContent,
	IonSpinner,
	InfiniteScrollCustomEvent,
} from '@ionic/vue';
import { chevronBackOutline } from 'ionicons/icons';
import { DateTime } from 'luxon';
import { useI18n } from 'vue-i18n';

import { RefundRequestResource } from '@/shared/interfaces/refundRequest';
import { RefundRequestStatus } from '@/shared/interfaces/refundRequest';
import AccordionList from '@/shared/components/ui/accordionlist/AccordionList.vue';
import AccordionListItem from '@/shared/components/ui/accordionlist/AccordionListItem.vue';
import AccordionListItemAction from '@/shared/components/ui/accordionlist/AccordionListItemAction.vue';
import hexRgb from 'hex-rgb';
import rgbHex from 'rgb-hex';
import TopBar from '@/shared/components/TopBar.vue';
import useTenant from '@/shared/composables/useTenant';
import useUtils from '@/shared/composables/useUtils';
import useWallet from '@/modules/wallet/composables/useWallet';
import { useRoute } from 'vue-router';

// Init
const { t } = useI18n();
const route = useRoute();
const walletId = route.params.wallet_id?.toString();
const { getTenantConfigAndStyles } = useTenant();
const { tenantConfig } = getTenantConfigAndStyles();
const { cancelRefund, getRefundRequests, getRefundInfo } = useWallet();
const {
	isFetchingWalletRefundRequests,
	isLoadingRefundRequests,
	refetchWalletRefundRequests,
	removeWalletRefundRequestsFromCache,
	walletRefundRequests,
	fetchNextPage,
	hasNextPage,
} = getRefundRequests(walletId);
const { refundInfo } = getRefundInfo(walletId);
const { calculateShade } = useUtils();
const primary_color = window.primary_color;
const primaryRgb = hexRgb(window.primary_color, { format: 'array' });
const primaryShade = '#' + rgbHex(calculateShade(primaryRgb.toString(), 0.2));
const cancelRefundRequested = ref(false);

// Refresh
const handleRefresh = async (event: any) => {
	removeWalletRefundRequestsFromCache();
	walletRefundRequests.value = [];
	await refetchWalletRefundRequests();
	event.target.complete();
};

// Infinite scroll
const ionInfinite = async (ev: InfiniteScrollCustomEvent) => {
	if (hasNextPage && hasNextPage.value) {
		await fetchNextPage();
	}
	ev.target.complete();
};

const requestCancelRefund = (refundId: string | number) => {
	cancelRefund(refundId).then(() => {
		cancelRefundRequested.value = true;
		refetchWalletRefundRequests();
	});
};
</script>

<template>
	<ion-content :style="`background: linear-gradient(${primary_color}, ${primaryShade});`">
		<ion-refresher class="mt-8" slot="fixed" @ionRefresh="handleRefresh($event)">
			<ion-refresher-content></ion-refresher-content>
		</ion-refresher>

		<div class="flex flex-col h-full justify-between">
			<div class="px-[34px] w-full">
				<TopBar>
					<template #left>
						<div class="flex items-center justify-start cursor-pointer">
							<router-link to="/dashboard" class="flex items-center justify-center">
								<ion-icon :icon="chevronBackOutline" class="text-white"></ion-icon>
							</router-link>
						</div>
					</template>

					<template #center>
						<div class="flex justify-center w-full font-sourcesanspro-semibold text-l2">
							{{ t('wallet.refund.title') }}
						</div>
					</template>
				</TopBar>

				<div class="flex flex-col items-center">
					<div class="mb-2 font-sourcesanspro-regular text-regular text-white/60">
						<div
							v-if="refundInfo && refundInfo.refund_message"
							v-dompurify-html="refundInfo.refund_message"
							class="flex mx-[34px] p-4 mb-2 bg-white/10 rounded-lg"></div>
					</div>
					<router-link :to="`/wallet/refunds/${walletId}/request`" class="w-full">
						<ion-button
							class="w-full mb-3 hover:bg-white hover:rounded-full"
							expand="full"
							size="large"
							shape="round">
							<div class="flex w-full items-center justify-center">
								{{ t('wallet.refund.new') }}
							</div>
						</ion-button>
					</router-link>
					<div class="max-w-[220px] mb-[34px] font-sourcesanspro-regular text-sm2 text-center text-white/60">
						{{ t('wallet.refund.top_warning_message', { days: 14 }) }}
					</div>
				</div>

				<div class="mb-4">
					<AccordionList :title="t('wallet.refund.requests')">
						<template #items>
							<div
								v-if="
									!isLoadingRefundRequests &&
									walletRefundRequests &&
									walletRefundRequests.length === 0
								"
								class="flex items-center justify-left p-4">
								<span class="font-sourcesanspro-light text-regular text-white/40">
									{{ t('wallet.refund.no_entries') }}
								</span>
							</div>

							<!-- Father item -->
							<div v-if="walletRefundRequests">
								<AccordionListItem
									v-for="(item, idx) in walletRefundRequests.map(
										(refund: RefundRequestResource, index) => {
											return {
												date: refund.created_at,
												id: index.toString(),
												refundId: refund.id,
												title: t('wallet.refund.item_title', { status: refund.status }),
												iconClass: 'text-white',
												info: refund.info,
												price: refund.fiat_amount,
												customSubtitle: refund.status,
												tag: refund.status,
												transactionType:
													refund.status !== RefundRequestStatus.FAILED ? 'CREDIT' : 'DEBIT',
											};
										},
									)"
									:key="idx"
									:key-value="item.id"
									:icon="require('@/statics/svg/profile/refund.svg')"
									:iconClass="item.iconClass"
									:tag="item.tag"
									:transaction-type="item.transactionType"
									:title="item.title">
									<template #actions>
										<AccordionListItemAction
											v-if="tenantConfig"
											:date="DateTime.fromISO(item.date).toFormat('dd LLLL yyyy • HH:mm')"
											:hide-transaction-sign="true"
											:price="+item.price"
											:transaction-type="item.transactionType"
											:tenant-config="tenantConfig" />
									</template>
									<template #content>
										<div
											v-if="item.info"
											class="p-4 bg-white/10 font-sourcesanspro-regular text-sm">
											{{ item.info }}
										</div>
										<div
											v-if="item.customSubtitle === 'pending' && item.refundId != null"
											class="p-4 bg-white/10 font-sourcesanspro-regular text-sm">
											<ion-button
												:disabled="cancelRefundRequested"
												@click="requestCancelRefund(item.refundId!)"
												color="primary"
												form="signInWithEmailForm"
												shape="round"
												size="large"
												type="submit">
												{{ $t('wallet.refund.cancel') }}
											</ion-button>
										</div>
									</template>
								</AccordionListItem>
							</div>

							<div
								class="flex p-4 items-center justify-center"
								v-if="isLoadingRefundRequests || isFetchingWalletRefundRequests">
								<ion-spinner name="dots"></ion-spinner>
							</div>
						</template>
					</AccordionList>
				</div>
			</div>
		</div>

		<ion-infinite-scroll @ionInfinite="ionInfinite">
			<ion-infinite-scroll-content></ion-infinite-scroll-content>
		</ion-infinite-scroll>
	</ion-content>
</template>

<style lang="scss" scoped>
ion-list {
	background: transparent;
}
</style>
