<script setup lang="ts">
import { toRefs } from 'vue';
import { useField } from 'vee-validate';
import { IonCheckbox } from '@ionic/vue';

const props = defineProps({
	id: String,
	class: String,
	containerClass: String,
	name: String,
});

// we are using toRefs to create reactive references to `name` and `value` props
// this is important because vee-validte needs to know if any of these props change
// https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
const { name } = toRefs(props);
// eslint-disable-next-line vue/no-dupe-keys
const { handleChange, value } = useField(name);

const swapCheckbox = () => {
	handleChange(!value.value);
};
</script>

<template>
	<div class="flex flex-wrap justify-left item-center border-white-15 mb-2">
		<div class="flex mb-2" :class="props.containerClass" lines="none">
			<input
				:id="props.id"
				type="checkbox"
				class="mr-4"
				:class="props.class"
				:name="name"
				@click="swapCheckbox()"
				:value="value"
				:checked="value === true ? true : false" />
			<div>
				<slot />
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
input[type='checkbox'] {
	transform: scale(1.5);
}
div {
	width: 100%;

	p {
		width: 325px;

		&::first-letter {
			text-transform: capitalize;
		}
	}

	ion-checkbox {
		--size: 20px;
		min-width: 20px;

		&::part(container) {
			border-radius: 6px;
		}
	}
}
</style>
