<script setup lang="ts">
import { ref } from 'vue';
import { IonContent, IonFooter, IonButton, IonIcon, IonSearchbar } from '@ionic/vue';
import BookingsReservationsItem from '@/modules/bookings-reservations/components/BookingsReservationsItem.vue';
import NavigationBar from '@/shared/components/ui/NavigationBar.vue';
import { close, search } from 'ionicons/icons';

const showSearch = ref(false);
</script>

<template>
	<ion-content>
		<div class="flex flex-col items-center px-[34px] pt-16">
			<div v-show="!showSearch">
				<div class="flex justify-between">
					<div class="w-[20px]"></div>
					<div>
						<ion-icon
							:icon="require('@/statics/svg/dashboard/anykrowd-logo.svg')"
							class="w-[100px] h-[100px]"></ion-icon>
					</div>
					<div class="flex items-start">
						<ion-icon :icon="search" class="w-[20px] h-[20px]" @click="showSearch = true"></ion-icon>
					</div>
				</div>

				<div class="flex items-center justify-center mb-6">
					<ion-button
						class="mr-2 w-[140px] h-[40px] backdrop-blur bg-white/20 rounded-full"
						shape="round"
						fill="clear">
						<span class="text-white text-base2">All Bookings</span>
					</ion-button>
					<ion-button class="w-[140px] h-[40px]" shape="round">
						<span class="text-base2">My Bookings</span>
					</ion-button>
				</div>
			</div>

			<div v-show="showSearch">
				<ion-searchbar
					class="mb-4 backdrop-blur bg-white/20 rounded-full font-sourcesanspro-semibold text-l"
					shape="round"
					@ionBlur="showSearch = false"
					@ionCancel="showSearch = false"
					v-show="showSearch"></ion-searchbar>
				<div class="flex flex-col mb-2">
					<div class="mb-4">
						<span class="font-sourcesanspro-semibold text-base2">Autocomplete Suggestions</span>
					</div>
					<div class="flex flex-col items-start justify-center font-sourcesanspro-regular text-m2">
						<div class="flex items-center mb-4 font-color-text-50">
							<ion-icon class="mr-2" :icon="close"></ion-icon>
							<div>
								<span class="text-white"><b>Boum</b></span> <span>De Noël 2022</span>
							</div>
						</div>
						<div class="flex items-center mb-4 font-color-text-50">
							<ion-icon class="mr-2" :icon="close"></ion-icon>
							<div>
								<span>Bart Peeters & De</span> <span class="text-white"><b>Boum</b></span
								><span>s Mannen</span>
							</div>
						</div>
					</div>
				</div>
			</div>

			<BookingsReservationsItem
				:accepted="true"
				:image="require('@/statics/svg-delete-after-implement/bookings-reservations-image-1.svg')"
				:price-original="38.99"
				:price="25.99"
				time="15 mins ago"
				description="Quisque pretium malesuada justo, nec maximus lacus efficitur nec."
				hour="08:00pm"
				link="/bookings-reservations/1"
				price-currency="€"
				title="VIP DISPLAY" />

			<BookingsReservationsItem
				:image="require('@/statics/svg-delete-after-implement/bookings-reservations-image-2.svg')"
				:price-original="38.99"
				:price="25.99"
				time="15 mins ago"
				hour="08:00pm"
				description="Quisque pretium malesuada justo, nec maximus lacus efficitur nec."
				link="/bookings-reservations/2"
				location="Ancienne Belgieque, Brussels"
				price-currency="€"
				title="VIP DISPLAY" />

			<div class="h-[100px]">&nbsp;</div>
		</div>
	</ion-content>

	<ion-footer>
		<NavigationBar item-active="bookings-reservations" />
	</ion-footer>
</template>

<style lang="scss" scoped></style>
