export function isCryptoSubtleSupported() {
	return window.crypto && window.crypto.subtle;
}

export async function hashString(hash: string) {
	if (!isCryptoSubtleSupported()) {
		return false;
	}
	const encoder = new TextEncoder();
	const data = encoder.encode(hash);
	const hashBuffer = await crypto.subtle.digest('SHA-256', data);
	const hashArray = Array.from(new Uint8Array(hashBuffer));
	const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
	return hashHex;
}
