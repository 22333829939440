<script setup lang="ts">
import vue, { watch } from 'vue';
import router from '@/router';
import * as yup from 'yup';
import { Form, useForm } from 'vee-validate';
import { mailOutline, lockClosedOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import { useSharedStore } from '@/stores/shared/sharedStore';
import TextInput from '@/shared/components/sign-up-forms/TextInput.vue';
import useAuth from '@/modules/auth/composables/useAuth';

// Init
const { t } = useI18n();
const emit = defineEmits(['onFormValidation']);
const { loginUser, loginData, isSuccessLogin } = useAuth();
const sharedStore = useSharedStore();

// Schema
const validationSchema = yup.object({
	email: yup
		.string()
		.required()
		.label(t('auth.sign_in_email_page.email_label'))
		.matches(sharedStore.EMAIL_REGX, t('auth.sign_in_email_page.errors.email')),
	password: yup.string().min(6).label(t('auth.sign_in_email_page.password_label')).required(),
});

const { handleSubmit, meta, validateField } = useForm({
	validationSchema: validationSchema,
});

// Send form
const onSubmit = handleSubmit(async (formData: any) => {
	await loginUser({
		userData: {
			...formData,
			rememberMe: true,
		},
		message: t('general.please_wait'),
	});
});

// Email login: Listen for change
watch(isSuccessLogin, () => {
	if (isSuccessLogin.value && loginData.value) {
		// Finally, redirect the user if we have a token
		router.push('/profile/additionaldata');
	}
});

// Validating form
const onInputChange = async (formValid: vue.ComputedRef<boolean>, fieldName: any) => {
	await validateField(fieldName.value);
	emit('onFormValidation', meta.value.valid);
};
</script>

<template>
	<form id="signInWithEmailForm" @submit="onSubmit" :validation-schema="validationSchema">
		<TextInput
			wrapper-class="flex flex-wrap justify-left item-center mb-2 border border-solid focus-within:background-white-15"
			:iconLeft="mailOutline"
			name="email"
			type="email"
			:placeholder="$t('auth.sign_in_email_page.email')"
			@inputChanged="onInputChange" />

		<TextInput
			wrapper-class="flex flex-wrap justify-left item-center mb-2 border border-solid focus-within:background-white-15"
			:iconLeft="lockClosedOutline"
			name="password"
			type="password"
			:placeholder="$t('auth.sign_in_email_page.password')"
			@inputChanged="onInputChange" />

		<div class="font-sourcesanspro-semibold text-base text-right">
			<router-link to="/profile/password/reset/request">
				{{ $t('auth.sign_in_email_page.forgot_password') }}
			</router-link>
		</div>
	</form>
</template>

<style lang="scss" scoped></style>
