<script setup lang="ts">
import { IonContent, IonModal } from '@ionic/vue';

const props = defineProps({
	modalId: { type: String },
	isOpen: { type: Boolean, required: true },
});
</script>

<template>
	<ion-modal
		:is-open="props.isOpen"
		:trigger="props.modalId"
		:initial-breakpoint="0.95"
		:backdrop-dismiss="false"
		handle-behavior="cycle">
		<ion-content class="ion-padding">
			<div class="ion-margin-top text-anykrowd-dark">
				<slot />
			</div>
		</ion-content>
	</ion-modal>
</template>

<style lang="scss" scoped>
ion-modal {
	--height: 100%;
}
ion-content {
	background: white;
}
</style>
