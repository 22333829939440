<script setup lang="ts">
import router from '@/router';
import { IonContent, IonFooter, IonIcon, IonRefresher, IonRefresherContent, IonSpinner } from '@ionic/vue';
import { useRoute } from 'vue-router';
import { chevronBackOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import NavigationBar from '@/shared/components/ui/NavigationBar.vue';
import TimeTable from '@/modules/timetables/components/timetable/TimeTable.vue';
import TopBar from '@/shared/components/TopBar.vue';
import useTimeTables from '@/modules/timetables/composables/useTimeTables';
import useUserProfile from '@/modules/profile/composables/useUserProfile';

// Init
const { t } = useI18n();
const route = useRoute();
const timeTableId = +route.params.id;
const { getUserProfile } = useUserProfile();
const { userProfile } = getUserProfile();

const { getTimeTable, getTimeTables } = useTimeTables();
const { timeTables } = getTimeTables();
const { isFetchedTimeTable, refetchTimeTable, removeTimeTableDataFromCache, timeTable } = getTimeTable(
	timeTableId,
	userProfile.value?.id,
);

// Refresh
const handleRefresh = async (event: any) => {
	removeTimeTableDataFromCache();
	timeTable.value = undefined;
	await refetchTimeTable();
	event.target.complete();
};

// Go back
const goBack = () => {
	router.back();
};
</script>

<template>
	<ion-content class="flex">
		<ion-refresher class="mt-8" slot="fixed" @ionRefresh="handleRefresh($event)">
			<ion-refresher-content></ion-refresher-content>
		</ion-refresher>

		<TopBar class="px-[34px]">
			<template #left>
				<div v-if="timeTables && timeTables.length > 1" class="flex items-center justify-start cursor-pointer">
					<router-link to="/timetables" class="flex items-center justify-center">
						<ion-icon :icon="chevronBackOutline" class="text-white"></ion-icon>
					</router-link>
				</div>
				<span v-else></span>
			</template>

			<template #center>
				<div v-if="timeTable" class="flex justify-center w-full font-sourcesanspro-semibold text-l2">
					{{ timeTable.name }}
				</div>
			</template>
		</TopBar>

		<div class="flex items-center justify-center" v-if="!isFetchedTimeTable">
			<ion-spinner name="dots"></ion-spinner>
		</div>
		<div v-if="timeTable" class="flex flex-col px-[34px]">
			<div class="mb-[100px]">
				<!-- Timetable -->
				<TimeTable :timeTable="timeTable" />
			</div>
		</div>
	</ion-content>

	<ion-footer>
		<NavigationBar item-active="timetables" />
	</ion-footer>
</template>

<style lang="scss" scoped></style>
