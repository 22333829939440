<script setup lang="ts">
import { ref } from 'vue';
import { useSpring, useMotionProperties } from '@vueuse/motion';
import { IonIcon } from '@ionic/vue';
import { closeOutline } from 'ionicons/icons';

// Propes
const props = defineProps({
	fullScreenImage: { type: String },
});

// Type
type MotionPropertiesCustom = {
	cursor: string;
	x: number;
	y: number;
};

// Init
const imageRef = ref(); // target image to apply the pinch gesture
const accumulated = ref(0);
const emit = defineEmits(['onCloseImage']);

// Bind to the element or component reference
// and init style properties that will be animated.
const { motionProperties } = useMotionProperties(imageRef, {
	cursor: 'grab',
	x: 0,
	y: 0,
});

const { set } = useSpring(motionProperties as MotionPropertiesCustom, {
	duration: 10,
});

const pinchHandler = ({ movement: [d, a] }: { movement: [number, number] }) => {
	accumulated.value += d / 30000;
	accumulated.value = accumulated.value <= 1 ? 1 : accumulated.value * 1.0;

	set({ scale: accumulated.value, offset: 1 });
};

const closeImage = () => {
	emit('onCloseImage');
};
</script>

<template>
	<div
		class="fixed flex top-0 left-0 items-center justify-center w-[100%] h-[100%] bg-black overflow-scroll max-w-none z-20">
		<div class="flex items-center justify-start cursor-pointer" @click="closeImage">
			<ion-icon
				class="fixed top-8 left-8 text-[38px] text-white cursor-pointer z-20"
				:icon="closeOutline"></ion-icon>
		</div>
		<img
			:src="props.fullScreenImage"
			alt=""
			class="max-w-[100%] scale-100 z-10"
			ref="imageRef"
			v-pinch="pinchHandler" />
	</div>
</template>
