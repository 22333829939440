import { ref, watch } from 'vue';
import { useMutation } from '@tanstack/vue-query';

import { anykrowdApi } from '@/api/anykrowdApi';
import type { FreeTickets, Voucher, VoucherMetaData } from '@/shared/interfaces/voucher';

// Redeem voucher
const _redeemVoucher = async (voucher: string): Promise<any> => {
	let voucherMetaData: VoucherMetaData;
	let voucherRedeemedItems: FreeTickets[];
	let error = <string | null>null;
	let msg = <string | null>null;

	// Send POST
	return await anykrowdApi
		.post('/voucher/redeem', { voucher_code: voucher })
		.then(({ data }) => {
			const voucherResult: Voucher = data;

			if (data.error) {
				if (data.error === 'invalid_voucher_code') {
					error = 'top_up.redeem_voucher.errors.invalid_voucher';
				} else if (data.error === 'voucher_pending') {
					error = 'top_up.redeem_voucher.errors.voucher_pending';
				} else {
					error = 'top_up.redeem_voucher.errors.voucher_configuration_issue';
				}
			}

			if (voucherResult.voucher_meta_data) {
				voucherMetaData = voucherResult.voucher_meta_data;
			}

			if (voucherMetaData && voucherMetaData['status'] === 'pending') {
				error = 'top_up.redeem_voucher.voucher_redeemed_pending';
			} else if (voucherResult.redeemed_items) {
				voucherRedeemedItems = Object.values(voucherResult.redeemed_items);
				msg = 'top_up.redeem_voucher.voucher_redeemed';
			}

			return {
				error,
				msg,
				voucherMetaData,
				voucherRedeemedItems,
			};
		})
		.catch((res) => {
			if (res.response.data.error === 'voucher_used') {
				error = 'top_up.redeem_voucher.errors.voucher_used';
			} else if (res.response.data.error === 'voucher_expired') {
				error = 'top_up.redeem_voucher.errors.voucher_expired';
			} else if (res.response.data.error === 'voucher_configuration_issue') {
				error = 'top_up.redeem_voucher.errors.voucher_configuration_issue';
			}

			return {
				error,
				msg,
				voucherMetaData,
				voucherRedeemedItems,
			};
		});
};

const useVoucher = () => {
	const voucherText = ref('');

	const {
		data: voucherData,
		error: errorVoucher,
		isLoading: isLoadingVoucher,
		isSuccess: isSuccessVoucher,
		mutate: redeemVoucher,
	} = useMutation(_redeemVoucher);

	return {
		errorVoucher,
		isLoadingVoucher,
		redeemVoucher,
		voucherData,
		voucherText,
	};
};

export default useVoucher;
