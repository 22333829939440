<script setup lang="ts">
import { PropType, ref } from 'vue';
import type { HorizontalTimePickerItem } from '@/shared/interfaces/horizontalTimePicker';

const props = defineProps({
	items: {
		type: Array as PropType<HorizontalTimePickerItem[]>,
		default: function () {
			return [];
		},
	},
});

const activeItem = ref(-1);

const getClass = (enabled: boolean, index: number) => {
	return {
		'text-white/20': !enabled && activeItem.value !== index,
		'text-white': enabled && activeItem.value !== index,
		'text-dark-blue': activeItem.value === index,
	};
};

const getContainerClass = (enabled: boolean, index: number) => {
	return {
		'ml-[34px]': index === 0,
		'border-white/20': !enabled && activeItem.value !== index,
		'border-white/50': enabled && activeItem.value !== index,
		'bg-white': activeItem.value === index,
		'cursor-pointer': enabled,
	};
};

const setActive = (enabled: boolean, index: number) => {
	if (enabled) {
		activeItem.value = index;
	}
};
</script>

<template>
	<div
		v-for="(item, index) in items"
		:key="item.hour"
		:class="getContainerClass(item.enabled, index)"
		@click="setActive(item.enabled, index)"
		class="flex flex-col justify-center items-center mr-[12px] w-[99px] h-[44px] rounded-full border">
		<div class="flex items-center">
			<div class="mr-1 leading-none">
				<span :class="getClass(item.enabled, index)" class="font-sourcesanspro-semibold text-l2">
					{{ item.hour }}
				</span>
			</div>
			<span :class="getClass(item.enabled, index)" class="font-sourcesanspro-regular text-sm2 leading-none">{{
				item.period
			}}</span>
		</div>
	</div>
</template>

<style scoped></style>
