<script setup lang="ts">
import { computed, defineProps } from 'vue';
import { useI18n } from 'vue-i18n';

import { CartCategoriesEnum } from '@/enums/values.enum';
import { ShopProduct } from '@/shared/interfaces/shopProduct';
import { useCart } from '@/modules/shop/composables/useCart';

// Props
const props = defineProps({
	product: {
		type: Object as () => ShopProduct,
		required: true,
	},
});

// Init
const { t } = useI18n();
const { getProductCount, addToCart, decreaseProductQuantity } = useCart();

// Compute the product count based on the current product ID
const productCartCount = computed(() => getProductCount(props.product.id));
</script>

<template>
	<div class="fixed button-container h-[150px] px-[34px] left-1/2 -translate-x-1/2 bottom-0 w-full">
		<div class="relative z-10 w-full h-full">
			<div class="absolute w-full bottom-0 mb-[34px]">
				<div
					class="flex w-full justify-between items-center btn-container bg-white text-dark font-sourcesanspro-semibold text-l2 px-5 rounded">
					<span>{{ t('shop.cart.add_to_cart') }} </span>
					<div class="flex items-center justify-between w-[100px]">
						<button class="custom-button" id="custom-button" @click="decreaseProductQuantity(product.id)">
							<img src="@/statics/svg/subtract-2.svg" class="stroke-black fill-back" />
						</button>
						<span class="font-sourcesanspro-semibold text-lg">{{ productCartCount }}</span>
						<button
							class="custom-button"
							id="custom-button"
							@click="addToCart(product, CartCategoriesEnum.shop)">
							<img src="@/statics/svg/add-2.svg" class="stroke-black fill-back" />
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
.text-dark {
	color: #1e1e1e;
}
.btn-container {
	border-radius: 52px;
	height: 60px;
}
.custom-button {
	display: inline-flex; /* Make the button inline-flex to match its size to its content */
	padding: 0; /* Remove default padding */
	border: none; /* Remove default border */
	background: none; /* Remove default background */
	height: auto;
	width: auto;
}
.custom-button img {
	height: 100%;
	width: auto;
	display: block;
}
</style>
