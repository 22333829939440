<script setup lang="ts">
import { IonAccordionGroup, IonIcon } from '@ionic/vue';

const props = defineProps({
	class: { type: String },
	headerActionIcon: { type: String },
	title: { type: String, required: true },
});

const emit = defineEmits(['onHeaderActionClick']);
const headerActionClick = (event: MouseEvent) => {
	emit('onHeaderActionClick', event);
};
</script>

<template>
	<ion-accordion-group class="backdrop-blur-xl bg-white/10 rounded-lg" :class="props.class">
		<div class="flex p-4 items-center justify-between font-sourcesanspro-semibold text-m2 p-4">
			{{ props.title }}
			<ion-icon
				v-if="props.headerActionIcon"
				:icon="props.headerActionIcon"
				class="text-white cursor-pointer"
				@click="headerActionClick"></ion-icon>
		</div>
		<hr class="h-px bg-white/10 border-0" />
		<slot name="items" />
	</ion-accordion-group>
</template>

<style lang="scss" scoped></style>
