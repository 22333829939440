<script setup lang="ts">
import router from '@/router';
import QrcodeVue from 'qrcode.vue';
import { useI18n } from 'vue-i18n';

// Props
const props = defineProps({
	qrCode: { type: String },
	class: { type: String },
	link: { type: String, required: true, default: '/dashboard' },
	name: { type: String, required: true },
	type: { type: String, required: true },
	valueOnly: { type: Boolean, required: true, default: false },
	venue: { type: String, required: true },
});

// Init
const { t } = useI18n();

const goTo = async (link: string) => {
	await router.push(link);
};
</script>

<template>
	<div class="flex ticket-item w-[325px] h-[100px]" :class="props.class" @click="goTo(props.link)">
		<div class="flex p-3 w-[75px] items-center justify-center">
			<qrcode-vue v-if="!props.valueOnly" :value="props.qrCode" :level="'M'" :size="50" />
			<div class="flex flex-col text-xxs break-all text-dark-blue/70" v-else>
				<span><b>CODE</b></span>
				{{ props.qrCode }}
			</div>
		</div>
		<div class="flex grow flex-col p-3 text-dark-blue">
			<div>
				<span class="font-sourcesanspro-semibold text-base2">{{ props.name }}</span>
			</div>
			<div class="flex justify-between leading-none">
				<span class="font-sourcesanspro-bold text-xs2 text-dark-blue/70"
					>{{ t('components.ticket_item.type') }}:
				</span>
				<span class="font-sourcesanspro-regular text-xs2 text-dark-blue/70">{{ props.type }}</span>
			</div>
			<div class="flex justify-between items-baseline">
				<span class="font-sourcesanspro-bold text-xs2 text-dark-blue/70"
					>{{ t('components.ticket_item.venue') }}:
				</span>
				<span class="font-sourcesanspro-regular text-xs2 text-dark-blue/70 text-right leading-none">{{
					props.venue
				}}</span>
			</div>
		</div>
	</div>
</template>

<style scoped>
.ticket-item {
	background: url('@/statics/svg/ticket-item.svg') no-repeat;
	background-size: 100%;
	position: relative;
}
</style>
