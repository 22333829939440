<script setup lang="ts">
import router from '@/router';
import { IonContent, IonIcon } from '@ionic/vue';
import { useRoute } from 'vue-router';
import { closeOutline } from 'ionicons/icons';

const route = useRoute();
const callbackPage = route.query.callbackPage;

const goTo = async () => {
	await router.push({ name: callbackPage as string });
};
</script>

<template>
	<ion-content>
		<div class="flex flex-col h-full items-center justify-between">
			<div></div>
			<img src="@/statics/svg/qr-code/scan-frame.svg" @click="goTo" />
			<div class="flex flex-col items-center">
				<div class="mb-4"><span class="font-sourcesanspro-regular text-base2">Please scan QR code</span></div>
				<div class="flex items-center justify-center w-[60px] h-[60px] mb-[34px] rounded-full bg-white">
					<ion-icon
						class="text-[38px] text-black cursor-pointer"
						:icon="closeOutline"
						@click="goTo"></ion-icon>
				</div>
			</div>
		</div>
	</ion-content>
</template>

<style lang="scss" scoped>
ion-content {
	background: url('@/statics/svg-delete-after-implement/qr-code-background.svg') no-repeat center center / cover;
	background-position: top;
}
</style>
