import { Capacitor } from '@capacitor/core';

// buildRedirectUrl
const buildRedirectUrl = () => {
	let redirectUrl = '';
	if (Capacitor.isNativePlatform()) {
		try {
			const currentPath = window.location.pathname;
			const domain = new URL(window.apiUrl).origin;

			redirectUrl = `${domain}${currentPath}`;
		} catch (e) {
			redirectUrl = window.apiUrl.replace('api/v1', '/dashboard');
		}
	} else {
		redirectUrl = location.href;
	}

	if (!redirectUrl.includes('fromMollie')) {
		redirectUrl = `${redirectUrl}${redirectUrl.includes('?') ? '&' : '?'}fromMollie=true`;
	}

	return redirectUrl;
};

/**
 * Helper method that will extract the Query Params of an url and will place it into an object
 *
 * @param url that is passed by the user
 */
const extractUrlQueryParams = (url: string): Record<string, any> | null => {
	if (!isValidUrl(url)) {
		return null;
	}
	try {
		const [_, query] = url.split('?');
		const queryParams = Object.fromEntries(new URLSearchParams(query));
		return queryParams;
	} catch (e) {
		return null;
	}
};

/**
 * Helper method that will check whether the passed string is a valid url
 *
 * @param url
 */
const isValidUrl = (url: string): boolean => {
	if (!url) {
		return false;
	}

	let url_string;
	try {
		url_string = new URL(url);
	} catch (_) {
		return false;
	}

	return url_string.protocol === 'http:' || url_string.protocol === 'https:';
};

export const isValidSelfLinkingUrl = (url: string): boolean => {
	if (!isValidUrl(url)) {
		return false;
	}

	return url.includes('activate') || url.includes('c=') || url.includes('R=');
};

export const getTokenFromSelfLinkingUrl = (url: string): string | undefined | null => {
	if (!isValidSelfLinkingUrl(url)) {
		return;
	}

	if (url.includes('activate')) {
		const urlObj = new URL(url);
		const segments = urlObj.pathname.split('/').filter((segment) => segment !== '');

		const activateIdx = segments.findIndex((segment) => segment === 'activate');

		if (activateIdx > -1) {
			return segments[activateIdx + 1];
		}
	} else if (url.includes('c=')) {
		const urlObj = new URL(url);
		return urlObj.searchParams.get('c');
	} else if (url.includes('R=')) {
		const urlObj = new URL(url);
		return urlObj.searchParams.get('R');
	}

	return;
};

export const isValidMagicLinkUrl = (url: string): boolean => {
	if (!isValidUrl(url)) {
		return false;
	}

	return /\/magiclink\/\w+/.test(url);
};

export const getTokenFromMagicLinkUrl = (url: string): string | undefined => {
	if (!isValidMagicLinkUrl(url)) {
		return;
	}

	const urlObj = new URL(url);
	const segments = urlObj.pathname.split('/').filter((segment) => segment !== '');

	const magicLinkIdx = segments.findIndex((segment) => segment === 'magiclink');

	if (magicLinkIdx > -1) {
		return segments[magicLinkIdx + 1];
	}

	return;
};

const useUrlHelpers = () => {
	return {
		buildRedirectUrl,
		extractUrlQueryParams,
		getTokenFromMagicLinkUrl,
		getTokenFromSelfLinkingUrl,
		isValidUrl,
	};
};

export default useUrlHelpers;
