<script setup lang="ts">
import { onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { Capacitor } from '@capacitor/core';
import { IonButton, IonContent, IonIcon, IonSpinner } from '@ionic/vue';
import { chevronBackOutline, downloadOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import TicketDetailItem from '@/shared/components/tickets/TicketDetailItem.vue';
import TopBar from '@/shared/components/TopBar.vue';
import useEvents from '@/modules/events/composables/useEvents';
import useUserProfile from '@/modules/profile/composables/useUserProfile';
import useUtils from '@/shared/composables/useUtils';
import router from '@/router';

// Init
const { t } = useI18n();
const route = useRoute();
const eventId = +route.params.eventId;
const ticketIdToScroll = route.params.ticketId.toString();
const { downloadTicket, getEventTickets, updateTicket } = useEvents();
const { eventTickets, isFetchingEventTickets, refetchTickets } = getEventTickets(eventId);
const { getUserProfile } = useUserProfile();
const { userProfile } = getUserProfile();
const { dragToScroll } = useUtils();

// onMountedHook
onMounted(async () => {
	// Update ticket if query params are present
	const shouldRefetchTickets = await updateTicket();
	if (shouldRefetchTickets) {
		refetchTickets();
	}

	// Scroll to ticket id
	setTimeout(() => {
		if (document.getElementById(`ticket-${ticketIdToScroll}`)) {
			document.getElementById(`ticket-${ticketIdToScroll}`)!.scrollIntoView({
				behavior: 'smooth',
				inline: 'center',
			});
		}
	}, 500);

	// Make content draggable to scroll on desktop
	if (!Capacitor.isNativePlatform()) {
		dragToScroll('dragToScrollElement');
	}
});

// Refetch tickets
const onRefetchTickets = () => {
	refetchTickets();
};
</script>

<template>
	<ion-content>
		<div class="flex flex-col h-full">
			<TopBar class="px-[34px] mb-4">
				<template #left>
					<div class="flex items-center justify-start cursor-pointer">
						<router-link :to="`/events/${eventId}/tickets`" class="flex items-center justify-center">
							<ion-icon :icon="chevronBackOutline" class="text-white"></ion-icon>
						</router-link>
					</div>
				</template>

				<template #center>
					<div class="flex grow items-center justify-center w-full">
						<div class="mr-2">
							<span class="font-sourcesanspro-semibold text-l2">{{
								t('events.tickets.tickets_detail.title')
							}}</span>
						</div>
						<!-- <div>
              <span class="font-sourcesanspro-regular text-base font-color-text-50">(7/24)</span>
            </div> -->
					</div>
				</template>
			</TopBar>

			<div v-if="isFetchingEventTickets" class="flex items-center justify-center mb-4">
				<ion-spinner name="dots"></ion-spinner>
			</div>

			<div
				id="dragToScrollElement"
				class="flex w-[100%] overflow-scroll invisible-scroller"
				:class="[
					{
						'justify-center': eventTickets && eventTickets.length === 1,
					},
				]">
				<div
					v-for="(ticket, idx) in eventTickets"
					:key="idx"
					class="flex flex-col"
					:class="[
						{
							'items-start justify-between': eventTickets && eventTickets?.length > 1,
							'items-center': eventTickets && eventTickets.length === 1,
						},
					]">
					<TicketDetailItem
						@on-refetch-tickets="onRefetchTickets"
						:key="idx"
						:qr-code="ticket.qr_code"
						:hide-qr-code="ticket.user_id !== userProfile?.id"
						:value-only="!!ticket.ticket_type?.value_only"
						:ticket-id="ticket.id.toString()"
						:name="
							ticket.participant?.full_name
								? ticket.participant?.full_name
								: userProfile?.full_name
									? userProfile?.full_name
									: ''
						"
						:email="
							ticket.participant?.full_name
								? ticket.participant?.email
								: userProfile?.full_name
									? userProfile?.email
									: ''
						"
						:ticket-disabled-edit="ticket.disable_edit"
						:ticket-type-color="ticket.ticket_type?.color ? ticket.ticket_type?.color : undefined"
						:ticket-type-name="
							ticket.combo_ticket_type
								? ticket.combo_ticket_type.name
								: ticket.ticket_type?.name
									? ticket.ticket_type?.name
									: ''
						"
						:event-allow-edit-tickets="!!ticket.event.allow_edit_tickets"
						:event-id="ticket.event_id"
						:event-name="ticket.event.name"
						:event-date="ticket.event.start_datetime"
						:venue="ticket.event.address"
						:wallet-linked="ticket.wallet_id"
						:class="[
							{
								'ml-[34px] mr-4': eventTickets && eventTickets?.length > 1 && idx === 0,
								'mr-8': eventTickets && eventTickets?.length > 1 && idx === eventTickets!.length - 1,
								'mr-4': eventTickets && eventTickets?.length > 1 && idx !== eventTickets!.length - 1,
							},
						]"
						class="mb-6" />

					<div
						class="flex w-[300px] items-center justify-center"
						:class="[
							{
								'ml-[34px] mr-4': eventTickets && eventTickets?.length > 1 && idx === 0,
								'mr-4': eventTickets && eventTickets?.length > 1 && idx > 0,
							},
						]">
						<ion-button
							v-if="
								!ticket.event?.delayed_delivery_datetime ||
								(ticket.event?.delayed_delivery_datetime && eventTickets && eventTickets[0].qr_code)
							"
							class="hover:bg-white hover:rounded-full"
							expand="full"
							size="large"
							shape="round"
							@click="downloadTicket(ticket.event, ticket)">
							<div class="flex items-center justify-center">
								<ion-icon :icon="downloadOutline" class="text-dark-blue mr-2"></ion-icon>
								<span class="text-m2">{{ t('events.tickets.tickets_detail.download') }}</span>
							</div>
						</ion-button>
						<!-- <ion-button
                class="w-full"
                expand="full"
                size="large"
                shape="round"
                fill="outline">
                <div class="flex items-center justify-center text-white">
                  <ion-icon :icon="shareSocialOutline" class="mr-2"></ion-icon>
                  <span class="text-m2">Share</span>
                </div>
              </ion-button> -->
					</div>
				</div>
			</div>
		</div>
	</ion-content>
</template>

<style lang="scss" scoped></style>
