import { ref, watch } from 'vue';
import router from '@/router';
import { useRoute } from 'vue-router';
import { useQuery } from '@tanstack/vue-query';
import { useI18n } from 'vue-i18n';

import { anykrowdApi } from '@/api/anykrowdApi';
import useUrlHelpers from '@/shared/composables/useUrlHelpers';
import useToast from '@/shared/composables/useToast';

// Init
const { extractUrlQueryParams } = useUrlHelpers();
const { presentToast } = useToast();

// getAutoOpenSelfOrder
const _getAutoOpenSelfOrder = async (): Promise<{
	auto_open_self_order: boolean;
	auto_open_self_order_qr: string | null;
}> => {
	const { data } = await anykrowdApi.get<{ auto_open_self_order: boolean; auto_open_self_order_qr: string | null }>(
		`/table-spot/auto-open-self-order`,
		false,
	);
	return data;
};

// getAutoOpenSelfOrder
const getAutoOpenSelfOrder = () => {
	// Init
	const autoOpenSelfOrder = ref<{ auto_open_self_order: boolean; auto_open_self_order_qr: string | null }>()!;

	const { data: autoOpenSelfOrderData } = useQuery({
		queryKey: ['autoOpenSelfOrder'],
		queryFn: () => _getAutoOpenSelfOrder(),
		retry: 3,
	});

	// Watch userProfile and registrationFormData
	watch(
		autoOpenSelfOrderData,
		() => {
			if (autoOpenSelfOrderData.value) {
				autoOpenSelfOrder.value = { ...autoOpenSelfOrderData.value };
			}
		},
		{ immediate: true },
	);

	// Return
	return {
		autoOpenSelfOrder,
	};
};

// Go to Self Ordering
const _goToSelfOrdering = async (qrCode: string) => {
	router.push(`/self-ordering/spot/${qrCode}`);
};

// useSelfOrderingGetSpotId
const useSelfOrderingGetSpotId = () => {
	// Init
	const { t } = useI18n();
	const route = useRoute();
	const shouldOpenCamera = ref(false);

	// Handle QR code sent as query param
	const handleSpotIdOnQueryParams = () => {
		if (route.query.spotid) {
			shouldOpenCamera.value = false;
			_goToSelfOrdering(route.query.spotid.toString());
		}
	};

	// onScanQR: Handle scanned QR code
	const onScanQR = async (scannedQrCode: string) => {
		const queryParams = extractUrlQueryParams(scannedQrCode);
		if (queryParams && queryParams.spotid) {
			_goToSelfOrdering(queryParams.spotid);
		} else {
			await presentToast('top', t('self_ordering.scan.invalid_table_spot_qr_error'), 5000, 'danger');
		}
	};

	return {
		getAutoOpenSelfOrder,
		handleSpotIdOnQueryParams,
		onScanQR,
		shouldOpenCamera,
	};
};

export default useSelfOrderingGetSpotId;
