import { ref } from 'vue';

import type { Tag } from '@/shared/interfaces/tag';
import useTags from '@/shared/composables/useTags';
import { ToggleChangeEventDetail } from '@ionic/vue';
import { IonToggleCustomEvent } from '@ionic/core';

// useTagCollections
const useTagCollections = (userTagsProp: Array<number>) => {
	const userTags = ref<number[]>(userTagsProp);
	const { error, isError, isLoading, tagsList, updateTags } = useTags();

	// Is selected
	const isSelected = (tag: Tag) => {
		const tagFound = userTags.value.find((tagId) => {
			return tagId === tag.id;
		});
		return tagFound ? true : false;
	};

	// Child tags selected
	const childTagsSelected = (tag: Tag) => {
		const childrenTags = tag.children ? tag.children.map((tag) => tag.id) : false;
		const childTagsSelected = childrenTags
			? userTags.value.filter((userSelectedTag) => childrenTags.includes(userSelectedTag))
			: [];

		return childTagsSelected;
	};

	// Select parent tag
	const selectParentTag = (event: IonToggleCustomEvent<ToggleChangeEventDetail<any>>, tag: Tag) => {
		const isTagSelected = isSelected(tag);
		const childTagsSelectedArray = childTagsSelected(tag);

		if (isTagSelected && childTagsSelectedArray.length === 0) {
			removeTag(tag);
		} else if (!isTagSelected) {
			addTag(tag);
		}

		updateTags({ tags: [...userTags.value] });
	};

	// Select child tag
	const selectChildTag = (tag: Tag, tagParent?: Tag) => {
		const isTagSelected = isSelected(tag);
		if (isTagSelected) {
			removeTag(tag);
		} else {
			addTag(tag);
			if (tagParent && !userTags.value.includes(tagParent.id)) {
				addTag(tagParent);
			}
		}

		updateTags({ tags: [...userTags.value] });
	};

	// Add tag
	const addTag = (tag: Tag) => {
		userTags.value.push(tag.id);
	};

	// Remove tag
	const removeTag = (tag: Tag) => {
		userTags.value.splice(
			userTags.value.findIndex((tagId) => tagId === tag.id),
			1,
		);
	};

	// Return
	return {
		childTagsSelected,
		error,
		isError,
		isLoading,
		isSelected,
		selectChildTag,
		selectParentTag,
		tagsList,
		userTags,
	};
};

export default useTagCollections;
