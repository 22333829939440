<script setup lang="ts">
import { IonContent, IonIcon } from '@ionic/vue';
import { chevronBackOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import { useCart } from '@/modules/shop/composables/useCart';
import ScanQR from '@/shared/components/ScanQR.vue';
import TopBar from '@/shared/components/TopBar.vue';
import useSelfOrderingGetSpotId from '@/modules/self-ordering/components/useSelfOrderingGetSpotId';

// Init
const { t } = useI18n();
const { handleSpotIdOnQueryParams, onScanQR, shouldOpenCamera } = useSelfOrderingGetSpotId();
const { clearCart } = useCart();
clearCart();
handleSpotIdOnQueryParams();
</script>

<template>
	<ion-content>
		<div class="flex flex-col justify-between h-full">
			<TopBar class="px-[34px]">
				<template #left>
					<div class="flex items-center justify-start cursor-pointer">
						<router-link to="/dashboard" class="flex items-center justify-center">
							<ion-icon :icon="chevronBackOutline" class="text-white"></ion-icon>
						</router-link>
					</div>
				</template>
			</TopBar>

			<div class="flex flex-col items-center justify-center">
				<div class="flex flex-col items-center justify-center p-4 font-sourcesanspro-regular text-center">
					{{ t('self_ordering.context') }}
				</div>
				<ScanQR
					@on-scan-q-r="onScanQR"
					:start-camera="shouldOpenCamera"
					:permissionTitle="t('scan_qr.camera_permission_title')"
					:permissionDescription="t('self_ordering.camera_request')">
				</ScanQR>
			</div>

			<div class="h-[200px]"></div>
		</div>
	</ion-content>
</template>

<style scoped></style>
