<script setup lang="ts">
import { ref, toRef, watch } from 'vue';
import router from '@/router';
import { IonButton, IonIcon, IonInput } from '@ionic/vue';
import {
	calendarClearOutline,
	closeOutline,
	createOutline,
	linkOutline,
	locationOutline,
	timeOutline,
} from 'ionicons/icons';
import * as Yup from 'yup';
import QrcodeVue from 'qrcode.vue';
import { DateTime } from 'luxon';
import { useI18n } from 'vue-i18n';

import ModalOne from '@/shared/components/ui/ModalOne.vue';
import TextInput from '@/shared/components/sign-up-forms/TextInput.vue';
import useCustomForm from '@/shared/composables/useCustomForm';

// Props
const props = defineProps({
	email: { type: String, required: true },
	eventAllowEditTickets: { type: Boolean, required: true },
	eventDate: { type: String, required: true },
	eventId: { type: Number, required: true },
	eventName: { type: String, required: true },
	hideQrCode: { type: Boolean, default: false },
	name: { type: String, required: true },
	qrCode: { type: String, required: true },
	ticketDisabledEdit: { type: Boolean, required: true },
	ticketId: { type: String, required: true },
	ticketTypeColor: { type: String },
	ticketTypeName: { type: String },
	valueOnly: { type: Boolean, required: true, default: false },
	venue: { type: String, required: true },
	walletLinked: { type: Number as () => number | null | undefined },
});

// Init
const hideQrCode = ref(props.hideQrCode);
const hideQrCodeRef = toRef(props, 'hideQrCode');
const { t } = useI18n();
const emit = defineEmits(['onRefetchTickets']);
const isModalOpen = ref(false);
const showHideModal = (openState: boolean) => {
	isModalOpen.value = openState;
};

// Using yup to generate a validation schema
const schema = Yup.object().shape({
	name: Yup.string().required(),
	email: Yup.string().required(),
});

// useCustomForm
const { isFormValid, isSuccess, onSubmit, onInputChange } = useCustomForm(
	schema,
	`/events/edit-ticket/${props.ticketId}`,
	'post',
	'ticket.saved',
	'ticket.saving_error',
	5000,
);

// Refetch ticket
watch(isSuccess, () => {
	if (isSuccess) {
		emit('onRefetchTickets');
		showHideModal(false);
		isSuccess.value = false;
	}
});

const showQrCode = () => {
	hideQrCode.value = false;
};

// Close modal and go to page
const closeModalAndGo = () => {
	showHideModal(false);
	router.push(`/friends/select?type=ticket&id=${props.ticketId}&eventId=${props.eventId}`);
};
</script>

<template>
	<div>
		<ModalOne :title="t('components.ticket_detail_item.edit_ticket')" :is-open="isModalOpen">
			<template #content>
				<form id="changePasswordForm" :validation-schema="schema" @submit="onSubmit">
					<div class="relative mt-6">
						<div>
							<ion-input type="text" hidden="hidden" name="eventTicketId" :value="props.ticketId" />
						</div>
						<div class="flex relative">
							<TextInput
								id="input-field"
								class="mr-2"
								wrapper-class="flex flex-wrap justify-left item-center mb-3 border border-solid focus-within:background-white-15"
								type="text"
								name="name"
								placeholder="Name"
								:value="props.name"
								@inputChanged="onInputChange" />
							<ion-button class="go-back-button" @click="closeModalAndGo">
								<ion-icon
									:icon="require('@/statics/svg/people-add.svg')"
									class="w-[50px] h-[50px] cursor-pointer"></ion-icon>
							</ion-button>
						</div>
						<div class="relative">
							<TextInput
								id="input-field"
								wrapper-class="flex flex-wrap justify-left item-center mb-3 border border-solid focus-within:background-white-15"
								type="text"
								name="email"
								placeholder="Email"
								:value="props.email"
								@inputChanged="onInputChange" />
						</div>
						<ion-button :disabled="!isFormValid" expand="full" shape="round" type="submit">
							{{ t('components.ticket_detail_item.edit_ticket') }}
						</ion-button>
					</div>
				</form>
			</template>

			<template #actions>
				<div class="flex items-center justify-center">
					<div class="flex items-center justify-center w-[60px] h-[60px] rounded-full bg-white">
						<ion-icon
							class="text-[38px] text-black cursor-pointer"
							:icon="closeOutline"
							@click="showHideModal(false)"></ion-icon>
					</div>
				</div>
			</template>
		</ModalOne>

		<div :id="`ticket-${props.ticketId}`" class="ticket-detail-item w-[300px] h-[620px]">
			<div class="flex items-center justify-center h-[256px]">
				<div v-if="hideQrCode" class="relative cursor-pointer" @click="showQrCode()">
					<div class="absolute flex items-center justify-center w-full h-full bg-black/50">
						<span class="uppercase font-sourcesanspro-semibold">{{
							t('components.ticket_detail_item.press_to_show')
						}}</span>
					</div>
					<qrcode-vue
						v-if="!props.valueOnly"
						:value="props.qrCode"
						:level="'M'"
						:size="180"
						background="#E0E0E0" />
				</div>
				<div v-else>
					<qrcode-vue
						v-if="!props.valueOnly"
						:value="props.qrCode"
						:level="'M'"
						:size="180"
						background="#E0E0E0" />
					<div v-else class="flex flex-col items-center text-l break-all text-dark-blue/70">
						<span
							><b>{{ t('components.ticket_detail_item.code') }}</b></span
						>
						{{ props.qrCode }}
					</div>
				</div>
			</div>

			<div v-if="props.walletLinked" class="absolute flex top-[232px] left-0 right-0 items-center justify-center">
				<div
					class="flex px-6 py-4 bg-black rounded-full font-sourcesanspro-semibolditalic text-xs2 text-white/70">
					<ion-icon :icon="linkOutline" class="mr-2 text-white"></ion-icon>
					{{ t('components.ticket_detail_item.your_wallet_linked') }}
				</div>
			</div>

			<div class="flex mt-10 items-center justify-center mb-1">
				<div class="flex items-center justify-center font-sourcesanspro-semibold text-dark-blue">
					<span class="text-lg24">{{ props.name }}</span>
					<ion-button
						v-if="!props.ticketDisabledEdit && props.eventAllowEditTickets"
						@click="showHideModal(true)"
						class="w-[24px] h-[24px] ion-no-margin ion-no-padding"
						fill="clear">
						<ion-icon :icon="createOutline" class="text-dark-blue"></ion-icon>
					</ion-button>
				</div>
			</div>

			<div class="px-6 text-dark-blue/70">
				<div class="flex flex-col items-center justify-center mb-2">
					<span class="font-sourcesanspro-bold text-base">{{
						t('components.ticket_detail_item.ticket_type')
					}}</span>
					<div
						:style="`background-color: ${props.ticketTypeColor !== undefined && $props.ticketTypeColor != '' ? props.ticketTypeColor : '#000000'}`"
						class="flex h-[37px] px-14 py-2 items-center justify-center rounded-md">
						<span class="font-sourcesanspro-semibold text-base2 text-white">
							{{ props.ticketTypeName }}
						</span>
					</div>
				</div>
				<div class="mb-2">
					<span class="font-sourcesanspro-semibold text-xs2 text-dark-blue">
						{{ props.eventName }}
					</span>
				</div>
				<div class="flex items-center justify-between mb-3">
					<div class="flex flex-col">
						<span class="font-sourcesanspro-bold text-base">{{
							t('components.ticket_detail_item.date')
						}}</span>
						<span class="font-sourcesanspro-regular text-base2">
							{{
								DateTime.fromFormat(props.eventDate, 'yyyy-MM-dd HH:mm:ss').toFormat(
									'EEEE dd MMM, yyyy',
								)
							}}
						</span>
					</div>
					<ion-icon :icon="calendarClearOutline" class="text-dark-blue"></ion-icon>
				</div>
				<div class="flex items-center justify-between mb-3">
					<div class="flex flex-col">
						<span class="font-sourcesanspro-bold text-base">{{
							t('components.ticket_detail_item.time')
						}}</span>
						<span class="font-sourcesanspro-regular text-base2">
							{{ DateTime.fromFormat(props.eventDate, 'yyyy-MM-dd HH:mm:ss').toFormat('HH:mm a') }}
						</span>
					</div>
					<ion-icon :icon="timeOutline" class="text-dark-blue"></ion-icon>
				</div>
				<div v-if="props.venue" class="flex items-center justify-between mb-3">
					<div class="flex flex-col">
						<span class="font-sourcesanspro-bold text-base">{{
							t('components.ticket_detail_item.venue')
						}}</span>
						<span class="font-sourcesanspro-regular text-base2">{{ props.venue }}</span>
					</div>
					<ion-icon :icon="locationOutline" class="text-dark-blue"></ion-icon>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
.ticket-detail-item {
	background: url('@/statics/svg/ticket-detail-item.svg') no-repeat;
	background-size: cover;
	position: relative;
}
</style>
