<script setup lang="ts">
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { IonButton, IonIcon } from '@ionic/vue';
import { GoogleMap, CustomMarker, Marker } from 'vue3-google-map';
import { chevronBackOutline } from 'ionicons/icons';

import NavigationBar from '@/shared/components/ui/NavigationBar.vue';
import useMaps from '@/modules/maps/composables/useMaps';
import { ApiMarker, mapCustomOverlay } from '@/shared/interfaces/map';

// Init
const mapRef = ref();
const showMarkerDetails = ref(false);
const selectedMarker = ref<ApiMarker>();
const route = useRoute();
const mapId = +route.params.id;
const { getMap, getMaps } = useMaps();
const { map } = getMap(mapId);
const { maps } = getMaps();
const GOOGLE_MAPS_API_KEY = process.env.VUE_APP_GOOGLE_MAPS_API_KEY;

// Third pattern: watch for "ready" then do something with the API or map instance
watch(
	() => mapRef.value?.ready,
	(ready) => {
		if (!ready) return;

		if (map.value) {
			const bounds = new google.maps.LatLngBounds(
				new google.maps.LatLng(map.value.boundries.south, map.value.boundries.west),
				new google.maps.LatLng(map.value.boundries.north, map.value.boundries.east),
			);

			mapRef.value.map.setOptions({
				mapTypeId: google.maps.MapTypeId.ROADMAP,
				restriction: {
					latLngBounds: bounds,
					strictBounds: true,
				},
			});

			// Setting overlay
			const image = map.value.overlay!.toString();
			const customOverlay = new mapCustomOverlay();
			const overlay = customOverlay.createMapTextOverlay(bounds, image);
			overlay.setMap(mapRef.value.map);
		}
	},
);

const openMarkerDetails = (markerId: number) => {
	const marker = map.value!.markers?.find((m) => m.id === markerId);
	if (marker) {
		selectedMarker.value = { ...marker };
		showMarkerDetails.value = true;
	}
};
</script>

<template>
	<div class="flex px-[34px]">
		<div v-if="maps && maps.length > 1" class="mt-8 z-10">
			<router-link to="/maps">
				<div class="flex items-center justify-center w-[60px] h-[60px] p-4 rounded-full bg-white/90">
					<ion-icon :icon="chevronBackOutline" class="ion-no-padding text-black"></ion-icon>
				</div>
			</router-link>
		</div>

		<GoogleMap
			class="absolute top-0 left-0"
			ref="mapRef"
			v-if="map"
			:api-key="GOOGLE_MAPS_API_KEY"
			:clickable-icons="false"
			mapTypeId="roadmap"
			:disableDefaultUi="true"
			style="width: 100%; height: 100%"
			:center="{ lat: map?.initialPosition.lat, lng: map?.initialPosition.lng }"
			:zoom="15"
			:zoom-control="true"
			zoom-control-position="RIGHT_TOP"
			@click="showMarkerDetails = false">
			<div v-for="(marker, idx) in map.markers" :key="idx">
				<CustomMarker
					v-if="marker.icon"
					:options="{
						position: { lat: marker.position.lat, lng: marker.position.lng },
						anchorPoint: 'BOTTOM_CENTER',
					}">
					<div class="relative drop-shadow-lg" @click="openMarkerDetails(marker.id)">
						<img :src="marker.icon" />
					</div>
				</CustomMarker>
				<Marker
					v-else
					:options="{ position: { lat: marker.position.lat, lng: marker.position.lng } }"
					@click="openMarkerDetails(marker.id)" />
			</div>

			<div
				v-show="showMarkerDetails"
				class="fixed flex p-4 items-start justify-between bg-white left-2/4 -translate-x-2/4 bottom-28 align-center rounded-3xl w-[360px] z-10">
				<div v-if="selectedMarker?.poi?.image" class="flex-1">
					<img class="rounded-3xl" :src="selectedMarker.poi.image" />
				</div>
				<div class="flex flex-[2] flex-col pl-3">
					<span class="font-sourcesanspro-bold text-base2 text-[#141414]">{{
						selectedMarker?.poi?.name
					}}</span>
					<span class="font-sourcesanspro-regular text-xs text-[#333333]/60">{{
						selectedMarker?.poi?.description_short
					}}</span>
				</div>
			</div>
		</GoogleMap>

		<NavigationBar item-active="maps" />
	</div>
</template>

<style scoped></style>
