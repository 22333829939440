<script setup lang="ts">
import { ref } from 'vue';
import { IonIcon } from '@ionic/vue';
import { RouterLink } from 'vue-router';
import { bagOutline, calendarOutline, homeOutline, locationOutline, ticketOutline } from 'ionicons/icons';

import useTenant from '@/shared/composables/useTenant';

const props = defineProps({
	itemActive: { type: String, required: true },
});

// Init
const { getTenantConfigAndStyles } = useTenant();
const { isSuccessTenantConfig, tenantConfig } = getTenantConfigAndStyles();
const hash = window.location.hash;
</script>

<template>
	<div
		v-if="isSuccessTenantConfig && tenantConfig"
		class="flex fixed px-2 justify-evenly bg-white left-2/4 -translate-x-2/4 bottom-4 align-center rounded-full w-[90%] min-w-[250px] max-w-[360px] h-[77px] z-10">
		<div class="flex flex-col items-center justify-center navigation-item">
			<router-link class="flex flex-col items-center" to="/dashboard">
				<ion-icon
					:class="[
						{
							'text-dark-blue': props.itemActive === 'dashboard' && hash !== '#news',
							'text-dark-blue/25': props.itemActive !== 'dashboard' || hash === '#news',
						},
					]"
					class="mb-[5px]"
					:icon="homeOutline"></ion-icon>
				<span
					:class="[
						{
							'text-dark-blue': props.itemActive === 'dashboard' && hash !== '#news',
							'text-dark-blue/25': props.itemActive !== 'dashboard' || hash === '#news',
						},
					]"
					class="text-xs leading-none"
					>Home</span
				>
			</router-link>
		</div>
		<div v-if="!tenantConfig.hide_maps" class="flex flex-col items-center justify-center navigation-item">
			<router-link class="flex flex-col items-center" to="/maps">
				<ion-icon
					:class="[
						{
							'text-dark-blue': props.itemActive === 'maps',
							'text-dark-blue/25': props.itemActive !== 'maps',
						},
					]"
					class="mb-[5px]"
					:icon="locationOutline"></ion-icon>
				<span
					:class="[
						{
							'text-dark-blue': props.itemActive === 'maps',
							'text-dark-blue/25': props.itemActive !== 'maps',
						},
					]"
					class="text-xs text-dark-blue leading-none"
					>Maps</span
				>
			</router-link>
		</div>
		<div v-if="!tenantConfig.hide_tickets" class="flex flex-col items-center justify-center navigation-item">
			<router-link class="flex flex-col items-center" to="/events">
				<ion-icon
					:class="[
						{
							'text-dark-blue': props.itemActive === 'events',
							'text-dark-blue/25': props.itemActive !== 'events',
						},
					]"
					class="mb-[5px]"
					:icon="ticketOutline"></ion-icon>
				<span
					:class="[
						{
							'text-dark-blue': props.itemActive === 'events',
							'text-dark-blue/25': props.itemActive !== 'events',
						},
					]"
					class="text-xs text-dark-blue leading-none"
					>Events</span
				>
			</router-link>
		</div>
		<!-- <div
      v-if="!tenantConfig.hide_bookings"
      class="flex flex-col items-center justify-center navigation-item">
      <router-link class="flex flex-col items-center" to="/bookings-reservations">
        <ion-icon
          :class="[{
            'text-dark-blue': props.itemActive === 'bookings-reservations',
            'text-dark-blue/25': props.itemActive !== 'bookings-reservations'
          }]"
					class="mb-[5px]"
          :icon="calendarClearOutline"></ion-icon>
        <span
          :class="[{
            'text-dark-blue': props.itemActive === 'bookings-reservations',
            'text-dark-blue/25': props.itemActive !== 'bookings-reservations'
          }]"
          class="text-xs text-dark-blue leading-none">Bookings</span>
      </router-link>
    </div> -->
		<div v-if="!tenantConfig.hide_shop" class="flex flex-col items-center justify-center navigation-item">
			<router-link class="flex flex-col items-center" to="/shop">
				<ion-icon
					:class="[
						{
							'text-dark-blue': props.itemActive === 'shop',
							'text-dark-blue/25': props.itemActive !== 'shop',
						},
					]"
					class="mb-[5px]"
					:icon="bagOutline"></ion-icon>
				<span
					:class="[
						{
							'text-dark-blue': props.itemActive === 'shop',
							'text-dark-blue/25': props.itemActive !== 'shop',
						},
					]"
					class="text-xs text-dark-blue leading-none"
					>Shop</span
				>
			</router-link>
		</div>
		<div v-if="!tenantConfig.hide_timetables" class="flex flex-col items-center justify-center navigation-item">
			<router-link class="flex flex-col items-center" to="/timetables">
				<ion-icon
					:class="[
						{
							'text-dark-blue': props.itemActive === 'timetables',
							'text-dark-blue/25': props.itemActive !== 'timetables',
						},
					]"
					class="mb-[5px]"
					:icon="calendarOutline"></ion-icon>
				<span
					:class="[
						{
							'text-dark-blue': props.itemActive === 'timetables',
							'text-dark-blue/25': props.itemActive !== 'timetables',
						},
					]"
					class="text-xs text-dark-blue leading-none"
					>Timetables</span
				>
			</router-link>
		</div>
		<!-- <div
      v-if="!tenantConfig.hide_friends"
      class="flex flex-col items-center justify-center navigation-item">
      <router-link class="flex flex-col items-center" to="/friends">
        <ion-icon
          :class="[{
            'text-dark-blue': props.itemActive === 'friends',
            'text-dark-blue/25': props.itemActive !== 'friends'
          }]"
					class="mb-[5px]"
          :icon="peopleOutline"></ion-icon>
        <span
          :class="[{
            'text-dark-blue': props.itemActive === 'friends',
            'text-dark-blue/25': props.itemActive !== 'friends'
          }]"
          class="text-xs text-dark-blue leading-none">Friends</span>
      </router-link>
    </div> -->
		<!-- <div
      v-if="!tenantConfig.hide_quiz"
      class="flex flex-col items-center justify-center navigation-item">
      <router-link class="flex flex-col items-center" to="/quiz">
        <ion-icon
          :class="[{
            'text-dark-blue': props.itemActive === 'quiz',
            'text-dark-blue/25': props.itemActive !== 'quiz'
          }]"
					class="mb-[5px]"
          :icon="bulbOutline"></ion-icon>
        <span
          :class="[{
            'text-dark-blue': props.itemActive === 'quiz',
            'text-dark-blue/25': props.itemActive !== 'quiz'
          }]"
          class="text-xs text-dark-blue leading-none">Quiz</span>
      </router-link>
    </div> -->
	</div>
</template>

<style lang="scss" scoped></style>
