import { ref } from 'vue';
import { AlertOptions, alertController } from '@ionic/vue';

const useAlert = () => {
	// Present alert
	const presentAlert = async (config: AlertOptions) => {
		const alert = await alertController.create(config);
		await alert.present();
	};

	return {
		presentAlert,
	};
};

export default useAlert;
