<script setup lang="ts">
import { onMounted, ref } from 'vue';
import router from '@/router';
import { useRoute } from 'vue-router';
import { IonButton, IonContent, IonIcon, IonTextarea, IonSpinner } from '@ionic/vue';
import { chevronBackOutline, closeOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import { CartCategoriesEnum } from '@/enums/values.enum';
import { TransactionType } from '@/enums/wallet.enum';
import { useCart } from '@/modules/shop/composables/useCart';
import { useCheckout } from '@/shared/composables/useCheckout';
import hexRgb from 'hex-rgb';
import ItemAdder from '@/shared/components/ui/ItemAdder.vue';
import ModalOne from '@/shared/components/ui/ModalOne.vue';
import rgbHex from 'rgb-hex';
import TopBar from '@/shared/components/TopBar.vue';
import useConvertValuta from '@/shared/composables/useConvertValuta';
import useSelfOrdering from '@/modules/self-ordering/components/useSelfOrdering';
import useTenant from '@/shared/composables/useTenant';
import useUtils from '@/shared/composables/useUtils';

// Init
const { t } = useI18n();
const route = useRoute();
const categoryId = ref(+route.params.id);
const {
	contentMessage,
	currentProduct,
	decreaseQuantity,
	getProductCategories,
	getProductsForCategory,
	getTableSpotAndQrCodeAndLocationFromStore,
	increaseQuantity,
	isModalOpen,
	onDeleteAComment,
	onWriteAComment,
	scrollToCategory,
	showHideModal,
} = useSelfOrdering();
const { tableSpot, location, qrCode } = getTableSpotAndQrCodeAndLocationFromStore();
const { tableSpotProductCategories } = getProductCategories(qrCode);
const { isFetchingProductsForCategory, tableSpotProducts } = getProductsForCategory(categoryId);
const {
	getCartItem,
	getCartTotalItemsByCategory,
	getProductCount,
	getCartTotalPrice,
	updateCartAdditionalInfoByProductId,
} = useCart();
const { processCheckout } = useCheckout();
const { getTenantConfigAndStyles } = useTenant();
const { tenantConfig } = getTenantConfigAndStyles();
const { convertValutaSync } = useConvertValuta();
const { calculateShade } = useUtils();
const primary_color = window.primary_color;
const primaryRgb = hexRgb(window.primary_color, { format: 'array' });
const primaryShade = '#' + rgbHex(calculateShade(primaryRgb.toString(), 0.2));

// Go back
const goBack = () => {
	router.back();
};

// onMountedHook
onMounted(async () => {
	selectCategory(+route.params.id);
});

const selectCategory = (selectedCategoryId: number) => {
	categoryId.value = selectedCategoryId;
	scrollToCategory(selectedCategoryId);
};

const saveMessage = () => {
	const cartItem = getCartItem(currentProduct.value!.id);
	const additionalInfo = cartItem?.additionalInfo ? cartItem.additionalInfo : {};
	const comments =
		cartItem?.additionalInfo && cartItem.additionalInfo.comments ? cartItem?.additionalInfo.comments : [];
	updateCartAdditionalInfoByProductId(currentProduct.value!.id, {
		...additionalInfo,
		comments: [...comments, contentMessage.value],
	});
	showHideModal(false, false);
	contentMessage.value = '';
};
</script>

<template>
	<ion-content :style="`background: linear-gradient(${primary_color}, ${primaryShade});`">
		<ModalOne :title="t('self_ordering.cart.add_content')" :is-open="isModalOpen">
			<template #content>
				<div class="mt-6">
					<ion-textarea
						class="mb-2 px-2 rounded-xl bg-white text-anykrowd-dark-purple font-sourcesanspro-regular text-sm"
						max-length="100"
						:placeholder="t('self_ordering.cart.type_something_here')"
						v-model="contentMessage"></ion-textarea>
					<ion-button expand="full" shape="round" @click="saveMessage()">
						{{ t('self_ordering.cart.add_content') }}
					</ion-button>
				</div>
			</template>

			<template #actions>
				<div class="flex items-center justify-center">
					<div class="flex items-center justify-center w-[60px] h-[60px] rounded-full bg-white">
						<ion-icon
							class="text-[38px] text-black cursor-pointer"
							:icon="closeOutline"
							@click="showHideModal(false, true)"></ion-icon>
					</div>
				</div>
			</template>
		</ModalOne>

		<div v-if="tenantConfig" class="flex flex-col">
			<TopBar class="px-[34px]">
				<template #left>
					<div class="flex items-center justify-start cursor-pointer" @click="goBack">
						<ion-icon :icon="chevronBackOutline" class="text-white"></ion-icon>
					</div>
				</template>
			</TopBar>
			<div class="flex overflow-scroll mb-10 invisible-scroller scroll-smooth">
				<div
					v-for="(category, idx) in tableSpotProductCategories"
					:key="idx"
					:class="[
						{
							'ml-[34px]': idx === 0,
						},
					]"
					class="flex flex-col items-center justify-center mr-5"
					@click="selectCategory(category.id)">
					<div
						:id="`category-${category.id}`"
						:class="[
							{
								'bg-white': category.id === categoryId,
								'bg-white/10': category.id !== categoryId,
							},
						]"
						class="flex w-[72px] h-[72px] items-center justify-center mb-3 rounded-md">
						<img
							v-if="category.image_url"
							:class="[
								{
									'invert': category.id !== categoryId,
									'opacity-40': category.id !== categoryId,
								},
							]"
							:src="category.image_url"
							class="w-[32px] h-[32px]" />
					</div>
					<div
						class="h-[65px] font-sourcesanspro-regular text-center text-m2 capitalize"
						:class="[
							{
								'text-white': category.id === categoryId,
								'text-white/30': category.id !== categoryId,
							},
						]">
						{{ category.name.toLowerCase() }}
					</div>
				</div>
			</div>
			<div v-if="isFetchingProductsForCategory" class="flex items-center justify-center mb-4">
				<ion-spinner name="dots"></ion-spinner>
			</div>
			<div v-if="tableSpotProducts" class="px-[34px]">
				<ItemAdder
					v-for="(productItem, idx) in tableSpotProducts.filter(
						(productItem) => productItem.product_category_id === categoryId,
					)"
					@onDecreaseProductQuantity="
						(product) => {
							decreaseQuantity(product);
						}
					"
					@onIncreaseProductQuantity="
						(product) => {
							increaseQuantity(product);
						}
					"
					add-more-error="self_ordering.cart.stock_alert_cart"
					:add-more-error-detail="(+productItem.stock).toString()"
					:key="idx"
					:productId="productItem.id"
					:product="productItem"
					class="mb-4"
					:title="productItem.name"
					:description="productItem.description"
					:discount="productItem.discount"
					:price="+productItem.price"
					:price-with-discount="+productItem.discountedPrice()!"
					:quantity="getProductCount(productItem.id)"
					:can-write-a-comment="true"
					:comments="
						getCartItem(productItem.id)?.additionalInfo
							? getCartItem(productItem.id)?.additionalInfo.comments
							: []
					"
					@on-delete-a-comment="onDeleteAComment"
					@onWriteAComment="onWriteAComment(productItem)"
					:can-add-more="
						tenantConfig.out_of_stock_order_clientx
							? true
							: productItem.stock_type === 'managed' || productItem.stock_type === 'cross_stock'
								? getProductCount(productItem.id) < +productItem.stock
								: true
					" />
			</div>
			<div class="h-[100px]">&nbsp;</div>
		</div>

		<div class="fixed button-container mb-[34px] px-[34px] left-1/2 -translate-x-1/2 bottom-0 w-full">
			<div class="relative z-10 w-full h-full">
				<ion-button
					:disabled="getCartTotalItemsByCategory(CartCategoriesEnum.selfOrder) === 0"
					class="w-full"
					expand="full"
					size="large"
					shape="round"
					@click.prevent="
						processCheckout(TransactionType.SELF_ORDER, {
							tableSpot: tableSpot,
							location: location ? location : tableSpot.manual_location_type,
						})
					">
					<div class="flex w-full justify-between items-center font-sourcesanspro-semibold text-m2">
						<span>{{ getCartTotalItemsByCategory(CartCategoriesEnum.selfOrder, true) }} Items</span>
						<span v-if="tenantConfig && tenantConfig.custom_valuta_enabled">
							{{ tenantConfig.custom_valuta.prefix }}
							{{
								convertValutaSync(
									tenantConfig,
									getCartTotalPrice(CartCategoriesEnum.selfOrder).toFixed(2),
									true,
								)
							}}
						</span>
						<span v-else>€{{ getCartTotalPrice(CartCategoriesEnum.selfOrder).toFixed(2) }}</span>
					</div>
				</ion-button>
			</div>
		</div>
	</ion-content>
</template>

<style lang="scss" scoped></style>
