import { DateTime } from 'luxon';
import { Event } from '@/shared/interfaces/event';
import { Interval } from 'luxon';

/** ----------------------- APP MODELS ----------------------- */

/**
 * Class that represents an Time table in the FE
 */
export class Timetable {
	public id: number;
	public name: string;
	public navigation: number;
	public events: Event[];
	public startAt: DateTime;
	public endAt: DateTime;

	public tracks: Track[];

	public timelineDetails: (DateTime | null)[];
	public timelineDayBreakpoints: { [day: string]: number };

	constructor(data: ApiTimetable | Timetable) {
		this.id = data.id;
		this.name = data.name;
		this.events = data.events || [];
		this.navigation = 0;

		this.tracks = data.tracks.map((track) => new Track(track));

		const earliestActivity = this._getEarliestDateOfTracks(this.tracks);
		const latestActivity = this._getLatestDateOfTracks(this.tracks);
		this.startAt = earliestActivity;
		// add one hour as the Interval.fromDateTimes doesn't include the end DateTime
		this.endAt = latestActivity.plus({ hours: 1 });

		const interval = Interval.fromDateTimes(this.startAt, this.endAt);
		this.timelineDetails = interval.splitBy({ hours: 1 }).map((hour) => hour.start);

		this.timelineDayBreakpoints = {};
	}

	/**
	 * Class helper method that will search for the earliest moment object in a tracks array
	 *
	 * @param tracks Array of tracks
	 * @returns a moment object that represents the earliest moment OR null if no tracks were passed
	 */
	private _getEarliestDateOfTracks(tracks: Track[]): DateTime {
		// if (!tracks || !tracks.length) {
		//   return null;
		// }

		const datesArray = this.tracks
			.filter((t) => t.activities.length >= 1)
			.reduce((acc: DateTime[], currentTrack) => {
				const mappedActivities = currentTrack.activities.map((a) => a.startAt);

				acc = [...acc, ...mappedActivities];
				return acc;
			}, []);

		return DateTime.min(...datesArray);
	}

	/**
	 * Class helper method that will search for the latest moment object in a tracks array
	 *
	 * @param tracks Array of tracks
	 * @returns a moment object that represents the latest moment OR null if no tracks were passed
	 */
	private _getLatestDateOfTracks(tracks: Track[]): DateTime {
		// if (!tracks || !tracks.length) {
		//   return null;
		// }

		const datesArray = this.tracks
			.filter((t) => t.activities.length >= 1)
			.reduce((acc: DateTime[], currentTrack) => {
				const mappedActivities = currentTrack.activities.map((a) => a.endAt);

				acc = [...acc, ...mappedActivities];
				return acc;
			}, []);

		return DateTime.max(...datesArray);
	}
}

export class Track {
	public id: number;
	public iconUrl?: string;
	public imageUrl?: string;

	public name: string;

	public activities: TrackActivity[];
	public displayActivities: TrackActivity[];

	constructor(data: ApiTrack | Track) {
		this.id = data.id;
		this.name = data.name;

		this.iconUrl = 'icon_url' in data ? data.icon_url : (data as Track).iconUrl;
		this.imageUrl = 'image_url' in data ? data.image_url : (data as Track).imageUrl;

		this.activities = data.activities.map((activity) => new TrackActivity(activity));
		this.displayActivities = this.activities;
	}
}

export class TrackActivity {
	id: number;
	pivot_id?: number;

	name: string;
	description?: string;
	description_short?: string;

	isFavorite: boolean;

	startAt: DateTime;
	endAt: DateTime;

	backgroundColor?: string;
	backgroundColorOpacity?: string;
	imageUrl?: string;
	iconUrl?: string;

	constructor(data: ApiActivity | TrackActivity) {
		this.id = data.id;
		this.pivot_id = data?.pivot_id;
		this.name = data.name;
		this.description = data?.description;
		this.description_short = data?.description_short;

		this.isFavorite = 'is_favorite' in data ? data.is_favorite : (data as TrackActivity).isFavorite;

		// this.startAt = moment('start_at' in data ? data.start_at : (data as TrackActivity).startAt);
		this.startAt =
			'start_at' in data
				? DateTime.fromFormat(data.start_at, 'yyyy-MM-dd HH:mm:ss')
				: (data as TrackActivity).startAt;
		// this.endAt = moment('end_at' in data ? data.end_at : (data as TrackActivity).endAt);
		this.endAt =
			'end_at' in data ? DateTime.fromFormat(data.end_at, 'yyyy-MM-dd HH:mm:ss') : (data as TrackActivity).endAt;

		this.backgroundColor =
			'background_color' in data ? data?.background_color : (data as TrackActivity).backgroundColor;
		this.backgroundColorOpacity =
			'background_color_opacity' in data
				? data.background_color_opacity
				: (data as TrackActivity).backgroundColorOpacity;
		this.imageUrl = 'image_url' in data ? data?.image_url : (data as TrackActivity).imageUrl;
		this.iconUrl = 'icon_url' in data ? data?.icon_url : (data as TrackActivity).iconUrl;
	}
}

/** ----------------------- API INTERFACES ----------------------- */

/**
 * Interface that represents an Time Table response
 */
export interface ApiTimetable {
	id: number;
	name: string;
	navigation: number;
	events: Event[];
	start_at: string;
	end_at: string;

	tracks: ApiTrack[];
}

export interface ApiTrack {
	id: number;
	name: string;
	image_url?: string;
	icon_url?: string;
	description?: string;
	background_color?: string;
	activities: ApiActivity[];
}

export interface ApiActivity {
	id: number;
	pivot_id?: number;

	name: string;
	description?: string;
	description_short?: string;

	is_favorite: boolean;

	start_at: string;
	end_at: string;

	background_color?: string;
	background_color_opacity?: string;
	image_url?: string;
	icon_url?: string;
}
