import { computed, ref, Ref, watch } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import router from '@/router';
import { useI18n } from 'vue-i18n';

import { anykrowdApi } from '@/api/anykrowdApi';
import { CartCategoriesEnum } from '@/enums/values.enum';
import { Product, ProductCategory } from '@/shared/interfaces/tableSpot';
import { TableSpot } from '@/shared/interfaces/tableSpot';
import { useCart } from '@/modules/shop/composables/useCart';
import useTableSpotLocation from '@/modules/self-ordering/composables/useTableSpotLocation';
import useToast from '@/shared/composables/useToast';
import { EventTicketType } from '@/shared/interfaces/eventTicketType';
import { EventComboTicketType } from '@/shared/interfaces/eventComboTicketType';

// Init
const { presentToast } = useToast();

// Get products for category
const _getProductsForCategory = async (tableSpotId: number, productCategoryId: number): Promise<Product[]> => {
	const { data } = await anykrowdApi.get<Product[]>(
		`/table-spot/${tableSpotId}/product-category/${productCategoryId}/products`,
		false,
	);
	return data;
};

// useSelfOrdering
const useSelfOrdering = (qrCode: string) => {
	// Init
	const { t } = useI18n();
	const currentProduct = ref<Product>();
	const tableSpot = ref<TableSpot>();
	const isModalOpen = ref(false);
	const contentMessage = ref('');
	const locationModalOpen = ref(false);
	const shouldOpenCamera = ref(true);
	const { getLocation, getQrCode, getTableSpot, resetTableSpotStore, saveTableSpot } = useTableSpotLocation();
	const enableGetProductCategories = ref(false);
	const productLimitIncrease = ref(false);
	const { addToCart, decreaseProductQuantity, getCartItem } = useCart();

	// clearLocationAndContinue
	const clearLocationAndContinue = () => {
		// resetTableSpotStore();
		router.push('/dashboard');
	};

	// closeLocationModal
	const closeLocationModal = () => {
		locationModalOpen.value = false;
		// resetTableSpotStore();
		router.push('/dashboard');
	};

	// Decrease product quantity
	const decreaseQuantity = (product: EventTicketType | EventComboTicketType | Product) => {
		productLimitIncrease.value = false;
		decreaseProductQuantity(product);
	};

	// Get product categories
	const _getProductCategories = async (): Promise<ProductCategory[]> => {
		const { data } = await anykrowdApi.get<ProductCategory[]>(
			`/table-spot/${tableSpot.value?.id}/product-categories`,
			false,
		);
		return data;
	};

	// getProductCategories
	const getProductCategories = (selfOrderingSpotId: string) => {
		// Init
		const tableSpotProductCategories = ref<ProductCategory[]>()!;

		const { data: tableSpotProductCategoriesData, isFetching } = useQuery({
			queryKey: ['tableSpotProductCategories', selfOrderingSpotId],
			queryFn: () => _getProductCategories(),
			retry: 3,
			enabled: enableGetProductCategories,
		});

		// Watch userProfile and registrationFormData
		watch(
			tableSpotProductCategoriesData,
			() => {
				if (tableSpotProductCategoriesData.value) {
					tableSpotProductCategories.value = [...tableSpotProductCategoriesData.value];
				}
			},
			{ immediate: true },
		);

		// Return
		return {
			isFetchingProductCategories: computed(() => isFetching.value),
			tableSpotProductCategories,
		};
	};

	// getProductsForCategory
	const getProductsForCategory = (categoryId: Ref<number>) => {
		// Init
		const tableSpotProducts = ref<Product[]>()!;
		const tableSpot = getTableSpot();

		const {
			data: tableSpotProductsData,
			isFetching,
			refetch,
		} = useQuery({
			queryKey: ['tableSpotProducts', categoryId],
			queryFn: () => _getProductsForCategory(tableSpot.id, categoryId.value),
			retry: 3,
		});

		// Watch userProfile and registrationFormData
		watch(
			tableSpotProductsData,
			() => {
				if (tableSpotProductsData.value) {
					tableSpotProducts.value = tableSpotProductsData.value.map((product) => new Product(product));
				}
			},
			{ immediate: true },
		);

		// Return
		return {
			isFetchingProductsForCategory: computed(() => isFetching.value),
			refetchTableSpotProducts: refetch,
			tableSpotProducts,
		};
	};

	const getTableSpotAndQrCodeAndLocationFromStore = () => {
		return {
			qrCode: getQrCode(),
			tableSpot: getTableSpot(),
			location: getLocation(),
		};
	};

	// getTableSpotByQRCode
	const getTableSpotByQRCode = async (): Promise<TableSpot | void> => {
		const { data } = await anykrowdApi.get<TableSpot>('/table-spot', false, { params: { qr_code: qrCode } });
		const tableSpotFromStore = saveTableSpot(data, qrCode);

		if (tableSpotFromStore.qrCode === qrCode) {
			enableGetProductCategories.value = true;

			if (tableSpotFromStore && tableSpotFromStore.tableSpot.manual_location) {
				locationModalOpen.value = true;
			}

			tableSpot.value = new TableSpot(tableSpotFromStore.tableSpot);
		} else {
			try {
				tableSpot.value = new TableSpot(data);
				enableGetProductCategories.value = true;

				// Open location modal if manual location is required
				if (tableSpotFromStore && tableSpotFromStore.tableSpot.manual_location) {
					locationModalOpen.value = true;
				}

				// Return tableSpot
				tableSpot.value = new TableSpot(tableSpotFromStore.tableSpot);
			} catch (e) {
				await presentToast('top', t('self_ordering.scan.invalid_table_spot_qr_error'), 5000, 'danger');
				router.push('/dashboard');
			}
		}
	};

	// Increase product quantity
	const increaseQuantity = async (item: Product) => {
		addToCart(item, CartCategoriesEnum.selfOrder, {
			disableSalesCataloguePayments: item.disable_sale_catalogue_payments,
			productCategoryId: item.product_category_id,
		});
	};

	// onDeleteAComment
	const onDeleteAComment = (productId: number, commentIndex: number) => {
		const cartItem = getCartItem(productId);
		const comments = cartItem?.additionalInfo.comments;
		comments.splice(commentIndex, 1);
	};

	// onWriteAComment
	const onWriteAComment = (product: Product) => {
		currentProduct.value = product;
		isModalOpen.value = true;
	};

	// scrollToCategory
	const scrollToCategory = (selectedCategoryId: number) => {
		// Scroll to category
		setTimeout(() => {
			if (document.getElementById(`category-${selectedCategoryId}`)) {
				document.getElementById(`category-${selectedCategoryId}`)!.scrollIntoView({
					behavior: 'smooth',
					inline: 'center',
				});
			}
		}, 500);
	};

	// showHideModal
	const showHideModal = (openState: boolean, clear: boolean) => {
		isModalOpen.value = openState;

		if (clear) {
			contentMessage.value = '';
		}
	};

	return {
		currentProduct,
		clearLocationAndContinue,
		closeLocationModal,
		contentMessage,
		decreaseQuantity,
		getProductCategories,
		getProductsForCategory,
		getTableSpotAndQrCodeAndLocationFromStore,
		getTableSpotByQRCode,
		increaseQuantity,
		isModalOpen,
		locationModalOpen,
		onDeleteAComment,
		onWriteAComment,
		productLimitIncrease,
		scrollToCategory,
		shouldOpenCamera,
		showHideModal,
		tableSpot,
	};
};

export default useSelfOrdering;
