import { Discount, Discountable } from '@/shared/interfaces/discount';

export class ShopProduct extends Discountable {
	id: number;
	web_shop_product_category_id: null | number;
	name: string;
	description: null | string;
	price: number;
	vat_percentage: number;
	image_url: null | string;
	category_name: string;
	in_stock?: boolean;

	constructor(data: {
		id: number;
		web_shop_product_category_id: null | number;
		name: string;
		description: null | string;
		price: number;
		vat_percentage: number;
		image_url: null | string;
		category_name: string;
		in_stock?: boolean;
		discount?: Discount;
	}) {
		super(data);
		this.id = data.id;
		this.web_shop_product_category_id = data.web_shop_product_category_id;
		this.name = data.name;
		this.description = data.description;
		this.price = data.price;
		this.vat_percentage = data.vat_percentage;
		this.image_url = data.image_url;
		this.category_name = data.category_name;
		this.in_stock = data.in_stock;
	}

	getFullPrice(): number {
		return Number(this.price);
	}
}

export interface ShopProductState {
	products: ShopProduct[];
}
export interface GroupedShopProducts {
	category_id: number;
	category_name: string;
	products: ShopProduct[];
}
