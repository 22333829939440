<script setup lang="ts">
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import {
	IonContent,
	IonButton,
	IonFooter,
	IonIcon,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonSearchbar,
	IonSpinner,
	InfiniteScrollCustomEvent,
} from '@ionic/vue';
import { close, search } from 'ionicons/icons';
import { DateTime } from 'luxon';
import { useI18n } from 'vue-i18n';

import EventsItem from '@/modules/events/components/EventsItem.vue';
import NavigationBar from '@/shared/components/ui/NavigationBar.vue';
import useEvents from '@/modules/events/composables/useEvents';
import useTenant from '@/shared/composables/useTenant';
import { useAssetsStore } from '@/stores/shared/assetsStore';

// Init
const { t } = useI18n();
const route = useRoute();
const eventTypeQueryString = route.query.eventType?.toString();
const logoUrl = window.logoUrl;
const eventType = ref(eventTypeQueryString ? eventTypeQueryString : 'all');
const assetStore = useAssetsStore();
const eventSearch = ref('');
const { getEvents } = useEvents();
const { events, fetchNextPage, hasNextPage, isFetchingEventsDataRequests } = getEvents(eventSearch, eventType);
const showSearch = ref(false);
const { getTenantConfigAndStyles } = useTenant();
const { tenantConfig, showLoggedData } = getTenantConfigAndStyles();

// Infinite scroll
const ionInfinite = async (ev: InfiniteScrollCustomEvent) => {
	if (hasNextPage && hasNextPage.value) {
		await fetchNextPage();
	}
	ev.target.complete();
};

const clearSearch = () => {
	eventSearch.value = '';
};

const swapSearch = () => {
	showSearch.value = !showSearch.value;
	setTimeout(() => {
		if (showSearch.value) {
			const searchBar = document.querySelector('.events-searchbar');
			const inputElement = searchBar!.querySelector('input');
			inputElement!.focus();
		}
	}, 500);
};
</script>

<template>
	<ion-content>
		<div class="flex flex-col items-center px-[34px] pt-16">
			<div v-show="!showSearch">
				<div class="flex justify-between">
					<div class="w-[20px]"></div>

					<div class="flex items-start">
						<div
							@click="swapSearch()"
							v-if="eventSearch"
							class="flex pl-3 pr-4 py-2 items-center ml-2 backdrop-blur bg-white/20 rounded-full font-sourcesanspro-semibold text-sm">
							<div class="flex items-center mr-2">
								<ion-icon :icon="search" class="w-[20px] h-[20px]"></ion-icon>
							</div>
							<span>{{ eventSearch }}</span>
						</div>
						<!-- <ion-icon v-else :icon="search" class="w-[20px] h-[20px]" @click="swapSearch()"></ion-icon> -->
					</div>
				</div>

				<div class="flex items-center justify-center mb-4">
					<img :src="assetStore.logoUrl" class="max-w-[70%]" />
				</div>

				<div v-if="showLoggedData()" class="flex items-center justify-center mb-6">
					<ion-button
						class="mr-2 w-[140px] h-[40px] button-solid rounded-full"
						:class="eventType === 'all' ? 'hover:bg-white' : 'backdrop-blur bg-white/20'"
						shape="round"
						:fill="eventType === 'all' ? 'default' : 'clear'"
						@click="eventType = 'all'">
						<span class="text-base2" :class="eventType === 'all' ? '' : 'text-white'">{{
							t('events.all_events')
						}}</span>
					</ion-button>
					<ion-button
						v-if="tenantConfig && !tenantConfig?.hide_tickets"
						class="w-[140px] h-[40px] button-solid rounded-full"
						:class="eventType !== 'all' ? 'hover:bg-white' : 'backdrop-blur bg-white/20 hover:bg-white/20'"
						shape="round"
						:fill="eventType !== 'all' ? 'default' : 'clear'"
						@click="eventType = 'going'">
						<span class="text-base2" :class="eventType !== 'all' ? '' : 'text-white'">{{
							t('events.my_tickets')
						}}</span>
					</ion-button>
				</div>
			</div>

			<div v-show="showSearch" class="w-full">
				<ion-searchbar
					v-model="eventSearch"
					v-show="showSearch"
					class="mb-4 backdrop-blur bg-white/20 rounded-full font-sourcesanspro-semibold text-l events-searchbar"
					shape="round"
					:debounce="500"
					@ion-cancel="clearSearch()"
					@ion-blur="swapSearch()"></ion-searchbar>
				<div v-if="eventSearch" class="flex flex-col mb-2">
					<div class="mb-4">
						<span class="font-sourcesanspro-semibold text-base2">{{
							t('events.autocomplete_suggestions')
						}}</span>
					</div>
					<div
						v-for="(event, idx) in events?.filter((event) =>
							event.name.toLowerCase().includes(eventSearch.toLowerCase()),
						)"
						:key="idx"
						class="flex flex-col items-start justify-center font-sourcesanspro-regular text-m2">
						<router-link :to="`/events/${event.id}`">
							<div class="flex items-center mb-4 font-color-text-50">
								<ion-icon class="mr-2" :icon="close"></ion-icon>
								<div
									v-dompurify-html="
										event.name.replaceAll(
											eventSearch,
											`<span class='text-white'><b>${eventSearch}</b></span>`,
										)
									"></div>
							</div>
						</router-link>
					</div>
				</div>
			</div>

			<div v-if="isFetchingEventsDataRequests" class="flex items-center justify-center pt-3">
				<ion-spinner name="dots"></ion-spinner>
			</div>

			<EventsItem
				v-for="(event, idx) in events"
				:id="event.id"
				:key="idx"
				:summary="event.summary ? event.summary : ''"
				:image="event.main_image_url"
				:link="`/events/${event.id}`"
				:title="event.name"
				:ticket-count="event.event_tickets_count"
				:going="event.going"
				:hour="
					event.start_datetime
						? DateTime.fromFormat(event.start_datetime, 'yyyy-MM-dd HH:mm:ss').toFormat('HH:mm')
						: ''
				"
				:location="event.address ? event.address : ''"
				:date="event.start_datetime ? event.start_datetime : ''"
				:show-logged-data="showLoggedData()" />
		</div>

		<ion-infinite-scroll @ionInfinite="ionInfinite">
			<ion-infinite-scroll-content></ion-infinite-scroll-content>
		</ion-infinite-scroll>

		<div class="h-[100px]">&nbsp;</div>
	</ion-content>

	<ion-footer>
		<NavigationBar item-active="events" />
	</ion-footer>
</template>

<style lang="scss" scoped></style>
