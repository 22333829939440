<script setup lang="ts">
import { IonButton, IonContent, IonIcon, IonSpinner, IonItem, IonList } from '@ionic/vue';
import { arrowForwardOutline, checkmarkCircle, close } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import ItemsDetails from '@/shared/components/ui/ItemsDetails.vue';
import TopAlert from '@/shared/components/ui/TopAlert.vue';
import useTenant from '@/shared/composables/useTenant';
import useUtils from '@/shared/composables/useUtils';
import useTransaction from '@/shared/composables/useTransaction';
import { TransactionType, TransactionSubtype } from '@/enums/wallet.enum';
import useUserProfile from '@/modules/profile/composables/useUserProfile';
import { useSharedStore } from '@/stores/shared/sharedStore';
import useTopUp from '@/modules/top-up/composables/useTopUp';
import { onMounted, watch } from 'vue';
import useCurrencies from '@/shared/composables/useCurrencies';

// Init
const { t } = useI18n();
const { getTenantConfigAndStyles } = useTenant();
const { tenantConfig } = getTenantConfigAndStyles();
const { transaction, transactionStatus, goTo } = useTransaction();
const { replaceTopUpGeneralMessageVariables } = useUtils();
const { getUserProfile } = useUserProfile();
const { userProfile } = getUserProfile();
const sharedStore = useSharedStore();

// Apologies to posterity.
// the purpose of the abuse of this hook (with undefined) and the watcher is to make sure that, if we did not explicitly select a payment country before topping up,
// we do so now, so that the 'please select payment method' info block disappears from the topup screen
const { persistDefaultCountryIfNoneWasSet } = useTopUp(tenantConfig, undefined as any);
watch(transactionStatus, (value) => {
	if (value.success) {
		persistDefaultCountryIfNoneWasSet();
	}
});
</script>

<template>
	<ion-content>
		<div
			v-if="!transactionStatus || !transactionStatus.finished"
			class="flex w-full h-full items-center justify-center">
			<ion-spinner name="dots"></ion-spinner>
		</div>
		<div
			v-if="transactionStatus && transactionStatus.finished"
			class="flex w-full"
			:class="[
				{
					'h-full': transactionStatus.success,
				},
			]">
			<div v-if="transactionStatus.success" class="flex flex-col w-full h-full justify-between">
				<TopAlert
					class="mb-2 pt-[75px] pb-12"
					:class="[
						{
							'bg-anykrowd-green':
								!tenantConfig?.viva_wallet_payconiq_preferred_mode ||
								(tenantConfig?.viva_wallet_payconiq_preferred_mode && !sharedStore.shouldUsePayconiq),
							'payconiq':
								tenantConfig?.viva_wallet_payconiq_preferred_mode && sharedStore.shouldUsePayconiq,
						},
					]">
					<div class="flex flex-col items-center justify-center">
						<ion-icon :icon="checkmarkCircle" class="text-white text-[78px] mb-2"></ion-icon>
						<h1
							v-if="transaction.subtype !== TransactionSubtype.PACKAGE"
							class="font-sourcesanspro-semibold text-[40px] mb-4">
							{{ transaction.totalPriceString }}
						</h1>
						<span class="font-sourcesanspro-regular text-m2">
							{{ t(transactionStatus.subTitle) }}
						</span>
					</div>
				</TopAlert>
				<div>
					<div
						v-if="userProfile && tenantConfig && tenantConfig.top_up_general_message"
						v-dompurify-html="
							replaceTopUpGeneralMessageVariables(tenantConfig.top_up_general_message, userProfile)
						"
						class="flex mx-[34px] p-4 mb-2 bg-white/10 rounded-lg"></div>
				</div>
				<div class="flex flex-col w-full px-[34px] align-center mb-[34px]">
					<ItemsDetails
						v-if="transaction.type !== TransactionType.WALLET_MERGE"
						border-class="border-y border-y-slate-200"
						class="mb-4 bg-white rounded-xl"
						text-class="text-dark-blue"
						text-class-two="text-dark-blue/70"
						:title="t('transaction.order_details')"
						:items="
							transaction.items.map((item) => {
								return {
									itemId: item.itemId,
									title: item.description,
									price: item.unitPrice,
									quantity: item.quantity,
									hideQuantity: item.hideQuantity,
									hidePrice: item.hidePrice,
									totalPrice: item.itemPrice,
									discount: item.discount,
									currency: item.currency,
								};
							})
						"
						:total-title="transaction.totalDescription"
						:total-price="transactionStatus.totalPrice"
						:total-price-string="transaction.totalPriceString || '0'" />

					<div v-if="transaction.type === TransactionType.WALLET_MERGE">
						<ion-list class="mb-4 bg-white rounded-xl">
							<div class="flex px-6 py-4 items-center text-dark-blue">
								<span class="font-sourcesanspro-semibold text-l2">{{
									t('transaction.wallet_merged')
								}}</span>
							</div>
							<div class="border-t border-t-slate-200">
								<ion-item
									class="font-sourcesanspro-regular ion-no-padding text-dark-blue/70"
									lines="none">
									<div class="flex px-6 py-2 w-full justify-between">
										<div>
											<span class="text-base">{{ transaction.items[0].description }}</span>
										</div>
									</div>
								</ion-item>
							</div>
						</ion-list>
					</div>

					<ion-button
						class="w-full hover:bg-white hover:rounded-full"
						expand="full"
						size="large"
						shape="round"
						@click="goTo">
						<div class="flex w-full items-center justify-between">
							{{ t('transaction.continue') }}
							<ion-icon :icon="arrowForwardOutline" class="text-dark-blue mr-2"></ion-icon>
						</div>
					</ion-button>
				</div>
			</div>

			<TopAlert v-if="!transactionStatus.success" class="mb-8 pt-[48px] pb-12 bg-anykrowd-red">
				<div class="flex items-center justify-start cursor-pointer" @click="goTo">
					<ion-icon :icon="close" class="text-xl text-white"></ion-icon>
				</div>
				<div class="flex flex-col items-center justify-center">
					<h2 class="mb-4 font-sourcesanspro-semibold text-lg">
						{{ t(transactionStatus.errorTitle) }}
					</h2>
					<span class="w-[337px] text-center font-sourcesanspro-regular text-base2 leading-[26px]">
						{{ t(transactionStatus.errorSubTitle) }}
					</span>
				</div>
			</TopAlert>
		</div>
	</ion-content>
</template>

<style lang="scss" scoped>
ion-item {
	--background: transparent;
	--inner-padding-end: 0;
	--min-height: 30px;
}

.payconiq {
	background-color: #ff4785;
}
</style>
