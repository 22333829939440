import { anykrowdApi } from '@/api/anykrowdApi';
import { Products } from '@/shared/interfaces/tableSpot';

const getProductPrices = async (params?: { ids?: any }): Promise<Products> => {
	const { data } = await anykrowdApi.get<Products>(`/product-prices`, true, { params });
	return data;
};

const useProducts = () => {
	return {
		getProductPrices,
	};
};

export default useProducts;
