<script setup lang="ts">
import router from '@/router';
import { IonButton, IonContent, IonIcon, IonInput } from '@ionic/vue';
import TopBar from '@/shared/components/TopBar.vue';
import { arrowForwardOutline, chevronBackOutline, qrCodeOutline } from 'ionicons/icons';

// Go back
const goBack = () => {
	router.back();
};

const qrRead = async (link: string) => {
	await router.push({
		name: link,
		query: {
			callbackPage: 'redeem-voucher',
		},
	});
};

const goTo = async (link: string) => {
	await router.push({ path: link, query: { callbackPage: 'dashboard' } });
};
</script>

<template>
	<ion-content>
		<div class="flex flex-col h-full">
			<TopBar class="px-[34px]">
				<template #left>
					<div class="flex items-center justify-start cursor-pointer" @click="goBack">
						<ion-icon :icon="chevronBackOutline" class="text-white"></ion-icon>
					</div>
				</template>

				<template #center>
					<div class="flex grow justify-center w-full font-sourcesanspro-regular text-base2 text-white/50">
						Manual activate
					</div>
				</template>
			</TopBar>

			<div class="flex grow flex-col items-center justify-center px-[34px]">
				<div class="flex flex-wrap w-full justify-left item-center p-2 rounded-full background-white-15 mb-4">
					<div class="flex w-full items-center px-6">
						<ion-input
							class="text-center font-sourcesanspro-regular text-l2 tracking-[.20em]"
							name="voucher-code"
							type="text"
							placeholder="xxxx - xxxx - xxxx">
						</ion-input>
						<ion-icon
							class="cursor-pointer"
							:icon="qrCodeOutline"
							slot="end"
							@click="qrRead('qr-reader')"></ion-icon>
					</div>
				</div>
				<div class="font-sourcesanspro-regular text-sm2 font-color-text-50">Redeem your voucher code here.</div>
			</div>

			<div class="flex flex-col w-full px-[34px] align-center mb-8">
				<ion-button class="w-full" expand="full" size="large" shape="round" @click="goTo('/vouchers/success')">
					<div class="flex w-full items-center justify-between">
						Continue
						<ion-icon :icon="arrowForwardOutline" class="text-dark-blue mr-2"></ion-icon>
					</div>
				</ion-button>
			</div>
		</div>
	</ion-content>
</template>

<style lang="scss" scoped></style>
