import { anykrowdApi } from '@/api/anykrowdApi';
import { CacheTimestamp } from '@/shared/interfaces/cacheTimestampt';

const getTimestamps = async (): Promise<CacheTimestamp> => {
	const { data } = await anykrowdApi.get<CacheTimestamp>('/cache/timestamps', false);
	return data;
};

const useCacheService = () => {
	return {
		getTimestamps,
	};
};

export default useCacheService;
