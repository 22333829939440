<script setup lang="ts">
import { bagOutline } from 'ionicons/icons';
import { useCart } from '@/modules/shop/composables/useCart';
import { IonIcon } from '@ionic/vue';

import { CartCategoriesEnum } from '@/enums/values.enum';

// Init
const { getCartTotalItemsByCategory } = useCart();
</script>

<template>
	<router-link to="/shop/shopping-cart">
		<div class="flex items-center justify-left w-full">
			<div class="mr-2">
				<span class="font-sourcesanspro-semibold text-base2"
					>({{ getCartTotalItemsByCategory(CartCategoriesEnum.shop) }})</span
				>
			</div>
			<ion-icon :icon="bagOutline" class="text-white"></ion-icon>
		</div>
	</router-link>
</template>

<style scoped lang="scss"></style>
