import { ref, watch } from 'vue';
import { useQuery } from '@tanstack/vue-query';

import { anykrowdApi } from '@/api/anykrowdApi';
import type { TenantConfig, TenantStyles } from '@/shared/interfaces/tenant';
import useStorageService from '@/shared/composables/useStorageService';

const { get, set } = useStorageService();

// Get config
const getConfig = async (): Promise<TenantConfig> => {
	// Get tenant options
	const { data } = await anykrowdApi.get<TenantConfig>('/tenant/config', false);

	// Update cache
	set('tenantConfig', data);

	return data;
};

// Get styles
const getStyles = async (): Promise<TenantStyles> => {
	// Get tenant styles
	const { data } = await anykrowdApi.get<TenantStyles>('/tenant/styles', false);

	// Update cache
	set('tenantStyles', data);

	return data;
};

// getTenantConfigAndStyles
const getTenantConfigAndStyles = () => {
	// Tenant Config
	// const tenantConfig = ref<TenantConfig>()!;
	const { data: tenantConfig, isSuccess: isSuccessTenantConfig } = useQuery({
		queryKey: ['tenantConfig'],
		queryFn: () => getConfig(),
		enabled: !window.apiUrl.includes('REPLACE_API_URL'),
		cacheTime: 1000 * 60 * 60 * 24 * 365,
		//staleTime: 1000 * 60, // avoid to reload tenant in short periods of time
		retry: 3,
	});

	// watch(
	// 	tenantConfigData,
	// 	() => {
	// 		if (tenantConfigData.value) {
	// 			tenantConfig.value = { ...tenantConfigData.value };
	// 		}
	// 	},
	// 	{ immediate: true },
	// );

	// Tenant Styles
	// const tenantStyles = ref<TenantStyles>()!;
	const { data: tenantStyles, isSuccess: isSuccessTenantStyles } = useQuery({
		queryKey: ['tenantStyles'],
		queryFn: () => getStyles(),
		enabled: !window.apiUrl.includes('REPLACE_API_URL'),
		cacheTime: 1000 * 60 * 60 * 24 * 365,
		staleTime: 1000 * 60, // avoid to reload tenant in short periods of time
		retry: 3,
	});

	// watch(
	// 	tenantStylesData,
	// 	() => {
	// 		if (tenantStylesData.value) {
	// 			tenantStyles.value = { ...tenantStylesData.value };
	// 		}
	// 	},
	// 	{ immediate: true },
	// );

	function anySocialLoginEnabled() {
		return (
			tenantConfig?.value?.enable_apple_login ||
			tenantConfig?.value?.enable_facebook_login ||
			tenantConfig?.value?.enable_google_login
		);
	}

	function showLoggedData() {
		return tenantConfig?.value?.enable_full_guest_mode === false;
	}

	// Return
	return {
		isSuccessTenantConfig,
		isSuccessTenantStyles,
		tenantConfig,
		tenantStyles,
		anySocialLoginEnabled,
		showLoggedData,
	};
};

const useTenant = () => {
	// Return
	return {
		getConfig,
		getStyles,
		getTenantConfigAndStyles,
	};
};

export default useTenant;
