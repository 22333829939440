<script setup lang="ts">
import router from '@/router';
import LabelTopLeft from '@/shared/components/ui/LabelTopLeft.vue';
import { IonButton, IonIcon } from '@ionic/vue';
import { timeOutline } from 'ionicons/icons';

const props = defineProps({
	accepted: { type: Boolean, default: false },
	description: { type: String, required: true },
	hour: { type: String, required: true },
	image: { type: String, required: true },
	link: { type: String, required: true },
	location: { type: String, required: true },
	price: { type: Number, required: true },
	priceCurrency: { type: String, required: true },
	priceOriginal: { type: Number },
	time: { type: String, required: true },
	title: { type: String, required: true },
});

const goTo = async (link: string) => {
	await router.push(link);
};
</script>

<template>
	<div class="w-full max-w-[325px] mb-6 bookings-reservations-item rounded-md backdrop-blur-3xl">
		<div class="relative p-3">
			<div class="bookings-reservations-detail-image relative">
				<img :src="props.image" class="w-full rounded-md" />
				<LabelTopLeft
					v-if="props.accepted"
					class="border-l-anykrowd-item-added-green"
					label-container-class="left-0 top-3"
					label="Accepted" />
			</div>
		</div>

		<div class="flex flex-col items-start px-3">
			<div class="mb-1">
				<span class="font-sourcesanspro-semibold text-l2 leading-none">{{ props.title }}</span>
			</div>
			<div class="flex items-center w-full">
				<template v-if="!props.accepted">
					<div class="mr-1">
						<span
							v-if="props.priceOriginal"
							class="font-sourcesanspro-semibold text-sm text-white/40 line-through">
							{{ props.priceCurrency }}{{ props.priceOriginal }}
						</span>
					</div>
					<span class="font-sourcesanspro-bold text-m2 text-white">
						{{ props.priceCurrency }}{{ props.price }}
					</span>
				</template>
				<div class="flex flex-col w-full" v-if="props.accepted">
					<span class="font-sourcesanspro-semibold text-base2 text-white leading-none">{{ props.hour }}</span>
					<div class="flex justify-between mt-2">
						<div class="flex items-center">
							<ion-icon class="mr-1" :icon="timeOutline"></ion-icon>
							<span class="font-sourcesanspro-regular text-sm2 text-white/50">{{ props.time }}</span>
						</div>
						<div class="flex items-center font-sourcesanspro-semibold text-sm text-white/30">ID 45</div>
					</div>
				</div>
			</div>
		</div>
		<div class="flex flex-col mt-10 mb-4 px-4 font-sourcesanspro-regular text-base2 text-white/50">
			{{ props.description }}
		</div>

		<div class="flex justify-center mb-4 px-4">
			<ion-button class="h-[48px]" color="primary" fill="outline" shape="round" @click="goTo(props.link)">
				<span class="font-sourcesanspro-semibold text-base2">View Details</span>
			</ion-button>
			<ion-button v-if="!props.accepted" class="w-[145px] h-[48px]" color="primary" fill="outline" shape="round">
				<span class="font-sourcesanspro-semibold text-base2" @click="goTo('/events/tickets')">Book</span>
			</ion-button>
			<ion-button v-if="props.accepted" class="w-[145px] h-[48px]" shape="round">
				<span class="font-sourcesanspro-semibold text-base2" @click="goTo('/events/tickets')">Ticket</span>
			</ion-button>
		</div>
	</div>
</template>

<style scoped>
.bookings-reservations-item {
	background: url('@/statics/svg/bookings-reservations/ticket-background.svg') no-repeat;
	background-size: 100%;
	position: relative;
}

.bookings-reservations-detail-image::after {
	background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(20, 19, 33, 1));
	border-radius: 0.375rem;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 1;
}

.bookings-reservations-detail-image img {
	display: block;
	width: 100%;
	height: auto;
	position: relative;
	z-index: 1;
}
</style>
