import { Discount, Discountable } from '@/shared/interfaces/discount';
import { Currency } from '@/shared/interfaces/balance';

export class GenericProduct extends Discountable {
	id: number;
	currency_id?: number;
	currency?: Currency;
	name: string;
	description: null | string;
	price: string | number;
	vat_percentage: string | number;
	category_name?: string;

	constructor(data: {
		id: number;
		name: string;
		currency_id?: number;
		currency?: Currency;
		description: null | string;
		price: string | number;
		vat_percentage: string | number;
		category_name?: string;
		discount?: Discount;
	}) {
		super(data);
		this.id = data.id;
		this.currency_id = data.currency_id;
		this.currency = data.currency;
		this.name = data.name;
		this.description = data.description;
		this.price = data.price;
		this.vat_percentage = data.vat_percentage;
		this.category_name = data.category_name;
	}

	getFullPrice(): number {
		return Number(this.price);
	}
}

export interface GroupedGenericProducts {
	category_id: number;
	category_name: string;
	products: GenericProduct[];
}
