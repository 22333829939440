<script setup lang="ts">
import { useRoute } from 'vue-router';
import router from '@/router';
import { IonButton, IonContent, IonIcon, IonSpinner } from '@ionic/vue';
import { chevronBackOutline, share } from 'ionicons/icons';
import { DateTime } from 'luxon';
import { useI18n } from 'vue-i18n';

import BottomGradient from '@/shared/components/ui/BottomGradient.vue';
import TicketItem from '@/shared/components/tickets/TicketItem.vue';
import TopBar from '@/shared/components/TopBar.vue';
import useEvents from '@/modules/events/composables/useEvents';
import useUserProfile from '@/modules/profile/composables/useUserProfile';
import { useSharedStore } from '@/stores/shared/sharedStore';

// Init
const { t } = useI18n();
const route = useRoute();
const eventId = +route.params.id;
const { addToCalendar, getEvent, getEventTickets } = useEvents();
const { event } = getEvent(eventId);
const { eventTickets, isFetchingEventTickets } = getEventTickets(eventId);
const { getUserProfile } = useUserProfile();
const { userProfile } = getUserProfile();
const sharedStore = useSharedStore();

// Save previous visited page if is the first time the page is visited
// as go back is not always the same page
if (!sharedStore.customNavigation.find((item) => item.key === route.path) && router.options.history.state.back) {
	sharedStore.customNavigation.push({ key: route.path, value: router.options.history.state.back?.toString() });
}

// Handle go back
const goBack = async () => {
	const previousRoute = sharedStore.customNavigation.find((item) => item.key === route.path)?.value;
	const indexOfPreviousRoute = sharedStore.customNavigation.findIndex((item) => item.key === route.path);
	if (indexOfPreviousRoute != -1) {
		sharedStore.customNavigation.splice(indexOfPreviousRoute, 1);
		await router.push({ path: previousRoute! });
	} else {
		await router.push({ path: '/dashboard' });
	}
};
</script>

<template>
	<ion-content>
		<TopBar class="px-[34px]">
			<template #left>
				<div class="flex items-center justify-start cursor-pointer">
					<div class="flex items-center justify-center" @click="goBack()">
						<ion-icon :icon="chevronBackOutline" class="text-white"></ion-icon>
					</div>
				</div>
			</template>

			<template #center>
				<div class="flex justify-center w-full font-sourcesanspro-semibold text-l2">{{ event?.name }}</div>
			</template>
		</TopBar>

		<div v-if="event" class="px-[34px] mb-2">
			<div class="flex justify-between mb-4">
				<div class="flex flex-col">
					<span class="font-sourcesanspro-semibold text-l2">
						{{ DateTime.fromFormat(event.start_datetime, 'yyyy-MM-dd HH:mm:ss').toFormat('dd') }}
					</span>
					<span class="font-sourcesanspro-regular text-base font-color-text-50">
						{{ DateTime.fromFormat(event.start_datetime, 'yyyy-MM-dd HH:mm:ss').toFormat('MMMM') }}
					</span>
				</div>
				<div class="flex flex-col">
					<span class="font-sourcesanspro-semibold text-l2">{{
						DateTime.fromFormat(event.start_datetime, 'yyyy-MM-dd HH:mm:ss').toFormat('cccc')
					}}</span>
					<span class="font-sourcesanspro-regular text-base font-color-text-50"
						>{{ DateTime.fromFormat(event.start_datetime, 'yyyy-MM-dd HH:mm:ss').toFormat('hh:ss a') }} -
						{{ t('events.tickets.end') }}</span
					>
				</div>
				<div class="flex justify-right">
					<ion-button class="ion-no-margin ion-no-padding" fill="clear" @click="addToCalendar(event)">
						<ion-icon :src="require('@/statics/svg/events/calendar-add.svg')" class="h-[54px] w-[54px]" />
					</ion-button>
				</div>
			</div>
		</div>

		<div v-if="isFetchingEventTickets" class="flex items-center justify-center mb-4">
			<ion-spinner name="dots"></ion-spinner>
		</div>

		<div class="flex flex-col items-center">
			<div
				v-for="(ticket, idx) in eventTickets"
				:key="idx"
				class="flex flex-col w-full items-center justify-center">
				<TicketItem
					:qr-code="ticket.qr_code"
					:link="`/events/${event?.id}/tickets/${ticket.id}/details`"
					:name="
						ticket.participant?.full_name
							? ticket.participant?.full_name
							: userProfile?.full_name
								? userProfile?.full_name
								: ''
					"
					:type="
						ticket.combo_ticket_type
							? ticket.combo_ticket_type.name
							: ticket.ticket_type?.name
								? ticket.ticket_type?.name
								: ''
					"
					:venue="ticket.event.address"
					:value-only="!!ticket.ticket_type?.value_only"
					class="mb-4" />

				<hr v-if="idx === 0" class="w-full mb-4 h-px bg-white/10 border-0" />
			</div>
		</div>

		<BottomGradient />
	</ion-content>
</template>

<style lang="scss" scoped></style>
