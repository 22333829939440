import { ref, Ref } from 'vue';
import * as Yup from 'yup';
import { StringSchema } from 'yup';
import { isValid } from 'iban-validator-js';

import { RefundInfo } from '@/shared/interfaces/balance';

const useProfileRefunds = (refundInfo: Ref<RefundInfo | undefined>) => {
	const amount = ref(0);
	const contentMessage = ref('');
	const iban = ref('');
	const isModalOpen = ref(false);

	Yup.addMethod<StringSchema>(Yup.string, 'validIban', function () {
		return Yup.string().test('test-valid-iban', 'Not valid IBAN number', (iban) => {
			iban = iban?.replaceAll(' ', '');
			return iban !== '' && iban !== undefined && iban !== null && isValid(iban);
		});
	});

	// Using yup to generate a validation schema
	const schema = Yup.object().shape({
		amount: Yup.lazy(() => {
			if (refundInfo && refundInfo.value) {
				return Yup.number().min(1).max(refundInfo.value.total_amount).required();
			} else {
				return Yup.number().min(1).required();
			}
		}),
		iban: Yup.string().when('amount', ([amount], schema) => {
			if (
				refundInfo &&
				refundInfo.value &&
				refundInfo.value.manual_refund_enabled &&
				refundInfo.value.online_amount < +amount
			) {
				return Yup.string().validIban('no valid iban').required('Field is required');
			}
			return Yup.string().nullable();
		}),
	});

	const showHideModal = (openState: boolean, clear: boolean) => {
		isModalOpen.value = openState;

		if (clear) {
			contentMessage.value = '';
		}
	};

	return {
		amount,
		contentMessage,
		iban,
		isModalOpen,
		schema,
		showHideModal,
	};
};

export default useProfileRefunds;
