import router from '@/router';
import { computed, ref, watch } from 'vue';
import { useMutation, useQuery } from '@tanstack/vue-query';
import { useI18n } from 'vue-i18n';

import { anykrowdApi } from '@/api/anykrowdApi';
import { RfidTag } from '@/shared/interfaces/rfidTag';
import { TopUpType } from '@/shared/interfaces/topUp';
import { TransactionType } from '@/enums/wallet.enum';
import type { CurrentBalance } from '@/shared/interfaces/balance';
import type { TopUpTransactionMetadata } from '@/shared/interfaces/topUp';
import type { Transaction } from '@/shared/interfaces/transaction';
import useLoading from '@/shared/composables/useLoading';
import useToast from '@/shared/composables/useToast';
import useUrlHelpers from '@/shared/composables/useUrlHelpers';
import useUtils from '@/shared/composables/useUtils';
import useWallet from '@/modules/wallet/composables/useWallet';
import { useTransactionStore } from '@/stores/transaction/transactionStore';

// Init
const { getTokenFromSelfLinkingUrl, isValidUrl } = useUrlHelpers();
const { presentLoading } = useLoading();
const { presentToast } = useToast();
const { getCurrentWindowLocationOrigin } = useUtils();
const { mergeWalletByRfidTag } = useWallet();

const activateRfid = async (rfidActivationToken: string): Promise<{ encrypted_rfid_tag: string }> => {
	const { data } = await anykrowdApi.post('/rfid-tags/activate', { token: rfidActivationToken }, false);
	return data;
};

// _getWalletBalance
const _getWalletBalance = async (dataParams: { encryptedRfidTag?: string }): Promise<CurrentBalance> => {
	const { data } = await anykrowdApi.get<CurrentBalance>(
		`/rfid-tags/${dataParams.encryptedRfidTag}/wallet-balance`,
		false,
	);
	return data;
};

const getWalletBalance = (encryptedRfidTag?: string) => {
	// Init
	const topUpMetadataGuest = ref<TopUpTransactionMetadata>();

	const {
		data: walletBalanceData,
		refetch: refetchWalletBalanceData,
		remove: removeWalletBalanceData,
	} = useQuery({
		queryKey: ['rfidWallet'],
		queryFn: () => _getWalletBalance({ encryptedRfidTag }),
		retry: 3,
		enabled: false,
	});

	// Watch userProfile and registrationFormData
	watch(
		walletBalanceData,
		async () => {
			if (walletBalanceData.value) {
				// Prepare topUpMetadata
				topUpMetadataGuest.value = {
					transactionId: '',
					paymentAmount:
						walletBalanceData.value.latest_topup_order && walletBalanceData.value.latest_topup_order.price
							? +walletBalanceData.value.latest_topup_order.price
							: 0,
					paymentAmountTotal: walletBalanceData.value.current_balance,
					paymentStatus:
						walletBalanceData.value.latest_topup_order &&
						walletBalanceData.value.latest_topup_order.payment_status
							? walletBalanceData.value.latest_topup_order.payment_status
							: 'no_latest_topup',
					transactionType: TopUpType.GUEST_TOP_UP,
				};
			}
		},
		{ immediate: true },
	);

	// Return
	return {
		removeWalletBalanceData,
		refetchWalletBalanceData,
		topUpMetadataGuest,
		walletBalanceData,
	};
};

// _searchRfidTag
const _searchRfidTag = async (dataParams: { encrypted_rfid_tag: string }): Promise<RfidTag> => {
	const { data } = await anykrowdApi.post<RfidTag>(`/rfid-tags/search`, dataParams, false);
	return data;
};

// searchRfidTag
const searchRfidTag = () => {
	// Init
	const rfidTag = ref<RfidTag>()!;

	const { data: rfidTagData, mutate: rfidTagMutate, isSuccess } = useMutation(_searchRfidTag);

	// Watch
	watch(
		rfidTagData,
		() => {
			if (rfidTagData.value) {
				rfidTag.value = { ...rfidTagData.value };
			}
		},
		{ immediate: true },
	);

	// Return
	return {
		isSuccessRfidTag: computed(() => isSuccess.value),
		rfidTag,
		rfidTagMutate,
	};
};

// useRfidGuest
const useRfidGuest = () => {
	// Init
	const { t } = useI18n();
	const transactionStore = useTransactionStore();
	const { setItemsAndTotal } = transactionStore;

	/**
	 * This function merges the wallets linked to the RFID to the user account
	 */
	const _mergeWallet = async (
		encryptedRfidTag: string,
		confirmedEncryptedRfidTag: string,
		current_balance: number,
		redirectUrl: string,
		uuid: string,
	): Promise<void> => {
		try {
			const data = await mergeWalletByRfidTag(
				encryptedRfidTag,
				confirmedEncryptedRfidTag,
				current_balance,
				redirectUrl,
			);

			// Create a transaction and redirect
			const transaction: Transaction = {
				additionalInfo: {
					isGuestTopUp: true,
					encryptedRfidTag,
				},
				items: [
					{
						description: t('rfid.guest.merged_wallet.rfid_linked', { rfidTag: uuid }),
						quantity: 0,
						itemPrice: 0,
						unitPrice: 0,
					},
				],
				type: TransactionType.WALLET_MERGE,
				totalDescription: '',
				totalPriceString: current_balance.toString(),
				totalPriceNumber: current_balance,
				totalPriceWithoutFee: 0,
			};

			setItemsAndTotal(transaction);
			await router.push('/transaction');
		} catch (error: any) {
			// We show the API error as warning because this will be a validation error
			// We will get this error when the confirmation call failed
			if (error && error.response && error.response.data.message) {
				await presentToast('top', error?.response.data.message, 5000, 'danger');
			} else {
				await presentToast('top', t('common.error'), 5000, 'danger');
			}
		}
	};

	// onScanQR: Handle scanned QR code
	const onScanQR = async (
		scannedQrCode: string,
		encryptedRfidTag: string,
		walletId: string,
		rfidTag: string,
		amount: string,
	) => {
		const url = scannedQrCode;

		if (url && isValidUrl(url) && (url.includes('activate/') || url.includes('c=') || url.includes('R='))) {
			const selfLinkingBraceletToken = getTokenFromSelfLinkingUrl(url);
			if (selfLinkingBraceletToken) {
				const loading = await presentLoading(t('general.please_wait'));
				const redirectUrl = `${getCurrentWindowLocationOrigin()}/dashboard?merged_wallet_id=${walletId}&rfid_tag=${rfidTag}&amount=${amount}`;
				try {
					const data = await activateRfid(selfLinkingBraceletToken);
					_mergeWallet(encryptedRfidTag, data.encrypted_rfid_tag, +amount, redirectUrl, rfidTag);
					loading.dismiss();
				} catch (e: any) {
					loading.dismiss();
					await presentToast('top', e?.error?.message, 5000, 'danger');
				}
			}
		} else {
			// this.utilsService.showCommonWarning('The scanned RFID tag was not valid. Please try another one');
		}
	};

	return {
		activateRfid,
		getWalletBalance,
		onScanQR,
		searchRfidTag,
	};
};

export default useRfidGuest;
