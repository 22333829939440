<script setup lang="ts"></script>

<template>
	<div class="grid grid-cols-10 ion-no-border no-color alternative-header h-[84px] mt-8">
		<slot name="left" />
		<div class="flex items-center justify-center col-span-8">
			<slot name="center" />
		</div>
		<div class="flex items-center justify-center">
			<slot name="right" />
		</div>
	</div>
</template>

<style lang="scss" scoped>
ion-header {
	--background: transparent;
}
</style>
