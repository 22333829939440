<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { IonContent, IonIcon } from '@ionic/vue';
import { chevronBackOutline, linkOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import RfidWalletBox from '@/modules/rfid/components/RfidWalletBox.vue';
import router from '@/router';
import TopBar from '@/shared/components/TopBar.vue';
import useRfidGuest from '@/modules/rfid/composables/useRfidGuest';
import useUserProfile from '@/modules/profile/composables/useUserProfile';
import { useAssetsStore } from '@/stores/shared/assetsStore';
import { useAuthStore } from '@/stores/auth/authStore';

// Init
const { t } = useI18n();
const assetStore = useAssetsStore();

const route = useRoute();
const encryptedRfidTag = route.params.id.toString();
const { searchRfidTag } = useRfidGuest();
const { isSuccessRfidTag, rfidTag, rfidTagMutate } = searchRfidTag();
const { getUserProfile } = useUserProfile();
const { userProfile } = getUserProfile();
const authStore = useAuthStore();
const continueLinking = ref(false);

// onMountedHook
onMounted(async () => {
	rfidTagMutate({ encrypted_rfid_tag: encryptedRfidTag });
	if (route.query.continueLinking) {
		continueLinking.value = true;
	}
});

watch(
	[rfidTag, userProfile],
	() => {
		if (rfidTag.value && userProfile.value) {
			if (authStore.isAuthenticated() && rfidTag.value.user && rfidTag.value.user.id === userProfile.value.id) {
				router.push({ path: '/top-up', query: { backToDashboard: 'true' } });
			}
		}
	},
	{ immediate: true },
);
</script>

<template>
	<ion-content>
		<TopBar class="px-[34px]">
			<template #left>
				<div class="flex items-center justify-start cursor-pointer">
					<router-link to="/dashboard" class="flex items-center justify-center">
						<ion-icon :icon="chevronBackOutline" class="text-white"></ion-icon>
					</router-link>
				</div>
			</template>
			<template #center>
				<div class="flex justify-center w-full font-sourcesanspro-semibold text-l2">
					{{ t('rfid.guest.dashboard') }}
				</div>
			</template>
		</TopBar>

		<div>
			<div class="px-[34px] icon flex justify-center mb-4">
				<img :src="assetStore.logoUrl" class="max-w-[70%]" />
			</div>

			<div v-if="rfidTag" class="mb-2 px-[34px] flex">
				<div class="ml-2">
					<router-link :to="`/wallet/history?id=${encryptedRfidTag}`">
						<div class="flex w-[100px] h-[34px] items-center justify-center p-2 bg-white rounded-full">
							<span class="font-sourcesanspro-semibold text-xs2 text-anykrowd-dark">{{
								t('components.rfid_wallet_box.history')
							}}</span>
						</div>
					</router-link>
				</div>
				<div v-if="rfidTag.user">
					<div class="flex w-[100px] h-[34px] items-center justify-between p-2 bg-white rounded-full">
						<div class="flex items-center mr-2">
							<ion-icon :icon="linkOutline" class="text-anykrowd-dark text-[#707070]"></ion-icon>
						</div>
						<span class="font-sourcesanspro-semibolditalic text-xs2 text-[#707070]">
							{{ t('components.rfid_wallet_box.rfid_linked') }}
						</span>
					</div>
				</div>
			</div>
			<RfidWalletBox
				:encryptedRfidTag="encryptedRfidTag"
				:isSuccessRfidTag="isSuccessRfidTag"
				:rfidTag="rfidTag"
				:user-profile="userProfile"
				:continue-linking="continueLinking"></RfidWalletBox>
		</div>
	</ion-content>
</template>

<style scoped></style>
