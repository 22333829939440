<script setup lang="ts">
import { ref, toRef } from 'vue';
import { IonIcon, IonInput, IonLabel } from '@ionic/vue';
import { useField, useIsFormValid } from 'vee-validate';
import { checkmarkOutline, eyeOffOutline, eyeOutline } from 'ionicons/icons';

const props = defineProps({
	disabled: { type: Boolean, default: false },
	iconLeft: { type: String, default: '' },
	inputClass: { type: String, default: 'font-sourcesanspro-regular text-base' },
	inputMode: {
		type: String as () => 'text' | 'search' | 'email' | 'tel' | 'url' | 'none' | 'numeric' | 'decimal' | undefined,
		default: 'text',
	},
	label: { type: String },
	name: { type: String, required: true },
	manualSubmit: { type: Function },
	placeholder: { type: String, default: '' },
	step: { type: String as () => string | undefined },
	showErrorMessage: { type: Boolean, default: true },
	showInputSuccess: { type: Boolean, default: true },
	shouldHandleBlur: { type: Boolean, default: true },
	shouldValidate: { type: Boolean, default: true },
	successMessage: { type: String, default: '' },
	type: { type: String, default: 'text' },
	value: { type: String as () => string | number | null | undefined, default: null },
	wrapperClass: { type: String, default: '' },
});

const emit = defineEmits(['inputChanged', 'enterHit']);

// Handle password visibility
const type = ref(props.type);
const showPassword = ref(false);
const swapPasswordVisibility = () => {
	type.value = type.value === 'password' ? 'text' : 'password';
	showPassword.value = !showPassword.value;
};

// use `toRef` to create reactive references to `name` prop which is passed to `useField`
// this is important because vee-validte needs to know if the field name changes
// https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
const name = toRef(props, 'name');

// we don't provide any rules here because we are using form-level validation
// https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
const { value, errorMessage, handleChange, meta, setValue } = useField(name, undefined, {
	initialValue: props.value,
});

const isFormValid = useIsFormValid();

const handleInputChange = (event: any, enterHit: boolean) => {
	if (props.shouldValidate) {
		if (props.shouldHandleBlur) {
			handleChange(event);
		} else {
			if (event.detail && event.detail.value) {
				handleChange(event);
				setValue(event.detail.value);
			}
		}
	}
	handleInputUpdate(event);
	if (props.manualSubmit && isFormValid && enterHit) {
		props.manualSubmit();
	}
};

// Validate form
const handleInputUpdate = (event: any) => {
	// Let the parent know when the input change
	emit('inputChanged', isFormValid, name, event);
};
</script>

<template>
	<div v-show="type !== 'hidden'" class="flex flex-col w-full">
		<div v-if="props.label" class="mb-2">
			<ion-label class="font-sourcesanspro-regular">{{ props.label }}</ion-label>
		</div>
		<div
			class="wrapper border-white-15"
			:class="[
				{
					'border-error': !!errorMessage && !!wrapperClass,
					'border-success': props.showInputSuccess && meta.valid && meta.dirty,
				},
				wrapperClass,
			]">
			<div class="flex w-full items-center px-6">
				<ion-icon
					:icon="props.iconLeft"
					class="mr-3"
					color="primary"
					v-if="props.iconLeft"
					slot="start"></ion-icon>
				<ion-input
					:class="props.inputClass"
					:name="props.name"
					:type="type"
					:disabled="props.disabled"
					:placeholder="props.placeholder"
					:step="props.step"
					:inputmode="props.inputMode"
					:value="value"
					@keydown.enter="(event) => handleInputChange(event, true)"
					@ionChange="(event) => handleInputChange(event, false)"
					@ionBlur="(event) => handleInputChange(event, false)">
				</ion-input>
				<ion-icon
					:icon="checkmarkOutline"
					slot="end"
					v-if="props.type !== 'password' && props.showInputSuccess && meta.valid && meta.dirty"></ion-icon>

				<ion-icon
					:icon="eyeOutline"
					slot="end"
					v-if="props.type === 'password' && showPassword"
					@click="swapPasswordVisibility"></ion-icon>
				<ion-icon
					:icon="eyeOffOutline"
					slot="end"
					v-if="props.type === 'password' && !showPassword"
					@click="swapPasswordVisibility"></ion-icon>
			</div>
		</div>
		<p class="mb-4 font-sourcesanspro-regular text-sm font-color-error" v-show="errorMessage && showErrorMessage">
			{{ errorMessage }}
		</p>
	</div>
</template>

<style lang="scss" scoped>
ion-label {
	color: rgb(255 255 255 / 0.5) !important;
	font-size: 12px !important;
}

div.wrapper {
	border-radius: 9999px;
	height: 52px;

	p {
		&::first-letter {
			text-transform: capitalize;
		}
	}

	ion-icon {
		cursor: pointer;
	}

	// .border-success input {
	//   background-color: var(--success-bg-color);
	//   color: var(--success-color);
	// }

	&.border-success {
		border: 1px solid var(--clientx-color-success) !important;

		ion-icon {
			color: var(--clientx-color-success);
		}
	}
}
</style>
