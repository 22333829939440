import { defineStore } from 'pinia';

export const useQuizStore = defineStore('quizStore', {
	state: () => ({
		currentStep: 0,
	}),
	actions: {
		decrementStep() {
			this.currentStep--;
		},
		getStep() {
			return this.currentStep;
		},
		incrementStep() {
			this.currentStep++;
		},
		setStep(step: number) {
			this.currentStep = step;
		},
	},
});
