<script setup lang="ts">
import { ref, watch } from 'vue';
import { IonItem, IonLabel, IonList, IonSpinner } from '@ionic/vue';

import SmsPhoneHandler from '@/modules/profile/components/SmsPhoneHandler.vue';
import ToggleInput from '@/shared/components/sign-up-forms/ToggleInput.vue';
import useUserProfile from '@/modules/profile/composables/useUserProfile';
import useProfileAdditionalConfig from '@/modules/profile/composables/useProfileAdditionalConfig';

// Init
const isUpdatingCellPhoneNumber = ref(false);
const { getUserProfile } = useUserProfile();
const { onNotificationChange, onCellPhoneNumberChange } = useProfileAdditionalConfig();
const { userProfile, isSuccessUserProfile, isFetchingUserProfile } = getUserProfile();
const phoneNumberState = ref(!!userProfile.value?.notification_sms);

// Check if userProfile changed and update phone number state
watch(userProfile, () => {
	phoneNumberState.value = !!userProfile.value?.notification_sms;
});

// If cellphone number changed, block continue if not valid
const cellPhoneNumberSent = async (userPhoneNumber: string) => {
	isUpdatingCellPhoneNumber.value = true;
	await onCellPhoneNumberChange(userPhoneNumber, () => {
		isUpdatingCellPhoneNumber.value = false;
	});
};
</script>

<template>
	<div>
		<ion-list v-if="isSuccessUserProfile" class="ion-no-padding bg-transparent" lines="full">
			<ion-item class="w-full border-b border-white/5" lines="none">
				<div class="flex w-full items-center justify-between">
					<ion-label class="font-sourcesanspro-regular text-base">
						{{ $t('auth.sign_up_additional_page.step_six.email') }}
					</ion-label>
					<ToggleInput
						name="emailNotifications"
						slot="end"
						:value="!!userProfile?.notification_email"
						@on-state-changed="onNotificationChange" />
				</div>
			</ion-item>
			<ion-item class="w-full border-b border-white/5" lines="none">
				<div class="flex w-full items-center justify-between">
					<ion-label class="font-sourcesanspro-regular text-base">
						{{ $t('auth.sign_up_additional_page.step_six.push') }}
					</ion-label>
					<ToggleInput
						name="push"
						slot="end"
						:value="!!userProfile?.notification_in_app"
						@on-state-changed="onNotificationChange" />
				</div>
			</ion-item>
			<ion-item class="w-full" lines="none">
				<div class="flex w-full items-center justify-between">
					<ion-label class="font-sourcesanspro-regular text-base">
						{{ $t('auth.sign_up_additional_page.step_six.sms') }}
					</ion-label>
					<ToggleInput
						name="smsNotifications"
						slot="end"
						:value="!!userProfile?.notification_sms"
						@on-state-changed="onNotificationChange" />
				</div>
			</ion-item>
			<ion-item v-if="!!userProfile?.notification_sms" class="w-full mb-4" lines="none">
				<div class="flex flex-col items-center justify-center w-full">
					<div v-if="!!userProfile?.notification_sms" class="flex w-full items-center justify-between">
						<SmsPhoneHandler
							:cell-phone-number="userProfile.notification_cellphone_number"
							@on-cell-phone-number-sent="cellPhoneNumberSent" />
					</div>
				</div>
			</ion-item>
		</ion-list>
	</div>
</template>

<style scoped></style>
