<script setup lang="ts">
import { IonAvatar, IonItem } from '@ionic/vue';

const props = defineProps({
	image: { type: String, required: true },
	location: { type: String },
	name: { type: String, required: true },
});
</script>

<template>
	<ion-item class="flex justify-between w-full ion-no-padding pb-2" lines="none">
		<ion-avatar slot="start">
			<img :src="props.image" />
		</ion-avatar>
		<div class="grow">
			<div class="flex flex-col">
				<span class="font-sourcesanspro-semibold text-m2">{{ props.name }}</span>
			</div>
		</div>
		<div class="flex items-center justify-center w-[113px] h-[34px] border border-white/50 rounded-full">
			<span class="font-sourcesanspro-semibold text-base2 text-white/50">+ Add friend</span>
		</div>
	</ion-item>
</template>

<style lang="scss" scoped>
ion-item {
	--inner-padding-end: 0;
}
</style>
