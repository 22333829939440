import { ref, Ref } from 'vue';

const useModal = () => {
	// Init
	const isModalOpen = ref(false);
	const modalTitle: Ref<string | undefined> = ref(undefined);
	const modalContent: Ref<string | undefined> = ref(undefined);

	// Show / Hide modal
	const showHideModal = (openState: boolean, title?: string, content?: string) => {
		modalTitle.value = title;
		modalContent.value = content;
		isModalOpen.value = openState;
	};

	// Return
	return {
		isModalOpen,
		modalTitle,
		modalContent,
		showHideModal,
	};
};

export default useModal;
