import axios from 'axios';
import { useAuthStore } from '@/stores/auth/authStore';
import { storeToRefs } from 'pinia';

const authApi = axios.create();

// Add a request interceptor
authApi.interceptors.request.use(
	function (config) {
		config.baseURL = window.apiUrl;
		const authStore = useAuthStore();
		const { user } = storeToRefs(authStore);

		if (user.value.token) {
			config.headers.Authorization = `Bearer ${user.value.token}`;
		}
		return config;
	},
	function (error) {
		// Do something with request error
		return Promise.reject(error);
	},
);

export default authApi;
