import { defineStore } from 'pinia';

export const useAssetsStore = defineStore('assets', {
	state: () => {
		return {
			logoUrl: '',
			backgroundUrl: '',
		};
	},
	actions: {
		updateAssetsValues(assetsValues: { logoUrl: string; backgroundUrl: string }) {
			console.log('Updating pinia', assetsValues);
			this.logoUrl = assetsValues.logoUrl;
			this.backgroundUrl = assetsValues.backgroundUrl;
		},
	},
});
