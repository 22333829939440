<script setup lang="ts">
import router from '@/router';
import { IonContent, IonIcon, IonSpinner, IonButton } from '@ionic/vue';
import { bagHandleOutline, chevronBackOutline, imageOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import { CartCategoriesEnum } from '@/enums/values.enum';
import { useCart } from '@/modules/shop/composables/useCart';
import ShoppingCartIcon from '@/shared/components/shop/ShoppingCartIcon.vue';
import TopBar from '@/shared/components/TopBar.vue';
import useShop from '@/modules/shop/composables/useShop';

// Props
const props = defineProps({
	id: { type: String, required: true },
});

// Init
const { t } = useI18n();
const { getCategorizedProducts } = useShop();
const { categoriesWithProducts } = getCategorizedProducts(+props.id);
const { getCartTotalItemsByCategory, getCartTotalPrice } = useCart();

// Go back
const goBack = () => {
	router.back();
};
</script>

<template>
	<ion-content>
		<div class="relative flex flex-col px-[34px]">
			<TopBar class="mb-16">
				<template #left>
					<div class="flex items-center justify-start cursor-pointer" @click="goBack()">
						<ion-icon :icon="chevronBackOutline" class="text-white"></ion-icon>
					</div>
				</template>

				<template #center>
					<div class="flex justify-center w-full font-sourcesanspro-semibold text-l2">
						{{ props.id ? t('shop.pages.title.category') : t('shop.pages.title.all') }}
					</div>
				</template>

				<template #right><shopping-cart-icon /></template>
			</TopBar>
		</div>
		<div v-if="!categoriesWithProducts" class="flex items-center justify-center">
			<ion-spinner name="dots"></ion-spinner>
		</div>
		<div v-else class="relative flex flex-col px-[34px]">
			<div class="flex flex-col mb-7" v-for="(category, i) in categoriesWithProducts" :key="i">
				<div class="font-sourcesanspro-semibold text-l2 mb-4 first-letter:capitalize">
					{{ category.category_name.toLowerCase() }}
				</div>
				<div class="grid grid-cols-2 gap-4 w-full">
					<router-link
						:to="product.in_stock ? { name: 'shop-product-detail', params: { id: product.id } } : {}"
						v-for="product in category.products"
						:key="product.id"
						class="flex justify-center"
						:class="[
							{
								'opacity-50': !product.in_stock,
							},
						]">
						<div
							class="relative flex flex-col items-center justify-end w-[155px] h-[155px] rounded-lg overflow-hidden backdrop-blur-3xl bg-white/10">
							<img
								v-if="product.image_url"
								:src="product.image_url"
								class="absolute w-[100%] h-[100%] object-fit-cover" />
							<ion-icon v-else :icon="imageOutline" class="text-white"></ion-icon>
							<div class="absolute w-[100%] h-[100%] rounded-lg bg-black/60"></div>
							<div class="mb-1 text-center z-10">
								<span class="font-sourcesanspro-bold text-base2">{{ product.name }}</span>
							</div>
							<div class="flex items-center mb-4 z-10">
								<div v-if="product.discount" class="flex items-center mb-4">
									<div class="mr-2">
										<span class="font-sourcesanspro-semibold text-sm2 line-through text-white/30">
											&euro; {{ product.price }}
										</span>
									</div>
									<span class="font-sourcesanspro-bold text-l2"
										>€{{ product.discountedPrice()?.toFixed(2) }}</span
									>
								</div>

								<div v-if="!product.discount" class="flex items-center mb-4">
									<span class="font-sourcesanspro-bold text-l2">€{{ product.price }}</span>
								</div>
							</div>
						</div>
					</router-link>
				</div>
			</div>
		</div>

		<div class="h-[100px]">&nbsp;</div>

		<div class="fixed button-container h-[150px] px-[34px] left-1/2 -translate-x-1/2 bottom-0 w-full">
			<div class="relative z-10 w-full h-full">
				<div class="absolute w-full bottom-0 mb-[34px]">
					<ion-button
						class="w-full h-[60px] hover:bg-white hover:rounded-full"
						expand="full"
						size="large"
						shape="round"
						router-link="/shop/shopping-cart">
						<div class="flex w-full justify-between items-center font-sourcesanspro-semibold text-l2">
							<ion-icon
								:icon="bagHandleOutline"
								class="w-[28px] h-[28px] text-dark-blue"
								slot="end"></ion-icon>
							<span
								>{{ t('shop.cart.title') }} ({{
									getCartTotalItemsByCategory(CartCategoriesEnum.shop)
								}})</span
							>
							<span>€{{ getCartTotalPrice(CartCategoriesEnum.shop).toFixed(2) }}</span>
						</div>
					</ion-button>
				</div>
			</div>
		</div>
	</ion-content>
</template>

<style lang="scss" scoped></style>
