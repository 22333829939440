<script setup lang="ts">
import { onMounted, onBeforeUnmount, ref } from 'vue';
import { RouterLink } from 'vue-router';
import { useRoute } from 'vue-router';
import { IonButton, IonContent, IonIcon, IonInput, IonSpinner } from '@ionic/vue';
import { bagHandleOutline, chevronBackOutline, closeOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import { TransactionType } from '@/enums/wallet.enum';
import { useCart } from '@/modules/shop/composables/useCart';
import { useCheckout } from '@/shared/composables/useCheckout';
import ModalOne from '@/shared/components/ui/ModalOne.vue';
import TopBar from '@/shared/components/TopBar.vue';
import useSelfOrdering from '@/modules/self-ordering/components/useSelfOrdering';
import useTableSpotLocation from '@/modules/self-ordering/composables/useTableSpotLocation';

// Init
const { t } = useI18n();
const locationSearch = ref('');
const route = useRoute();
const selfOrderingSpotId = route.params.id.toString();
const {
	clearLocationAndContinue,
	closeLocationModal,
	getProductCategories,
	getTableSpotByQRCode,
	locationModalOpen,
	tableSpot,
} = useSelfOrdering(selfOrderingSpotId);
const { getLocation, saveLocation } = useTableSpotLocation();
const { isFetchingProductCategories, tableSpotProductCategories } = getProductCategories(selfOrderingSpotId);
const { getCartSize } = useCart();
const { processCheckout } = useCheckout();

// selectLocation
const selectLocation = (locationSelected: string) => {
	saveLocation(locationSelected);
	locationModalOpen.value = false;
};

onMounted(async () => {
	await getTableSpotByQRCode();
});

onBeforeUnmount(() => {
	// Close modal
	document.getElementsByTagName('ion-modal')[0]?.dismiss();
});

// clearSearch
const clearSearch = () => {
	locationSearch.value = '';
};
</script>

<template>
	<ion-content>
		<ModalOne :title="t('self_ordering.what_is_your_location')" :is-open="locationModalOpen">
			<template #content>
				<div class="mt-6 mb-2 font-sourcesanspro-regular text-base2 text-white">
					{{ t('self_ordering.enter_location') }}
				</div>
				<ion-input
					v-model="locationSearch"
					class="rounded-full bg-red font-sourcesanspro-semibold text-base2 text-white location-searchbar bg-black/10"
					shape="round"
					:placeholder="`${t('self_ordering.enter_manual_location_type')} ${tableSpot?.manual_location_type}`"
					:debounce="500"
					@ion-cancel="clearSearch()"></ion-input>
				<div class="mb-4">
					<!-- <span v-show="!tableSpot?.manual_location_values.split(',').includes(locationSearch)" class="text-xs">The provided location is unknown</span> -->
				</div>
				<div class="flex flex-col mb-2">
					<div class="mb-4">
						<span class="font-sourcesanspro-semibold text-base2 text-white">{{
							t('self_ordering.suggestions')
						}}</span>
					</div>
					<div
						v-for="(location, idx) in tableSpot?.manual_location_values
							.split(',')
							.filter((location: string) =>
								location.toLowerCase().includes(locationSearch.toLowerCase()),
							)"
						:key="idx"
						class="flex flex-col items-start justify-center font-sourcesanspro-regular text-m2 text-white cursor-pointer"
						:class="[
							{
								'bg-[#514f5a]': getLocation() === location,
							},
						]"
						@click="selectLocation(location)">
						<div class="flex p-2 items-center">
							<div
								v-dompurify-html="
									location.replaceAll(
										locationSearch,
										`<span class='text-white'><b>${locationSearch}</b></span>`,
									)
								"></div>
						</div>
					</div>
				</div>
				<ion-button
					@click="selectLocation(locationSearch)"
					:disabled="!tableSpot?.manual_location_values.split(',').includes(locationSearch)"
					expand="full"
					shape="round">
					{{ t('self_ordering.continue') }}
				</ion-button>
			</template>
			<template #actions>
				<div class="flex items-center justify-center">
					<div class="flex items-center justify-center w-[60px] h-[60px] rounded-full bg-white">
						<ion-icon
							class="text-[38px] text-black cursor-pointer"
							:icon="closeOutline"
							@click="closeLocationModal()"></ion-icon>
					</div>
				</div>
			</template>
		</ModalOne>
		<div class="flex flex-col justify-center px-[34px]">
			<TopBar>
				<template #left>
					<div class="flex items-center justify-start cursor-pointer" @click="clearLocationAndContinue()">
						<router-link to="/dashboard" class="flex items-center justify-center">
							<ion-icon :icon="chevronBackOutline" class="text-white"></ion-icon>
						</router-link>
					</div>
				</template>
			</TopBar>

			<div class="flex flex-col items-center justify-center mb-6">
				<h2 class="font-sourcesanspro-semibold text-m2">{{ t('self_ordering.page_title') }}</h2>
				<span
					v-if="tableSpot && tableSpot.manual_location"
					class="font-sourcesanspro-regular text-l2 text-white/60"
					>{{ getLocation() }}</span
				>
				<span
					v-if="tableSpot && !tableSpot.manual_location"
					class="font-sourcesanspro-regular text-l2 text-white/60"
					>{{ tableSpot?.friendly_id }}</span
				>
			</div>

			<div v-if="isFetchingProductCategories" class="flex items-center justify-center mb-4">
				<ion-spinner name="dots"></ion-spinner>
			</div>
			<div v-if="tableSpotProductCategories" class="flex justify-center">
				<div class="grid grid-cols-2 gap-4 w-[326px]">
					<router-link
						v-for="(category, idx) in tableSpotProductCategories"
						class="flex items-center justify-center"
						:key="idx"
						:to="`/self-ordering/cart/${category.id}`">
						<div
							class="flex flex-col items-center justify-center w-[155px] h-[155px] rounded-lg backdrop-blur-3xl bg-white/10">
							<img
								v-if="category.image_url"
								:src="category.image_url"
								class="w-[56px] h-[56px] invert mb-4" />
							<span class="font-sourcesanspro-semibold text-m2 text-center capitalize">{{
								category.name.toLowerCase()
							}}</span>
						</div>
					</router-link>
				</div>
			</div>
		</div>

		<div class="fixed button-container mb-[34px] px-[34px] left-1/2 -translate-x-1/2 bottom-0 w-full">
			<div class="relative w-full h-full">
				<ion-button
					@click.prevent="
						processCheckout(TransactionType.SELF_ORDER, {
							tableSpot: tableSpot,
							location: tableSpot.location ? tableSpot.location : tableSpot.manual_location_type,
						})
					"
					class="w-full"
					expand="full"
					size="large"
					shape="round">
					<div class="flex w-full justify-between items-center font-sourcesanspro-semibold text-m2">
						{{
							getCartSize() > 0
								? `${getCartSize()} ${getCartSize() === 1 ? t('self_ordering.item') : t('self_ordering.items')}`
								: t('self_ordering.empty')
						}}
						<ion-icon :icon="bagHandleOutline" class="text-dark-blue" slot="end"></ion-icon>
					</div>
				</ion-button>
			</div>
		</div>

		<div class="h-[100px]">&nbsp;</div>
	</ion-content>
</template>

<style lang="scss" scoped></style>
