<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import router from '@/router';
import { IonButton, IonContent, IonIcon, IonLabel, IonSpinner } from '@ionic/vue';
import { chevronBackOutline, chevronDownOutline, closeOutline } from 'ionicons/icons';
import { Form } from 'vee-validate';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import { useI18n } from 'vue-i18n';
import { DateTime } from 'luxon';

import CircleStencil from '@/shared/components/CircleStencil.vue';
import DateInputMask from '@/shared/components/sign-up-forms/DateInputMask.vue';
import hexRgb from 'hex-rgb';
import ModalTwo from '@/shared/components/ui/ModalTwo.vue';
import rgbHex from 'rgb-hex';
import SelectInput from '@/shared/components/sign-up-forms/SelectInput.vue';
import TextInput from '@/shared/components/sign-up-forms/TextInput.vue';
import TopBar from '@/shared/components/TopBar.vue';
import useCamera from '@/shared/composables/useCamera';
import useCustomForm from '@/shared/composables/useCustomForm';
import useLoading from '@/shared/composables/useLoading';
import useProfileEdit from '@/modules/profile/composables/useProfileEdit';
import UserAvatar from '@/shared/components/user-ui/UserAvatar.vue';
import useRegistrationForm from '@/modules/auth/composables/useRegistrationForm';
import useUtils from '@/shared/composables/useUtils';

// Init
const { t } = useI18n();
const editingImage = ref(false);
const img = ref();
const editedImg = ref();
const { savePhoto } = useProfileEdit();
const { photo, cameraGetPhoto } = useCamera();
const { presentLoading } = useLoading();
const loading = ref();
const { getRegistrationFormForAdditionalData } = useRegistrationForm();
const {
	registrationFormOriginal,
	validationSchemaOneStep,
	userProfile,
	refetchUserProfile,
	removeUserProfile,
	isFetchedUserProfile,
} = getRegistrationFormForAdditionalData();
const { calculateShade } = useUtils();
const primary_color = window.primary_color;
const primaryRgb = hexRgb(window.primary_color, { format: 'array' });
const primaryShade = '#' + rgbHex(calculateShade(primaryRgb.toString(), 0.2));

// useCustomForm
const { isSuccess, isFormValid, customOnSubmit, onInputChange, validate } = useCustomForm(
	validationSchemaOneStep.value,
	'/profile',
	'post',
	'profile.saved',
	'profile.saving_error',
	5000,
);

// Validate form on mounted
onMounted(async () => {
	const { valid } = await validate();
	if (valid) {
		isFormValid.value = valid;
	}
});

const handleSubmit = (event: Event) => {
	if (event.target) {
		let formData = {};
		const data = new FormData(event.target);
		const dataEntries = Object.fromEntries(data.entries());
		if (dataEntries.date_of_birth) {
			formData = {
				...dataEntries,
				'date_of_birth': DateTime.fromFormat(dataEntries.date_of_birth, 'dd-MM-yyyy').toFormat('yyyy-MM-dd'),
			};
		} else {
			formData = {
				...dataEntries,
			};
		}
		customOnSubmit(formData);
	}
	event.preventDefault();
};

const refetchUserAndRedirect = async () => {
	// fetch user
	await removeUserProfile();
	await refetchUserProfile();
	img.value = null;
	editingImage.value = false;
	await router.push({ path: `/profile` });
	if (loading.value) {
		loading.value.dismiss();
	}
};

// Refetch user after profile change
watch(isSuccess, async () => {
	await refetchUserAndRedirect();
});

// getPhoto
const getPhoto = async () => {
	await cameraGetPhoto();

	watch(photo, async () => {
		if (photo.value && userProfile.value) {
			img.value = photo.value;
			editingImage.value = true;
		}
	});
};

// change
const change = async ({ coordinates, canvas }: { coordinates: any; canvas: any }) => {
	editedImg.value = canvas.toDataURL();
};

const close = () => {
	img.value = null;
	editingImage.value = false;
};

// saveModifiedPhoto
const saveModifiedPhoto = async () => {
	loading.value = await presentLoading(t('general.please_wait'));
	try {
		await savePhoto(editedImg.value, userProfile.value!);
	} catch (e) {
		loading.value.dismiss();
	}

	setTimeout(async () => {
		await refetchUserAndRedirect();
	}, 1000);
};
</script>

<template>
	<ion-content :style="`background: linear-gradient(${primary_color}, ${primaryShade});`">
		<ModalTwo :is-open="editingImage">
			<div class="flex flex-col h-full pt-[64px] px-[34px] pb-[34px] bg-black items-center justify-between">
				<div class="flex items-center justify-center w-[60px] h-[60px] rounded-full bg-white">
					<ion-icon
						class="text-[38px] text-black cursor-pointer"
						:icon="closeOutline"
						@click="close"></ion-icon>
				</div>
				<div>
					<cropper
						class="w-[300px] h-[300px]"
						:src="img"
						@change="change"
						:stencil-component="CircleStencil" />
				</div>
				<ion-button
					class="w-full hover:bg-white hover:rounded-full"
					expand="full"
					size="large"
					shape="round"
					@click="saveModifiedPhoto()">
					<div class="flex w-full items-center justify-center">
						{{ t('profile.save_changes') }}
					</div>
				</ion-button>
			</div>
		</ModalTwo>

		<div class="flex flex-col h-full justify-between">
			<div class="px-[34px] w-full">
				<TopBar>
					<template #left>
						<div class="flex items-center justify-start cursor-pointer">
							<router-link to="/profile" class="flex items-center justify-center">
								<ion-icon :icon="chevronBackOutline" class="text-white"></ion-icon>
							</router-link>
						</div>
					</template>

					<template #center>
						<div class="flex justify-center w-full font-sourcesanspro-semibold text-l2">
							{{ t('profile.edit_profile') }}
						</div>
					</template>
				</TopBar>

				<div class="flex justify-center mb-8 cursor-pointer">
					<ion-spinner v-if="!isFetchedUserProfile" name="dots"></ion-spinner>
					<div v-if="userProfile && isFetchedUserProfile">
						<UserAvatar
							v-if="!img"
							:container-class="'w-[100px] h-[100px]'"
							:image-class="'w-[100px] h-[100px]'"
							:src="
								userProfile.profile_image_url
									? userProfile.profile_image_url
									: require('@/statics/img/no_profile_pic.jpg')
							"
							alt="User icon"
							:showStatus="false"
							@click="getPhoto" />
					</div>
				</div>
				<div v-if="registrationFormOriginal && userProfile && validationSchemaOneStep" class="relative mb-4">
					<form
						id="profileEditForm"
						@submit="(event: Event) => handleSubmit(event)"
						:validation-schema="validationSchemaOneStep"
						validate-on-mount>
						<div v-for="(field, index) in registrationFormOriginal.data" :key="index">
							<div v-if="field.field_name === 'email'" class="mb-4">
								<ion-label class="font-sourcesanspro-regular text-xs2 text-white/50">{{
									field.field_label
								}}</ion-label>
								<div>
									{{ userProfile[field.field_name] }}
								</div>
								<TextInput
									wrapper-class="flex flex-wrap justify-left item-center mb-3 border border-solid focus-within:background-white-15"
									:value="userProfile[field.field_name]"
									:label="field.field_label"
									:name="field.field_name"
									:placeholder="field.field_label"
									type="hidden"
									v-if="field.field_name === 'email'"
									@inputChanged="onInputChange" />
							</div>

							<TextInput
								wrapper-class="flex flex-wrap justify-left item-center mb-3 border border-solid focus-within:background-white-15"
								:value="userProfile[field.field_name]"
								:label="field.field_label"
								:name="field.field_name"
								:placeholder="field.field_label"
								type="text"
								v-if="
									(field.type === 'text' || field.type === 'tel') &&
									!['email'].includes(field.field_name)
								"
								@inputChanged="onInputChange" />

							<SelectInput
								:name="field.field_name"
								:placeholder="$t('auth.sign_up_additional_page.step_four.placeholder')"
								:values="field.options"
								:label="field.field_label"
								:icon="chevronDownOutline"
								:value="userProfile[field.field_name]"
								v-if="field.type === 'select'"
								@inputChanged="onInputChange" />

							<DateInputMask
								wrapper-class="flex flex-wrap justify-left item-center mb-3 border border-solid focus-within:background-white-15"
								customInputClass="justify-start"
								:name="field.field_name"
								:label="field.field_label"
								:value="userProfile[field.field_name]"
								type="date"
								v-if="field.type === 'date'"></DateInputMask>

							<div
								v-if="registrationFormOriginal.data.length > 3 && index === 2"
								class="mt-4 mb-4 font-sourcesanspro-semibold text-base2">
								{{ t('profile.other_information') }}
							</div>
						</div>
					</form>
				</div>
			</div>

			<div class="px-[34px] mb-[34px]">
				<ion-button
					class="w-full hover:bg-white hover:rounded-full"
					:disabled="!isFormValid"
					expand="full"
					form="profileEditForm"
					size="large"
					shape="round"
					type="submit">
					<div class="flex w-full items-center justify-center">
						{{ t('profile.save_changes') }}
					</div>
				</ion-button>
			</div>
		</div>
	</ion-content>
</template>

<style lang="scss" scoped>
ion-list {
	background: transparent;
}
</style>
