export enum TransactionType {
	TOP_UP = 'top_up',
	VOUCHER_TOP_UP = 'voucher_top_up',
	TOP_UP_COST = 'top_up_cost',
	PAYMENT = 'payment',
	SELF_ORDER = 'self_order',
	SHOP_ORDER = 'shop_order',
	REFUND_TRANSACTION_COST = 'refund_transaction_cost',
	REFUND_HOLDING = 'refund_holding',
	REFUNDED_AMOUNT = 'refunded_amount',
	REVERT_REFUND_HOLDING = 'revert_refund_holding',
	RELEASE_REFUND_HOLDING = 'release_refund_holding',
	WALLET_MERGE = 'wallet_merge',
}

export enum TransactionSubtype {
	PACKAGE = 'package',
}
