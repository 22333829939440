import { defineStore } from 'pinia';

export interface NavigationArray {
	key: string;
	value: string;
}

export const useSharedStore = defineStore('shared', {
	state: () => {
		return {
			customNavigation: [] as NavigationArray[],
			EMAIL_REGX: /^[^@\s]+@[^@\s]+\.[^@\s]+$/,
			shouldUsePayconiq: true,
			shouldShowLogin: false,
		};
	},
	persist: true,
});
