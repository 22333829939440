<script setup lang="ts">
import { ref } from 'vue';
import { IonButton, IonContent, IonIcon } from '@ionic/vue';
import { cashOutline, chevronBackOutline, helpBuoyOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import { Wallet } from '@/shared/interfaces/balance';
import TopBar from '@/shared/components/TopBar.vue';
import useTenant from '@/shared/composables/useTenant';
import useUserProfile from '@/modules/profile/composables/useUserProfile';
import WalletBox from '@/shared/components/WalletBox.vue';

// Init
const { t } = useI18n();
const route = useRoute();
const { getTenantConfigAndStyles } = useTenant();
const { tenantConfig } = getTenantConfigAndStyles();
const { getUserProfile } = useUserProfile();
const { userProfile } = getUserProfile();
const selectedWallet = ref();
const walletId = route.params.wallet_id?.toString();

const onWalletSet = (wallet: Wallet) => {
	selectedWallet.value = wallet;
};
</script>

<template>
	<ion-content>
		<TopBar class="px-[34px]">
			<template #left>
				<div class="flex items-center justify-start cursor-pointer">
					<router-link to="/dashboard" class="flex items-center justify-center">
						<ion-icon :icon="chevronBackOutline" class="text-white"></ion-icon>
					</router-link>
				</div>
			</template>

			<template #center>
				<div class="flex justify-center w-full font-sourcesanspro-semibold text-l2">
					{{ t('wallet.manage_wallet.title') }}
				</div>
			</template>

			<template #right>
				<span></span>
			</template>
		</TopBar>

		<div class="w-full">
			<div class="px-[34px]">
				<div class="mb-2 p-4 rounded-md bg-white/10 text-white/60">
					{{ t('wallet.manage_wallet.help') }}
				</div>
			</div>

			<WalletBox
				v-if="tenantConfig"
				:user="`${userProfile?.firstname} ${userProfile?.lastname}`"
				@on-wallet-set="onWalletSet"
				:wallet-id="walletId"></WalletBox>

			<div v-if="selectedWallet" class="px-[34px] grid grid-cols-2 grid-flow-col gap-2 justify-stretch mb-4">
				<div class="backdrop-blur-xl bg-white/10 h-[90px] rounded cursor-pointer">
					<router-link :to="`/wallet/history/${selectedWallet.id}`">
						<ion-button
							class="flex flex-col items-center justify-center w-full h-full ion-no-padding ion-no-margin transparent">
							<div class="flex flex-col items-center justify-center">
								<ion-icon class="mt-1" :icon="cashOutline"></ion-icon>
								<span class="font-sourcesanspro-semibold text-sm2 text-white">{{
									t('wallet.manage_wallet.transactions')
								}}</span>
							</div>
						</ion-button>
					</router-link>
				</div>

				<div
					v-if="selectedWallet && tenantConfig?.enable_refund"
					class="backdrop-blur-xl bg-white/10 h-[90px] rounded cursor-pointer">
					<router-link :to="`/wallet/refunds/${selectedWallet.id}`">
						<ion-button
							class="flex flex-col items-center justify-center w-full h-full ion-no-padding ion-no-margin transparent">
							<div class="flex flex-col items-center justify-center">
								<ion-icon class="mt-1" :icon="helpBuoyOutline"></ion-icon>
								<span class="font-sourcesanspro-semibold text-sm2 text-white">{{
									t('wallet.manage_wallet.request_refunds')
								}}</span>
							</div>
						</ion-button>
					</router-link>
				</div>
			</div>
		</div>
	</ion-content>
</template>

<style lang="scss" scoped></style>
