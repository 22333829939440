export enum ApiSyncState {
	UNSYNCED = 'UNSYNCED',
	SYNCING = 'SYNCING',
	SYNCED = 'SYNCED',
}

export interface CachedItem<T> {
	value: T;
	expiryDate?: number;
	updatedAt?: number;
	userID?: number;
}

export abstract class CacheMeta {
	syncState: ApiSyncState;
	lastUpdatedAt: number;

	constructor() {
		this.syncState = ApiSyncState.UNSYNCED;
		this.lastUpdatedAt = 0;
	}
}
