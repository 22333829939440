<script setup lang="ts">
import { ref } from 'vue';
import router from '@/router';
import LabelTopLeft from '@/shared/components/ui/LabelTopLeft.vue';
import { IonButton, IonIcon } from '@ionic/vue';
import { location as locationIcon, timeOutline } from 'ionicons/icons';
import { DateTime } from 'luxon';

const props = defineProps({
	id: { type: Number, required: true },
	date: { type: String, required: true },
	going: { type: Boolean, default: false },
	hour: { type: String, required: true },
	image: { type: String, required: true },
	link: { type: String, required: true },
	location: { type: String, required: true, default: '' },
	summary: { type: String as () => string | null | undefined, required: true, default: '' },
	ticketCount: { type: Number },
	title: { type: String, required: true },
	showLoggedData: { type: Boolean, required: true, default: true },
});
</script>

<template>
	<div class="w-full mb-4 event-item bg-cover bg-center rounded-md backdrop-blur-2xl bg-black/50">
		<div class="relative">
			<div class="event-detail-image aspect-[16/9] relative overflow-hidden">
				<img :src="props.image" class="w-full h-full relative rounded-lg object-cover object-center" />
				<LabelTopLeft
					v-if="props.going"
					class="border-l-anykrowd-item-added-green"
					label-container-class="left-1 top-2"
					label="GOING" />
			</div>
		</div>
		<div class="flex flex-col p-4">
			<div class="divide-y divide-white/10">
				<div>
					<h1 class="text-l2">{{ props.title }}</h1>
					<div class="pb-2">
						<div v-if="props.date" class="flex items-center">
							<span class="font-sourcesanspro-regular text-xs2 text-white/30">
								{{ DateTime.fromFormat(props.date, 'yyyy-MM-dd HH:mm:ss').toFormat('dd/MM/yyyy') }} @
								{{ props.hour }}
								-
								{{ props.location }}
							</span>
						</div>
					</div>
				</div>
				<div class="pt-2 mb-6">
					<p class="font-sourcesanspro-regular text-sm2 text-white/60">
						{{ props.summary }}
					</p>
				</div>
			</div>
			<div class="flex items-center justify-center">
				<router-link :to="props.link" class="w-full">
					<ion-button
						:class="[
							{
								'w-full': !props.going,
								'w-[145px]': props.going,
							},
						]"
						class="h-[48px]"
						color="primary"
						fill="outline"
						shape="round">
						<span class="font-sourcesanspro-semibold text-base2">View Details</span>
					</ion-button>
				</router-link>

				<router-link
					v-if="props.showLoggedData && props.going && props.ticketCount"
					:to="`/events/${id}/tickets`">
					<ion-button class="w-[145px] h-[48px] hover:bg-white hover:rounded-full" shape="round">
						<span class="font-sourcesanspro-semibold text-base2"> {{ props.ticketCount }} Ticket(s) </span>
					</ion-button>
				</router-link>
			</div>
		</div>
	</div>
</template>

<style scoped>
.event-detail-image::after {
	background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(20, 19, 33, 1));
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 1;
}
</style>
