import { useTableSpotLocationStore } from '@/stores/self-ordering/tableSpotLocationStore';
import { TableSpot } from '@/shared/interfaces/tableSpot';

const useTableSpotLocation = () => {
	// Init
	const tableSpotStore = useTableSpotLocationStore();

	// getQrCode
	const getQrCode = () => {
		return tableSpotStore.qrCode;
	};

	// getTableSpot
	const getTableSpot = () => {
		return tableSpotStore.tableSpot;
	};

	// getLocation
	const getLocation = () => {
		return tableSpotStore.location;
	};

	// removeLocation
	const removeLocation = () => {
		return tableSpotStore.removeLocation();
	};

	// resetTableSpotStore
	const resetTableSpotStore = () => {
		tableSpotStore.reset();
	};

	// saveLocation
	const saveLocation = (location: string) => {
		tableSpotStore.saveLocation(location);
	};

	// saveTableSpot
	const saveTableSpot = (tableSpot: TableSpot, qrCode: string) => {
		return tableSpotStore.saveTableSpot(tableSpot, qrCode);
	};

	return {
		getLocation,
		getQrCode,
		getTableSpot,
		removeLocation,
		resetTableSpotStore,
		saveLocation,
		saveTableSpot,
	};
};

export default useTableSpotLocation;
