<script setup lang="ts">
import { ref } from 'vue';
import { IonIcon } from '@ionic/vue';
import { chevronDownOutline, chevronUpOutline } from 'ionicons/icons';

const props = defineProps({
	hasDescription: { type: Boolean },
	icon: { type: String, required: true },
	isSelected: { type: Boolean, default: false },
	image: { type: String, default: null },
	price: { type: String, required: true },
	title: { type: String, required: true },
});

// Init
const isOpen = ref(false);
const emit = defineEmits(['onSelectItem']);

const open = ($event: MouseEvent) => {
	isOpen.value = !isOpen.value;
	// $event.stopImmediatePropagation();
};

const selectItem = ($event: MouseEvent) => {
	$event.stopPropagation();

	emit('onSelectItem');
};
</script>

<template>
	<div class="overflow-hidden mb-2 rounded-lg w-full cursor-pointer">
		<div
			:class="[
				{
					'bg-white/10 text-white': !isSelected,
					'bg-[var(--ion-color-primary)] text-black': isSelected,
				},
			]"
			class="flex w-full justify-between h-[64px] cursor-pointer transition-[background-color] duration-500 ease-in-out"
			slot="header"
			:color="isSelected ? 'primary' : undefined">
			<div class="flex items-center px-4 py-2 grow" @click="selectItem">
				<div class="flex items-center justify-center mr-4">
					<ion-icon
						v-if="image === null"
						:icon="props.icon"
						:class="[
							{
								'border-white/10 text-white fill-none': !isSelected,
								'border-black/10 text-black': isSelected,
							},
						]"
						class="w-[22px] h-[22px] p-2 border rounded-full"></ion-icon>
					<img
						v-else
						:src="props.image"
						class="w-[40px] h-[40px] border rounded-full"
						:class="[
							{
								'border-white/10 text-white fill-none': !isSelected,
								'border-black/10 text-black': isSelected,
							},
						]" />
				</div>
				<div class="flex flex-col justify-center grow select-none">
					<span class="font-sourcesanspro-semibold text-base2">{{ props.title }}</span>
					<span class="font-sourcesanspro-semibold text-xs2">{{ props.price }}</span>
				</div>
			</div>
			<div
				v-if="hasDescription"
				class="flex items-center px-4 py-2 font-sourcesanspro-semibold text-lg24"
				@click="open">
				<ion-icon
					:icon="isOpen ? chevronUpOutline : chevronDownOutline"
					:class="[
						{
							'border-white/10 text-white/50 fill-none': !isSelected,
							'border-black/10 text-black/50': isSelected,
						},
					]"></ion-icon>
			</div>
		</div>
		<div
			v-if="hasDescription"
			class="bg-white/5 transition-[max-height] duration-500 ease-in-out select-none"
			:class="[
				{
					'max-h-0': !isOpen,
					'max-h-screen': isOpen,
				},
			]">
			<div class="p-4">
				<slot />
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped></style>
