export interface DownloadableItem {
	href: string;
	linkName: string;
	data?: object;
	extension?: string;
}

export interface DownloadModalProps {
	title?: string;
	downloadItem: DownloadableItem;
	downloadType?: DownloadType;
}

export enum DownloadType {
	TICKET = 'download_modal.download_ticket',
	ICS = 'download_modal.download_ics',
	INVOICE = 'download_modal.download_invoice',
	MESSAGE = 'download_modal.download_message',
	QRCODE = 'download_modal.download_qrcode',
}
