<script setup lang="ts">
import { useRoute } from 'vue-router';
import { IonContent, IonButton, IonIcon } from '@ionic/vue';
import { chevronBackOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import ScanQR from '@/shared/components/ScanQR.vue';
import TopBar from '@/shared/components/TopBar.vue';
import useRfidGuest from '@/modules/rfid/composables/useRfidGuest';
import useToast from '@/shared/composables/useToast';

// Init
const { t } = useI18n();
const { onScanQR } = useRfidGuest();
const route = useRoute();
const amount = route.query.amount?.toString();
const encryptedRfidTag = route.query.encryptedRfidTag?.toString();
const walletId = route.query.walletId?.toString();
const rfidTag = route.query.rfidTag?.toString();
const { presentToast } = useToast();

const onScanQRPrepare = async (scannedQrQcode: string) => {
	if (encryptedRfidTag && walletId && rfidTag && amount) {
		onScanQR(scannedQrQcode, encryptedRfidTag, walletId, rfidTag, amount);
	} else {
		await presentToast('top', t('rfid.guest.scan_error'), 5000, 'danger');
	}
};
</script>

<template>
	<ion-content>
		<div class="flex flex-col items-center justify-between h-full">
			<TopBar class="px-[34px]">
				<template #left>
					<div class="flex items-center justify-start cursor-pointer">
						<router-link to="/dashboard" class="flex items-center justify-center">
							<ion-icon :icon="chevronBackOutline" class="text-white"></ion-icon>
						</router-link>
					</div>
				</template>
			</TopBar>

			<div class="flex items-center justify-center">
				<ScanQR
					@on-scan-q-r="onScanQRPrepare"
					:start-camera="true"
					:permissionTitle="t('scan_qr.camera_permission_title')"
					:permissionDescription="t('scan_qr.camera_permission_description')"></ScanQR>
			</div>

			<div class="h-[200px]"></div>
		</div>
	</ion-content>
</template>

<style scoped></style>
