import { useRoute } from 'vue-router';

import { useCart } from '@/modules/shop/composables/useCart';
import useUserProfile from '@/modules/profile/composables/useUserProfile';
import { Event, EventParticipant, EventTicket } from '@/shared/interfaces/event';

const useEventsTicketsAddMembers = () => {
	// Init
	const route = useRoute();
	const { getCartItem, updateCartAdditionalInfoByIndex } = useCart();
	const { getUserProfile } = useUserProfile();
	const { userProfile } = getUserProfile();
	const cartItemIndex = route.query.cartItemIndex?.toString();

	// updateTicketData
	const updateTicketData = (event: Event) => {
		// If user is not participant of ticket and there is no cartItemIndex
		if (!event.user_is_participant && !cartItemIndex) {
			const name = userProfile.value?.firstname;
			const email = userProfile.value?.email;
			const user_id = userProfile.value?.id;
			const cartItemIndex = 0;

			// Update cart item
			updateCartAdditionalInfoByIndex(cartItemIndex, {
				email,
				name,
				user_id,
			});
		} else {
			// User already participant or cartItemIndex is sent
			const name = route.query.friendFullName?.toString();
			const email = route.query.friendEmail?.toString();
			const user_id = route.query.friendId?.toString();

			// Update cart item
			if (name && email && user_id && cartItemIndex) {
				const cartItem = getCartItem(+cartItemIndex);
				updateCartAdditionalInfoByIndex(+cartItemIndex, {
					...cartItem?.additionalInfo,
					email,
					name,
					user_id,
				});
			}
		}
	};

	return {
		updateTicketData,
	};
};

export default useEventsTicketsAddMembers;
