<script setup lang="ts">
import router from '@/router';
import { IonContent, IonIcon, IonFooter } from '@ionic/vue';
import NavigationBar from '@/shared/components/ui/NavigationBar.vue';
import { chevronBackOutline } from 'ionicons/icons';
import PersonItemCompact from '@/shared/components/ui/PersonItemCompact.vue';
import TopBar from '@/shared/components/TopBar.vue';

// Go back
const goBack = () => {
	router.back();
};

const friendsCount = 4;
</script>

<template>
	<ion-content>
		<div>
			<TopBar class="px-[34px]">
				<template #left>
					<div class="flex items-center justify-start cursor-pointer" @click="goBack">
						<ion-icon :icon="chevronBackOutline" class="text-white"></ion-icon>
					</div>
				</template>

				<template #center>
					<div class="flex grow justify-center w-full font-sourcesanspro-semibold text-l2">Add Friend</div>
				</template>
			</TopBar>

			<div
				:class="[
					{
						'justify-between': friendsCount === 0,
					},
				]"
				class="flex flex-col h-full px-[34px]">
				<div>
					<ion-searchbar
						placeholder="Search now"
						class="mb-4 backdrop-blur bg-white/20 rounded-full font-sourcesanspro-semibold text-l"
						shape="round"></ion-searchbar>
				</div>

				<div class="flex flex-col items-start">
					<PersonItemCompact
						name="BullPuppy"
						description="Aenean tempor, diam volutpat tempor varius, enim ligula.."
						:image="require('@/statics/svg-delete-after-implement/person-8.svg')"
						last-time-online="Today" />
					<PersonItemCompact
						name="April Curtis"
						description="Vivamus rhoncus, quam sed tempor."
						:image="require('@/statics/svg-delete-after-implement/person-9.svg')"
						last-time-online="Yesterday" />
					<PersonItemCompact
						name="Angela Bower"
						description="Vivamus rhoncus, quam sed tempor."
						:image="require('@/statics/svg-delete-after-implement/person-10.svg')"
						last-time-online="Today" />
					<PersonItemCompact
						name="Thomas Magnum"
						description="Aenean tempor, diam volutpat tempor varius, enim ligula.."
						:image="require('@/statics/svg-delete-after-implement/person-11.svg')"
						last-time-online="01/21/2023" />
					<PersonItemCompact
						name="Johnathan Higgins"
						description="Vivamus rhoncus, quam sed tempor."
						:image="require('@/statics/svg-delete-after-implement/person-12.svg')"
						last-time-online="01/17/2023" />
					<PersonItemCompact
						name="Willie Tanner"
						description="Aenean tempor, diam volutpat tempor varius, enim ligula.."
						:image="require('@/statics/svg-delete-after-implement/person-13.svg')"
						last-time-online="12/22/2022" />
					<PersonItemCompact
						name="Sarrah Williams"
						description="Aenean tempor, diam volutpat tempor varius, enim ligula.."
						:image="require('@/statics/svg-delete-after-implement/person-14.svg')"
						last-time-online="12/22/2022" />
				</div>

				<div class="h-[77px] mb-4">&nbsp;</div>
			</div>
		</div>
	</ion-content>

	<ion-footer>
		<NavigationBar item-active="friends" />
	</ion-footer>
</template>

<style lang="scss" scoped></style>
