<script setup lang="ts">
import { closeCircle } from 'ionicons/icons';

const props = defineProps({
	class: { type: String, required: true },
	name: { type: String, required: true },
	image: { type: String, required: true },
});
</script>

<template>
	<div :class="props.class" class="flex justify-between ion-no-padding bg-white/[.15] p-2 rounded-full">
		<ion-avatar slot="start" class="w-[24px] h-[24px] mr-2">
			<img :src="props.image" class="rounded-full" />
		</ion-avatar>
		<div class="mr-2">
			<div class="flex flex-col">
				<span class="font-sourcesanspro-semibold text-m2">{{ props.name }}</span>
			</div>
		</div>
		<ion-icon :icon="closeCircle" class="text-white"></ion-icon>
	</div>
</template>

<style scoped></style>
