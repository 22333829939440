<script setup lang="ts">
import { IonButton, IonContent, IonIcon } from '@ionic/vue';
import router from '@/router';
import ItemStandalone from '@/shared/components/ui/ItemStandalone.vue';
import TopAlert from '@/shared/components/ui/TopAlert.vue';
import { bagHandleOutline, chevronBackOutline, cafeOutline } from 'ionicons/icons';

// Go back
const goBack = () => {
	router.back();
};
</script>

<template>
	<ion-content>
		<div class="flex h-full flex-col justify-between">
			<div>
				<TopAlert class="mb-8 pt-[75px] pb-12 bg-white">
					<div class="flex items-center mb-5">
						<div class="flex items-center justify-start mr-4 cursor-pointer" @click="goBack">
							<ion-icon :icon="chevronBackOutline" class="text-dark-blue"></ion-icon>
						</div>
						<h1 class="flex font-sourcesanspro-semibold text-l2 text-dark-blue">Your returnable cups</h1>
					</div>
					<div class="flex justify-evenly">
						<div class="flex items-center justify-center">
							<ion-icon
								:icon="cafeOutline"
								class="w-[32px] h-[32px] p-4 bg-dark-blue rounded-full text-white"></ion-icon>
						</div>
						<div class="flex flex-col items-start text-dark-blue w-[208px]">
							<h3 class="font-sourcesanspro-semibold text-l2">4x - Cups</h3>
							<p class="font-sourcesanspro-regular text-base2">
								If we get the cups back we will refund money to you.
							</p>
						</div>
					</div>
				</TopAlert>

				<div class="relative px-[34px]">
					<ItemStandalone
						:icon="cafeOutline"
						title="2x - Coca Cola"
						subtitle="2.00€ / per cup"
						price="€ 4.00" />

					<ItemStandalone :icon="cafeOutline" title="1x - Fanta" subtitle="1.00€ / per cup" price="€ 1.00" />

					<ItemStandalone
						:icon="cafeOutline"
						title="1x - Red Bull"
						subtitle="1.50€ / per cup"
						price="€ 1.50" />
				</div>
			</div>
			<div class="flex w-full px-[34px] align-center mb-[34px]">
				<ion-button class="w-full" expand="full" size="large" shape="round">
					<div class="flex w-full justify-between items-center">
						€6.50 (4 Cups)
						<ion-icon :icon="bagHandleOutline" class="text-dark-blue" slot="end"></ion-icon>
					</div>
				</ion-button>
			</div>
		</div>
	</ion-content>
</template>

<style lang="scss" scoped></style>
