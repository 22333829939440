export enum BrowserType {
	EDGE = 'edge',
	SAMSUNG = 'samsung',
	CHROME = 'chrome',
	CHROMIUM_WEBVIEW = 'chromium-webview',
	FIREFOX = 'firefox',
	OPERA = 'opera',
	INTERNET_EXPLORER = 'ie',
	ANDROID = 'android',
	IOS = 'ios',
	SAFARI = 'safari',
	FACEBOOK = 'facebook',
	INSTAGRAM = 'instagram',
	IOS_WEBVIEW = 'ios-webview',
	OTHER = 'other',
}
