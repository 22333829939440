import { useQueryClient } from '@tanstack/vue-query';

const useQueryCacheHelper = () => {
	const queryClient = useQueryClient();

	// Let's start out by centralizing the removeCaches function
	// so that we make it easier to actually remove the caches later by properly scoping them by user id
	// should we want to
	const removeCaches = async () => {
		// Remove caches
		const keysToRemove = [
			// 'registrationForm',
			// 'event',
			// 'events',
			// 'eventTicketTypes',
			// 'eventTickets',
			// 'map',
			// 'maps',
			// 'post',
			'userProfile',
			'userProfileDetail',
			// 'rfidTags',
			// 'rfidWallet',
			// 'rfidSearch',
			// 'tableSpotProductCategories',
			// 'tableSpotProducts',
			// 'autoOpenSelfOrder',
			// 'shop',
			// 'shopCategory',
			// 'shopCategoriesWithProducts',
			// 'tagsList',
			// 'timeTable',
			// 'timeTables',
			// 'wallets',
			// 'walletRefundRequests',
			// 'userWallet',
			// 'walletTransactions',
			// 'walletTransaction',
			// 'walletQrCode',
			// 'refundInfo',
			// 'friends',
		];

		// TODO: we are not actually cancelling any calls that are already in progress
		// to do that we would have to call cancelQueries before removing the queries
		keysToRemove.forEach((key: string) => queryClient.removeQueries({ queryKey: [key] }));
	};

	// const authStore = useAuthStore();
	// const id = authStore.user.token;
	// console.log('id', id, authStore.guestOrUserId);
	//
	// // authStore.$subscribe((mutation, state) => {
	// // 	state.user.id
	// //
	// // })
	// watch(
	// 	() => authStore.guestOrUserId,
	// 	(newId, oldId) => {
	// 		console.log('id watcher called', { newId, oldId });
	// 		if (newId === oldId) return;
	// 		console.log(`New user id: ${newId}. Clearing queries for ${oldId}`);
	// 		queryClient.cancelQueries({ queryKey: [oldId] });
	// 		queryClient.removeQueries({ queryKey: [oldId] });
	// 	},
	// 	// { immediate: true },
	// );

	return {
		getScopedQueryKey: (scope: string | Array<string>) => {
			const key = Array.isArray(scope) ? scope : [scope];
			// return ['user', id, ...key];
			return key;
		},
		removeCaches,
	};
};

export default useQueryCacheHelper;
