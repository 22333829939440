<script setup lang="ts">
import router from '@/router';
import { useRoute } from 'vue-router';
import { IonContent, IonIcon } from '@ionic/vue';
import { chevronBackOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import ScanQR from '@/shared/components/ScanQR.vue';
import TopBar from '@/shared/components/TopBar.vue';

// Init
const { t } = useI18n();
const route = useRoute();

const onScan = async (scannedQrQcode: string) => {
	const urlObj = new URL(scannedQrQcode);
	const pathname = urlObj.pathname;
	const domain = urlObj.hostname;

	if (pathname.includes('activate')) {
		// Push to the route
		await router.push({
			path: pathname,
		});
	}

	if (domain === 'payconiq.com' && scannedQrQcode.includes('R=')) {
		const token = urlObj.searchParams.get('R');
		// Push to the route
		await router.push({
			path: '/activate/' + token,
		});
	}
};
</script>

<template>
	<ion-content>
		<div class="flex flex-col justify-between h-full">
			<TopBar class="px-[34px]">
				<template #left>
					<div class="flex items-center justify-start cursor-pointer">
						<router-link to="/rfid" class="flex items-center justify-center">
							<ion-icon :icon="chevronBackOutline" class="text-white"></ion-icon>
						</router-link>
					</div>
				</template>
			</TopBar>

			<div class="flex items-center justify-center">
				<ScanQR
					@on-scan-q-r="onScan"
					:start-camera="true"
					:permissionTitle="t('scan_qr.camera_permission_title')"
					:permissionDescription="t('scan_qr.camera_permission_description')"></ScanQR>
			</div>

			<div class="h-[200px]"></div>
		</div>
	</ion-content>
</template>

<style scoped></style>
