<script setup lang="ts">
import { onBeforeMount, onMounted, ref, watch } from 'vue';
import { RouterLink } from 'vue-router';
import router from '@/router';
import { IonContent, IonIcon } from '@ionic/vue';
import { chevronBackOutline, chevronDownOutline, closeOutline } from 'ionicons/icons';
import { DateTime } from 'luxon';

import CheckboxInput from '@/shared/components/sign-up-forms/CheckboxInput.vue';
import countries from '@/statics/countries.json';
import DateInputMask from '@/shared/components/sign-up-forms/DateInputMask.vue';
import FormStep from '@/shared/components/sign-up-forms/FormStep.vue';
import FormWizard from '@/shared/components/sign-up-forms/FormWizard.vue';
import hexRgb from 'hex-rgb';
import ImageInput from '@/shared/components/sign-up-forms/ImageInput.vue';
import ModalThree from '@/shared/components/ui/ModalThree.vue';
import RadioInput from '@/shared/components/sign-up-forms/RadioInput.vue';
import rgbHex from 'rgb-hex';
import SearchInput from '@/shared/components/sign-up-forms/SearchInput.vue';
import TextInput from '@/shared/components/sign-up-forms/TextInput.vue';
import TopBar from '@/shared/components/TopBar.vue';
import useAuth from '@/modules/auth/composables/useAuth';
import useRegistrationForm from '@/modules/auth/composables/useRegistrationForm';
import useStepChanger from '@/shared/composables/useStepChanger';
import useTenant from '@/shared/composables/useTenant';
import useUserProfile from '@/modules/profile/composables/useUserProfile';
import useUtils from '@/shared/composables/useUtils';

// Init
const { getTenantConfigAndStyles } = useTenant();
const { tenantConfig } = getTenantConfigAndStyles();
const { getRegistrationFormForAdditionalData } = useRegistrationForm();
const { registrationForm, registrationFormOriginal, validationSchema, userProfile } =
	getRegistrationFormForAdditionalData();
const { nextStep, previousStep, stepIndex } = useStepChanger();
const { updateProfile } = useUserProfile();
const { updateProfileMutate } = updateProfile();
const { getRedirect, setRedirect } = useAuth();
const isLoading = ref(true);
const { calculateShade } = useUtils();
const primary_color = window.primary_color;
const primaryRgb = hexRgb(window.primary_color, { format: 'array' });
const primaryShade = '#' + rgbHex(calculateShade(primaryRgb.toString(), 0.2));

// Modals
const modalState = ref(false);
const modalType = ref('');
const setModal = (state: boolean, type?: string) => {
	modalState.value = state;
	modalType.value = type === 'tos' ? 'tos' : 'pp';
};

// Reset step
onBeforeMount(() => {
	stepIndex.value = 0;
});

onMounted(() => {
	checkUser();
});

const checkUser = () => {
	if (userProfile.value) {
		isLoading.value = false;
		if (
			userProfile.value?.profile_completed ||
			(registrationForm.value && registrationForm.value.data && registrationForm.value.data.length == 0)
		) {
			// Get redirection from store
			// If set, it can be used to go back to callback page
			const redirect = getRedirect();
			if (redirect.callbackPage && redirect.id) {
				// reset redirection and redirect
				setRedirect({ callbackPage: '', id: '' });
				router.push({ path: `/${redirect.callbackPage}/${redirect.id}`, query: { continueLinking: 'true' } });
			} else {
				router.push('/dashboard');
			}
		}
	}
};

watch([userProfile, registrationForm], () => {
	if (userProfile.value && registrationForm.value) {
		checkUser();
	}
});

// Submit form
async function onSubmit(formData: FormData | any) {
	// Adding completed fields to formData
	registrationFormOriginal.value!.data.forEach((field) => {
		// field.is_active = show / hide
		if (field.is_active) {
			// Check if profile completed for this field
			const completed = !!userProfile.value?.[field.field_name];

			// If field is completed, add it to the formData
			// Don't send profile image, as when it already exists it is just a string
			// and the endpoint requires a file
			if (
				completed &&
				field.field_name !== 'profile_image' &&
				userProfile.value?.[field.field_name] != null &&
				userProfile.value?.[field.field_name] != 'null'
			) {
				formData[field.field_name] = userProfile.value?.[field.field_name];
			}
		}
	});

	if (formData.date_of_birth) {
		formData.date_of_birth = DateTime.fromFormat(formData.date_of_birth, 'dd-MM-yyyy').toFormat('yyyy-MM-dd');
	}

	await updateProfileMutate(formData);
	await router.push('/profile/additionalconfig');
}
</script>

<template>
	<ion-content :style="`background: linear-gradient(${primary_color}, ${primaryShade});`" class="flex">
		<ModalThree :is-open="modalState">
			<div class="flex w-full items-center justify-center mb-4">
				<div class="flex items-center justify-center w-[60px] h-[60px] rounded-full bg-anykrowd-gray/30 mb-4">
					<ion-icon
						class="text-[38px] text-black cursor-pointer"
						:icon="closeOutline"
						@click="setModal(false)"></ion-icon>
				</div>
			</div>
			<h1 class="font-sourcesanspro-semibold text-lg">
				{{ modalType === 'tos' ? 'Terms and conditions' : 'Privacy Policy' }}
			</h1>
			<div
				v-dompurify-html="
					modalType === 'tos' ? tenantConfig!.terms_and_conditions : tenantConfig!.privacy_policy
				"
				class="text-anykrowd-dark"></div>
		</ModalThree>

		<div class="flex flex-col h-full">
			<TopBar v-if="!isLoading && validationSchema && registrationForm" class="px-[34px]">
				<template #left>
					<div class="flex items-center justify-start cursor-pointer" @click="previousStep">
						<div v-if="stepIndex === 0"></div>
						<ion-icon v-if="stepIndex > 0" :icon="chevronBackOutline" class="text-white"></ion-icon>
					</div>
				</template>

				<template #center>
					<button
						type="button"
						v-for="(step, index) in validationSchema"
						class="step-line bg-white mx-1"
						:class="[{ 'background-white-15': index > stepIndex }]"
						:key="index"></button>
				</template>

				<template #right>
					<div
						v-if="registrationForm?.data.length && !registrationForm?.data[stepIndex].required"
						class="font-sourcesanspro-regular text-base">
						<router-link to="" @click="nextStep">Skip</router-link>
					</div>
				</template>
			</TopBar>
			<div v-if="isLoading" class="flex items-center justify-center h-full">
				<ion-spinner name="dots"></ion-spinner>
			</div>
			<div v-if="!isLoading && validationSchema && registrationForm" class="h-full px-[34px] pt-6 pb-20">
				<FormWizard
					class="height-inherit"
					:validation-schema="validationSchema"
					:last-step-index="validationSchema.length - 1"
					:currentStep="stepIndex"
					@on-go-next-step="nextStep"
					@submit="onSubmit"
					v-slot="slotProps">
					<FormStep
						v-for="(field, index) in registrationForm?.data"
						:key="index"
						:index="index"
						:currentStep="stepIndex"
						class="flex flex-col height-inherit">
						<div>
							<!-- <p class="font-sourcesanspro-regular text-base text-color-magenta text-center">
                <span>{{ field.field_label }}</span>
              </p> -->
							<!-- <h1 class="mb-4 font-sourcesanspro-semibold text-white text-lg text-center">
                {{ $t('auth.sign_up_additional_page.step_one.title') }}
              </h1> -->
							<h1 class="mb-4 font-sourcesanspro-semibold text-white text-lg text-center">
								{{ field.field_label }}
							</h1>
						</div>
						<div class="flex flex-col grow justify-center mb-8">
							<div class="mb-4">
								<TextInput
									wrapper-class="flex flex-wrap justify-left item-center mb-2 border border-solid focus-within:background-white-15"
									:name="field.field_name"
									:placeholder="field.field_label"
									:show-error-message="true"
									:show-input-success="true"
									:value="slotProps.values[field.field_name]"
									type="text"
									v-if="
										(field.type === 'text' || field.type === 'tel') &&
										!['country'].includes(field.field_name)
									" />

								<div v-if="field.type === 'password'">
									<TextInput
										wrapper-class="flex flex-wrap justify-left item-center mb-2 border border-solid focus-within:background-white-15"
										:name="field.field_name"
										:placeholder="field.field_label"
										:show-error-message="true"
										:show-input-success="true"
										:value="slotProps.values[field.field_name]"
										type="password" />
									<TextInput
										wrapper-class="flex flex-wrap justify-left item-center mb-2 border border-solid focus-within:background-white-15"
										name="password_confirmation"
										:placeholder="field.field_label"
										:show-error-message="true"
										:show-input-success="true"
										:value="slotProps.values['password_confirmation']"
										type="password" />
								</div>

								<RadioInput
									:name="field.field_name"
									:values="field.options"
									:value="slotProps.values[field.field_name]"
									v-if="field.type === 'select'" />

								<div
									v-if="
										field.type === 'checkbox' &&
										field.field_name === 'terms_and_conditions_accepted'
									">
									<CheckboxInput
										container-class="justify-center"
										id="terms_and_conditions_accepted"
										name="terms_and_conditions_accepted"
										type="checkbox">
										<label
											for="terms_and_conditions_accepted"
											class="font-sourcesanspro-regular text-small text-color-magenta text-left">
											{{ $t('auth.sign_up_additional_page.step_six.terms') }}
											<a
												@click="setModal(true, 'tos')"
												target="_blank"
												class="text-white underline cursor-pointer">
												{{ $t('auth.sign_in_home_page.terms_of_use') }}
											</a>
										</label>
									</CheckboxInput>
								</div>

								<div
									v-if="field.type === 'checkbox' && field.field_name === 'privacy_policy_accepted'"
									class="mb-[100px]">
									<CheckboxInput
										container-class="justify-center"
										id="privacy_policy_accepted"
										name="privacy_policy_accepted"
										type="checkbox"
										label="">
										<label
											for="privacy_policy_accepted"
											class="font-sourcesanspro-regular text-small text-color-magenta text-left">
											{{ $t('auth.sign_up_additional_page.step_six.terms') }}
											<a
												@click="setModal(true, 'pp')"
												target="_blank"
												class="text-white underline cursor_pointer">
												{{ $t('auth.sign_in_home_page.terms_privacy_policy') }}
											</a>
										</label>
									</CheckboxInput>
								</div>

								<DateInputMask
									wrapper-class="flex flex-wrap justify-left item-center mb-3 border border-solid focus-within:background-white-15"
									customInputClass="justify-start"
									:name="field.field_name"
									:value="slotProps[field.field_name]"
									type="date"
									v-if="field.type === 'date'"></DateInputMask>

								<SearchInput
									:name="field.field_name"
									:placeholder="$t('auth.sign_up_additional_page.step_four.placeholder')"
									:data="countries.map((country) => country.name)"
									:icon="chevronDownOutline"
									:value="slotProps.values[field.field_name]"
									v-if="field.field_name === 'country'" />

								<ImageInput
									wrapper-class="flex flex-col items-center justify-center"
									:name="field.field_name"
									:placeholder="field.field_label"
									:show-error-message="false"
									:show-input-success="false"
									:value="slotProps.values[field.field_name]"
									type="text"
									v-if="field.field_name === 'profile_image'" />
							</div>
							<!-- <p class="font-sourcesanspro-regular text-base text-color-magenta text-center">
                <span>{{ $t('auth.sign_up_additional_page.step_one.message') }}</span>
              </p> -->
						</div>
					</FormStep>
				</FormWizard>
			</div>
		</div>
	</ion-content>
</template>

<style lang="scss" scoped>
p,
h1 {
	width: 100%;
}

ion-checkbox {
	margin-right: 10px;
}

ion-list,
ion-item {
	--background: transparent;
	background: transparent !important;
}

ion-button.go-back-button {
	--background: transparent;
	--box-shadow: none;
	height: 100%;
	margin: 0;
	width: initial;
}

.step-line {
	height: 3px;
	width: 24px;
}
</style>
