<script setup lang="ts">
import vue from 'vue';
import { Form, useForm } from 'vee-validate';
import * as yup from 'yup';
import TextInput from '@/shared/components/sign-up-forms/TextInput.vue';

// Props
const props = defineProps({
	cellPhoneNumber: { type: String as () => string | null | undefined, default: null },
});

// Init
const emit = defineEmits(['onCellPhoneNumberSent']);

// Schema
const validationSchema = yup.object({
	phoneNumber: yup.string().required(),
});

// useForm
const { handleSubmit, meta, validateField } = useForm({
	validationSchema: validationSchema,
});

// On input change
const onInputChange = async (formValid: vue.ComputedRef<boolean>, phoneNumber: any) => {
	await validateField(phoneNumber.value);
};

// On submit
const onSubmit = handleSubmit(async (formData: any) => {
	emit('onCellPhoneNumberSent', formData.phoneNumber);
});
</script>

<template>
	<form @submit="onSubmit" class="flex w-full" :validation-schema="validationSchema">
		<TextInput
			wrapper-class="flex flex-wrap justify-left item-center mb-2 border border-solid focus-within:background-white-15"
			name="phoneNumber"
			type="text"
			label="Phone Number"
			:placeholder="$t('auth.sign_up_additional_page.step_six.phone')"
			:value="props.cellPhoneNumber"
			@inputChanged="onInputChange" />
	</form>
</template>
