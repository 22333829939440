import { computed, ref, watch } from 'vue';
import { useQuery } from '@tanstack/vue-query';

import { ApiMap } from '@/shared/interfaces/map';
import { anykrowdApi } from '@/api/anykrowdApi';
import useStorageService from '@/shared/composables/useStorageService';

const { get, set } = useStorageService();

// getMapsApiCall
const getMapsApiCall = async (): Promise<ApiMap[]> => {
	const { data } = await anykrowdApi.get<any>('/maps', false);
	return data.data;
};

// getMaps
const getMaps = () => {
	// Init
	const maps = ref<ApiMap[]>()!;

	const {
		data: mapsData,
		isFetched,
		refetch: refetchMaps,
		remove: removeMapsDataFromCache,
	} = useQuery({
		queryKey: ['maps'],
		queryFn: () => getMapsApiCall(),
		retry: 3,
	});

	// Watch mapsData
	watch(
		mapsData,
		() => {
			if (mapsData.value) {
				maps.value = [...mapsData.value];

				// Update cache
				set('maps', mapsData.value);
			}
		},
		{ immediate: true },
	);

	// Return
	return {
		isFetchedMaps: computed(() => isFetched.value),
		maps,
		refetchMaps,
		removeMapsDataFromCache,
	};
};

// Get Map api call
const getMapApiCall = async (id: number): Promise<ApiMap> => {
	const { data } = await anykrowdApi.get<ApiMap>(`/maps/${id}`, false);
	return data;
};

// Get Map
const getMap = (mapId: number) => {
	// Init
	const map = ref<ApiMap>()!;

	const {
		data: mapData,
		isFetched,
		refetch: refetchMap,
		remove: removeMapDataFromCache,
	} = useQuery({
		queryKey: ['map', mapId],
		queryFn: () => getMapApiCall(mapId),
		retry: 3,
	});

	// Watch mapData
	watch(
		mapData,
		() => {
			if (mapData.value) {
				map.value = { ...mapData.value };

				// Update cache
				const key = ['map', mapId].join('-');
				set(key, mapData.value);
			}
		},
		{ immediate: true },
	);

	return {
		map,
		isFetchedMap: computed(() => isFetched.value),
		refetchMap,
		removeMapDataFromCache,
	};
};

// useMaps
const useMaps = () => {
	return {
		getMap,
		getMapApiCall,
		getMaps,
		getMapsApiCall,
	};
};

export default useMaps;
