<script setup lang="ts">
import { ref } from 'vue';
import { IonButton } from '@ionic/vue';
import router from '@/router';
import { DateTime } from 'luxon';
import { useI18n } from 'vue-i18n';

import FavoriteButton from '@/shared/components/FavoriteButton.vue';
import ShareButton from '@/shared/components/ShareButton.vue';

// Props
const props = defineProps({
	canceledAt: { type: String as () => string | Date | null },
	isEvent: { type: Boolean as () => boolean | undefined },
	happeningNow: { type: Boolean as () => boolean | undefined },
	happeningSoon: { type: Boolean as () => boolean | undefined },
	date: { type: String as () => string | undefined },
	favorite: { type: Boolean as () => boolean | undefined, required: true, default: false },
	id: { type: Number as () => number | undefined, required: true },
	image: { type: String, required: true, default: '' },
	link: { type: String, required: true },
	locationAddress: { type: String as () => string | null | undefined },
	summary: { type: String as () => string | null | undefined, required: true, default: '' },
	title: { type: String, required: true },
	type: { type: String, required: true },
	showLoggedData: { type: Boolean, required: true, default: true },
});

// Init
const { t } = useI18n();
const emit = defineEmits(['onFavoriteClicked']);

const favoriteClicked = (type: 'event' | 'post', id: number) => {
	emit('onFavoriteClicked', type, id);
};

const goTo = async (link: string) => {
	await router.push(link);
};
</script>

<template>
	<div class="flex items-center justify-center mb-4 backdrop-blur-2xl bg-black/50 rounded-lg">
		<div class="w-full">
			<div v-if="props.image" class="relative w-full aspect-[16/9] overflow-hidden">
				<div v-if="props.showLoggedData" class="absolute flex right-[10px]">
					<FavoriteButton
						@on-favorite-clicked="favoriteClicked"
						:id="props.id"
						:favorite="props.favorite ? props.favorite : false"
						:type="props.type"></FavoriteButton>
					<ShareButton
						:id="props.id"
						:title="props.title"
						:type="props.type.includes('event') ? 'events' : 'news'"></ShareButton>
				</div>
				<img :src="props.image" class="w-full h-full rounded-t-lg object-cover object-center" />
			</div>
			<div class="flex flex-col p-4" @click="goTo(props.link)">
				<div class="divide-y divide-white/10">
					<div>
						<h1 class="text-l2">
							<span v-if="props.isEvent && props.canceledAt === null && props.happeningNow">
								{{ t('events.live_now') }}:
							</span>
							<span
								v-if="
									props.isEvent &&
									props.canceledAt === null &&
									!props.happeningNow &&
									props.happeningSoon
								">
								{{ t('events.soon') }}:
							</span>
							<span
								v-if="
									props.isEvent &&
									props.canceledAt === null &&
									!props.happeningNow &&
									!props.happeningSoon
								">
								{{ t('events.upcoming') }}:
							</span>
							<span v-if="props.isEvent && props.canceledAt !== null"> {{ t('events.canceled') }}: </span>
							{{ props.title }}
						</h1>
						<div class="pb-2" v-if="props.type === 'event_now'">
							<div v-if="props.date" class="flex items-center">
								<span class="font-sourcesanspro-regular text-xs2 text-white/30">
									{{ DateTime.fromFormat(props.date, 'yyyy-MM-dd HH:mm:ss').toFormat('dd/MM/yyyy') }}
									-
									{{ props.locationAddress }}
								</span>
							</div>
						</div>
					</div>
					<div class="pt-2 mb-6">
						<p class="font-sourcesanspro-regular text-sm2 text-white/60">
							{{ props.summary }}
						</p>
					</div>
				</div>

				<div class="flex items-center justify-center">
					<ion-button class="grow h-[36px] text-base2" color="primary" fill="outline" shape="round">
						{{ t('components.feed_item.view_details') }}
					</ion-button>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped></style>
