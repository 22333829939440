/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */

// import { convertValutaSync } from 'src/app/core/helpers/customValuta.helpers';

// import { TenantConfiguration } from './AppConfiguration';

export enum DiscountType {
	PERCENTAGE = 'percentage',
	FIXED = 'fixed',
}

export class Discount {
	id: number;
	name: string;
	type: DiscountType;
	amount: number;
	discountable_type: string;
	discountable_id: number;

	constructor(data: {
		id: number;
		name: string;
		type: DiscountType;
		amount: number;
		discountable_type: string;
		discountable_id: number;
	}) {
		Object.assign(this, data);
		this.id = data.id;
		this.name = data.name;
		this.type = data.type;
		this.amount = data.amount;
		this.discountable_type = data.discountable_type;
		this.discountable_id = data.discountable_id;
	}

	public applyDiscount(price: number): number {
		switch (this.type) {
			case DiscountType.PERCENTAGE:
				return price - Math.round(price * this.amount) / 100;
			case DiscountType.FIXED:
				return Math.max(price - this.amount, 0); // Return either the discounted price or 0, whichever is bigger, as we can't have a negative price after discount
			default:
				return price;
		}
	}

	public getDiscountAmount(price: number): number {
		switch (this.type) {
			case DiscountType.PERCENTAGE:
				return Math.round(price * this.amount) / 100;
			case DiscountType.FIXED:
				return Math.min(this.amount, price); // Return either the discounted price or 0, whichever is bigger, as we can't have a negative price after discount
			default:
				return 0;
		}
	}

	// public getDisplay(tenantConfig: TenantConfiguration): string|null {
	//   switch (this.type) {
	//     case DiscountType.PERCENTAGE:
	//       return '- ' + this.amount + '%';
	//     case DiscountType.FIXED:
	//       if (tenantConfig.custom_valuta_enabled) {
	//         return '- ' + tenantConfig.custom_valuta.prefix + convertValutaSync(tenantConfig, this.amount, true);
	//       }

	//       return '- €' + this.amount;
	//     default:
	//       return null;
	//   }
	// }
}

export interface DiscountableContract {
	discount?: Discount;
	discountedPrice(): number | null;
	actualPrice(): number;
	getFullPrice(): number;
}

export abstract class Discountable implements DiscountableContract {
	public discount?: Discount;

	constructor(data: any) {
		if (!data) {
			return;
		}

		Object.assign(this, data);

		if (data.discount) {
			this.discount = new Discount(data.discount);
		}
	}

	public discountedPrice(): number | null {
		if (!this.discount) {
			return null;
		}

		return this.discount.applyDiscount(this.getFullPrice());
	}

	public actualPrice(): number {
		const fullPrice = this.getFullPrice();

		if (!this.discount) {
			return fullPrice;
		}

		return fullPrice - this.discount.getDiscountAmount(this.getFullPrice());
	}

	abstract getFullPrice(): number;
}
