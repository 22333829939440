<script setup lang="ts">
import router from '@/router';
const props = defineProps({
	class: { type: String },
	link: { type: String, required: true, default: '/dashboard' },
	name: { type: String, required: true },
	type: { type: String, required: true },
	venue: { type: String, required: true },
});

const goTo = async (link: string) => {
	await router.push(link);
};
</script>

<template>
	<div class="flex ticket-item w-[325px] h-[100px]" :class="props.class" @click="goTo(props.link)">
		<div class="flex p-3 w-[75px] items-center justify-center">
			<img src="@/statics/svg-delete-after-implement/qr-code.svg" class="w-[50px] h-[50px]" />
		</div>
		<div class="flex grow flex-col p-3 text-dark-blue">
			<div>
				<span class="font-sourcesanspro-semibold text-base2">{{ props.name }}</span>
			</div>
			<div class="flex justify-between leading-none">
				<span class="font-sourcesanspro-bold text-xs2 text-dark-blue/70">TYPE: </span>
				<span class="font-sourcesanspro-regular text-xs2 text-dark-blue/70">{{ props.type }}</span>
			</div>
			<div class="flex items-baseline">
				<span class="font-sourcesanspro-bold text-xs2 text-dark-blue/70">VENUE: </span>
				<span class="font-sourcesanspro-regular text-xs2 text-dark-blue/70 text-right leading-none">{{
					props.venue
				}}</span>
			</div>
		</div>
	</div>
</template>

<style scoped>
.ticket-item {
	background: url('@/statics/svg/ticket-item.svg') no-repeat;
	background-size: 100%;
	position: relative;
}
</style>
