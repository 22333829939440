// Init
import { defineStore } from 'pinia';
import { hashString } from '@/shared/composables/useDebugTools';
import config from '@/config/native-app.config.json';

export const useDebugStore = defineStore('debug', {
	state: () => ({
		debugActive: false,
		numDebugClicks: 0,
	}),
	actions: {
		async incrementDebugClicks() {
			this.numDebugClicks++;
			if (this.numDebugClicks > 4) {
				if (
					(await hashString(config.DEBUG_TOOLS_ENABLED)) ===
					'0e23867f9e7a02b22a397212e747360647c7f22b9a794b8c799aeddcf4389f4e'
				) {
					console.log('Toggling debug tools!');
					this.toggleDebug();
					this.numDebugClicks = 0;
				}
			}
		},
		toggleDebug() {
			this.debugActive = !this.debugActive;
		},
		debugEnabled() {
			return this.debugActive;
		},
	},
	persist: true, // will just persist in local storage and that is fine for this store
});
