<script setup lang="ts">
import { ref, onMounted, toRef, watch } from 'vue';
import { IonButton, IonIcon } from '@ionic/vue';
import { useField } from 'vee-validate';
import { cameraOutline } from 'ionicons/icons';

import useCamera from '@/shared/composables/useCamera';
import useUtils from '@/shared/composables/useUtils';

const props = defineProps({
	iconLeft: { type: String, default: '' },
	inputClass: { type: String, default: 'font-sourcesanspro-regular text-base' },
	label: { type: String },
	name: { type: String, required: true },
	placeholder: { type: String, default: '' },
	showErrorMessage: { type: Boolean, default: true },
	showInputSuccess: { type: Boolean, default: true },
	successMessage: { type: String, default: '' },
	type: { type: String, default: 'text' },
	value: { type: Object as () => Blob, default: null },
	wrapperClass: { type: String, default: '' },
});

// Init
const emit = defineEmits(['inputChanged']);
const { cameraGetPhoto, photo } = useCamera();
const { dataURLtoBlob, convertBlobToBase64 } = useUtils();
const profileImage = ref('');

// onMountedHook
onMounted(async () => {
	if (props.value) {
		profileImage.value = await convertBlobToBase64(props.value);
	}
});

// Handle password visibility
const type = ref(props.type);
const showPassword = ref(false);
const swapPasswordVisibility = () => {
	type.value = type.value === 'password' ? 'text' : 'password';
	showPassword.value = !showPassword.value;
};

// use `toRef` to create reactive references to `name` prop which is passed to `useField`
// this is important because vee-validte needs to know if the field name changes
// https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
const name = toRef(props, 'name');

// we don't provide any rules here because we are using form-level validation
// https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
const { handleChange } = useField(name, undefined, {
	initialValue: props.value,
});

// getPhoto
const getPhoto = async () => {
	await cameraGetPhoto();

	watch(photo, async () => {
		if (photo.value) {
			profileImage.value = photo.value;
			const blob = dataURLtoBlob(photo.value);
			handleChange(blob);
		}
	});
};
</script>

<template>
	<div class="flex mb-4" :class="wrapperClass">
		<input :name="props.name" type="file" accept="image/*;capture=camera" hidden />

		<ion-button fill="outline" shape="round" @click="getPhoto()" color="primary">
			<span class="font-sourcesanspro-semibold text-base">{{ $t('scan_qr.use_camera') }}</span>
			<ion-icon class="cursor-pointer" :icon="cameraOutline" slot="end"></ion-icon>
		</ion-button>
	</div>
	<img :src="profileImage" />
</template>

<style lang="scss" scoped>
ion-label {
	color: rgb(255 255 255 / 0.5) !important;
	font-size: 12px !important;
}

div.wrapper {
	border-radius: 9999px;
	height: 52px;

	p {
		&::first-letter {
			text-transform: capitalize;
		}
	}

	ion-icon {
		cursor: pointer;
	}

	// .border-success input {
	//   background-color: var(--success-bg-color);
	//   color: var(--success-color);
	// }

	&.border-success {
		border: 1px solid var(--clientx-color-success) !important;

		ion-icon {
			color: var(--clientx-color-success);
		}
	}
}
</style>
